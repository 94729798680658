import { alpha, Box, Container, Icon, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import feimg from "../images/features.png";
import "./style.css";
import Grid from '@mui/material/Unstable_Grid2';

const PREFIX = 'WpoFeatures';

const classes = {
  root: `${PREFIX}-root`,
  featuresItem2: `${PREFIX}-featuresItem2`,
  featuresItem: `${PREFIX}-featuresItem`,
  icon: `${PREFIX}-icon`,
  body: `${PREFIX}-body`,
  title: `${PREFIX}-title`,
  featureImg: `${PREFIX}-featureImg`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    background: theme.palette.background.paper,
    "& .wpo-section-title h2": {
      color: theme.palette.text.primary,
    },
    "& .wpo-features-item .fi": {
      color: theme.palette.primary.main,
    },
    "& .wpo-features-icon": {
      border: "6px solid",
      borderColor: alpha(theme.palette.common.white, 0.8),
      background: theme.palette.primary.main,
    },
    "& .wpo-features-text h3": {
      color: theme.palette.text.primary,
    },
  },

  [`& .${classes.featuresItem2}`]: {
    textAlign: "right",
    [theme.breakpoints.down('lg')]: {
      textAlign: "center",
    },
  },

  [`& .${classes.featuresItem}`]: {
    textAlign: "left",
    [theme.breakpoints.down('lg')]: {
      textAlign: "center",
    },
  },

  [`& .${classes.icon}`]: {
    fontFamily: "Material Icons Outlined",
    fontSize: 50,
    verticalAlign: "middle",
  },

  [`& .${classes.title}`]: {
    marginTop: 0,
    marginBottom: theme.spacing(1)
  },
  [`& .${classes.body}`]: {
    marginTop: 0,
  },

  [`& .${classes.featureImg}`]: {
    display: "block",
    [theme.breakpoints.down('lg')]: {
      display: "none",
    },
  }
}));

const WpoFeatures = () => {
  const { t } = useTranslation("home");

  const sections = t("home:featureSections", { returnObjects: true });

  return (
    <Root id="features" className={clsx(classes.root, "wpo-features-area")}>
      <Container>
        <Box sx={{ textAlign: "center" }} className="wpo-section-title">
          <span>{t("home:featuresSubtitle")}</span>
          <Typography variant="h2" color={"text.primary"} mb={6}>{t("home:featuresTitle")}</Typography>
        </Box>

        <Grid container spacing={2} sx={{ position: "relative" }}>
          <Grid xs={12} md={6} lg={4}>
            <div className={classes.featuresItem2}>
              {sections.slice(0, Math.ceil(sections.length / 2)).map((section, i) => (
                <Section
                  key={i}
                  icon={section.icon}
                  title={section.title}
                  body={section.body}
                />
              ))}
            </div>
          </Grid>
          <Grid xs={12} md={6} lg={4} className={classes.featureImg}>
            <div className="wpo-features-item">
              <div className="wpo-feature-img">
                <img src={feimg} alt="" />
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <div className={classes.featuresItem}>
              {sections.slice(Math.ceil(sections.length / 2)).map((section, i) => (
                <Section
                  key={i}
                  icon={section.icon}
                  title={section.title}
                  body={section.body}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default WpoFeatures;

const Section = (props) => {
  const { icon, title, body } = props;

  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="wpo-feature-wrap">
        <div className="wpo-features-icon">
          <Icon className={classes.icon}>{icon}</Icon>
        </div>
        <div className="wpo-features-text">
          <h3 className={classes.title}>{title}</h3>
          <p className={classes.body}>{body}</p>
        </div>
      </div>
    </div>
  );
};
