/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon, Stack } from "@mui/material";
import { Edit } from "@mui/icons-material";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { SHIPPING_SERVICE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import ServiceBranchesTable from './Components/ServiceBranchesTable'
const PREFIX = 'ShippingServiceView';

const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  paperTitle: `${PREFIX}-paperTitle`,
};

const Root = styled(Stack)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.paperTitle}`]: {
    justifyContent: "center",
    background: `${theme.palette.background.appTitle} !important`
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const ShippingServiceView = (props) => {
  const shippingServiceId = props.match.params.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${SHIPPING_SERVICE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(shippingServiceId) },
    }
  );
  const ifSeparatedBranches = Globals.settings.separatedBranches
  const user = Globals.user;
  const branchId = data?.shippingService?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit = user.hasPermission("shipping.shipping_service.update")
    ? canAccessBranch
    : false;
  const shipmentTypes = data?.shippingService?.shipmentTypes
    ?.map((i) => i.name)
    .toString()
    .replace(/,/g, " , ");

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/shipping-services/${shippingServiceId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ]
  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.shippingService ?
      <NotFound />
      :
      <>
        <Fragment>
          <TitleAppBar path={props.match.path} >
            <LongMenu icons={icons} />
          </TitleAppBar>
        </Fragment>
        <Root
          spacing={2}
          p={2}
          justifyContent="center"
        >
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("code")}
              value={data?.shippingService?.code}
            />
            <KeyValuePair
              title={t("name")}
              value={data?.shippingService?.name}
            />
            <KeyValuePair
              title={t("active")}
              value={
                data?.shippingService?.active ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("shippingDays")}
              value={data?.shippingService?.days}
            />
            <KeyValuePair
              title={t("branch")}
              value={data?.shippingService?.branch?.name}
            />
            {!ifSeparatedBranches && <KeyValuePair
              title={t("deliveryAccount")}
              value={data?.shippingService?.deliveryAccount?.name}
            />}
            {!ifSeparatedBranches && <KeyValuePair
              title={t("collectionAccount")}
              value={data?.shippingService?.collectionAccount?.name}
            />}
            {!ifSeparatedBranches && <KeyValuePair
              title={t("commissionAccount")}
              value={data?.shippingService?.commissionAccount?.name}
            />}
            <KeyValuePair title={t("shipmentType")} value={shipmentTypes} />
            {Globals.settings.eInvoicing && <KeyValuePair
              title={t("egsUnitOfMeasure")}
              value={data?.shippingService?.egsUnitOfMeasure}
            />}
            {Globals.settings.eInvoicing && <KeyValuePair
              title={t("egsCode")}
              value={data?.shippingService?.egsCode}
            />}
          </Paper>
          {ifSeparatedBranches && <ServiceBranchesTable
            data={data.shippingService.serviceBranches}
            classes={classes}
          />}
        </Root>
      </>

};

export default ShippingServiceView;
