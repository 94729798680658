import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../../HOC/FunctionComponents/LoadingPages/ButtonLoading";
// import { shipmentFields } from "../Shipments/Graphql"
export default function SelectColumView(props) {
  const { disabled, openViewColum, setOpenViewColum, localStorageName, initialData, setLocalDataKey, shipmentFields } = props;
  const [shipmentFieldsState, setShipmentFieldsState] = useState([]);
  const { t } = useTranslation();
  const icon = !Boolean(setOpenViewColum)
  useEffect(() => {
    const queryShipmentFields = shipmentFields;
    if (!queryShipmentFields[0].id)
      queryShipmentFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });
    const localStorageViewShipment = localStorage.getItem(localStorageName);
    const parsedViewShipment = localStorageViewShipment
      ? JSON.parse(localStorageViewShipment)
      : initialData;
    const notCheckedFields = queryShipmentFields.filter((i) => {
      if (
        !parsedViewShipment?.includes(i.key) &&
        parsedViewShipment !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = queryShipmentFields.filter((i) => {
      if (parsedViewShipment?.includes(i.key)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedViewShipment?.indexOf(a.key) >
        parsedViewShipment?.indexOf(b.key)
        ? 1
        : -1;
    });
    setShipmentFieldsState(
      [...sortedFields, ...notCheckedFields]

    );
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openViewColum]);
  const [dialogState, setDialogState] = useState(false);

  const fieldCount = shipmentFieldsState.length;
  const fieldSelected = shipmentFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fields = [...shipmentFieldsState];
    fields.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setShipmentFieldsState(fields);
  };


  const onCloseDialog = () => {
    icon ? setDialogState(false) : setOpenViewColum(false)
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(shipmentFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setShipmentFieldsState(items);
  }
  const handelViewShipment = () => {

    const updatedFields = shipmentFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.key);
      }
      return previous;
    }, []);
    localStorage.setItem(localStorageName, JSON.stringify(updatedFields));
    setLocalDataKey([])
    setLocalDataKey(updatedFields)
    onCloseDialog()

  };
  return (
    <>
      {icon && <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip>}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={icon ? dialogState : openViewColum}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("viewColums")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={fieldSelected === 0}
              onClick={() => {
                handelViewShipment();
              }}>

              {<ButtonLoading /> && t("save")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {shipmentFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.key}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={shipmentFieldsState}
                            setState={setShipmentFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  const { t } = useTranslation();
  // const [checkboxState,setCheckboxState] = React.state(checked)
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={t(label)} />
    </ListItem>
  );
};
export const renderData = (shipmentLocalKey, setShipmentListDetails, shipmentFieldsQuery) => {
  const shipmentFieldsState = shipmentFieldsQuery.filter(obj => shipmentLocalKey.includes(obj.key));
  const sortedFields = shipmentFieldsState.sort((a, b) => {
    return shipmentLocalKey?.indexOf(a.key) >
      shipmentLocalKey?.indexOf(b.key)
      ? 1
      : -1;
  });
  const shipmentTable = sortedFields
    .map((i) => (i.key));
  const shipmentField = sortedFields
    .map((i) => (i.name));
  const shipmentTableHead = sortedFields
    .map((i) => (i.label));
  if (shipmentTable.includes("senderZone")) {
    const indexCode = shipmentTable.indexOf("senderZone");
    const element = { senderSubzone: ['name'] }
    shipmentField.splice(indexCode, 0, element);
  }
  if (shipmentTable.includes("recipientZone")) {
    const indexCode = shipmentTable.indexOf("recipientZone");
    const element = { recipientSubzone: ['name'] }
    shipmentField.splice(indexCode, 0, element);
  }
  if (shipmentTable.includes("recipient")) {
    const indexCode = shipmentTable.indexOf("recipient");
    shipmentField.splice(indexCode, 0, "recipientPhone");
    shipmentField.splice(indexCode + 1, 0, "recipientMobile");
  }
  if (shipmentTable.includes("recipientPhone")) {
    const indexCode = shipmentTable.indexOf("recipientPhone");
    shipmentField.splice(indexCode, 0, "recipientPhone");
    shipmentField.splice(indexCode + 1, 0, "recipientMobile");
  }
  if (shipmentTable.includes("sender")) {
    const indexCode = shipmentTable.indexOf("sender");
    shipmentField.splice(indexCode, 0, "senderPhone");
    shipmentField.splice(indexCode + 1, 0, "senderMobile");
  }
  if (shipmentTable.includes("senderPhone")) {
    const indexCode = shipmentTable.indexOf("senderPhone");
    shipmentField.splice(indexCode, 0, "senderPhone");
    shipmentField.splice(indexCode + 1, 0, "senderMobile");
  }
  setShipmentListDetails({
    shipmentQueryFields: shipmentField,
    shipmentTableBody: shipmentTable,
    shipmentTableHead: shipmentTableHead,
  })

};



// const getTableBody = (shipment, tableShipment, index) => {
//   if (tableShipment === "code") {
//     return <CellLink
//       key={index}
//       className={classes.shipmentTable_bodyCode}
//       pathname={`/admin/shipments/${shipment.id}`}>
//       {shipment[tableShipment]}
//     </CellLink>

//   }

//   if (["originBranch", "branch"].includes(tableShipment)) {
//     return <CellLink key={index} pathname={`/admin/branches/${shipment[tableShipment]["id"]}`}>
//       {shipment[tableShipment]["name"]}
//     </CellLink>
//   }
//   if (tableShipment === "customer") {
//     return <CellLink key={index} pathname={`/admin/customers/${shipment.customer?.id}`}>
//       <Box
//         component="span"
//         fontWeight="bold"
//       >{` (${shipment.customer.code}) `}</Box>
//       <Box key={index} component="span">  {shipment[tableShipment]["name"]}</Box>
//     </CellLink>
//   }
//   if (["senderZone", "recipientZone"].includes(tableShipment)) {
//     return <ShipmentRegion
//       key={index}
//       pathname={`/admin/zones/${shipment[tableShipment]["id"]}`}
//       zone={shipment[tableShipment]["name"]}
//       subzone={(tableShipment === "senderZone") ? shipment.senderSubzone.name : shipment.recipientSubzone.name}
//     />
//   }
//   if (tableShipment === "lastDeliveryAgent") {
//     return <CellLink
//       key={index}
//       pathname={`/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`}
//     >
//       {shipment.lastDeliveryAgent?.name}
//     </CellLink>
//   }
//   if (["collected", "paidToCustomer", "cancelled", "inWarehouse", "hasProducts"].includes(tableShipment)) {
//     return <FixedTableCell key={index}>
//       {shipment[tableShipment] ? (
//         <Icon className={classes.iconColor}>
//           check_circle_outline
//         </Icon>
//       ) : (
//         <Icon color="error">highlight_off</Icon>
//       )}
//       {/* {shipment[tableShipment] === false ? t("no") : t("yes")} */}
//     </FixedTableCell>
//   }
//   if (tableShipment === "customerDueCredit") {
//     if (shipment?.customerDue >= 0) {
//       return <FixedTableCell key={index}>{formatMoney(shipment?.customerDue)}</FixedTableCell>
//     } else { return <FixedTableCell key={index}>0</FixedTableCell> }
//   }

//   if (tableShipment === "customerDueDebit") {
//     if (shipment?.customerDue < 0) {
//       return <FixedTableCell key={index}>
//         {formatMoney(Math.abs(shipment?.customerDue))}
//       </FixedTableCell>
//     } else {
//       return <FixedTableCell key={index}>0</FixedTableCell>
//     }
//   }
//   if (tableShipment === "netCollection") {
//     return <FixedTableCell key={index}>
//       {formatMoney(shipment?.pendingCollectionAmount - shipment?.deliveryCommission)}
//     </FixedTableCell>
//   }
//   if (["price", "deliveredAmount", "allDueFees", "customerDue", "amount", "totalAmount", "deliveryCommission", "netCollection", "collectedFees", "returningDueFees", "pendingCollectionAmount"].includes(tableShipment)) {
//     return <FixedTableCell key={index}>
//       {/* {functionSum(i,index)} */}
//       {formatMoney(shipment[tableShipment])}</FixedTableCell>
//   }
//   if (tableShipment === "status") {
//     return <TableCellColor key={index}
//       className={`${tableBodyCell.includes("code") ? classes.shipmentTable_bodyStatus : classes.shipmentTable_bodyCode}`}
//       shipmentStatus={shipment[tableShipment]}
//       shipmentDeliveryType={shipment.deliveryType} />
//   }
//   if (tableShipment === "lastTransactionDate") {
//     return <FixedTableCell key={index} >
//       {dateFormatLL(shipment?.lastTransaction.date)}
//     </FixedTableCell>
//   }
//   if (["deliveredCanceledDate", "date", "deliveryDate"].includes(tableShipment)) {
//     return <FixedTableCell key={index} >
//       {dateFormatLL(shipment[tableShipment])}
//     </FixedTableCell>
//   }
//   if (["type", "paymentType", "priceType", "openable", "cancellationReason", "returnType", "deliveryType", "warehouseSection"].includes(tableShipment)) {
//     return <FixedTableCell key={index} >
//       {shipment[tableShipment]?.name}
//     </FixedTableCell>
//   }

//   if (tableShipment === "updata") { return updataDeliveryBody(shipment, index) }
//   return <FixedTableCell key={index} >
//     {shipment[tableShipment]}
//   </FixedTableCell>
// };

// const getTableSum = (sumShipments, shipmentCell, index) => {
//   if (shipmentCell === "code") {
//     return <FixedTableCell key={index} className={classes.shipmentTable_bodyCode}>
//       {" "}
//     </FixedTableCell>
//   }
//   if (shipmentCell === "status") {
//     return <FixedTableCell key={index} className={sumShipmentcels.includes("code") ? classes.shipmentTable_bodyStatus : classes.shipmentTable_bodyCode}>
//       {" "}
//     </FixedTableCell>
//   }
//   if (shipmentCell === "netCollection") {
//     const netCollection = sumShipments?.sumShipments["pendingCollectionAmount"] - sumShipments?.sumShipments["deliveryCommission"]
//     return <FixedTableCell key={index}>
//       <Typography color="textPrimary" variant="h6" >
//         {formatMoney(netCollection?.toFixed(2)) ?? "--"}
//       </Typography>
//     </FixedTableCell>
//   }
//   if (["price", "deliveredAmount", "allDueFees", "customerDue", "amount", "totalAmount", "deliveryCommission", "collectedFees", "returningDueFees", "collectedAmount", "pendingCollectionAmount", "customerDueDebit", "customerDueCredit"].includes(shipmentCell)) {
//     return <FixedTableCell key={index} >
//       <Typography color="textPrimary" variant="h6" >
//         {formatMoney(sumShipments?.sumShipments[shipmentCell]?.toFixed(2)) ?? "--"}
//       </Typography>
//     </FixedTableCell >
//   }
//   return <FixedTableCell key={index}>{" "}</FixedTableCell>
// }