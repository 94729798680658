import { Box, SwipeableDrawer, Typography, useTheme } from "@mui/material"
import { classesCollectionForm } from "../CollectionStyle"
import { EnhancedTableToolbarForm } from "./EnhancedTableToolbar"
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader"
import ShipmentsTable from "../../HOC/CustomComponents/ShipmentsTable"
import { useEffect, useState } from "react"
import useWidth from "../../../Hooks/useWidth"
import ConciergeRequestsList from "../CollectionConciergeRequests/ConciergeRequestsList"
import ConciergeRequestsBarcodeSelector from "../CollectionConciergeRequests/ConciergeRequestsBarcodeSelector"
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination"
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import ShipmentList from "../CollectionShipments/ShipmentList"
import BarcodeSelector from "../CollectionShipments/BarcodeSelector"
import ShipmentsOperationTable from "../../HOC/CustomComponents/ShipmentsOperationTable"
import { useTranslation } from "react-i18next"
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading"

export const ConciergeRequestTableForm = (props) => {
    const {
        watch,
        disableForm,
        collectionType,
        collectionData,

        conciergeRequestsData,
        setConciergeRequestsData,
        loadingConciergeData,
        refetchConciergeDataQuery,
        getConciergeData,

        conciergeRequestsDetails
    } = props

    const screenWidth = useWidth();
    const anchor = screenWidth === "xs" ? "bottom" : "right";
    const { t } = useTranslation()
    const theme = useTheme()

    const handleChangePage = (event, newPage) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const [state, setState] = useState({
        conciergeRequestList: {
            left: false,
            bottom: false,
            right: false,
        },
    });


    const conciergeRequestDrawerHandler = (anchor, open) => {
        setState((prev) => ({
            ...prev,
            conciergeRequestList: { ...prev.conciergeRequestList, [anchor]: open },
        }));
    };

    const [barcodeConciergeRequestDrawerState, setBarcodeConciergeRequestDrawerState] = useState({
        left: false,
        bottom: false,
        right: false,
    });

    const barcodeConciergeRequestDrawerHandler = (anchor, open) => {
        setBarcodeConciergeRequestDrawerState((prev) => ({
            ...prev,
            [anchor]: open,
        }));
    };

    useEffect(() => {
        conciergeRequestsData.noData && watch("id") && getConciergeData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <SwipeableDrawer
                variant="persistent"
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={state.conciergeRequestList[anchor]}
                onClose={() => false}
                onOpen={() => conciergeRequestDrawerHandler(anchor, true)}
                classes={{ paper: classesCollectionForm.shipmentList }}
                dir={theme.direction}
            >
                {state.conciergeRequestList[anchor] && (
                    <ConciergeRequestsList
                        type={collectionType}
                        done={() => conciergeRequestDrawerHandler(anchor, false)}
                        variables={watch()}
                        refetchConciergeDataQuery={refetchConciergeDataQuery}
                    />
                )}
            </SwipeableDrawer>

            <SwipeableDrawer
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={barcodeConciergeRequestDrawerState[anchor]}
                onClose={() => false}
                onOpen={() => barcodeConciergeRequestDrawerHandler(anchor, true)}
                sx={{
                    width: "auto",
                    "& .MuiDrawer-paper": {
                        width: { md: "50%" },

                        height: "100%",
                        overflow: "hidden",
                    },
                }}
                dir={theme.direction}
            >
                {barcodeConciergeRequestDrawerState[anchor] && (
                    <ConciergeRequestsBarcodeSelector
                        type={collectionType}
                        done={() => barcodeConciergeRequestDrawerHandler(anchor, false)}
                        variables={watch()}
                        refetchConciergeDataQuery={refetchConciergeDataQuery}
                    />)}
            </SwipeableDrawer>

            <EnhancedTableToolbarForm
                {...props}
                addShipment={() => conciergeRequestDrawerHandler(anchor, true)}
                barcode={() => barcodeConciergeRequestDrawerHandler(anchor, true)}
                approved={collectionData?.approved}
            />
            <TableFixedHeaderWraper>
                {loadingConciergeData ?
                    <FullScreenLoading minHeight={"100px"} /> : conciergeRequestsData.pickedConciergeRequests && (
                        <ShipmentsTable
                            data={conciergeRequestsData.pickedConciergeRequests}
                            headCells={conciergeRequestsDetails.tableHead}
                            parseBodyCell={conciergeRequestsDetails.tableBody}
                            total={conciergeRequestsDetails.tableTotal}
                            sumRow={conciergeRequestsDetails?.sum}
                            sumTableNumber={conciergeRequestsDetails?.sumTableNumber}
                        />
                    )}
            </TableFixedHeaderWraper>
            {conciergeRequestsData.total > 0 && (
                <MUITablePagination
                    count={conciergeRequestsData.total}
                    rowsPerPage={conciergeRequestsData.rowsPerPage}
                    page={conciergeRequestsData.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}

            {(!watch("id") || !disableForm) && (
                <Box className={classesCollectionForm.overlay}>
                    <Typography color="textPrimary" variant="body1">
                        {t("saveManifestFirst")}
                    </Typography>
                </Box>
            )}
            <Grid container justifyContent="center" alignItems="center">
                {!loadingConciergeData && conciergeRequestsData.total === 0 && watch("id") && disableForm && (
                    <EmptyTableMessage loading={false} />
                )}
            </Grid>
        </>
    )
}

export const ShipmentTableForm = (props) => {
    const {
        watch,
        collectionType,
        disableForm,
        collectionDetails,
        collectionData,
        clearanceCollection,

        shipmentData,
        setShipmentData,
        loadingShipmentData,
        refetchShipmentDataQuery

    } = props

    const screenWidth = useWidth();
    const anchor = screenWidth === "xs" ? "bottom" : "right";
    const { t } = useTranslation()
    const theme = useTheme()

    const handleChangePage = (event, newPage) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const [state, setState] = useState({
        shipmentList: {
            left: false,
            bottom: false,
            right: false,
        },
        conciergeRequestList: {
            left: false,
            bottom: false,
            right: false,
        },
    });

    const [barcodeDarwerState, setBarcodeDarwerState] = useState({
        left: false,
        bottom: false,
        right: false,
    });

    const shipmentDrawerHandler = (anchor, open) => {
        setState((prev) => ({
            ...prev,
            shipmentList: { ...prev.shipmentList, [anchor]: open },
        }));
    };

    const barcodeDrawerHandler = (anchor, open) => {
        setBarcodeDarwerState((prev) => ({
            ...prev,
            [anchor]: open,
        }));
    };

    return (
        <Box display={"grid"}>
            <SwipeableDrawer
                variant="persistent"
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={state.shipmentList[anchor]}
                onClose={() => false}
                onOpen={() => shipmentDrawerHandler(anchor, true)}
                classes={{ paper: classesCollectionForm.shipmentList }}
                dir={theme.direction}
            >
                {state.shipmentList[anchor] && (
                    <ShipmentList
                        type={collectionType}
                        done={() => shipmentDrawerHandler(anchor, false)}
                        variables={watch()}
                        refetchShipmentDataQuery={refetchShipmentDataQuery}
                    />
                )}
            </SwipeableDrawer>
            <SwipeableDrawer
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={barcodeDarwerState[anchor]}
                onClose={() => false}
                onOpen={() => barcodeDrawerHandler(anchor, true)}
                sx={{
                    width: "auto",
                    "& .MuiDrawer-paper": {
                        width: { md: "50%" },
                        height: "100%",
                        overflow: "hidden",
                    },
                }}
                dir={theme.direction}
            >
                {barcodeDarwerState[anchor] && (
                    <BarcodeSelector
                        type={collectionType}
                        done={() => barcodeDrawerHandler(anchor, false)}
                        variables={watch()}
                        refetchShipmentDataQuery={refetchShipmentDataQuery}
                    />)}
            </SwipeableDrawer>
            <EnhancedTableToolbarForm
                {...props}
                addShipment={() => shipmentDrawerHandler(anchor, true)}
                barcode={() => barcodeDrawerHandler(anchor, true)}
                approved={clearanceCollection ? clearanceCollection.finished : collectionData?.approved}
            />
            <TableFixedHeaderWraper>
                {loadingShipmentData ?
                    <FullScreenLoading minHeight={"100px"} /> : collectionDetails && (
                        <ShipmentsOperationTable
                            data={shipmentData.pickedShipment}
                            headCells={collectionDetails.tableHead}
                            parseBodyCell={collectionDetails.tableBody}
                            total={collectionDetails.tableTotal}
                        />
                    )}
            </TableFixedHeaderWraper>
            {shipmentData?.total!== 0 && (
                <MUITablePagination
                    count={shipmentData.total}
                    rowsPerPage={shipmentData.rowsPerPage}
                    page={shipmentData.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
            {(!watch("id") || !disableForm) && (
                <Box className={classesCollectionForm.overlay}>
                    <Typography color="textPrimary" variant="body1">
                        {t("saveManifestFirst")}
                    </Typography>
                </Box>
            )}
            <Grid container justifyContent="center" alignItems="center">
                {!loadingShipmentData && shipmentData.pickedShipment.length === 0 && watch("id") && disableForm && (
                    <EmptyTableMessage loading={false} />
                )}
            </Grid>
        </Box>
    )
}
