import { Icon } from "@mui/material";
import React from "react";

function CheckCrossIcons(props) {
  const { active, ...restProps } = props
  return active ? (
    <Icon color="success" {...restProps}>check_circle_outline</Icon>
  ) : (
    <Icon color="error"  {...restProps}>highlight_off</Icon>
  );
}

export default CheckCrossIcons;
