import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { FilterList, Print, ViewColumn } from "@mui/icons-material";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import SearchTable from "../ShipmentSearch/SearchTable";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import FiltersForm from "../../Hooks/shipmentListFilters";
import ExportShipments from "../Shipments/ExportShipments";
import useWidth from "../../Hooks/useWidth";
import PrintComponent from "../Shipments/PrintComponent";
import { printLink } from "../../helpers/defaultPrintList";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { Globals } from "../HOC/Classes/Globals";
import ShipmentColumView from "../ShipmentSearch/shipmentColumView"
import config from "../../config.json";
import { defaultQueryFields, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";


// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({


}));

// const drawerWidth = 240;
const UncollectedFromDeliveryAgents = (props) => {
  const urlQuery = urlParameters(window.location.search);

  const [keys, setKeys] = useState(localStorage.getItem("uncollected-from-delivery-agents") ? JSON.parse(localStorage.getItem("uncollected-from-delivery-agents")) : initialData["uncollected-from-delivery-agents"])

  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const [tableLoad, setTableLoad] = useState(false);

  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };
  const [filterLoad, setFilterLoad] = useState(true);

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  // const theme = useTheme();

  // const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  // const [dateValue, setDateValue] = useState();
  const [checkedIds, setCheckedIds] = useState([]);
  const [queryVariables, setQueryVariables] = useState({
    refetch: false,
    page: -1,
  });
  const resetPage = () => setPage(0);

  const queryVariablesWithInitValue = {
    ...(queryVariables && queryVariables),
  };
  const {
    refetch,
    page: pageNum,
    ...excelFilters
  } = queryVariablesWithInitValue;
  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/uncollected-from-delivery-agents",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };
  const clearCheckBox = () => setCheckedIds([]);
  const defaultPrint = Globals.settings.waybillCode;

  const [openPrint, setOpenPrint] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [printListDisabled, setPrintListDisabled] = useState(true)
  const openPrintDialog = () => {
    defaultPrint
      ? printLink(defaultPrint, false, checkedIds)
      : setOpenPrint(true);
  };
  const openExportDialog = () => {
    setOpenExport(true);
  };

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      if (i === "date") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent(y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      if (i === "lastTransactionDate") {
        for (const y in param[i]) {
          const lastTransaction = (y === "fromDate" ? "lastTransactionFromDate" : "lastTransactionToDate")
          queryParams.push(encodeURIComponent(lastTransaction) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      i !== "page" && i !== "refetch" && i !== "date" && i !== "lastTransactionDate" && i !== "inDeliveryAgentCustody" && encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    urlQuery["lastDeliveryAgentId"] &&
      queryParams.push(
        encodeURIComponent("lastDeliveryAgentId") + "=" + encodeURIComponent(urlQuery["lastDeliveryAgentId"])
      );

    const queryString = queryParams.join("&");
    return queryString;
  };

  const onPrint = () => {
    const params = searchParams(queryVariables);
    const domain = `${window.location.origin}`;
    window.open(`${domain}/shipment-list/print?listType=uncollected-from-delivery-agents&${params}`);
  };

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
      color: "default",
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0,
    },
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIds.length === 0,
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: printListDisabled
    },
    {
      id: "ViewColumn",
      title: "viewColums",
      action: openColumView,
      icon: ViewColumn,
    },

  ];

  return (
    <Root>
      {/* *******Table******* */}
      <PrintComponent
        shipmentsId={checkedIds}
        openPrint={openPrint}
        setOpenPrint={setOpenPrint}
      />
      <ExportShipments
        filters={excelFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"uncollected-from-delivery-agents"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />
      {queryVariables && (
        <SearchTable
          {...props}
          onPageChange={handleChangePage}
          onChangeRows={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          queryVariables={queryVariablesWithInitValue}
          loading={(param) => setTableLoad(param)}
          queryFields={defaultQueryFields(keys, adminNotesPermission)}
          setCheckedIds={(ids) => setCheckedIds(ids)}
          filterLoading={setFilterLoad}
          setPrintListDisabled={setPrintListDisabled}
          checkedIds={checkedIds}
          withCheckAll
          icons={icons}
          path={props.match.path}
          drawerState={drawerState[filterAnchor]}
          keys={keys}
          filters={<FiltersForm
            {...{ onSubmitFunc, resetPage, rowsPerPage, loading: tableLoad, filterLoad: filterLoad }}
            queryVariables={(variables) =>
              setQueryVariables({
                inDeliveryAgentCustody: {
                  ...(urlQuery["lastDeliveryAgentId"] && {
                    deliveryAgentId: urlQuery["lastDeliveryAgentId"],
                  })
                }, ...variables
              })
            }
            // dateValue={(date) => setDateValue(date)}
            // initData={FILTER_INIT_DATE}
            pathname="uncollected-from-delivery-agents"
            custody
            filtersAllowed={[
              "date",
              "dlvAtemp",
              "status",
              "branch",
              "originBranch",
              "customerType",
              "custm",
              "dlvAgent",
              "forwarded",
              "zoneSender",
              "subZoneSender",
              "zone",
              "subZone",
              "refNumber",
              "service",
              "deliveryType",
              "returnType",
              "packageType",
              "paymentType",
              
            ]}
            clearCheckBox={clearCheckBox}
          />}
        />
      )}

    </Root>
  );
};

export default UncollectedFromDeliveryAgents;
