/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  TableBody,
  TableHead,
  TableRow,
  Button,
  Icon,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import { useForm } from "react-hook-form";
import { FilterList, Add, Search } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import MUIselect from "../HOC/MUI/MUIselect";
import { LISTMANIFESTS_QUERY } from "./Graphql";
import moment from "moment";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Can } from "../HOC/CustomComponents/Secured";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CellLink from "../HOC/CustomComponents/CellLink";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";
import { dateFormatLL } from "../../helpers/dateFunctions";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "ManifestList";


const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },


  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));
///////////////////////////////////////////////////

//////////////////////////////////////////////////
//*********Table Function*********

function EnhancedTableHead(props) {
  // const {  numSelected, rowCount, } = props;
  const { type } = props;

  const { t } = useTranslation();
  const headCells = [
    { id: "1", numeric: true, disablePadding: false, label: t("manifestCode") },
    { id: "2", numeric: true, disablePadding: false, label: t("date") },
    {
      id: "3",
      numeric: true,
      disablePadding: false,
      label: t("branch"),
    },

    {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("transactionTypeName"),
    },
    { id: "7", numeric: true, disablePadding: false, label: t("approved") },
  ];
  if (type === "pmbr" || type === "pmbt") {
    const referenceBranch = {
      id: "8",
      numeric: true,
      disablePadding: false,
      label: type === "pmbt" ? t("toBranch") : t("fromBranch"),
    };
    headCells.splice(3, 0, referenceBranch);
  }
  if (type === "padd" || type === "prtrn") {
    const referenceBranch = {
      id: "4",
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    };
    headCells.splice(3, 0, referenceBranch);
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <FixedTableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </FixedTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const ManifestList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  // const validUrlParameters = Object.keys(urlParam).length !== 0;
  const { t } = useTranslation();
  const [refetch, setrefetch] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);

  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('calories');
  // const manifestName = {
  //   padd: t("receiveProductFromCustomer"),
  //   prtrn: t("returnProductToCustomer"),
  //   pmbr: t("receiveProductTransfer"),
  //   pmbt: t("sendProductTransfer"),
  // };

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters ? null : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters ? null : initDate;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [page, setPage] = useState(0);

  const [autocompleteValues, setAutocompleteValues] = useState({
    deliveryAgent: null,
    customer: null,
    toZone: null,
    toSubzone: null,
    fromZone: null,
    fromSubzone: null,
  });

  const screenWidth = useWidth();

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const listType = props.match.params.type;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/warehouse-manifests/${listType}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";


  const { data, loading } = useQuery(
    gql`
      ${LISTMANIFESTS_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          transactionTypeCode: listType.toUpperCase(),
          approved: search.approved,
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.code && { code: search.code }),
          ...(search.branchId && { branchId: search.branchId }),
        },
      },
    }
  );

  const manifestListData =
    data?.listWarehouseManifests?.data !== null ? data?.listWarehouseManifests?.data : null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...urlQuery, page: newPage, rowsPerPage: rowsPerPage, });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
    pushUrlSearch({ ...urlQuery, page: 0, rowsPerPage: +event.target.value });
  };
  const resetDate = () => {
    setDateRange([null, null]);
  };


  ////////////////////END DRAWER////////////////

  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    (refetch) ? setrefetch(false) : setrefetch(true)
    setFilterLoading(false)
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      code: data.code,
      ...data,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };
  // const onSubmit = (data) => {
  //   filterAnchor === "bottom" &&
  //     setState((prev) => ({ ...prev, [filterAnchor]: false }));
  //   for (const key in data) {
  //     if (data[key] === "") {
  //       delete data[key];
  //     }
  //   }
  //   data["fromDate"] = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  //   data["toDate"] = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  //   setQueryVar({
  //     ...data,
  //     transactionTypeCode: listType.toUpperCase(),
  //   });
  //   pushUrlSearch({ ...data, first: rowsPerPage, page: 1 });
  //   setPage(0);
  //   // setQuaryVar((prev) => {
  //   //     urlParams({ ...data, first: prev.first })
  //   //     return { first: prev.first, ...data }
  //   // })
  //   // setState({ ...state, [filterAnchor]: false })
  // };

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, `/admin/warehouse-manifests/create/${listType}`),
      icon: Add,
      permission: "shipping.warehouse_manifest.create",
    },
  ]

  const EnhancedTableToolbar = (props) => {
    // const { numSelected } = props;
    return (
      <TitleAppBar path={props.match.path} type={listType}>
        <LongMenu icons={icons} />
      </TitleAppBar>
    );
  };

  const branchTransfareCondition = listType === "pmbr" || listType === "pmbt";
  const cusromerCondition = listType === "padd" || listType === "prtrn";

  EnhancedTableToolbar.propTypes = {};


  return (
    <Root>
      {props.children}
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        type={listType}
        empty={manifestListData?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm} >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >

              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  name={"branchId"}
                  label={t("currentBranch")}
                  control={control}
                  skip={loading && filterLoading}
                  errors={errors}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  name={"approved"}
                  label={t("status")}
                  data={[
                    { key: t("approved"), value: true },
                    { key: t("notApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>
              <Can showException permission={cusromerCondition}>
                <Grid item sm={12} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    skip={loading && filterLoading}
                    name={"customerId"}
                    label={t("customer")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["customerId"],
                        "customer"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    variables={{
                      input: {
                        active: true,
                        typeCode: ["MERCHANT"],
                      },
                    }}
                    defaultValue={autocompleteValues.customer}
                  />
                </Grid>
              </Can>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>




          </Grid>
        }
        tableHeaders={
          <EnhancedTableHead
            type={listType}
            rowCount={manifestListData?.length}
          />
        }
        tableBody={
          <TableBody>
            {manifestListData &&
              manifestListData?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   pushUrl(props, `/admin/manifests/${row?.id}/edit`);
                  // }}
                  >
                    <CellLink
                      component="th"
                      scope="row"
                      pathname={`/admin/warehouse-manifests/${row?.id}`}
                    >
                      {row.code}
                    </CellLink>
                    <FixedTableCell>
                      {dateFormatLL(row?.date)}
                    </FixedTableCell>
                    <CellLink
                      pathname={`/admin/branches/${row?.branch?.id}`}
                    >
                      {row?.branch?.name}
                    </CellLink>

                    {branchTransfareCondition ? (
                      <CellLink
                        pathname={`/admin/branches/${row?.referenceBranch?.id}`}
                      >
                        {row?.referenceBranch?.name}
                      </CellLink>
                    ) : null}
                    {cusromerCondition ? (
                      <CellLink
                        pathname={`/admin/customers/${row?.customer?.id}`}
                      >
                        {row?.customer?.name}
                      </CellLink>
                    ) : null}

                    {/* {listType !== "rits" ? (
                      <CellLink
                        pathname={`/admin/delivery-agents/${row?.deliveryAgent?.id}`}
                      >
                        {row?.deliveryAgent?.name}
                      </CellLink>
                    ) : null} */}

                    {row?.transactionType ? (
                      <CellLink
                        pathname={`/admin/transaction-types/${row?.transactionType?.id}`}
                      >
                        {row?.transactionType?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}

                    {/* <FixedTableCell>{row?.shipmentsCount}</FixedTableCell> */}
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listWarehouseManifests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listWarehouseManifests ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ManifestList;
