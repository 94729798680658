import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
  Icon
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Dialpad } from "@mui/icons-material";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { dateFormatLL } from "../../helpers/dateFunctions";

const PREFIX = "RecordsTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  deleteIcon: `${PREFIX}-deleteIcon`,
  iconColor: `${PREFIX}-iconColor`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.error.main,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const RecordsTable = (props) => {
  const {
    records = [],
    addRecord,
    setRecordsList,
    yearId
  } = props;
  const { t } = useTranslation();
  const [recordsPage, setRecordPage] = useState(0);
  const [recordRowsPerPage, setrecordRowsPerPage] = useState(20);
  const handleRecordsPage = (event, newPage) => {
    setRecordPage(newPage);
  };
  const handleRecordsRowsPerPage = (event) => {
    setrecordRowsPerPage(parseInt(event.target.value));
    setRecordPage(0);
  };
  const editable = addRecord;

  return (
    <StyledGrid container alignItems="flex-start"  display={"disabled"}>
      <Grid container item sm={12} component={Paper}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("periods")}
          </Typography>

          {editable ? (
            <IconButton
              color="primary"
              disabled={!yearId}
              onClick={() => {
                addRecord();
              }}
              aria-label="Add record"
              size="large"
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          {records.length <= 0 ? (
            <EmptyTableMessage loading={false} message={t("searchNoResult")} />
          ) : (
            <TableFixedHeaderWraper className={classes.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("startDate")}</FixedTableCell>
                    <FixedTableCell>{t("endDate")}</FixedTableCell>
                    <FixedTableCell>{t("active")}</FixedTableCell>
                    {/* {editable ? <FixedTableCell></FixedTableCell> : null} */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.length > 0 &&
                    (recordRowsPerPage > 0
                      ? records.slice(
                        recordsPage * recordRowsPerPage,
                        recordsPage * recordRowsPerPage + recordRowsPerPage
                      )
                      : records
                    ).map((row, index) => (
                      <TableRow
                        className={row?.error && classes.focus}
                        hover
                        key={index}
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setRecordPage(0);
                            addRecord(recordsPage * recordRowsPerPage + index)
                          
                        }}

                      >
                        <FixedTableCell>{row.code}</FixedTableCell>
                        <FixedTableCell>{row.name}</FixedTableCell>
                        <FixedTableCell>{dateFormatLL(row?.startDate)}</FixedTableCell>
                        <FixedTableCell>{dateFormatLL(row?.endDate)}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>

                        {/* {editable ? (
                          <FixedTableCell id={index}>
                            <IconButton
                              size="small"
                              className={classes.deleteIcon}
                              onClick={(e) => {
                                setRecordsList((prev) => {
                                  const filtered = prev.filter(
                                    (item, deleteIndex) => deleteIndex !== index
                                  );
                                  return filtered;
                                });

                              }
                              }
                            >
                              <Delete />
                            </IconButton>
                          </FixedTableCell>
                        ) : null} */}
                      </TableRow>
                    ))}

                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={records?.length ?? 0}
            rowsPerPage={recordRowsPerPage}
            page={recordsPage}
            onPageChange={handleRecordsPage}
            onRowsPerPageChange={handleRecordsRowsPerPage}
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default RecordsTable;
