import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import "../ManifestPrint/print.css";
import { useState } from "react";
import LogoImg from "../../Layout/LogoImg";

const PREFIX = "AccountStatementPrint";

const classes = {
    headerCells: `${PREFIX}-headerCells`,
    title: `${PREFIX}-title`,
    notes: `${PREFIX}-notes`,
    noteCell: `${PREFIX}-noteCell`,
    barcodeFont: `${PREFIX}-barcodeFont`,
    cairoFont: `${PREFIX}-cairoFont`,
    barcode: `${PREFIX}-barcode`,
    headerTableCell: `${PREFIX}-headerTableCell`,
    mainCellBorder: `${PREFIX}-mainCellBorder`,
    signature: `${PREFIX}-signature`,
    reportHeader: `${PREFIX}-reportHeader`,
    hiddenFooter: `${PREFIX}-hiddenFooter`,
    pageFooter: `${PREFIX}-pageFooter`,
    logoSection: `${PREFIX}-logoSection`,
    headerDate: `${PREFIX}-headerDate`,
    whiteSpace: `${PREFIX}-whiteSpace`,
    approve: `${PREFIX}-approve`,
    rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
    mainDetailsCell: `${PREFIX}-mainDetailsCell`,
    tableHeadCell: `${PREFIX}-tableHeadCell`,
    tableWidth: `${PREFIX}-tableWidth`,
    shipmentsTable: `${PREFIX}-shipmentsTable`,
    signatureBlock: `${PREFIX}-signatureBlock`,
};

const Root = styled("div")(({ theme }) => ({
    fontFamily: ['"Cairo"', "sans-serif"].join(","),

    [`& .${classes.headerCells}`]: {
        paddingBottom: theme.spacing(1),
    },

    [`& .${classes.title}`]: {
        color: theme.palette.info.main,
        textTransform: "capitalize"
        // whiteS;pace: "break-spaces",
    },

    [`& .${classes.notes}`]: {
        padding: theme.spacing(1),
        border: "1px solid " + theme.palette.action.disabled,
        wordBreak: "break-all",
        width: "100%",
        minHeight: 80,
    },

    [`& .${classes.noteCell}`]: {
        width: "30%",
        overflow: "hidden",
    },

    [`& .${classes.barcodeFont}`]: {
        fontFamily: '"Libre Barcode 39 Text"',
    },

    [`& .${classes.cairoFont}`]: {
        fontFamily: ['"Cairo"', "sans-serif"].join(","),
    },

    [`& .${classes.barcode}`]: {
        fontSize: 40,
        textAlign: "center",
    },

    [`& .${classes.headerTableCell}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classes.mainCellBorder}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classes.signature}`]: {
        textAlign: "center",
        padding: theme.spacing(2, 1, 1, 1),
        border: "none",
        textTransform: "capitalize"
    },

    [`& .${classes.reportHeader}`]: {
        whiteSpace: "pre-line",
        padding: theme.spacing(0, 2),
        lineHeight: "120%",
        fontSize: 12,
    },

    [`& .${classes.hiddenFooter}`]: {
        visibility: "hidden",
    },

    [`& .${classes.pageFooter}`]: {
        //   "&::after": {
        //    " content:  counter(page)  of  counter(pages)"
        //   },
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid " + theme.palette.text.secondary,
        display: "flex",
        alignItems: "flex-end",
    },

    [`& .${classes.logoSection}`]: {
        display: "flex",
        alignItems: "center",
    },

    [`& .${classes.headerDate}`]: {
        textAlign: "end",
    },

    [`& .${classes.whiteSpace}`]: {
        "& tr": {
            "& td": {
                whiteSpace: "initial",
            },
        },
    },

    [`& .${classes.approve}`]: {
        textAlign: "end",
    },

    [`& .${classes.rowWithoutBorder}`]: {
        "& td": {
            border: "none",
            padding: 0,
        },
    },

    [`& .${classes.mainDetailsCell}`]: {
        "& td": { border: "none", verticalAlign: "top" },
        "& td:first-of-type": { paddingLeft: 0 },
        "& td:last-of-type": { paddingRight: 0 },

        border: "none",
        padding: theme.spacing(1, 0),
    },

    [`& .${classes.tableHeadCell}`]: {
        padding: 0,
    },

    [`& .${classes.tableWidth}`]: {
        width: "100vw",
    },

    [`& .${classes.shipmentsTable}`]: {
        "& tr": {
            "&:hover": {
                backgroundColor: "#ffffff00 !important",
            },
        },
        "& td , & th": {
            padding: theme.spacing(1),
        },
    },

    [`& .${classes.signatureBlock}`]: {
        breakInside: "avoid",
    },
}));

export { };

const org = {
    operation: "organization",
    fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
        "registrationNumber",
        "taxNumber",
    ],
    variables: {},
};

const sheet = {
    operation: "sheet",
    fields: [
        "code",
        "credit",
        "name",
        "debit",
        "id",
        "totalCredit",
        "totalDebit",
    ],
    variables: {
        statementId: {
            type: "Int",
            required: true,
        },
    },
};

const SheetsPrint = (props) => {
    const urlQuery = urlParameters(window.location.search);
    const statementId = urlQuery?.["statementId"];
    const statement = urlQuery?.["statement"];
    const [tableData, setTableData] = useState([]);
    const [sum, setSum] = useState({
        credit: 0,
        debit: 0,
        totalCredit: 0,
        totalDebit: 0,
    });


    const queryBuilder = () => {
        const queryArray = [org, sheet];
        return queryArray;
    };

    const queryBody = queryBuilder();
    const SHEETS_PRINT = gqlb.query(queryBody);
    const { t } = useTranslation();

    const { data: queryData, loading } = useQuery(
        gql`
            ${SHEETS_PRINT.query}
        `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            variables: {
                statementId: statementId,
                id: urlQuery?.["branchId"]
            },
            onError: () => {
                return;
            },
            onCompleted: (data) => {
                setTableData(data.sheet);
                const initial = {
                    credit: 0,
                    debit: 0,
                    totalCredit: 0,
                    totalDebit: 0,
                };
                data.sheet.forEach((i) => {
                    initial.credit += i.credit;
                    initial.debit += i.debit;
                    initial.totalCredit += i.totalCredit;
                    initial.totalDebit += i.totalDebit;
                });
                setSum(initial);
            }
        }
    );

    const reportDetails =
        queryData?.sheet?.data !== null
            ? queryData?.sheet?.data
            : null;

    useEffect(() => {
        if (queryData) {
            window.print();
        }
        return () => { };
    }, [queryData]);

    const header = !loading && queryData !== null && (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell padding="none">
                        <Table>
                            <TableBody>
                                <TableRow className={classes.rowWithoutBorder}>
                                    <TableCell>
                                        <Box className={classes.logoSection}>
                                            <LogoImg height={30} forceLight={true} />
                                            {/* <img src={logo} alt="logo" height={30} /> */}
                                            <Typography
                                                sx={{
                                                    whiteSpace: "pre-line !important",
                                                    paddingLeft: '10px',
                                                    lineHeight: "120%",
                                                    fontSize: 12,
                                                }}
                                                className={clsx(classes.reportHeader, "landscape-zoom")}
                                            >
                                                {queryData?.organization?.reportHeader}
                                            </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell className={clsx(classes.headerDate)}>
                                        <Typography variant="h6">
                                            {moment(new Date()).locale("en").format("ll")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell className={classes.headerTableCell}>
                        <Table className={classes.whiteSpace}>
                            <TableBody>
                                <TableRow className={classes.rowWithoutBorder}>
                                    <TableCell>
                                        <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                                            {t("createSheets")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell
                        className={clsx(classes.mainDetailsCell, "landscape-zoom")}
                    >
                        <Table className={classes.whiteSpace}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <TypographyKeyValue
                                            title={t("manifestName")}
                                            value={statement}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {urlQuery["typeCode"] === "PERIOD" &&
                                            < TypographyKeyValue
                                                title={t("fromDate")}
                                                value={moment(urlQuery?.fromDate).format("ll")}
                                            />
                                        }
                                        {urlQuery["typeCode"] === "PERIOD" &&
                                            < TypographyKeyValue
                                                title={t("toDate")}
                                                value={moment(urlQuery?.toDate).format("ll")}
                                            />
                                        }
                                        {urlQuery["typeCode"] === "DATE" &&
                                            <TypographyKeyValue
                                                title={t("date")}
                                                value={moment(urlQuery?.inDate).format("ll")}
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const table = !loading && queryData !== null && (
        <Table id="manifestPrintTable">
            <TableHead>
                <TableRow>
                    <FixedTableCell colSpan={2} allowPlaceholder={false} />

                    <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("partial")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("totals")}
                    </FixedTableCell>
                </TableRow>
                <TableRow>
                    <FixedTableCell>{t("recordNumber")}</FixedTableCell>
                    <FixedTableCell>{t("description")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {tableData.map((row, index) => {
                    return (
                        <TableRow key={row.id}>
                            <FixedTableCell>{row.code}</FixedTableCell>
                            <FixedTableCell>{row.name}</FixedTableCell>
                            <FixedTableCell>{row.debit.toFixed(2)}</FixedTableCell>
                            <FixedTableCell>{row.credit.toFixed(2)}</FixedTableCell>
                            <FixedTableCell>
                                {row.totalDebit.toFixed(2)}
                            </FixedTableCell>
                            <FixedTableCell>
                                {row.totalCredit.toFixed(2)}
                            </FixedTableCell>
                        </TableRow>
                    );
                })}
                <TableRow className={classes.balanceFont}>
                    <FixedTableCell allowPlaceholder={false}></FixedTableCell>
                    <FixedTableCell>{t("total")}</FixedTableCell>
                    <FixedTableCell>{sum.debit.toFixed(2)}</FixedTableCell>
                    <FixedTableCell>{sum.credit.toFixed(2)}</FixedTableCell>
                    <FixedTableCell>{sum.totalDebit.toFixed(2)}</FixedTableCell>
                    <FixedTableCell>
                        {sum.totalCredit.toFixed(2)}
                    </FixedTableCell>
                </TableRow>
            </TableBody>
        </Table >
    );

    return (
        <Root>
            {loading ? (
                <>
                    <FullScreenLoading minHeight="20%" />
                    {/* this spans for download font before print */}
                    <span className={clsx(classes.barcodeFont)} />
                    <span style={{ opacity: 0 }} className={classes.cairoFont}>
                        lا
                    </span>
                </>
            ) : reportDetails === null ? (
                <NotFound />
            ) : (
                <>
                    <div>
                        <div className="page-header">{header}</div>

                        <div className="page-footer">
                            {queryData?.organization?.reportFooter}
                        </div>

                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td>
                                        <div className="page-header-space">{header}</div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <div
                                            className={clsx(classes.shipmentsTable, {
                                                // [classes.tableWidth]: tableZoom,
                                            })}
                                        >
                                            {table}
                                        </div>
                                        <div className={classes.signatureBlock}>

                                            <Table className={classes.whiteSpace}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("accountant")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("chiefAccountant")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("generalManager")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td>
                                        <div className="page-footer-space">
                                            {queryData?.organization?.reportFooter}
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
            )}
        </Root>
    );
};

export default SheetsPrint;

function TypographyKeyValue(props) {
    const { title, value, hidden, ...restProps } = props;
    return hidden || !value ? null : (
        <Typography
            variant="body2"
            className={clsx(classes.keyValue)}
            {...restProps}
        >
            <span className={classes.title}>{title}:</span> <span>{value}</span>
        </Typography>
    );
}



