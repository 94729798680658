
import {
  Box,
  Icon,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { dateTimeFormatA } from "../../helpers/dateFunctions";
import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { fields } from "./Graphql"
import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
// import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import "../ManifestPrint/print.css";

import formatMoney from "../../helpers/numbersDot";
import { RootPrintCollectionList, classesPrintCollectionList } from "../Collection/CollectionStyle";
import CellLink from "../HOC/CustomComponents/CellLink";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";

import { styled } from "@mui/material/styles";
import { zoom } from "../ManifestPrint/ManifestPrintFunc";
import { TableCellColor } from "./TableCellColor";
import { Can } from "../HOC/CustomComponents/Secured";

const PREFIX = "ManifestPrint";

const classes = {
  headerCells: `${PREFIX}-headerCells`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  noteCell: `${PREFIX}-noteCell`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  barcode: `${PREFIX}-barcode`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  mainCellBorder: `${PREFIX}-mainCellBorder`,
  signature: `${PREFIX}-signature`,
  reportHeader: `${PREFIX}-reportHeader`,
  hiddenFooter: `${PREFIX}-hiddenFooter`,
  pageFooter: `${PREFIX}-pageFooter`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  approve: `${PREFIX}-approve`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIX}-mainDetailsCell`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  signatureBlock: `${PREFIX}-signatureBlock`,
};

const Root = styled("div")(({ theme }) => ({
  fontFamily: ['"Cairo"', "sans-serif"].join(","),

  [`& .${classes.cairoFont}`]: {
    fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 66,
  },

  [`& .${classes.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`&.${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    paddingLeft: theme.spacing(2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: "2px",
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: "2px" },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& th": {
      padding: theme.spacing(1),
    },
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },
}));

const org = {
  operation: "organization",
  fields: [
    "name",
  ],
  variables: {},
};
// const collectionsListFields = shipmentField;
const PAGE_COUNT = gqlb.query({
  operation: "listConciergeRequests",
  fields: [{ paginatorInfo: ["lastPage"] }],
  variables: {
    input: {
      type: "ListConciergeRequestsFilterInput",
      required: true,
    },
    first: {
      type: "Int",
    },
  },
});

const ConciergePrint = (props) => {
  const urlQuery = urlParameters(window.location.search);
  let inDeliveryAgentCustody
  if (urlQuery["typeCode"])
    urlQuery["typeCode"] = urlQuery["typeCode"].toUpperCase();
  if (urlQuery["listType"] === "uncollected-from-delivery-agents-concierge") {
    inDeliveryAgentCustody = true
    urlQuery["inDeliveryAgentCustody"] =
    {
      ...(urlQuery["lastDeliveryAgentId"] && {
        deliveryAgentId: urlQuery["lastDeliveryAgentId"],
      })
    }

  }
  if (urlQuery["search"]) {
    urlQuery["search"] = urlQuery["search"].toString().trim()
      .split(",")
      .filter((i) => i.trim() !== "")
      .map((i) => i.trim());
  }
  if (urlQuery["statusCode"])
    urlQuery["statusCode"] = urlQuery["statusCode"].split(",");



  const stringValues = ["code", "accountNumber"];
  const conciergeRequestSum = {
    operation: "sumConciergeRequests",
    fields: [
      "deliveryFees",
      "collectionFees",
      "localAmount",
      "totalAmount",
      "commissionAmount",
      "pendingCollectionAmount"
    ],
    variables: {
      input: {
        type: "ListConciergeRequestsFilterInput",
        required: true,
      },
    },
  };

  stringValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
  });

  delete urlQuery.listType;
  const { data: pageCount, loading: pageLoad } = useQuery(
    gql`
      ${PAGE_COUNT.query}
    `,
    {
      first: 100,
      variables: {
        input: urlQuery,
        first: 100,
      },
      onCompleted: (data) => {
      }
    }
  );

  const pages = pageCount?.listConciergeRequests?.paginatorInfo?.lastPage;
  const conciergeRequestsFieldsData = [
    {
      data: fields,
    },
  ];
  const queryBuilder = () => {
    // const queryNumber = Math.ceil(pages / 100);
    const queryArray = [org];
    queryArray.push(conciergeRequestSum)
    for (let index = 0; index < pages; index++) {
      const initialQuery = {
        operation: `listConciergeRequests${index}:listConciergeRequests`,
        fields: conciergeRequestsFieldsData,
        variables: {
          input: {
            type: "ListConciergeRequestsFilterInput",
            required: true,
          },
          first: {
            type: "Int",
          },
          ["page" + index]: {
            type: "Int",
            name: "page",
          },
        },
      };
      queryArray.push(initialQuery);
    }
    return queryArray;
  };

  const queryBody = queryBuilder();
  const SHIPMENT_PRINT = gqlb.query(queryBody);

  const { t } = useTranslation();

  let variables = {
    input: urlQuery,
    first: 100,
  };
  for (let index = 0; index < pages; index++) {
    variables["page" + index] = index + 1;
  }

  const { data: queryData, loading } = useQuery(
    gql`
      ${SHIPMENT_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pages,
      variables,
      onCompleted: (data) => {
      },
      onError: () => {
        return;
      },
    }
  );

  let parsedData = [];
  if (queryData?.listConciergeRequests0) {
    let concatData = [];
    for (const key in queryData) {
      if (key.startsWith("list")) {
        const listData = queryData[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }

  useEffect(() => {
    if (parsedData) {
      if (parsedData.length === 0) return;
      // if (!zoomed) {
      const zoomDetails = zoom();

      let newLink = document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.href = process.env.PUBLIC_URL + "/" + zoomDetails?.css;

      // document
      //   .querySelectorAll("#manifestPrintTable")
      //   .forEach((i) => (i.style.zoom = zoomDetails.percent));

      document.querySelectorAll("#shipmentsTableContainer").forEach((i) => {
        i.style.minWidth = "100%";
        i.style.width = "100vw";
      });
      newLink.onload = () => {
        window.print();
        newLink.onload = null;
      };
      document.getElementById("maniContainer").appendChild(newLink);
      // }

    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  // useEffect(() => {
  //   if (parsedData.length > 0) {
  //     window.print();
  //   }
  //   return () => { };
  // }, [queryData]);

  const reportDetails = parsedData.length > 0;

  function EnhancedTableHead(props) {
    const { t } = useTranslation();
    if (inDeliveryAgentCustody) {
      const headCells = [
        { id: "1", numeric: true, disablePadding: false, label: t("requestCode") },
        { id: "9", numeric: true, disablePadding: false, label: t("status") },
        { id: "13", numeric: true, disablePadding: false, label: t("shippingAgent") },
        { id: "2", numeric: true, disablePadding: false, label: t("implementationDate") },
        { id: "10", numeric: true, disablePadding: false, label: t("branch") },
        {
          id: "3",
          numeric: true,
          disablePadding: false,
          label: t("customer"),
        },
        {
          id: "7",
          numeric: true,
          disablePadding: false,
          label: t("deservedAmount"),
        },
        {
          id: "14",
          numeric: true,
          disablePadding: false,
          label: t("deliveryCommission"),
        },
        { id: "8", numeric: true, disablePadding: false, label: t("netCollection") },
      ];

      return headCells.map((headCell) => (
        <FixedTableCell
          className={classes.headerTable}
          key={headCell.id}
          padding={headCell.disablePadding ? "normal" : "normal"}
        >
          {headCell.label}
        </FixedTableCell>
      ))
    } else {
      const headCells = [
        { id: "1", numeric: true, disablePadding: false, label: t("requestCode") },
        { id: "9", numeric: true, disablePadding: false, label: t("status") },
        { id: "2", numeric: true, disablePadding: false, label: t("implementationDate") },
        { id: "10", numeric: true, disablePadding: false, label: t("branch") },
        {
          id: "3",
          numeric: true,
          disablePadding: false,
          label: t("customer"),
        },
        {
          id: "7",
          numeric: true,
          disablePadding: false,
          label: t("amount"),
        },
        {
          id: "14",
          numeric: true,
          disablePadding: false,
          label: t("localAmount"),
        },
        { id: "8", numeric: true, disablePadding: false, label: t("deliveryFees") },
        { id: "11", numeric: true, disablePadding: false, label: t("collectionFees") },
        { id: "12", numeric: true, disablePadding: false, label: t("total") },
        { id: "13", numeric: true, disablePadding: false, label: t("shippingAgent") },
        {
          id: "5",
          numeric: true,
          disablePadding: false,
          label: t("accountNumber"),
        },
        { id: "6", numeric: true, disablePadding: false, label: t("phone") },
        { id: "4", numeric: true, disablePadding: false, label: t("address") },
      ];

      return headCells.map((headCell) => (
        <FixedTableCell
          className={classes.headerTable}
          key={headCell.id}
          padding={headCell.disablePadding ? "normal" : "normal"}
        >
          {headCell.label}
        </FixedTableCell>
      ))
    }

  }
  const table = !loading && !pageLoad && reportDetails !== null && (
    // const table = (
    <Table id="manifestPrintTable">
      <TableHead>
        <TableRow>
          <FixedTableCell>
          </FixedTableCell>
          <EnhancedTableHead />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          parsedData.map((row, k) => {
            return <TableRow key={k}>
              <FixedTableCell
                key={k}
              >
                {k + 1}
              </FixedTableCell>
              <CellLink
                pathname={`/admin/concierge-request/${row.id}`}
                component="th"
                scope="row"
              >
                {row.code}
              </CellLink>
              <FixedTableCell>
                {row.status?.name}
              </FixedTableCell>
              {inDeliveryAgentCustody && (<CellLink
                pathname={`/admin/delivery-Agents/${row.transactions[0]?.deliveryAgent?.id}`}
              >
                {row.transactions[0]?.deliveryAgent?.name}
              </CellLink>)}
              <FixedTableCell>
                {dateTimeFormatA(row.date)}
              </FixedTableCell>

              <CellLink
                pathname={`/admin/branches/${row.branch?.id}`}
              >
                {row.branch?.name}
              </CellLink>
              <CellLink
                pathname={`/admin/customers/${row.customer?.id}`}
              >
                {row.customer?.name}
              </CellLink>
              <Can permission={!inDeliveryAgentCustody} showException>
                <FixedTableCell>{`${formatMoney(row.amount)} ${row.currency.name}`}</FixedTableCell>
                <FixedTableCell>{formatMoney(row.localAmount)}</FixedTableCell>
                <FixedTableCell>{formatMoney(row.deliveryFees)}</FixedTableCell>
                <FixedTableCell>{formatMoney(row.collectionFees)}</FixedTableCell>
                <FixedTableCell>{formatMoney(row.totalAmount)}</FixedTableCell>
                <CellLink
                  pathname={`/admin/delivery-Agents/${row.transactions[0]?.deliveryAgent?.id}`}
                >
                  {row.transactions[0]?.deliveryAgent?.name}
                </CellLink>
                <FixedTableCell>{row.accountNumber}</FixedTableCell>
                <FixedTableCell dir="ltr">{row.phone}</FixedTableCell>
                <FixedTableCell>{row.address}</FixedTableCell>
              </Can>
              <Can permission={inDeliveryAgentCustody === true} showException>
                <FixedTableCell>{formatMoney(row.pendingCollectionAmount)}</FixedTableCell>
                <FixedTableCell>{formatMoney(row.commissionAmount)}</FixedTableCell>
                <FixedTableCell>{formatMoney(row.pendingCollectionAmount - row.commissionAmount)}</FixedTableCell>
              </Can>
            </TableRow>
          })
        }
        {queryData?.sumConciergeRequests && <TableRow>
          <FixedTableCell> </FixedTableCell>
          <FixedTableCell
            colSpan={5}
          />
          <FixedTableCell>{t("total")}</FixedTableCell>
          <Can permission={inDeliveryAgentCustody === true} showException>
            <FixedTableCell>{formatMoney(queryData?.sumConciergeRequests?.pendingCollectionAmount)}</FixedTableCell>
            <FixedTableCell>{formatMoney(queryData?.sumConciergeRequests?.commissionAmount)}</FixedTableCell>
            <FixedTableCell>{formatMoney(queryData?.sumConciergeRequests?.pendingCollectionAmount - queryData?.sumConciergeRequests?.commissionAmount)}</FixedTableCell>
            <FixedTableCell colSpan={5}> </FixedTableCell>
          </Can>
          <Can permission={!inDeliveryAgentCustody} showException>
            <FixedTableCell>
              {formatMoney(queryData?.sumConciergeRequests?.localAmount)}
            </FixedTableCell>
            <FixedTableCell>
              {formatMoney(queryData?.sumConciergeRequests?.deliveryFees)}
            </FixedTableCell>
            <FixedTableCell>
              {formatMoney(queryData?.sumConciergeRequests?.collectionFees)}
            </FixedTableCell>
            <FixedTableCell>
              {formatMoney(queryData?.sumConciergeRequests?.totalAmount)}
            </FixedTableCell>
            <FixedTableCell colSpan={4}> </FixedTableCell>
          </Can>
        </TableRow>}
      </TableBody>
    </Table>
  );

  return (
    <>
      {<RootPrintCollectionList id="maniContainer">
        {loading || pageLoad ? (
          // {true ? (
          <>
            <FullScreenLoading minHeight="20%" />
            {/* this spans for download font before print */}
            <span className={clsx(classesPrintCollectionList.barcodeFont)} />
            <span
              style={{ opacity: 0 }}
              className={classesPrintCollectionList.cairoFont}
            >
              lا
            </span>
          </>
        ) : "reportDetails" === null ? (
          <NotFound />
        ) : (
          <Root>
            <div id="printManifestContainer">
              <table >
                <tbody>
                  <tr>
                    <td>
                      <div
                        className={clsx(classes.shipmentsTable, {
                          // [classes.tableWidth]: tableZoom,
                        })}
                        id="shipmentsTableContainer"
                      >
                        {table}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Root>
        )}
      </RootPrintCollectionList>}
    </>);
  // "ok")
};

export default ConciergePrint;