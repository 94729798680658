import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import "../ManifestPrint/print.css";
import LogoImg from "../../Layout/LogoImg";

const PREFIX = "AccountStatementPrint";

const classes = {
    headerCells: `${PREFIX}-headerCells`,
    title: `${PREFIX}-title`,
    notes: `${PREFIX}-notes`,
    noteCell: `${PREFIX}-noteCell`,
    barcodeFont: `${PREFIX}-barcodeFont`,
    cairoFont: `${PREFIX}-cairoFont`,
    barcode: `${PREFIX}-barcode`,
    headerTableCell: `${PREFIX}-headerTableCell`,
    mainCellBorder: `${PREFIX}-mainCellBorder`,
    signature: `${PREFIX}-signature`,
    reportHeader: `${PREFIX}-reportHeader`,
    hiddenFooter: `${PREFIX}-hiddenFooter`,
    pageFooter: `${PREFIX}-pageFooter`,
    logoSection: `${PREFIX}-logoSection`,
    headerDate: `${PREFIX}-headerDate`,
    whiteSpace: `${PREFIX}-whiteSpace`,
    approve: `${PREFIX}-approve`,
    rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
    mainDetailsCell: `${PREFIX}-mainDetailsCell`,
    tableHeadCell: `${PREFIX}-tableHeadCell`,
    tableWidth: `${PREFIX}-tableWidth`,
    shipmentsTable: `${PREFIX}-shipmentsTable`,
    signatureBlock: `${PREFIX}-signatureBlock`,
};

const Root = styled("div")(({ theme }) => ({
    fontFamily: ['"Cairo"', "sans-serif"].join(","),

    [`& .${classes.headerCells}`]: {
        paddingBottom: theme.spacing(1),
    },

    [`& .${classes.title}`]: {
        color: theme.palette.info.main,
        textTransform: "capitalize"
        // whiteS;pace: "break-spaces",
    },

    [`& .${classes.notes}`]: {
        padding: theme.spacing(1),
        border: "1px solid " + theme.palette.action.disabled,
        wordBreak: "break-all",
        width: "100%",
        minHeight: 80,
    },

    [`& .${classes.noteCell}`]: {
        width: "30%",
        overflow: "hidden",
    },

    [`& .${classes.barcodeFont}`]: {
        fontFamily: '"Libre Barcode 39 Text"',
    },

    [`& .${classes.cairoFont}`]: {
        fontFamily: ['"Cairo"', "sans-serif"].join(","),
    },

    [`& .${classes.barcode}`]: {
        fontSize: 40,
        textAlign: "center",
    },

    [`& .${classes.headerTableCell}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classes.mainCellBorder}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classes.signature}`]: {
        textAlign: "center",
        padding: theme.spacing(2, 1, 1, 1),
        border: "none",
        textTransform: "capitalize"
    },

    [`& .${classes.reportHeader}`]: {
        whiteSpace: "pre-line",
        padding: theme.spacing(0, 2),
        lineHeight: "120%",
        fontSize: 12,
    },

    [`& .${classes.hiddenFooter}`]: {
        visibility: "hidden",
    },

    [`& .${classes.pageFooter}`]: {
        //   "&::after": {
        //    " content:  counter(page)  of  counter(pages)"
        //   },
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid " + theme.palette.text.secondary,
        display: "flex",
        alignItems: "flex-end",
    },

    [`& .${classes.logoSection}`]: {
        display: "flex",
        alignItems: "center",
    },

    [`& .${classes.headerDate}`]: {
        textAlign: "end",
    },

    [`& .${classes.whiteSpace}`]: {
        "& tr": {
            "& td": {
                whiteSpace: "initial",
            },
        },
    },

    [`& .${classes.approve}`]: {
        textAlign: "end",
    },

    [`& .${classes.rowWithoutBorder}`]: {
        "& td": {
            border: "none",
            padding: 0,
        },
    },

    [`& .${classes.mainDetailsCell}`]: {
        "& td": { border: "none", verticalAlign: "top" },
        "& td:first-of-type": { paddingLeft: 0 },
        "& td:last-of-type": { paddingRight: 0 },

        border: "none",
        padding: theme.spacing(1, 0),
    },

    [`& .${classes.tableHeadCell}`]: {
        padding: 0,
    },

    [`& .${classes.tableWidth}`]: {
        width: "100vw",
    },

    [`& .${classes.shipmentsTable}`]: {
        "& tr": {
            "&:hover": {
                backgroundColor: "#ffffff00 !important",
            },
        },
        "& td , & th": {
            padding: theme.spacing(1),
        },
    },

    [`& .${classes.signatureBlock}`]: {
        breakInside: "avoid",
    },
}));

export { };

const ORG = gqlb.query({
    operation: "organization",
    fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
        "registrationNumber",
        "taxNumber",
    ],
    variables: {},
});

const FINANCE = gqlb.query([
    {
        operation: "listAllGLAccounts",
        fields: [
            "id",
            "code",
            "name",
            "level",
            { type: ["code"] },
            {
                operation: "sumEntryRecords",
                fields: ["debit", "credit"],
                variables: {
                    fromDate: { type: "Date" },
                    toDate: { type: "Date" },
                },
            },
            {
                operation: "startBalance:balance",
                fields: [],
                variables: {
                    startBalanceDate: { name: "date", type: "Date", required: true },
                },
            },
            {
                operation: "endBalance:balance",
                fields: [],
                variables: {
                    endBalanceDate: { name: "date", type: "Date", required: true },
                    endDayEdge: {
                        name: "dayEdge",
                        type: "DayStartEnd",
                    },
                },
            },
        ],
        variables: {
            input: {
                type: "ListGLAccountsFilterInput",
            },
        },
    },
    {
        operation: "sumGlAccounts",
        fields: [
            "startSumDebit",
            "startSumCredit",
            "trxSumDebit",
            "trxSumCredit",
            "endSumDebit",
            "endSumCredit",
        ],
        variables: {
            sumInput: {
                name: "input",
                type: "SumGlAccountsInput"
            }
        },
    }
]);




const MainAccountStatementPrint = (props) => {
    const urlQuery = urlParameters(window.location.search);
    const { t } = useTranslation();


    const { data: organizationData } = useQuery(
        gql`
            ${ORG.query}
        `,
        {
            variables: {}
        }
    );

    const { data, loading } = useQuery(
        gql`
            ${FINANCE.query}
        `,
        {
            variables: {
                input: {
                    ...(!urlQuery?.["level"] && { parentId: urlQuery?.["glAccountId"] === 'null' ? null : urlQuery?.["glAccountId"] }),
                    ...(urlQuery?.["level"] && { level: urlQuery?.["level"] })
                },
                startBalanceDate: urlQuery?.["startDate"],
                endBalanceDate: urlQuery?.["endDate"],
                fromDate: urlQuery?.["startDate"],
                toDate: urlQuery?.["endDate"],

                endDayEdge: "END_OF_DAY",
                sumInput: {
                    ...(!urlQuery?.["level"] && { parentId: urlQuery?.["glAccountId"] === 'null' ? null : urlQuery?.["glAccountId"] }),
                    fromDate: urlQuery?.["startDate"],
                    toDate: urlQuery?.["endDate"],
                },
            },
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
            notifyOnNetworkStatusChange: !urlQuery?.["renderState"],
            skip: !urlQuery,
            onError: ({ graphQLErrors }) => {
                console.log(graphQLErrors);
            },
        }
    );

    const tableData = data?.listAllGLAccounts;
    const tableTotalData = data?.sumGlAccounts;
    // const totals = (array = [], paramter, type) => {
    //     let sum = 0;

    //     array.forEach((ele) => {
    //         if (type === "credit" && ele[paramter] >= 0) {
    //             sum += ele[paramter];
    //         } else if (type === "debit" && ele[paramter] <= 0) {
    //             sum += ele[paramter];
    //         }
    //     });
    //     return sum;
    // };

    // const totalEntryRecords = (array = [], type) => {
    //     let sum = 0;

    //     array.forEach((ele) => {
    //         sum += ele?.["sumEntryRecords"]?.[type];
    //     });
    //     return sum;
    // };

    // const startBalanceDebit = totals(tableData, "startBalance", "debit");
    // const startBalanceCredit = totals(tableData, "startBalance", "credit");
    // const startBalance = startBalanceDebit + startBalanceCredit;

    // const endBalanceDebit = totals(tableData, "endBalance", "debit");
    // const endBalanceCredit = totals(tableData, "endBalance", "credit");
    // const endBalance = endBalanceDebit + endBalanceCredit;

    // const entryRacordsDebit = totalEntryRecords(tableData, "debit");
    // const entryRacordsCredit = totalEntryRecords(tableData, "credit");
    // const entryRacords = entryRacordsCredit - entryRacordsDebit;

    const startBalance = tableTotalData?.startSumCredit - tableTotalData?.startSumDebit;
    const endBalance = tableTotalData?.endSumCredit - tableTotalData?.endSumDebit;
    const entryRacords = tableTotalData?.trxSumCredit - tableTotalData?.trxSumDebit;
    useEffect(() => {
        if (tableData?.length > 0) {
            window.print();
        }
        return () => { };
    }, [tableData]);

    const HandelLevel = (num) => {
        let level = ''
        for (let index = 0; index < num; index++) {
            level += '-'
        }
        return level
    }

    const header = !loading && data !== null && (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell padding="none">
                        <Table>
                            <TableBody>
                                <TableRow className={classes.rowWithoutBorder}>
                                    <TableCell>
                                        <Box className={classes.logoSection}>
                                            <LogoImg height={30} forceLight={true} />
                                            {/* <img src={logo} alt="logo" height={30} /> */}
                                            <Typography
                                                sx={{
                                                    whiteSpace: "pre-line !important",
                                                    paddingLeft: '10px',
                                                    lineHeight: "120%",
                                                    fontSize: 12,
                                                }}
                                                className={clsx(classes.reportHeader, "landscape-zoom")}
                                            >
                                                {organizationData?.organization?.reportHeader}
                                            </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell className={clsx(classes.headerDate)}>
                                        <Typography variant="h6">
                                            {moment(new Date()).locale("en").format("ll")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell className={classes.headerTableCell}>
                        <Table className={classes.whiteSpace}>
                            <TableBody>
                                <TableRow className={classes.rowWithoutBorder}>
                                    <TableCell>
                                        <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                                            {!urlQuery?.["level"] ? t("mainAccountStatement") : t("trialBalance")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell
                        className={clsx(classes.mainDetailsCell, "landscape-zoom")}
                    >
                        <Table className={classes.whiteSpace}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <TypographyKeyValue
                                            title={t("fromDate")}
                                            value={moment(urlQuery?.["startDate"]).format("ll")}
                                        />
                                        <TypographyKeyValue
                                            title={t("toDate")}
                                            value={moment(urlQuery?.["endDate"]).format("ll")}
                                        />
                                    </TableCell>
                                    {urlQuery?.["level"] && (<TableCell>
                                        <TypographyKeyValue
                                            title={t("level")}
                                            value={urlQuery?.["level"]}
                                        />
                                    </TableCell>)}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const table = !loading && data !== null && (
        <Table id="manifestPrintTable">
            <TableHead>
                <TableRow>
                    <FixedTableCell colSpan={2} />

                    <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("startBalance")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("sumEntryRecords")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("balance")}
                    </FixedTableCell>
                </TableRow>
                <TableRow>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {tableData
                    .map((row, index) => {
                        return (
                            <TableRow key={Math.random()}>
                                <FixedTableCell component="th" scope="row">
                                    {row.code}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {urlQuery?.["level"] && HandelLevel(row.level)} {row.name}
                                </FixedTableCell>

                                <FixedTableCell>
                                    {row?.startBalance >= 0
                                        ? "0"
                                        : Math.abs(row?.startBalance)?.toFixed(2) ||
                                        "0"}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row?.startBalance <= 0
                                        ? "0"
                                        : Math.abs(row?.startBalance)?.toFixed(2) ||
                                        "0"}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row?.sumEntryRecords?.debit?.toFixed(2)}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row?.sumEntryRecords?.credit?.toFixed(2)}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row?.endBalance >= 0
                                        ? "0"
                                        : Math.abs(row?.endBalance)?.toFixed(2) || "0"}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row?.endBalance <= 0
                                        ? "0"
                                        : Math.abs(row?.endBalance)?.toFixed(2) || "0"}
                                </FixedTableCell>
                            </TableRow>
                        );
                    })
                }

                <TableRow className={classes.balanceFont}>
                    <FixedTableCell />
                    <FixedTableCell>{t("total")}</FixedTableCell>
                    <FixedTableCell>
                        {tableTotalData?.startSumDebit}
                    </FixedTableCell>
                    <FixedTableCell>
                        {tableTotalData?.startSumCredit}
                    </FixedTableCell>

                    <FixedTableCell>
                        {tableTotalData?.trxSumDebit}
                    </FixedTableCell>
                    <FixedTableCell>
                        {tableTotalData?.trxSumCredit}
                    </FixedTableCell>

                    <FixedTableCell>
                        {tableTotalData?.endSumDebit}
                    </FixedTableCell>
                    <FixedTableCell>
                        {tableTotalData?.endSumCredit}
                    </FixedTableCell>
                </TableRow>

                {!urlQuery?.["level"] && (<TableRow className={classes.balanceFont}>
                    <FixedTableCell />
                    <FixedTableCell>{t("balance")}</FixedTableCell>
                    <FixedTableCell>
                        {startBalance >= 0
                            ? "0"
                            : Math.abs(startBalance)?.toFixed(2) || "0"}
                    </FixedTableCell>
                    <FixedTableCell>
                        {startBalance <= 0
                            ? "0"
                            : Math.abs(startBalance)?.toFixed(2) || "0"}
                    </FixedTableCell>

                    <FixedTableCell>
                        {entryRacords >= 0
                            ? "0"
                            : Math.abs(entryRacords)?.toFixed(2) || "0"}
                    </FixedTableCell>
                    <FixedTableCell>
                        {entryRacords <= 0
                            ? "0"
                            : Math.abs(entryRacords)?.toFixed(2) || "0"}
                    </FixedTableCell>

                    <FixedTableCell>
                        {endBalance >= 0
                            ? "0"
                            : Math.abs(endBalance)?.toFixed(2) || "0"}
                    </FixedTableCell>
                    <FixedTableCell>
                        {endBalance <= 0
                            ? "0"
                            : Math.abs(endBalance)?.toFixed(2) || "0"}
                    </FixedTableCell>
                </TableRow>)}
            </TableBody>
        </Table >
    );

    return (
        <Root>
            {loading ? (
                <>
                    <FullScreenLoading minHeight="20%" />
                    {/* this spans for download font before print */}
                    <span className={clsx(classes.barcodeFont)} />
                    <span style={{ opacity: 0 }} className={classes.cairoFont}>
                        lا
                    </span>
                </>
            ) : data === null ? (
                <NotFound />
            ) : (
                <>
                    <div>
                        <div className="page-header">{header}</div>

                        <div className="page-footer">
                            {organizationData?.organization?.reportFooter}
                        </div>

                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td>
                                        <div className="page-header-space">{header}</div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <div
                                            className={clsx(classes.shipmentsTable, {
                                                // [classes.tableWidth]: tableZoom,
                                            })}
                                        >
                                            {table}
                                        </div>
                                        <div className={classes.signatureBlock}>

                                            <Table className={classes.whiteSpace}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("accountant")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("chiefAccountant")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("generalManager")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td>
                                        <div className="page-footer-space">
                                            {organizationData?.organization?.reportFooter}
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
            )}
        </Root>
    );
};

export default MainAccountStatementPrint;

function TypographyKeyValue(props) {
    const { title, value, hidden, ...restProps } = props;
    return hidden || !value ? null : (
        <Typography
            variant="body2"
            className={clsx(classes.keyValue)}
            {...restProps}
        >
            <span className={classes.title}>{title}:</span> <span>{value}</span>
        </Typography>
    );
}



