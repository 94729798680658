import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  TableBody,
  TableRow,
  Icon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { LIST_SHIPPING_SERVICES } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";

const PREFIX = 'ShippingServicesList';

const classes = {
  iconColor: `${PREFIX}-iconColor`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

const ShippingServicesList = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const { data, loading } = useQuery(
    gql`
      ${LIST_SHIPPING_SERVICES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => { },
    }
  );

  const listShippingServices = data?.listShippingServices?.data;
  // let tableBody;

  const icons = [
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, `/admin/shipping-services/create`),
      icon: Add,
      permission: "shipping.shipping_service.create",
    },
  ]

  // tableBody = (
  //   <StyledGrid
  //     container
  //     item
  //     xs={12}
  //     justifyContent="center"
  //     className={classes.mainContainer}
  //   >
  //     {/* *******Table******* */}
  //     <Paper className={clsx(classes.paper)}>
  //       <TitleAppBar path={props.match.path} >
  //         <LongMenu icons={icons} />
  //       </TitleAppBar>

  //       {loading || listShippingServices?.length === 0 ? (
  //         <Grid container item justifyContent="center" className={classes.main}>
  //           {loading ? (
  //             <FullScreenLoading minHeight={30} />
  //           ) : (
  //             <Box textAlign="center">
  //               <img width="50%" src={emptyList} alt={"delivery box"} />
  //               <Box
  //                 component={Typography}
  //                 variant="h6"
  //                 color="text.secondary"
  //                 marginTop="0"
  //               >
  //                 {t("searchNoResult")}
  //               </Box>
  //             </Box>
  //           )}
  //         </Grid>
  //       ) : (
  //         <TableFixedHeaderWraper>
  //           <Table aria-labelledby="tableTitle" aria-label="enhanced table">
  //             <TableHead>
  //               <TableRow>
  //                 <FixedTableCell>{t("name")}</FixedTableCell>
  //                 <FixedTableCell>{t("code")}</FixedTableCell>
  //                 <FixedTableCell>{t("shippingDays")}</FixedTableCell>
  //                 <FixedTableCell>{t("branch")}</FixedTableCell>
  //                 <FixedTableCell>{t("deliveryAccount")}</FixedTableCell>
  //                 <FixedTableCell>{t("collectionAccount")}</FixedTableCell>
  //                 <FixedTableCell>{t("commissionAccount")}</FixedTableCell>
  //                 <FixedTableCell>{t("active")}</FixedTableCell>
  //               </TableRow>
  //             </TableHead>
  //             <TableBody>
  //               {listShippingServices &&
  //                 listShippingServices?.map((row, index) => {
  //                   return (
  //                     <TableRow hover role="checkbox" tabIndex={-1} key={index}>
  //                       <CellLink
  //                         pathname={`/admin/shipping-services/${row?.id}`}
  //                       >
  //                         {row?.name}
  //                       </CellLink>

  //                       <FixedTableCell>{row?.code}</FixedTableCell>
  //                       <FixedTableCell>{row?.days}</FixedTableCell>
  //                       <FixedTableCell>{row?.branch?.name}</FixedTableCell>
  //                       <FixedTableCell>
  //                         {row?.deliveryAccount?.name}
  //                       </FixedTableCell>
  //                       <FixedTableCell>
  //                         {row?.collectionAccount?.name}
  //                       </FixedTableCell>
  //                       <FixedTableCell>
  //                         {row?.commissionAccount?.name}
  //                       </FixedTableCell>
  //                       <FixedTableCell>
  //                         {row?.active ? (
  //                           <Icon className={classes.iconColor}>
  //                             check_circle_outline
  //                           </Icon>
  //                         ) : (
  //                           <Icon color="error">highlight_off</Icon>
  //                         )}
  //                       </FixedTableCell>
  //                     </TableRow>
  //                   );
  //                 })}
  //             </TableBody>
  //           </Table>
  //         </TableFixedHeaderWraper>
  //       )}
  //       <MUITablePagination
  //         count={data?.listShippingServices?.paginatorInfo?.total}
  //         rowsPerPage={rowsPerPage}
  //         page={!data?.listShippingServices ? 0 : page}
  //         onPageChange={handleChangePage}
  //         onRowsPerPageChange={handleChangeRowsPerPage}
  //       />
  //     </Paper>
  //   </StyledGrid>
  // );

  return <StyledGrid>
    <ListWrapper
      drawerState={false}
      icons={icons}
      path={props.match.path}
      empty={listShippingServices?.length === 0}
      loading={loading}
      filters={false}
      tableHeaders={
        [
          {
            name: "name"
          },
          {
            name: "code"
          },
          {
            name: "shippingDays"
          },
          {
            name: "branch"
          },
          {
            name: "deliveryAccount"
          },
          {
            name: "collectionAccount"
          },
          {
            name: "commissionAccount"
          },
          {
            name: "active"
          },
        ]
      }
      tableBody={
        <TableBody>
          {listShippingServices &&
            listShippingServices?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <CellLink
                    pathname={`/admin/shipping-services/${row?.id}`}
                  >
                    {row?.name}
                  </CellLink>

                  <FixedTableCell>{row?.code}</FixedTableCell>
                  <FixedTableCell>{row?.days}</FixedTableCell>
                  <FixedTableCell>{row?.branch?.name}</FixedTableCell>
                  <FixedTableCell>
                    {row?.deliveryAccount?.name}
                  </FixedTableCell>
                  <FixedTableCell>
                    {row?.collectionAccount?.name}
                  </FixedTableCell>
                  <FixedTableCell>
                    {row?.commissionAccount?.name}
                  </FixedTableCell>
                  <FixedTableCell>
                    {row?.active ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )}
                  </FixedTableCell>
                </TableRow>
              );
            })}
        </TableBody>
      }
      pagination={
        <MUITablePagination
          count={data?.listShippingServices?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!data?.listShippingServices ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    />
  </StyledGrid>;
};

export default ShippingServicesList;
