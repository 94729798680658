import React, { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client';
import ManifestTransferView from '../ManifestsTransfer/ManifestTransferView';
import ManifestView from '../Manifests/ManifestView';
import FullScreenLoading from '../HOC/FunctionComponents/LoadingPages/FullScreenLoading';
import { MANIFEST_BY_ID_AND_PRINT, GET_MANIFEST_TYPE, initialManifest, shipmentManifest } from '../ManifestsTransfer/Graphql';
import NotFound from '../../Error/NotFound';
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { renderData } from '../shipmentInview/shipmentColumView/SelectFieldColumView';
const PREFIX = "ManifestViewRoute";

const classes = {
    main: `${PREFIX}-main`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
    [`&.${classes.main}`]: {
        height: "calc(100vh - (40px + 64px))",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            height: "calc(100dvh - (40px + 56px))",
        },
    },
}));

export default function ViewRouteManifest(props) {
    const manifestId = props.match.params.id
    const { data: manifestByIdQuery } = useQuery(
        gql`
            ${GET_MANIFEST_TYPE.query}
        `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            skip: !manifestId,
            variables: {
                id: parseInt(manifestId),
            },
            onCompleted: (data) => {
            },
        }
    );

    const [disableForm, setDisableForm] = useState(false);
    const [disableView, setDisableView] = useState(false);
    const [manifestType, setManifestType] = useState(null);
    const [referredManifestsApprove, setReferredManifestsApprove] = useState(true)
    const [receivedShipment, setReceivedShipment] = useState({ shipments: [] });
    const [referredManifestData, setReferredManifestData] = useState(false);
    const [transferManifestData, setTransferManifestData] = useState(null);

    const [shipmentListDetails, setShipmentListDetails] = useState({
        shipmentQueryFields: null,
        shipmentTableBody: [],
        shipmentTableHead: [],
    })
    const [localDataKey, setLocalDataKey] = useState(null)

    const { data, loading: manifestByIdLoad } = useQuery(
        gql`
            ${MANIFEST_BY_ID_AND_PRINT.query}
        `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            notifyOnNetworkStatusChange: true,
            skip: !shipmentListDetails.shipmentQueryFields || !manifestByIdQuery,
            variables: {
                id: parseInt(manifestId),
            },
            onCompleted: (data) => {
                if (data?.manifest === null) return;
                if (data.manifest.transferType) {
                    const manifest = data?.manifest;
                    setTransferManifestData(manifest);
                    setManifestType(data?.manifest?.referredManifests.length > 0 ? "BMR" : manifest?.transactionType?.type.code);
                    const shipments = []
                    if (data?.manifest?.referredManifests.length > 0) {
                        data?.manifest?.referredManifests.map((e) => {
                            // shipments.push(...e.manifestShipments)
                            !e.approved && setReferredManifestsApprove(false)
                            return (e)
                        })
                        setReceivedShipment(shipments)
                        setReferredManifestData(true)
                    } else {
                        setReferredManifestsApprove(false)
                    }
                    setDisableForm(true);
                } else {
                    const manifest = data?.manifest;
                    setManifestType(manifest?.transactionType?.type.code);
                    setDisableForm(true);
                }
            },
            onError: ({ graphQLErrors }) => {
                const auth = graphQLErrors[0].extensions.category
                auth === 'authorization' && setDisableView(true)
            }
        }
    );

    useEffect(() => {
        if (manifestByIdQuery) {
            setLocalDataKey(localStorage.getItem(`shipmentManifest${manifestByIdQuery?.manifest?.transactionType?.type?.code}`) ? JSON.parse(localStorage.getItem(`shipmentManifest${manifestByIdQuery?.manifest?.transactionType?.type?.code}`)) : initialManifest[manifestByIdQuery?.manifest?.transactionType?.type?.code])
        }
        return () => { };
    }, [manifestByIdQuery]);
    useEffect(() => {
        if (localDataKey) {
            renderData(localDataKey, setShipmentListDetails, shipmentManifest[manifestByIdQuery?.manifest?.transactionType?.type?.code])
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localDataKey]);

    console.log(data?.manifest.transferType);

    return manifestByIdLoad || !shipmentListDetails.shipmentQueryFields || !manifestByIdQuery ?
        <StyledLoading container item justifyContent="center" className={classes.main}>
            <FullScreenLoading height={"100%"} />
        </StyledLoading>
        : !data?.manifest ? <NotFound /> :
            data?.manifest.transferType
                ?
                (
                    <ManifestTransferView
                        pageProps={props}
                        data={data}
                        disableForm={disableForm}
                        disableView={disableView}
                        manifestType={manifestType}
                        manifestByIdLoad={manifestByIdLoad}
                        manifestId={manifestId}
                        setLocalDataKey={setLocalDataKey}
                        shipmentListDetails={shipmentListDetails}
                        transferManifestData={transferManifestData}

                        referredManifestsApprove={referredManifestsApprove}
                        receivedShipment={receivedShipment}
                        referredManifestData={referredManifestData}
                        transferType={data.manifest.transferType.code}
                    />
                ) :
                (
                    <ManifestView
                        pageProps={props}
                        data={data}
                        disableForm={disableForm}
                        disableView={disableView}
                        manifestType={manifestType}
                        manifestByIdLoad={manifestByIdLoad}
                        manifestId={manifestId}
                        setLocalDataKey={setLocalDataKey}
                        shipmentListDetails={shipmentListDetails}
                    />
                )
}
