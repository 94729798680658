import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  TableBody,
  TableRow,
  Typography,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Collapse,
  TableHead
} from "@mui/material";
import { FilterList, Search } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";
import { APPROVE_REGISTRATION, LIST_REGISTRATIONS } from "./Graphql";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import { useForm } from "react-hook-form";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUIselect from "../HOC/MUI/MUIselect";
import { useSnackbar } from "notistack";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import CellLink from "../HOC/CustomComponents/CellLink";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";
import ExportRegistrationsList from "./ExportRegistrationsList";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
// import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { Can } from "../HOC/CustomComponents/Secured";
import { GetPermissionSlug } from "../../helpers/getPermissionSlug";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "Registrations";

const classes = {
  button: `${PREFIX}-button`,
  table_status: `${PREFIX}-table_status`,
  table_code: `${PREFIX}-table_code`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
  approveRegistration: `${PREFIX}-approveRegistration`,
  iconColor: `${PREFIX}-iconColor`,
  last_header_item: `${PREFIX}-last_header_item`

};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },
  //////////////////////Drawer///////////////////

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.table_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },
  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  }, [`& .${classes.approveRegistration}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
  [`& .${classes.last_header_item}`]: {
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    textTransform: "capitalize",
    [theme.breakpoints.up("sm")]: {
      right: "0",
    },
  },
}));

const Registrations = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const listType = props.match?.params?.type.toUpperCase();

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : null;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    if (validUrlParameters) {
      if (urlQuery["token"]) urlQuery["token"] = urlQuery["token"]?.toString();
      const searchParams = urlQuery;
      delete searchParams["rowsPerPage"];
      searchParameters = searchParams;
    }
    return searchParameters;
  };
  const [search, setSearch] = useState(initSearch());
  const [page, setPage] = useState(0);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const { data, loading } = useQuery(
    gql`
      ${LIST_REGISTRATIONS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.token && { token: search.token }),
          ...(listType && { customerTypeCode: listType }),
          ...(search.verified !== "" && { verified: search.verified }),
          ...(search.new !== "" && { new: search.new }),
          ...(search.new !== "" && { new: search.new }),
          ...(search.emailVerified !== "" && {
            emailVerified: search.emailVerified,
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );
  const listRegistrations = data?.listRegistrations?.data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  useEffect(() => {
    if (urlQuery["token"]) urlQuery["token"] = urlQuery["token"].toString();
    urlQuery["token"] && setValue("token", urlQuery["token"]);
    urlQuery["verified"] !== undefined &&
      setValue("verified", urlQuery["verified"]);
    urlQuery["emailVerified"] !== undefined &&
      setValue("emailVerified", urlQuery["emailVerified"]);
    urlQuery["new"] !== undefined &&
      setValue("new", urlQuery["new"]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/registrations/${listType.toLowerCase()}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const onSubmit = (data) => {
    (refetch) ? setrefetch(false) : setrefetch(true)
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });

    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      token: data.token.toString(),
      ...data,
      page: 0,
    };
    data["verified"] === "" && delete handledData["verified"];
    data["new"] === "" && delete handledData["new"];
    data["emailVerified"] === "" && delete handledData["emailVerified"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }

    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    setSearch((prev) => ({
      // ...prev,
      ...handledData,
      refetch: !prev.refetch,
    }));
  };
  function EnhancedTableHead(props) {
    const { listType } = props;
    const { t } = useTranslation();

    const headCells = [
      { id: "1", numeric: true, disablePadding: false, label: t("storeName") },
      { id: "2", numeric: true, disablePadding: false, label: t("name") },
      { id: "3", numeric: true, disablePadding: false, label: t("date") },
      { id: "4", numeric: true, disablePadding: false, label: t("username") },
      { id: "5", numeric: true, disablePadding: false, label: t("source") },
      { id: "6", numeric: true, disablePadding: false, label: t("address") },
      { id: "7", numeric: true, disablePadding: false, label: t("email") },
      { id: "8", numeric: true, disablePadding: false, label: t("mobile") },
      { id: "9", numeric: true, disablePadding: false, label: t("verified") },
      { id: "10", numeric: true, disablePadding: false, label: t("emailVerified") },
      { id: "11", numeric: true, disablePadding: false, label: t(" "), class: "last_header_item" },
    ];
    if (listType === "CONCIERGE") {
      headCells.splice(4, 1);
    }
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <FixedTableCell
                className={headCell.class ? classes.last_header_item : classes.headerTable}
                key={headCell.id}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </FixedTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  // const lookupsComplete = (data, updateValue, name) => {
  //   if (updateValue && lookupCodeIsValid(data, updateValue)) {
  //     setValue(name, updateValue);
  //   }
  // };

  const [dialogState, setDialogState] = useState(false);
  const [registrationId, setRegistrationId] = useState(null);
  const handelApproveRegistration = (id) => {
    setRegistrationId(id);
    setDialogState(true);
  };
  const handleDialogClose = () => {
    setDialogState(false);
  };
  const resetDate = () => {
    setDateRange([null, null]);
  };
  const [approveRegistration, { loading: approveRegistrationLoading, client }] =
    useMutation(
      gql`
        ${APPROVE_REGISTRATION.query}
      `
    );
  const approveRegistrationData = () => {
    approveRegistration({
      variables: {
        id: registrationId,
      },
    })
      .then((data) => {
        setRegistrationId(null);
        handleDialogClose();
        client.refetchQueries({
          include: [
            gql`
              ${LIST_REGISTRATIONS.query}
            `,
          ],
        });
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        console.log(error);
        setRegistrationId(null);
        handleDialogClose();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const exportFilters = {
    verified: search.verified,
    new: search.new,
    ...(search.fromDate && { fromDate: search.fromDate }),
    ...(search.toDate && { toDate: search.toDate }),
    emailVerified: search.emailVerified,
    ...(search.token && { token: search.token }),
    ...(listType && { customerTypeCode: listType }),
  }

  const pagePermission = GetPermissionSlug("shipping", "registration", listType, "list")

  const [openExport, setOpenExport] = useState(false);
  const openExportDialog = () => {
    setOpenExport(true)
  };
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: Boolean(listRegistrations?.length === 0)
    },
  ]

  return (
    <Can permission={pagePermission}>
      <Root>
        <Dialog
          open={dialogState}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h6" color={"text.primary"}>
              {t("registeredAccounts")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("areYouSureToActivateTheUser?")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button
              onClick={approveRegistrationData}
              color="primary"
              autoFocus
              disabled={approveRegistrationLoading}
            >
              {approveRegistrationLoading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </DialogActions>
        </Dialog>
        <ExportRegistrationsList
          openExport={openExport}
          setOpenExport={setOpenExport}
          filters={exportFilters}
        />
        <ListWrapper
          drawerState={drawerState[filterAnchor]}
          icons={icons}
          path={props.match.path}
          type={listType}
          empty={listRegistrations?.length === 0}
          loading={loading}
          filters={
            <Grid
              container
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              className={classes.filterForm} >
              <Grid
                container
                item
                sm={12}
                justifyContent="flex-start"
                spacing={1}
                className={classes.filterField}
              >
                <Grid item sm={12} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"token"}
                    label={t("search")}
                  />
                </Grid>
                <Grid item sm={12} alignItems="flex-start">
                  <MUIDateRangeCustom
                    value={dateRange}
                    onChangeValue={(value) => setDateRange(value)}
                    // disabled={codeValidation}
                    resetDate={resetDate}
                  />
                </Grid>
                <Grid item sm={12} alignItems="flex-start">
                  <MUIselect
                    name={"new"}
                    label={t("new")}
                    control={control}
                    errors={errors}
                    data={[
                      { key: t("yes"), value: true },
                      { key: t("no"), value: false },
                    ]}
                  />
                </Grid>
                <Grid item sm={12} alignItems="flex-start">
                  <MUIselect
                    name={"verified"}
                    label={t("verified")}
                    control={control}
                    errors={errors}
                    data={[
                      { key: t("notVerified"), value: false },
                      { key: t("verified"), value: true },
                    ]}
                  />
                </Grid>
                <Grid item sm={12} alignItems="flex-start">
                  <MUIselect
                    name={"emailVerified"}
                    label={t("emailVerified")}
                    control={control}
                    errors={errors}
                    data={[
                      { key: t("emailNotVerified"), value: false },
                      { key: t("emailVerified"), value: true },
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                className={classes.button}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="primary"
                  startIcon={<Search />}
                >
                  {t("search")}
                </Button>
              </Grid>




            </Grid>
          }
          tableHeaders={
            <EnhancedTableHead
              listType={listType}
            />
          }
          tableBody={
            <TableBody>
              {listRegistrations &&
                listRegistrations?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <FixedTableCell>{row?.businessName}</FixedTableCell>
                      <FixedTableCell>{row?.name}</FixedTableCell>
                      <FixedTableCell>{row?.createdAt}</FixedTableCell>
                      <CellLink pathname={`/admin/users/${row?.user?.id}`}>
                        {row?.user?.username}
                      </CellLink>
                      <Can permission={listType !== "CONCIERGE"} showException>
                        <ShipmentRegion
                          pathname={`/admin/zones/${row?.zone?.id}`}
                          zone={row?.zone?.name}
                          subzone={row?.subzone?.name}
                        />
                      </Can>
                      <FixedTableCell>{row?.address}</FixedTableCell>
                      <FixedTableCell>{row?.email}</FixedTableCell>
                      <FixedTableCell>{row?.mobile}</FixedTableCell>
                      <FixedTableCell>
                        {row?.verified ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>
                      <FixedTableCell>
                        {row?.emailVerified ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>

                      <FixedTableCell
                        className={classes.approveRegistration}
                        component="th"
                        scope="row"
                      >
                        {!row.verified && (
                          <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              handelApproveRegistration(row.id)
                            }
                            startIcon={<Icon>task_alt</Icon>}
                          >
                            {t("approve")}
                          </Button>
                        )}
                      </FixedTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          }
          pagination={
            <MUITablePagination
              count={data?.listRegistrations?.paginatorInfo?.total}
              rowsPerPage={rowsPerPage}
              page={!data?.listRegistrations ? 0 : page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        />
      </Root>
    </Can>
  );
};

export default Registrations;
