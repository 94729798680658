/* eslint-disable no-useless-concat */
import { gql, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import { DeleteOutline, Edit } from "@mui/icons-material";
import { Icon, IconButton, Paper, Table, TableBody, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_PRODUCT, PRODUCTID } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CellLink from "../HOC/CustomComponents/CellLink";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'ProductView';

const classes = {
  box: `${PREFIX}-box`,
  toolbar: `${PREFIX}-toolbar`,
  main: `${PREFIX}-main`,
  iconColor: `${PREFIX}-iconColor`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
    width: "100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

// const formatDate = (date) => moment(date).locale("en").format("YYYY-MM-DD");
// const initToDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const ProductView = (props) => {

  const productId = props.id ?? props.match?.params?.id?.trim();


  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${PRODUCTID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(productId) },
    }
  );

  const user = Globals.user;

  const [openDelete, setOpenDelete] = useState(false);

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/products/${productId}/edit`),
      icon: Edit,
      permission: "shipping.product.update",
    },
    {
      id: "delete",
      title: "delete",
      action: () => setOpenDelete(true),
      icon: DeleteOutline,
      permission: Boolean(data?.product?.deletable),
    },
  ]

  return  loading?
  <StyledLoading container item justifyContent="center" className={classes.main}>
    <FullScreenLoading height={"100%"} />
  </StyledLoading>
  : !data?.product? (
    <NotFound />
  ) : // <Can permission={pagePermission}>
    (
        <StyledGrid container justifyContent="center" sx={{ margin: "0", width: "100%" }}>
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
            <MutationWithDialog
              mutaion={DELETE_PRODUCT.query}
              setOpenDelete={setOpenDelete}
              openDelete={openDelete}
              dialogTitle={t("deleteManifest")}
              dialogContent={t("deleteManifestConfirmation")}
              mutaionProps={{ variables: { id: parseInt(productId) } }}
              onCompleted={() =>
                pushUrl(props, `/admin/products`)
              }
              onCompleteMessage={t("manifestDeleted")}
            />
          </TitleAppBar>

          <Grid
            container
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair title={t("code")} value={data?.product.code} />
              <KeyValuePair title={t("name")} value={data?.product.name} />
              <KeyValuePair
                title={t("createdAt")}
                value={data?.product.createdAt}
              />
              <KeyValuePair
                title={t("updatedAt")}
                value={data?.product.updatedAt}
              />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.product?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("quantity")}
                value={data?.product?.availableQuantity}
              />
              <KeyValuePair
                title={t("weight")}
                value={data?.product?.weight}
              />
              <KeyValuePair
                title={t("price")}
                value={data?.product?.price}
              />
              {!user?.account && (<KeyValuePair
                title={t("customer")}
                value={
                  data?.product?.customer?.name && (
                    <SpanLink
                      pathname={`/admin/customers/${data?.product?.customer?.id}`}
                    >
                      {data?.product?.customer?.name}
                    </SpanLink>
                  )
                }
              />)}
              <KeyValuePair
                title={t("createdBy")}
                value={
                  data?.product && (
                    <SpanLink
                      pathname={`/admin/users/${data?.product?.createdBy?.id}`}
                    >
                      {data?.product?.createdBy?.username}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  data?.product && (
                    <SpanLink
                      pathname={`/admin/users/${data?.product?.updatedBy?.id}`}
                    >
                      {data?.product?.updatedBy?.username}
                    </SpanLink>
                  )
                }
              />
            </Paper>
            <Paper container className={classes.box} component={Grid}>
              <Toolbar className={clsx(classes.toolbar)} variant="dense">
                <Typography color="inherit" variant="h6">
                  {t("availableQuantity")}
                </Typography>
              </Toolbar>
              {data?.product?.branchesBalance.length > 0 ?
                <TableFixedHeaderWraper >
                  <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <TableHead>
                      <TableRow>
                        <FixedTableCell>#</FixedTableCell>
                        <FixedTableCell>{t("branch")}</FixedTableCell>
                        <FixedTableCell>{t("quantity")}</FixedTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.product?.branchesBalance.map((balance, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          // className={classes.tableBodyRow}
                          >
                            <FixedTableCell>{index + 1}</FixedTableCell>
                            <CellLink
                              align="left"
                              pathname={`/admin/branches/${balance?.branch?.id}`}
                            >
                              {balance.branch.name}
                            </CellLink>
                            <FixedTableCell>{balance.quantity}</FixedTableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableFixedHeaderWraper>
                : <EmptyTableMessage loading={false} message={t("noAvailableQuantity")} />}
            </Paper>
          </Grid>
          </StyledGrid>
    
  );
};

export default ProductView;
