import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Collapse,
  DialogTitle,
  DialogContent,
  FormHelperText,
  Button,
  DialogActions,
  Dialog,
  Box,
} from "@mui/material";
import { ROUTE_ID, SAVE_ROUTE } from "./Graphql";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import StopsTable from "./StopsTable";
import {
  LIST_DRIVERS_DROPDOWN,
  LIST_VEHICLES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = "RouteForm";

const classes = {
  mainForm: `${PREFIX}-mainForm`,
  paper: `${PREFIX}-paper`,
  typography: `${PREFIX}-typography`,
  main: `${PREFIX}-main`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainForm}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },

  [`& .${classes.typography}`]: {
    margin: theme.spacing(2),
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const RouteForm = (props) => {
  const [stopsList, setStopsList] = useState([]);
  const sortedStopsList = stopsList.sort((a, b) => a.order - b.order);
  const [stopIndex, setStopIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [stopErrorMessage, setStopErrorMessage] = useState(false);
  const [autocompleteValues, setAutocompleteValues] = useState({
    driver: null,
    vehicle: null,
  });
  const [selectedZones, setSelectedZones] = useState([]);
  const [selectedSubZones, setSelectedSubZones] = useState([]);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, setValue, setError } = useForm({
    defaultValues: {
      active: true,
    },
  });
  const {
    control: stopsControl,
    formState: { errors: stopsErrors },
    handleSubmit: stopsHandleSubmit,
    setValue: stopsSetValue,
    watch: stopsWatch,
    reset: stopsReset,
  } = useForm({
    defaultValues: {
      order: "",
      zone: "",
      subzone: "",
    },
  });
  const { errors } = formState;
  const [saveRoute, { loading: saveRouteLoading }] = useMutation(
    gql`
      ${SAVE_ROUTE.query}
    `
  );
  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog(false);
    setStopErrorMessage(false);
    setStopIndex((prev) => ({ ...prev, update: false }));
    stopsReset();
  };

  const addStopDialog = (index) => {
    stopsReset();
    if (index || index === 0) {
      setStopIndex({
        index,
        update: true,
      });
    } else {
      setStopIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onChangeZone = (e) => {
    const zones = []
    e && e.map((ele) =>
      zones.push(ele.name)
    )
    setStopErrorMessage(false);
    setSelectedZones(zones)
  };
  const onChangeSubZone = (e) => {
    const subSones = []
    e && e.map((ele) =>
      subSones.push(ele.name)
    )
    setStopErrorMessage(false);
    setSelectedSubZones(subSones)
  };

  const onSubmitStops = (data) => {
    let order = sortedStopsList.length + 1
    const newStops = []
    data.zone.length && !(data.subzone.length > 1) && data.zone.map((ele, index) => {
      let newStop = {
        order: order++,
        zone: { id: ele, name: selectedZones[index] },
        subzone: data.subzone
          ? { id: data.subzone[0], name: selectedSubZones[0] }
          : null,
      };
      return newStops.push(newStop)
    })
    data.subzone.length > 1 && data.subzone.map((ele, index) => {
      let newStop = {
        order: order++,
        zone: { id: data.zone[0], name: selectedZones[0] },
        subzone: { id: ele, name: selectedSubZones[index] },
      }
      return newStops.push(newStop)
    })

    const updateStops = [...sortedStopsList];
    const invalidStop = updateStops.some((i, index) =>
      newStops.some((ele) =>
        i.zone.id === ele.zone.id && i.subzone?.id === ele.subzone?.id
      )
    );

    if (invalidStop) {
      setStopErrorMessage(true);
    } else {
      newStops.map((ele) =>
        updateStops.push(ele)
      )
      setStopsList(updateStops);
      handelCloseDialog();
    }
  };

  const routeId = parseInt(props.match.params.id);

  const { loading: routeLoading ,data} = useQuery(
    gql`
      ${ROUTE_ID.query}
    `,
    {
      skip: !routeId,
      variables: { id: routeId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const routeData = data?.route;
        const saveRoutesParams = ["id", "code", "name"];
        const makeName = routeData?.vehicle?.series?.model?.make?.name;
        const modelName = routeData?.vehicle?.series?.model?.name;
        const seriesName = routeData?.vehicle?.series?.name;
        const plateNumber = routeData?.vehicle?.plateNumber;
        setStopsList(routeData.stops);
        saveRoutesParams.forEach((i) => {
          routeData[i] && setValue(i, routeData[i]);
        });
        setValue("active", routeData.active);
        setAutocompleteValues({
          ...(routeData?.driver && { driver: routeData?.driver }),
          ...(routeData?.vehicle && {
            vehicle: {
              id: routeData?.vehicle?.id,
              name:
                (makeName ? makeName + " - " : "") +
                (modelName ? modelName + " - " : "") +
                (seriesName ? seriesName : "") +
                (plateNumber ? " (" + plateNumber + ")" : ""),
            },
          }),
        });
      },
    }
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    const stops = sortedStopsList
      ? sortedStopsList.map((i) => {
        const stop = {
          order: 0,
          zoneId: i.zone.id,
          ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
        };
        return stop;
      })
      : [];
    saveRoute({
      variables: {
        input: {
          ...data,
          stops,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/routes/${data?.data?.saveRoute?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const stopForm = (
    <StopsForm
      handleSubmit={stopsHandleSubmit}
      onSubmitStop={onSubmitStops}
      control={stopsControl}
      errors={stopsErrors}
      setValue={stopsSetValue}
      watch={stopsWatch}
      parseData={parseData}
      onChangeZone={onChangeZone}
      onChangeSubZone={onChangeSubZone}
      stopIndex={stopIndex}
      stopList={sortedStopsList}
      stopErrorMessage={stopErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedZones={setSelectedZones}
      setSelectedSubZones={setSelectedSubZones}
    />
  );

  return routeLoading ?
  <StyledLoading container item justifyContent="center" className={classes.main}>
    <FullScreenLoading height={"100%"} />
  </StyledLoading>
  : !data?.route&&routeId ? (
    <NotFound />
  )  : 
   (
    <Root>
      <TitleAppBar path={props.match.path} />

      <Dialog fullWidth maxWidth="xs" open={dialog} onClose={handelCloseDialog}>
        {stopForm}
      </Dialog>
          <Paper
            className={classes.paper}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid
              container
              justifyContent="end"
              alignItems="center"
              sm={12}
            >
              <Box>
                <MuiSwitch
                  edge="end"
                  name="active"
                  label={t("active")}
                  control={control}
                />
              </Box>
            </Grid>

            <Grid container spacing={2} className={classes.mainForm}>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"vehicleId"}
                  label={t("car")}
                  parseData={(data) => parseData(data)}
                  query={LIST_VEHICLES_DROPDOWN.query}
                  defaultValue={autocompleteValues.vehicle}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"driverId"}
                  label={t("driver")}
                  parseData={(data) => parseData(data)}
                  query={LIST_DRIVERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.driver}
                />
              </Grid>

              <Grid xs={12} sm={12} justifyContent="flex-end">
                <FormButton
                  className={classes.button}
                  disabled={saveRouteLoading}
                >
                  {saveRouteLoading ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>
            </Grid>
          </Paper>
          <StopsTable
            stops={sortedStopsList}
            addStop={addStopDialog}
            setStopsList={setStopsList}
          />
     \
    </Root>
  );
};

export default RouteForm;

function StopsForm({
  handleSubmit,
  onSubmitStop,
  control,
  errors,
  parseData,
  onChangeZone,
  onChangeSubZone,
  stopIndex,
  stopList,
  setValue,
  watch,
  stopErrorMessage,
  handelCloseDialog,
  setSelectedZones,
  setSelectedSubZones,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    order: null,
    zone: [],
    subzone: [],
  });
  return (
    <form onSubmit={handleSubmit(onSubmitStop)}>
      <DialogTitle><Typography variant="inherit" color={"text.primary"} >{t("addStop")}  </Typography></DialogTitle>
      <DialogContent>
        <MultipleAutocomplete
          multiple
          limitTags={1000}
          valueKey="id"
          allowSelectAll={t("selectAll")}
          setHeight={"120px"}
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"zone"}
          label={t("zone")}
          parseData={(data) => parseData(data)}
          query={LIST_ZONES_DROPDOWN.query}
          onChangeValue={(e) => {
            e.length > 1 && setValue("subzone", '');
            onChangeZone(e);
          }}
          disabled={Boolean(watch("subzone").length > 1)}
          margin="normal"
          defaultValue={autocompleteValues.zone}
          variables={{
            input: { parentId: null },
          }}
        />
        <MultipleAutocomplete
          multiple
          valueKey="id"
          allowSelectAll={t("selectAll")}
          setHeight={"120px"}
          limitTags={1000}
          control={control}
          errors={errors}
          name={"subzone"}
          label={t("subzone")}
          parseData={(data) => parseData(data)}
          query={LIST_ZONES_DROPDOWN.query}
          skip={!watch("zone") || watch("zone").length > 1}
          disabled={(!watch("zone") || watch("zone").length > 1)}
          variables={{
            input: {
              parentId: watch("zone")[0],
            },
          }}
          onChangeValue={(e) => onChangeSubZone(e)}
          margin="normal"
          defaultValue={autocompleteValues.subzone}
        />
        {stopErrorMessage && (
          <FormHelperText error>{t("thisZoneAlreadyExists")}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}
