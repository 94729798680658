import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Checkbox, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { dateTimeFormatA } from "../../../helpers/dateFunctions";
import CellLink from "../../HOC/CustomComponents/CellLink";
import formatMoney from "../../../helpers/numbersDot";
import { TableCellColor } from "../../ConciergeRequest/TableCellColor";

const PREFIX = "PickupListDetails";

const classes = {
  pickupTable_checkbox: `${PREFIX}-pickupTable_checkbox`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.pickupTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

export const collectionConciergeRequestListCells = (conciergeRequestDetails) => {
  const cellsByType = (
    <>
      <FixedTableCell>
        {dateTimeFormatA(conciergeRequestDetails.date)}
      </FixedTableCell>

      <TableCellColor
        status={conciergeRequestDetails.status}
      />
      <CellLink
        pathname={`/admin/branches/${conciergeRequestDetails.branch?.id}`}
      >
        {conciergeRequestDetails.branch?.name}
      </CellLink>
      <CellLink
        pathname={`/admin/customers/${conciergeRequestDetails.customer?.id}`}
      >
        {conciergeRequestDetails.customer?.name}
      </CellLink>
      <FixedTableCell>{conciergeRequestDetails.accountNumber}</FixedTableCell>
      <FixedTableCell dir="ltr"> {conciergeRequestDetails.phone}</FixedTableCell>
      <FixedTableCell>{conciergeRequestDetails.address}</FixedTableCell>
      <FixedTableCell>{formatMoney(conciergeRequestDetails.commissionAmount)}</FixedTableCell>
    </>
  )
  return cellsByType;
};
export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const { t } = useTranslation();

  const headCells = [
    t("requestCode"),
    t("date"),
    t("status"),
    t("branch"),
    t("customer"),
    t("accountNumber"),
    t("phone"),
    t("address"),
    t("deservedAmount"),
  ];
  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.pickupTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </FixedTableCell>
        {headCells.map((headCell, index) => (
          <FixedTableCell key={index}>{headCell}</FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
