import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Button,
} from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { FilterList, Search, DeleteOutline, Add } from "@mui/icons-material";
import { DELETE_TEAM, LIST_TEAMS } from "./Graphql";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { styled } from "@mui/material/styles";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import useWidth from "../../Hooks/useWidth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TeamsDialog from "./TeamsForm";
import CellLink from "../HOC/CustomComponents/CellLink";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "TEAMS";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterFields: `${PREFIX}-filterFields`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
  span: `${PREFIX}-span`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.span}`]: {
    display: "inline"
  },
}));

const Teams = (props) => {
  const { t } = useTranslation();
  const [refetchs, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const screenWidth = useWidth();
  const user = Globals.user
  const userCanEdit = user.hasPermission('crm.team.update')
  const userCanCreate = user.hasPermission('crm.team.create')
  const userCanDelete = user.hasPermission('crm.team.delete')

  const [open, setOpen] = React.useState(false);
  const [supportTeamsObject, setSupportTeamsObject] =
    React.useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSupportTeamsObject();
  };

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    return () => { };
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/crm/teams",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      code: data.code.toString(),
      name: data.name.toString(),
      page: 0,
    };
    (refetchs) ? setrefetch(false) : setrefetch(true)
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_TEAMS.query}
    `,
    {
      partialRefetch: refetchs,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
        },
      },
    }
  );
  const listTeams = data?.listTeams?.data;

  const convertToString = (row, link) => {
    let content = row.map((user, index) =>
      link ? <SpanLink key={index} className={classes.span}
        pathname={`/admin/users/${user.id}`}
      >
        {user.username} {!(row.length - 1 === index) && ' , '}
      </SpanLink> :
        <span key={index} className={classes.span}>
          {user.name} {!(row.length - 1 === index) && ' , '}
        </span>
    )
    return content
  }

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => handleClickOpen(),
      icon: Add,
      permission: userCanCreate,
    },
  ]

  return (
    <Root>
      {open && (
        <TeamsDialog
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          supportTeamsObject={supportTeamsObject}
        />
      )}

      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listTeams?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "name"
            },
            {
              name: "code"
            },
            {
              name: "supportCategories"
            },
            {
              name: "members"
            },
            {
              name: ""
            },
          ]
        }
        tableBody={
          <TableBody>
            {listTeams &&
              listTeams?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {userCanEdit ? <CellLink
                      onClick={() => {
                        handleClickOpen();
                        setSupportTeamsObject(row);
                      }}
                    >
                      {row?.name}
                    </CellLink> : <FixedTableCell>{row?.name}</FixedTableCell>}
                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <FixedTableCell>
                      ( {convertToString(row.categories)} )
                    </FixedTableCell>
                    <FixedTableCell>
                      ( {convertToString(row.members, 'users')} )
                    </FixedTableCell>
                    <FixedTableCell>
                      {userCanDelete && <MutationWithDialog
                        mutaion={DELETE_TEAM.query}
                        icon={DeleteOutline}
                        permission={true}
                        iconTooltip={t("deleteTeam")}
                        dialogTitle={t("deleteTeam")}
                        dialogContent={t("deleteTeamConfirmation")}
                        mutaionProps={{ variables: { id: parseInt(row.id) } }}
                        onCompleted={() =>
                          refetch()
                        }
                        onCompleteMessage={t("teamDeleted")}
                      />}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listTeams?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listTeams ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default Teams;
