import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Paper,
  Collapse,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import { LIST_EGS_CODE, SAVE_SHIPPING_SERVICE, SHIPPING_SERVICE_ID } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_SHIPMENT_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import EnumDropdown from "../HOC/CustomComponents/EnumDropdown"
import MUIselect from "../HOC/MUI/MUIselect";
import ServiceBranchesTable from "./Components/ServiceBranchesTable";
import { useHistory } from "react-router";

const PREFIX = 'ShippingServiceForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
  addIcon: `${PREFIX}-addIcon`,
  toolbar: `${PREFIX}-toolbar`,
  paperTitle: `${PREFIX}-paperTitle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.toolbar}`]: {
    justifyContent: "space-between",
  },
  [`& .${classes.paperTitle}`]: {
    justifyContent: "center",
    background: `${theme.palette.background.appTitle} !important`
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const ShippingServiceForm = (props) => {
  const lookupsComplete = (data, updateValue, name) => {
    setValue(name, updateValue);
  };
  const [activeUser, setActiveUser] = useState(true);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    deliveryAccount: null,
    collectionAccount: null,
    commissionAccount: null,
    shipmentTypes: [],
  });

  const ifSeparatedBranches = Globals.settings.separatedBranches
  const [pathURL, setPathURL] = useState(props.match.path)
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError, watch } =
    useForm();
  const { errors } = formState;

  const history = useHistory();

  const [saveShippingService, { loading: saveShippingServiceLoading }] =
    useMutation(
      gql`
        ${SAVE_SHIPPING_SERVICE.query}
      `
    );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };

  // update
  const shippingServiceId = parseInt(props.match.params.id);

  const { data: updateShippingService, loading: updateShippingServiceLoading, refetch } = useQuery(
    gql`
      ${SHIPPING_SERVICE_ID.query}
    `,

    {
      skip: !shippingServiceId,
      variables: { id: shippingServiceId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const shippingServiceData = data.shippingService;
        setActiveUser(shippingServiceData.active);
        const saveShippingServiceParams = ["id", "code", "name", "days"];
        saveShippingServiceParams.forEach((i) => {
          shippingServiceData[i] && setValue(i, shippingServiceData[i]);
        });
        setValue("active", shippingServiceData["active"]);

        const shipmentTypes = shippingServiceData?.shipmentTypes?.map(
          (i) => i.code
        );
        setAutocompleteValues({
          branch: shippingServiceData?.branch,
          collectionAccount: shippingServiceData?.collectionAccount,
          commissionAccount: shippingServiceData?.commissionAccount,
          deliveryAccount: shippingServiceData?.deliveryAccount,
          shipmentTypes: shipmentTypes,
        });
      },
    }
  );

  const shippingServiceData = updateShippingService?.shippingService;

  const selectDefaultValue = (data) => {
    return data;
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    if (data["shipmentTypes"] === null) data["shipmentTypes"] = [];
    saveShippingService({
      variables: {
        input: {
          ...data,
          active: activeUser,
          days: parseInt(data.days),
        },
      },
    })
      .then((data) => {
        !ifSeparatedBranches && pushUrl(
          props,
          `/admin/shipping-services/${data?.data?.saveShippingService?.id}`
        );
        const url = history.createHref({
          pathname: `/admin/shipping-services/${data?.data?.saveShippingService?.id}/edit`,
        });
        ifSeparatedBranches && setPathURL(url)
        ifSeparatedBranches && windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const [listEgsCodeEn, setListEgsCodeEn] = useState([])
  const [listEgsCodeAr, setListEgsCodeAr] = useState([])

  const keyStyle = (element, lang) => {
    return lang === "ar" ?
      <Stack>
        <span >{element.descriptionSecondaryLang}</span>
        <span >{element.itemCode}</span>
        <span >{element.ownerTaxpayer.issuerNameAr} </span>
      </Stack> :
      <Stack>
        <span >{element.descriptionPrimaryLang}</span>
        <span >{element.itemCode}</span>
        <span >{element.ownerTaxpayer.issuerName} </span>
      </Stack>
  }
  useQuery(
    gql`
      ${LIST_EGS_CODE.query}
    `,
    {
      skip: !Globals.settings.eInvoicing,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const listAr = data.listEGSCode.map(element => {
          return {
            key: keyStyle(element, "ar"),
            value: element.itemCode
          }
        })
        const listEn = data.listEGSCode.map(element => {
          return {
            key: keyStyle(element, "en"),
            value: element.itemCode
          }
        })
        setListEgsCodeAr(listAr)
        setListEgsCodeEn(listEn)
        setValue("egsCode", shippingServiceData?.egsCode)
      },
      onError: ({ graphQLErrors }) => {
        setError("egsCode", {
          type: "required",
          message: graphQLErrors[0].message,
          shouldFocus: true,
        })
      }
    }
  );


  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classes.spacing)}
        sx={{ p: 1 }}
      >
        <Grid display={"flex"} justifyContent="end" xs={12}>
          <FormControlLabel
            checked={activeUser}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"days"}
            label={t("shippingDays")}
            rules={{ required: t("fieldIsRequired") }}
            type="number"
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ListBranches
            control={control}
            errors={errors}
            name={"branchId"}
            defaultValue={autocompleteValues.branch}
            disabled={shippingServiceId ? true : false}
            onChangeValue={() => {
              setValue("deliveryAccountId", "");
              setValue("collectionAccountId", "");
              setValue("commissionAccountId", "");
            }}
            skipDefaultBranch={Boolean(shippingServiceId)}
          />
        </Grid>
        {!ifSeparatedBranches && <Grid xs={12} sm={6} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAccountId"}
            label={t("deliveryAccount")}
            disabled={shippingServiceData?.deliveryAccount ? true : false}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
                ...(watch("branchId") && {
                  branchId: { value: watch("branchId"), includeNull: true },
                }),
              },
            }}
            defaultValue={autocompleteValues.deliveryAccount}
          />
        </Grid>}
        {!ifSeparatedBranches && <Grid xs={12} sm={6} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"collectionAccountId"}
            label={t("collectionAccount")}
            disabled={shippingServiceData?.collectionAccount ? true : false}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
                ...(watch("branchId") && {
                  branchId: { value: watch("branchId"), includeNull: true },
                }),
              },
            }}
            defaultValue={autocompleteValues.collectionAccount}
          />
        </Grid>}
        {!ifSeparatedBranches && <Grid xs={12} sm={6} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"commissionAccountId"}
            label={t("commissionAccount")}
            disabled={shippingServiceData?.commissionAccount ? true : false}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
                ...(watch("branchId") && {
                  branchId: { value: watch("branchId"), includeNull: true },
                }),
              },
            }}
            defaultValue={autocompleteValues.commissionAccount}
          />
        </Grid>}
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MultipleAutocomplete
            control={control}
            errors={errors}
            variables={{ code: "SHP_REQUEST_STATUS" }}
            name={"shipmentTypes"}
            label={t("shipmentType")}
            parseData={(data) => data}
            query={LIST_SHIPMENT_TYPES_DROPDOWN.query}
            defaultValue={autocompleteValues.shipmentTypes}
            multiple
            valueKey="code"
          />
        </Grid>
        {Globals.settings.eInvoicing && <Grid xs={12} sm={6} alignItems="flex-start">
          <EnumDropdown
            name={"egsUnitOfMeasure"}
            label={t("egsUnitOfMeasure")}
            control={control}
            errors={errors}
            rules={{ required: t("fieldIsRequired") }}
            variables={
              { name: "WeightUnitCode" }
            }
            onCompleted={(data) =>
              lookupsComplete(
                data,
                shippingServiceData?.egsUnitOfMeasure
                ,
                "egsUnitOfMeasure"
              )
            }


          />
        </Grid>}
        {Globals.settings.eInvoicing && <Grid xs={12} sm={6} alignItems="flex-start">
          <MUIselect
            name={"egsCode"}
            label={t("egsCode")}
            control={control}
            errors={errors}
            rules={{ required: t("fieldIsRequired") }}
            data={i18n.language === "ar" ? listEgsCodeAr : listEgsCodeEn}
          />
        </Grid>}

      </Paper>
      <Grid container justifyContent="flex-end" className={classes.spacing}>
        <FormButton disabled={saveShippingServiceLoading}>
          {saveShippingServiceLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (shippingServiceId) {
    DOM = updateShippingService ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateShippingServiceLoading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !updateShippingService && shippingServiceId ? (
      <NotFound />
    ) :
      (
        <Root>
          <TitleAppBar path={pathURL} />

          <Stack spacing={2} p={2}>
            {DOM}
            {ifSeparatedBranches && <ServiceBranchesTable
              data={shippingServiceData?.serviceBranches}
              tableHeader
              classes={classes}
              shippingServiceId={shippingServiceId}
              refetch={refetch}
            />}
          </Stack>
        </Root>
      )
};

export default ShippingServiceForm;