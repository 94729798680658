import React from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";

const PREFIX = "ProductListDetails";

const classes = {
  productTable_checkbox: `${PREFIX}-productTable_checkbox`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.productTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

// export const ManifestProductsCells = ({ productDetails, type, product }) => {
//   const cellsByType = {
//     PADD: (
//       <>
        
//       </>
//     )
//   };

//   return cellsByType[type];
// };

export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, type, prepareShipment } = props;

  const { t } = useTranslation();
  const headCells = {
    PADD: [t("name"), t("quantity"), t("price"), t("weight")],
    PRE: [t("name"), t("quantity")],
  };

  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.productTable_checkbox}
          padding="checkbox"
        >
          {!prepareShipment && <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />}
        </FixedTableCell>
        {!prepareShipment && <FixedTableCell>{t("code")}</FixedTableCell>}
        {headCells[type].map((headCell, index) => (
          <FixedTableCell
            key={index}
          >
            {headCell}
          </FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
