import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Button,
  Icon,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { FilterList, Search, Add } from "@mui/icons-material";
import { LIST_FINANCIAL_YEAR } from "./Graphql";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { styled } from "@mui/material/styles";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import useWidth from "../../Hooks/useWidth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { Globals } from "../HOC/Classes/Globals";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import MUIselect from "../HOC/MUI/MUIselect";
import { dateFormat } from "../../helpers/dateFunctions";
import MUIDateCustom from "../HOC/MUI/MUIDateCustom";
import moment from "moment";
const PREFIX = "FinancialYear";
const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterFields: `${PREFIX}-filterFields`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

const FinancialYearList = (props) => {
  const { t } = useTranslation();
  const [reFetches, setReFetches] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const screenWidth = useWidth();
  const user = Globals.user

  const canCreate = user.hasPermission('cash.financial_year.create')
  const canUpdate = user.hasPermission('cash.financial_year.update')
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const initCreateStartDate = urlQuery["startDate"]
    && urlQuery["startDate"]

  const initCreateEndDate = urlQuery["endDate"]
    && urlQuery["endDate"]

  const [dateRangeCreate, setDateRangeCreate] = useState(
    {
      startDate: initCreateStartDate ?? null,
      endDate: initCreateEndDate ?? null
    }
  );
  const startDate = moment(dateRangeCreate.startDate).locale("en").format("YYYY-MM-DD");
  const endDate = moment(dateRangeCreate.endDate).locale("en").format("YYYY-MM-DD");
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRangeCreate.startDate && { startDate }),
      ...(dateRangeCreate.endDate && { endDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["closed"] !== undefined && setValue("closed", urlQuery["closed"]);
    if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/finance/financial-year",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    setReFetches((prev) => !prev)
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      active: data.active,
      closed: data.closed,
      code: data.code.toString(),
      name: data.name.toString(),
      page: page,
      ...(dateRangeCreate.startDate && {
        startDate,
      }),
      ...(dateRangeCreate.endDate && {
        endDate,
      }),
    };
    data["active"] === "" && delete handledData["active"];
    data["closed"] === "" && delete handledData["closed"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_FINANCIAL_YEAR.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: reFetches,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(search.active != null && { active: search.active }),
          ...(search.closed != null && { closed: search.closed }),
          ...(search.code && { code: search.code.toString() }),
          ...(search.name && { name: search.name.toString() }),
          ...(search.startDate && { startDate: search.startDate }),
          ...(search.endDate && { endDate: search.endDate }),
        },
      },
    },
  );
  const listCurrencies = data?.listFinancialYears?.data;
  const resetEndDate = () => {
    setDateRangeCreate((prev) => ({ ...prev, endDate: null }))
    setValue("endDate", "");
  };
  const resetStartDate = () => {
    setDateRangeCreate((prev) => ({ ...prev, startDate: null }))
    setValue("startDate", "");
  };
  const addOneDay = (inputDateString) => {
    let startDate = new Date(inputDateString);
    return startDate.setDate(startDate.getDate() + 1);
  };
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, `financial-year/create`),
      icon: Add,
      permission: canCreate,
    },
  ]

  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listCurrencies?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"closed"}
                  label={t("close")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("no"), value: false },
                    { key: t("yes"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateCustom
                  name="startDate"
                  label={t("startDate")}
                  control={control}
                  value={dateRangeCreate.startDate}
                  onChange={(date) => setDateRangeCreate((prev) => ({ ...prev, startDate: date }))}
                  readOnly={false}
                  resetDate={resetStartDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateCustom
                  name="endDate"
                  label={t("endDate")}
                  control={control}
                  value={dateRangeCreate.endDate}
                  onChange={(date) => setDateRangeCreate((prev) => ({ ...prev, endDate: date }))}
                  minDate={addOneDay(dateRangeCreate.startDate)}
                  resetDate={resetEndDate}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "code"
            },
            {
              name: "name"
            },
            {
              name: "active"
            },
            {
              name: "close"
            },
            {
              name: "startDate"
            },
            {
              name: "endDate"
            },

          ]
        }
        tableBody={
          <TableBody>
            {listCurrencies &&
              listCurrencies?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    sx={{ cursor: canUpdate ? "pointer" : "default" }}
                    onClick={() => {
                      if (canUpdate) {
                        pushUrl(props, `/admin/finance/financial-year/${row.id}`)
                      }
                    }}
                  >
                    <FixedTableCell >
                      {row.code}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row.name}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row.closed ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>{dateFormat(row?.startDate)}</FixedTableCell>
                    <FixedTableCell>{dateFormat(row?.endDate)}</FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listFinancialYears?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listFinancialYears ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default FinancialYearList;
