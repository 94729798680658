export const invoice = [
    "code",
    "date",
    "recipient",
    "recipientZone",
    "sender",
    "senderZone",
    "weight",
    "piecesCount",
    "deliveryFees",
    "postFees",
    "tax",
    "allFees",
];
