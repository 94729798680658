import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Button, Icon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Add, FilterList, Search } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_BANKS } from "./Graphql";
import { useTranslation } from "react-i18next";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import useWidth from "../../Hooks/useWidth";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import BankForm from "./CreateBank";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "BranchList";
const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterFields: `${PREFIX}-filterFields`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%",
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column",
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

const BankList = (props) => {
  const { t } = useTranslation();
  const [reFetches, setReFetches] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const screenWidth = useWidth();
  const user = Globals.user;
  const userCanEdit = user.hasPermission("shipping.bank.update");
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/banks",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setReFetches(false) : setReFetches(true);
    let handledData = {
      name: data.name,
      code: String(data.code),
      page: 0,
    };
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    // for (const key in handledData) {
    //     if (handledData[key] === null) {
    //         delete handledData[key];
    //     }
    // }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();

  const handelCloseDialog = () => {
    setOpen(false);
    setId("");
  };

  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_BANKS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: reFetches,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.name && { name: search.name }),
          ...(search.code && { code: search.code }),
        },
      },
      onCompleted: (data) => { },
    }
  );
  const bankList =
    data?.listBanks?.data !== null ? data?.listBanks?.data : null;

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => setOpen(true),
      icon: Add,
      permission: "shipping.bank.create",
    },
  ];

  return (
    <Root>
      {open && (
        <BankForm
          id={id}
          open={open}
          handelCloseDialog={handelCloseDialog}
          refetch={refetch}
        />
      )}
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={bankList?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "code",
          },
          {
            name: "name",
          },
          {
            name: "active",
          },
        ]}
        tableBody={
          <TableBody>
            {bankList &&
              bankList?.map((row, index) => {
                return (
                  <>
                    {userCanEdit ? (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpen(true);
                          setId(row.id);
                        }}
                      >
                        <FixedTableCell> {row?.code} </FixedTableCell>
                        <FixedTableCell> {row?.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        <FixedTableCell> {row?.code} </FixedTableCell>
                        <FixedTableCell> {row?.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    )}
                  </>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listBanks?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listBanks ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default BankList;
