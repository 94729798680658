/* eslint-disable no-useless-concat */
import { gql, useMutation, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import { DeleteOutline, Edit } from "@mui/icons-material";
import { Button, Collapse, Paper, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CUSTOMER_REQUEST_BY_ID_QUERY, DELETE_CUSTOMER_REQUEST_MUTATION, UPDATE_CUSTOMER_REQUEST_STATUS } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { GetCustomerReqPermissionSlug, GetManifestPermissionSlug, GetPaymentPermissionSlug } from "../../helpers/getPermissionSlug";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { useSnackbar } from "notistack";
import { useState } from "react";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { Fragment } from "react";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'CustomerView';

const classes = {
  box: `${PREFIX}-box`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));


const CustomerRequestsView = (props) => {
  const customerRequestId = props.id ?? props.match?.params?.id?.trim();
  const user = Globals.user
  const isNotAdmin = user.account
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [dialog, setDialog] = useState(false);

  const closeDialog = () => setDialog(false);
  const openDialog = () => setDialog(true);

  const { loading, data, refetch } = useQuery(
    gql`
      ${CUSTOMER_REQUEST_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(customerRequestId) },
    }
  );

  const typeCode = data?.customerRequest?.type?.code
  const canDelete = typeCode && user.hasPermission(GetCustomerReqPermissionSlug(typeCode.toLowerCase(), "delete"))
  const canFinish = typeCode && user.hasPermission(GetCustomerReqPermissionSlug(typeCode.toLowerCase(), "update_status"))

  // const customerRequestName = {
  //   PMNT: t("paymentRequestView"),
  //   RTRN: t("returnRequestView"),
  //   MTRL: t("materialRequestView"),
  // };

  const [updateCustomerRequestStatusMutation, { loading: updateStatusLoad }] =
    useMutation(
      gql`
      ${UPDATE_CUSTOMER_REQUEST_STATUS.query}
    `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        },
      }
    );

  const finishCustomerRequest = () => {
    updateCustomerRequestStatusMutation({
      variables: {
        input: {
          id: parseInt(customerRequestId),
          statusCode: "FINISHED",
        },
      },
    })
      .then((data) => {
        refetch()
        closeDialog()
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const finishButton = () => {
    const buttonCondition = canFinish && !data?.customerRequest.editable && !["FINISHED", "CANCELLED"].includes(data?.customerRequest?.status.code)
    return (
      buttonCondition && (<Button
        className={classes.button}
        variant="contained"
        size="medium"
        customcolor={theme.palette.error.main}
        onClick={() => openDialog()}
        name="updateStatus"
      >
        {t("finish")}
      </Button>)
    );
  };

  const [openDelete, setOpenDelete] = useState(false);

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/customer-requests/${customerRequestId}/edit`),
      icon: Edit,
      permission: data?.customerRequest.editable,
    },
    {
      id: "delete",
      title: "delete",
      action: () => setOpenDelete(true),
      icon: DeleteOutline,
      permission: data?.customerRequest.deletable && Boolean(canDelete),
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    :
    !data?.customerRequest ? (
      <NotFound />
    ) : (
      <StyledGrid container justifyContent="center" sx={{ margin: "0", width: "100%" }}>
        <CustomDialog
          // title={"dialogTitle"}
          fullWidth
          maxWidth="xs"
          onClose={closeDialog}
          content={t("confirmationStatus")}
          open={dialog}
          actions={
            <Fragment>
              <Button color="primary" onClick={closeDialog}>
                {t("cancel")}
              </Button>
              <Button
                color="primary"
                disabled={loading}
                onClick={() => finishCustomerRequest()}
              >
                {updateStatusLoad ? <ButtonLoading /> : t("confirm")}
              </Button>
            </Fragment>
          }
        // {...dialogProps}
        />
        <TitleAppBar path={props.match.path} type={typeCode}>
          <LongMenu icons={icons} />
          <MutationWithDialog
            mutaion={DELETE_CUSTOMER_REQUEST_MUTATION.query}
            setOpenDelete={setOpenDelete}
            openDelete={openDelete}
            dialogTitle={t("deleteManifest")}
            dialogContent={t("deleteManifestConfirmation")}
            mutaionProps={{ variables: { id: parseInt(customerRequestId) } }}
            onCompleted={() =>
              pushUrl(props, `/admin/customer-requests/${typeCode?.toLowerCase()}`)
            }
            onCompleteMessage={t("manifestDeleted")}
          />
        </TitleAppBar>

        <Grid
          container
          justifyContent="center"
          sx={{ width: "100%", p: 2, gap: 2 }}
        >
          <Paper container className={classes.box} component={Grid}>
            <KeyValuePair
              title={t("code")}
              value={data?.customerRequest.id}
            />
            <KeyValuePair
              title={t("payee")}
              value={data?.customerRequest.payeeName}
            />
            <KeyValuePair
              title={t("status")}
              value={data?.customerRequest.status.name}
            />
            {data?.customerRequest?.deliveryAgent ? (
              <KeyValuePair
                title={t("shippingAgent")}
                value={
                  data?.customerRequest?.deliveryAgent?.name && (
                    <SpanLink
                      pathname={`/admin/delivery-agents/${data?.customerRequest?.deliveryAgent?.id}`}
                    >
                      {data?.customerRequest?.deliveryAgent?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            {Boolean(!isNotAdmin) && data?.customerRequest?.payment ? (
              <KeyValuePair
                title={t("payment")}
                value={
                  data?.customerRequest?.payment &&
                    user?.hasPermission(GetPaymentPermissionSlug(data?.customerRequest?.payment.type.code.toLowerCase(), 'list')) ? (
                    <SpanLink
                      align="left"
                      pathname={`/admin/payments/${data?.customerRequest?.payment?.id}`}
                    >
                      {data?.customerRequest?.payment.code}
                    </SpanLink>
                  ) : (
                    data?.customerRequest?.payment.code
                  )
                }
              />
            ) : null}
            {Boolean(!isNotAdmin) && data?.customerRequest?.manifest ? (
              <KeyValuePair
                title={t("manifest")}
                value={
                  data?.customerRequest?.manifest &&
                    user?.hasPermission(GetManifestPermissionSlug(data?.customerRequest?.manifest.transactionType.type.code.toLowerCase(), 'list')) ? (
                    <SpanLink
                      align="left"
                      pathname={`/admin/manifests/${data?.customerRequest?.manifest?.id}`}
                    >
                      {data?.customerRequest?.manifest.code}
                    </SpanLink>
                  ) : (
                    data?.customerRequest?.manifest.code
                  )
                }
              />
            ) : null}
            <KeyValuePair
              title={t("requestDate")}
              value={data?.customerRequest.date}
            />
            <KeyValuePair
              title={t("deliveryType")}
              value={data?.customerRequest.deliveryType.name}
            />
            <KeyValuePair
              title={t("customer")}
              value={
                <SpanLink
                  pathname={`/admin/customers/${data?.customerRequest?.customer?.id}`}
                >
                  {data?.customerRequest?.customer?.name}
                </SpanLink>
              }
            />
            <KeyValuePair
              title={t("createdBy")}
              value={
                data?.customerRequest?.createdBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.customerRequest?.createdBy?.id}`}
                  >
                    {data?.customerRequest?.createdBy?.username}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("updatedBy")}
              value={
                data?.customerRequest?.updatedBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.customerRequest?.updatedBy?.id}`}
                  >
                    {data?.customerRequest?.updatedBy?.username}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("notes")}
              value={data?.customerRequest.notes}
            />
          </Paper>
          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {finishButton()}
          </Grid>
        </Grid>
      </StyledGrid>)
}

export default CustomerRequestsView;
