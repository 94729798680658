import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useQuery, gql } from "@apollo/client";
import {

  TableBody,
  TableRow,

  Icon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { LIST_ROUTES_PAGED } from "./Graphql";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
const PREFIX = 'ListRoutes';

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,

};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));
const ListRoutes = (props) => {
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading } = useQuery(
    gql`
      ${LIST_ROUTES_PAGED.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => { },
    }
  );
  const listRoutes = data?.listRoutes?.data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const addURL = () => {
    pushUrl(props, `/admin/routes/create`);
  }

  const icons = [
    {
      id: "createNew",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: "shipping.route.create",
    },
  ]

 
  return(
    <Root>
    <ListWrapper
    drawerState={false}
      icons={icons}
      path={props.match.path}
      empty={listRoutes?.length === 0}
      loading={loading}
      filters={false}
      tableHeaders={
        [
          {
            name: "name"
          },
          {
            name: "code"
          },
          {
            name: "car"
          },
          {
            name: "driver"
          },
          {
            name: "active"
          },

         
        ]
      }
      tableBody={
        <TableBody>
        {listRoutes &&
          listRoutes?.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <CellLink pathname={`/admin/routes/${row?.id}`}>
                  {row?.name}
                </CellLink>

                <FixedTableCell>{row?.code}</FixedTableCell>
                <FixedTableCell
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <span className={classes.span}>
                    {row?.vehicle?.series?.model?.make?.name}
                  </span>
                  {row?.vehicle?.series?.model?.make?.name ? (
                    <span>-</span>
                  ) : (
                    ""
                  )}
                  <span className={classes.span}>
                    {row?.vehicle?.series?.model?.name}
                  </span>
                  {row?.vehicle?.series?.name ? <span>-</span> : ""}
                  <span className={classes.span}>
                    {row?.vehicle?.series?.name}
                  </span>
                  {row?.vehicle?.plateNumber ? <span>(</span> : ""}
                  <span className={classes.span}>
                    {row?.vehicle?.plateNumber}
                  </span>
                  {row?.vehicle?.plateNumber ? <span>)</span> : ""}
                </FixedTableCell>
                <FixedTableCell>{row?.driver?.name}</FixedTableCell>
                <FixedTableCell>
                  {row?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )}
                </FixedTableCell>
              </TableRow>
            );
          })}
      </TableBody> 
      }
      pagination={
        <MUITablePagination
          count={data?.listRoutes?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!data?.listRoutes ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    />
  </Root>);
};

export default ListRoutes;
