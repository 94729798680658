import * as gqlb from "gql-query-builder";
import { gql } from "@apollo/client";
export const SETTINGS = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});
export const LIST_SHIPMENTS_TRANSACTIONS =
  gqlb.query([
    {
      operation: "listShipmentTransactions",
      fields: [
        {
          operation: "paginatorInfo",
          fields: [
            "count",
            "currentPage",
            "firstItem",
            "hasMorePages",
            "lastItem",
            "lastPage",
            "perPage",
            "total",
          ],
          variables: {},
        },
        {
          operation: "data",
          fields: [
            "id",
            "date",
            "deliverdAmount",
            "fees",
            {
              cancellationReason: ["name"],
            },
            {
              branch: ["name"],
            },
            {
              shipmentStatus: ["code", "name"],
            },
            {
              deliveryType: ["code", "name"],
            },
            {
              returnType: ["code", "name"],
            },
            {
              deliveryAgent: ["id", "name"],
            },
            {
              createdBy: ["id", "username"],
            },
            {
              trxType: ["id", "name", { type: ["name"] }],
            },
            {
              manifest: ["id", "code", { transactionType: [{ type: ["code"] }] }],
            },
            {
              shipment: ["id", "code", "inWarehouse", "paidToCustomer", "collected", { customer: ["id", "code", "name"] }],
            },
            {
              manifestEntry: ["notes"],
            },
          ],
          variables: {},
        },
      ],
      variables: {
        page: {
          type: "Int",
        },
        first: {
          type: "Int",
        },
        input: {
          type: "ListShipmentTransactionsInput",
        },
      },
    }
  ]);

export const MESSAGE_CREATED_SUBSCRIPTION = gql`
  subscription OnShipmentMessageCreated {
    shipmentMessageCreated {
        id
        body
        images {
          path
          subjectCode
        }
        createdAt
        hidden
        user {
          username
          id
          account {
            ... on Customer {
              customerId:id
              name
            }
            ... on DeliveryAgent {
              deliveryAgentId:id
            }
          }
        }
        shipment{
          id
        }
    }
  }
`;

export const SHIPMENT_MESSAGES = gqlb.query({
  operation: "shipment",
  fields: [
    {
      messages: [
        "id",
        "body",
        {
          images: [
            "path",
            "subjectCode"
          ]
        },
        "createdAt",
        "hidden",

        {
          user: [
            "username",
            "id",
            {
              operation: "account",
              fields: [
                {
                  operation: "...on Customer",
                  fields: ["customerId:id", "name"],
                  variables: {},
                },
                {
                  operation: "...on DeliveryAgent",
                  fields: ["deliveryAgentId:id", "name"],
                  variables: {},
                },
              ],
              variables: {},
            },
          ],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
    },
    code: {
      type: "String",
    },
  },
});
export const SAVE_SHIPMENT_QUERY = gqlb.mutation({
  operation: "saveShipment",
  fields: [
    "id",
    "date",
    "code",
    "recipientName",
    "description",
    "piecesCount",
    "recipientAddress",
    "amount",
    "totalAmount",
    "allDueFees",
    "inWarehouse",
    {
      recipientZone: ["id", "name"],
    },
    {
      customer: ["id", "name", "code"],
    },
    {
      recipientSubzone: ["id", "name"],
    },
  ],
  variables: {
    input: {
      type: "ShipmentInput",
      required: true,
    },
  },
});
export const IMPORT_SHIPMENTS = gqlb.mutation({
  operation: "importShipments",
  fields: [
    {
      fields: [
        "name", "value", "originalValue", "errors"
      ]
    },
    {
      products: ["name", "value", "originalValue", "errors"]
    }
  ],
  variables: {
    input: {
      type: "ImportShipmentsInput",
      required: true,
    },
  },
});
export const IMPORT_SHIPMENTS_MESSAGES = gqlb.mutation({
  operation: "importShipmentsMessages",
  variables: {
    input: {
      type: "ImportShipmentsMessagesInput",
      required: true,
    },
  },
});
export const CALCULATE_FEES_QUERY = gqlb.query({
  operation: "calculateShipmentFees",
  fields: [
    "amount",
    "delivery",
    "weight",
    "collection",
    "total",
    "tax",
    "post",
    "return",
  ],
  variables: {
    input: {
      type: "CalculateShipmentFeesInput",
      required: true,
    },
  },
});

export const CUSTOMER_ID = gqlb.query([
  {
    operation: "customer",
    fields: [
      "id",
      "name",
      "address",
      "phone",
      "mobile",
      "activityName",
      "specifyShipmentCode",
      "postalCode",
      "warehousing",
      {
        zone: [
          "id",
          "name",
          {
            branch: [
              {
                country: ["id"],
              },
            ],
          },
        ],
      },
      {
        subzone: ["id", "name"],
      },
      {
        defaultShipmentType: ["code", "name"],
      },
      {
        paymentTypes: ["code", "id"],
      },
      {
        defaultPriceType: ["code", "name"],
      },
      {
        defaultOpenable: ["code", "name"],
      },
      {
        type: ["id", "name", "code"],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "listShippingServicesDropdown",
    fields: ["id", "name"],

    variables: {
      input: {
        type: "ListShippingServicesFilterInput",
      },
    },
  },
]);

export const SHIPMENT_UPDATE_QUERY = () =>
  gqlb.query({
    operation: "shipment",
    fields: [
      "id",
      "date",
      "code",
      "description",
      "weight",
      "piecesCount",
      "price",
      "notes",
      "recipientName",
      "recipientAddress",
      "recipientPhone",
      "recipientMobile",
      "deliveredAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "senderAddress",
      "collected",
      "paidToCustomer",
      "paidToDeliveryAgent",
      "returnedValue",
      "amount",
      "allDueFees",
      "editable",
      "returningDueFees",
      "extraWeightFees",
      "collectionFees",
      "collectedFees",
      "totalAmount",
      "editable",
      "senderName",
      "refNumber",
      "returnPiecesCount",
      "deliveryFees",
      "extraWeightFees",
      "collectionFees",
      "returnFees",
      "tax",
      "postFees",
      "recipientPostalCode",
      "senderPostalCode",
      "deliveryCommission",
      {
        shipmentProducts: [
          "price", "quantity",
          "type",
          {
            product: [
              "id",
              "name",
              {
                operation: "availableQuantity",
                fields: [],
                variables: {
                  excludeShipmentId: {
                    type: "Int",
                    required: true,
                  },
                },
              },
              "weight"
            ]
          }
        ]
      },
      {
        size: ["length", "width", "height"],
      },
      {
        service: ["id", "name"],
      },
      {
        recipientZone: [
          "id",
          "name",
          {
            branch: [
              {
                country: ["id", "name"],
              },
            ],
          },
        ],
      },
      {
        recipientSubzone: ["id", "name"],
      },
      {
        senderZone: [
          "id",
          "name",
          {
            branch: [
              {
                country: ["id", "name"],
              },
            ],
          },
        ],
      },
      {
        senderSubzone: ["id", "name"],
      },
      {
        paymentType: ["code", "name"],
      },
      {
        priceType: ["code", "name"],
      },
      {
        type: ["code", "name"],
      },
      {
        openable: ["code", "name"],
      },
      {
        status: ["code", "name"],
      },
      {
        lastDeliveryAgent: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
      {
        branch: ["id", "name"],
      },
      {
        originBranch: ["id", "name"],
      },
      {
        returnStatus: ["code", "name"],
      },
      {
        pickup: [{ customer: ["id", "name"] }, { branch: ["id", "name"] }],
      },
      {
        customer: [
          "id",
          "name",
          "address",
          "phone",
          "mobile",
          "warehousing",
          {
            zone: ["id", "name"],
          },
          {
            subzone: ["id", "name"],
          },
          {
            type: ["id", "name", "code"],
          },
        ],
      },
      {
        transactions: [
          "id",
          "createdAt",
          {
            cancellationReason: ["name"],
          },
          {
            shipmentStatus: ["code", "name"],
          },
        ],
      },
    ],
    variables: {
      id: {
        type: "Int",
      },
    },
  });

export const DELETE_SHIPMENT = gqlb.mutation({
  operation: "deleteShipment",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const CANCEL_SHIPMENT = gqlb.mutation({
  operation: "cancelShipments",
  fields: ["cancelled"],
  variables: {
    input: {
      type: "CancelShipmentsInput",
      required: true,
    },
  },
});
export const SHIPMENT_MESSAGE = gqlb.mutation({
  operation: "createShipmentMessage",
  fields: ["id", {
    user: [
      "username",
      "id",
    ],
  },
    {
      images: [
        "path",
        "subjectCode"
      ]
    },
  ],
  variables: {
    input: {
      type: "ShipmentMessageInput",
      required: true,
    },
  },
});

export const DELETE_LAST_SHIPMENT_ACTION = gqlb.mutation({
  operation: "deleteLastShipmentAction",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const RECEIVE_SHIPMENTS_IN_WAREHOUSE = gqlb.mutation({
  operation: "receiveShipmentsInWarehouse",
  fields: ["id"],
  variables: {
    input: {
      type: "ReceiveShipmentInWarehouseInput",
      required: true,
    },
  },
});

export const BARCODE = gqlb.query({
  operation: "barcode",
  fields: [
    "id",
    "code",
    { customer: ["id", "name", "code"] },
  ],
  variables: {
    code: {
      name: "code"
    },
  },
});

export const DELETE_MESSAGE_MUTATION = gqlb.mutation({
  operation: "hideShipmentMessage",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
    hidden: {
      type: "Boolean",
    },
  },
});
export const shipmentFields = [
  {
    key: "code",
    name: "code",
    label: "code"
  },
  { name: { status: ["name", "code"] }, key: "status", label: "status" },
  { name: "date", key: "date", label: "date" },
  { name: "deliveredOrReturnedDate", key: "deliveredOrReturnedDate", label: "deliveryCanclingDate" },
  { name: "description", key: "description", label: "description" },
  { name: "senderName", key: "senderName", label: "senderName" },
  { name: "recipientName", key: "recipientName", label: "recipient" },
  { name: "recipientAddress", key: "recipientAddress", label: "recipientAddress" },
  { name: "recipientMobile", key: "recipientMobile", label: "recipientMobile" },
  { name: "piecesCount", key: "piecesCount", label: "pieceCount" },
  { name: "price", key: "price", label: "packagePrice" },
  { name: "allDueFees", key: "allDueFees", label: "allDueFees" },
  { name: "deliveredAmount", key: "deliveredAmount", label: "deliveredAmount" },
  { name: "collectedFees", key: "collectedFees", label: "collectedFees" },
  { name: "amount", key: "amount", label: "customerNet" },
  { name: "totalAmount", key: "totalAmount", label: "totalPackagePrice" },
  { name: "customerDue", key: "customerDue", label: "customerDueAmount" },
  { name: "returningDueFees", key: "returningDueFees", label: "returningDueFees" },
  { name: "collected", key: "collected", label: "collected" },
  { name: "hasProducts", key: "hasProducts", label: "hasProducts" },
  { name: "paidToCustomer", key: "paidToCustomer", label: "paidToCustomer" },
  { name: "collectedAmount", key: "collectedAmount", label: "collectedAmount" },
  { name: "deliveryDate", key: "deliveryDate", label: "redeliveryDate" },
  { name: "cancelled", key: "cancelled", label: "cancelled" },
  { name: "inWarehouse", key: "inWarehouse", label: "inWarehouse" },
  { name: "customerDue", key: "customerDueDebit", label: "customerDueDebit" },
  { name: "customerDue", key: "customerDueCredit", label: "customerDueCredit" },
  { name: "attempts", key: "attempts", label: "attempts" },
  { name: "refNumber", key: "refNumber", label: "refNumber" },
  { name: "notes", key: "notes", label: "notes" },
  { name: "deliveryCommission", key: "deliveryCommission", label: "deliveryCommission" },
  { name: "deliveryCommission", key: "netCollection", label: "netCollection" },
  { name: "pendingCollectionAmount", key: "pendingCollectionAmount", label: "deservedAmount" },
  { name: { deliveryType: ["name"], }, key: "deliveryType", label: "deliveryType" },
  { name: { customer: ["id", "name", "code"], }, key: "customer", label: "customer" },
  { name: { originBranch: ["id", "name"], }, key: "originBranch", label: "originBranch" },
  { name: { branch: ["id", "name"], }, key: "branch", label: "branch" },
  { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
  { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
  { name: { priceType: ["name"], }, key: "priceType", label: "priceType" },
  { name: { type: ["name", "code"], }, key: "type", label: "type" },
  { name: { openable: ["name"] }, key: "openable", label: "packageOpen" },
  { name: { lastDeliveryAgent: ["id", "name"], }, key: "lastDeliveryAgent", label: "shippingAgent" },
  { name: { cancellationReason: ["id", "name"], }, key: "cancellationReason", label: "CancellationReasonName" },
  { name: { paymentType: ["name", "code"], }, key: "paymentType", label: "paymentType" },
  { name: { returnType: ["name"] }, key: "returnType", label: "returnType" },
  { name: { warehouseSection: ["id", "name"] }, key: "warehouseSection", label: "wareHouse" },
  { name: { lastTransaction: ["date"] }, key: "lastTransactionDate", label: "lastTransactionDate" }
];


export const initialData = {
  "shipments": [
    "code",
    "status",
    "date",
    "customer",
    "senderName",
    "branch",
    "originBranch",
    "senderZone",
    "recipientName",
    "recipientZone",
    "description",
    "price",
    "lastDeliveryAgent",



  ],
  "shipments-in-warehouse":
    [
      "code",
      "status",
      "date",
      "warehouseSection",
      "lastDeliveryAgent",
      "totalAmount",
      "deliveredAmount",
      "customerDue",
    ],
  "delivery-list-actions": [
    "code",
    "status",
    "date",
    "senderName",
    "recipientName",
    "lastDeliveryAgent",
    "paymentType",
    "type"],
  "receive-undelivered-shipments": [
    "code",
    "status",
    "cancellationReason",
    "type",
    "deliveryDate",
    "attempts",
    "lastDeliveryAgent",


    "deliveryType",
  ],
  "not-paid-to-customer": [
    "code",
    "status",
    "date",
    "customer",
    "price",
    "allDueFees",
    "customerDueCredit",
    "customerDueDebit",
    "customerDue",
    "totalAmount",
  ],
  "uncollected-from-delivery-agents": [
    "code",
    "status",
    "date",
    "deliveredOrReturnedDate",
    "lastDeliveryAgent",
    "senderName",
    "recipientName",
    "recipientZone",
    "price",
    "collectedFees",
    "deliveredAmount",
    "pendingCollectionAmount",

  ]







}