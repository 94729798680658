import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Icon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import clsx from "clsx";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { LIST_JOURNAL_TYPE } from "./Graphql";
import { useTranslation } from "react-i18next";
import JournalTypeForm from "./JournalTypeForm";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";

const PREFIX = 'JournalTypeList';

const classes = {
  iconColor: `${PREFIX}-iconColor`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

const JournalTypeList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [journalTypeObject, setJournalTypeObject] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setJournalTypeObject();
  };
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_JOURNAL_TYPE.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => { },
    }
  );

  const listJournalTypes = data?.listJournalTypes?.data;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const user = Globals.user;
  const hasCreatePermission = user.hasPermission(
    "accounting.journal_type.create"
  );
  const hasUpdatePermission = user.hasPermission(
    "accounting.journal_type.update"
  );

  let tableBody;

  const icons = [
    {
      id: "add",
      title: "createNew",
      action: () => handleClickOpen(),
      icon: Add,
      permission: hasCreatePermission,
    },
  ]

  tableBody = (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      className={classes.mainContainer}
    >
      {/* *******Table******* */}
      <Paper className={clsx(classes.paper)}>
        <TitleAppBar path={props.match.path} >
          <LongMenu icons={icons} />
        </TitleAppBar>

        {loading || listJournalTypes?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight='15%' />
            ) : (
              <EmptyTableMessage
                loading={false}
                message={t("searchNoResult")}
              />
            )}
          </Grid>
        ) : (
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("name")}</FixedTableCell>
                  <FixedTableCell>{t("code")}</FixedTableCell>
                  <FixedTableCell>{t("branch")}</FixedTableCell>
                  <FixedTableCell>{t("sequence")}</FixedTableCell>
                  <FixedTableCell>{t("reference")}</FixedTableCell>
                  <FixedTableCell>{t("active")}</FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listJournalTypes &&
                  listJournalTypes?.map((row, index) => {
                    const branchId = row.branch?.id;
                    const canAccessBranch = branchId
                      ? user.canAccessBranch(branchId)
                      : true;

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {(hasUpdatePermission ? canAccessBranch : false) ? (
                          <CellLink
                            onClick={() => {
                              handleClickOpen();
                              setJournalTypeObject(row);
                            }}
                          >
                            {row.name}
                          </CellLink>
                        ) : (
                          <FixedTableCell>{row.name}</FixedTableCell>
                        )}

                        <FixedTableCell>{row.code}</FixedTableCell>
                        <FixedTableCell>{row?.branch?.name}</FixedTableCell>
                        <FixedTableCell>{row.sequence.name}</FixedTableCell>
                        <FixedTableCell>{row.reference.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
        )}
        <MUITablePagination
          count={data?.listJournalTypes?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!data?.listJournalTypes ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );

  return (
    <Root>
      {open && (
        <JournalTypeForm
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          journalTypeObject={journalTypeObject}
        />
      )}
      <ListWrapper
        drawerState={false}
        icons={icons}
        path={props.match.path}
        empty={listJournalTypes?.length === 0}
        loading={loading}
        filters={false}
        tableHeaders={
          [
            {
              name: "name"
            },
            {
              name: "code"
            },
            {
              name: "branch"
            },
            {
              name: "sequence"
            },
            {
              name: "reference"
            },
            {
              name: "active"
            },
          ]
        }
        tableBody={
          <TableBody>
            {listJournalTypes &&
              listJournalTypes?.map((row, index) => {
                const branchId = row.branch?.id;
                const canAccessBranch = branchId
                  ? user.canAccessBranch(branchId)
                  : true;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {(hasUpdatePermission ? canAccessBranch : false) ? (
                      <CellLink
                        onClick={() => {
                          handleClickOpen();
                          setJournalTypeObject(row);
                        }}
                      >
                        {row.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell>{row.name}</FixedTableCell>
                    )}

                    <FixedTableCell>{row.code}</FixedTableCell>
                    <FixedTableCell>{row?.branch?.name}</FixedTableCell>
                    <FixedTableCell>{row.sequence.name}</FixedTableCell>
                    <FixedTableCell>{row.reference.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listJournalTypes?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listJournalTypes ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default JournalTypeList;
