import React from 'react'
import { useContext } from 'react'
import { ModeContext } from '../Context/ModeContext'
import PropTypes from "prop-types";

function LogoImg(props) {
    const { forceLight, className, style, height } = props

    const { darkMode } = useContext(ModeContext)
    let darkLogo
    let lightLogo
    try {
        darkLogo = module.require("../assets/Image/logo-dark.png")
        lightLogo = module.require("../assets/Image/logo-light.png")
    } catch (error) {
       try { darkLogo = lightLogo = module.require("../assets/Image/logo.png") } catch{}
    }

    const logo = forceLight ? lightLogo : darkMode ? darkLogo : lightLogo
    return (
        <img src={logo} alt="logo" className={className ?? ''} style={style ?? {}} height={height ?? ""} />
    )
}



LogoImg.propTypes = {
    forceLight: PropTypes.bool,
    className: PropTypes.any,
    height: PropTypes.any,
    style: PropTypes.any,
};

export default LogoImg;





