import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Box,
  Container,
  Tooltip,
  Icon,
  colors,
  useTheme,
} from "@mui/material";

import { useForm } from "react-hook-form";

import { IMPORT_SHIPMENTS, SAVE_SHIPMENT_QUERY } from "./Graphql";
import { useTranslation } from "react-i18next";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { useMutation, gql, useQuery } from "@apollo/client";
import adminFile from "../../assets/Files/shipments-admin.xlsx";
import customerFile from "../../assets/Files/shipments-customer.xlsx";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { Globals } from "../HOC/Classes/Globals";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { LIST_TRANSACTION_TYPES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { Can } from "../HOC/CustomComponents/Secured";
import UploadFile from "../HOC/MUI/UploadFile";
import clsx from "clsx";
import {
  Add,
  CancelOutlined,
  CheckCircleOutline,
  Close,
  Done,
  PriorityHigh,
} from "@mui/icons-material";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import * as gqlb from "gql-query-builder";
import TitleAppBar from "../../Layout/TitleAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";

const PREFIX = "ImportShipments";

const classes = {
  table: `${PREFIX}-table`,
  form: `${PREFIX}-form`,
  alert: `${PREFIX}-alert`,
  download: `${PREFIX}-download`,
  upload: `${PREFIX}-upload`,
  cellError: `${PREFIX}-cellError`,
  errorIcon: `${PREFIX}-errorIcon`,
  shipmentSuccess: `${PREFIX}-shipmentSuccess`,
  shipmentError: `${PREFIX}-shipmentError`,
  saveButton: `${PREFIX}-saveButton`,
  resetButton: `${PREFIX}-resetButton`,
  rowError: `${PREFIX}-rowError`,
  shipmentStateCell: `${PREFIX}-shipmentStateCell`,
};

const Root = styled(`div`)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",

    minHeight: "250px",
  },

  [`& .${classes.form}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.alert}`]: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  [`& .${classes.download}`]: {
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.upload}`]: {
    "& .MuiInputBase-input": {
      visibility: (props) => props.upload && "hidden",
      "&::-webkit-file-upload-text": {
        display: "none",
      },
    },
  },

  [`& .${classes.cellError}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.errorIcon}`]: {
    margin: theme.spacing(0, 0.5),
  },

  [`& .${classes.shipmentSuccess}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.shipmentError}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.saveButton}`]: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },

  [`& .${classes.resetButton}`]: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },

  [`& .${classes.shipmentStateCell}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

//*********Table Function*********

const ImportShipments = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [shipmentList, setShipmentList] = useState([]);
  const [rowErrors, setRowErrors] = useState([]);
  const [saveShipmentLoading, setSaveShipmentLoading] = useState(false);
  const [allHasError, setAllHasError] = useState(false);
  const [autocompleteValues, setAutocompleteValues] = useState({
    service: null,
    branch: null,
    transactionType: null,
  });

  const chooseCustomerPermission = Globals.user.hasPermission(
    "shipping.shipment.choose_customer"
  );
  const wareHouseOpen = Globals.settings.warehousing

  const tableFieldsLabels = useRef([
    {
      key: "code",
      translationKey: "code",
    },
    {
      key: "serviceId",
      translationKey: "service",
    },
    {
      key: "typeCode",
      translationKey: "type",
    },
    {
      key: "recipientName",
      translationKey: "recipient",
    },
    {
      key: "recipientZoneId",
      translationKey: "recipientZone",
    },
    {
      key: "recipientSubzoneId",
      translationKey: "recipientSubzone",
    },
    {
      key: "recipientPhone",
      translationKey: "recipientPhone",
    },
    {
      key: "recipientMobile",
      translationKey: "recipientMobile",
    },
    {
      key: "recipientAddress",
      translationKey: "recipientAddress",
    },
    {
      key: "longitude",
      translationKey: "longitude",
    },
    {
      key: "latitude",
      translationKey: "latitude",
    },
    {
      key: "description",
      translationKey: "description",
    },
    {
      key: "piecesCount",
      translationKey: "pieceCount",
    },
    {
      key: "weight",
      translationKey: "weight",
    },
    {
      key: "price",
      translationKey: "price",
    },
    {
      key: "priceTypeCode",
      translationKey: "priceType",
    },
    {
      key: "paymentTypeCode",
      translationKey: "paymentType",
    },
    {
      key: "refNumber",
      translationKey: "refNumber",
    },
    {
      key: "notes",
      translationKey: "notes",
    },
    {
      key: "senderName",
      translationKey: "senderName",
    },
    {
      key: "senderZoneId",
      translationKey: "senderZone",
    },
    {
      key: "senderSubzoneId",
      translationKey: "senderSubzone",
    },
    {
      key: "senderPhone",
      translationKey: "senderPhone",
    },
    {
      key: "senderMobile",
      translationKey: "senderMobile",
    },
    {
      key: "senderAddress",
      translationKey: "senderAddress",
    },
    {
      key: "openableCode",
      translationKey: "packageOpen",
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  const user = Globals.user;

  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: chooseCustomerPermission
      ? [
        { defaultShippingService: ["id", "name"] },
        { defaultTransactionType: ["id", "name"] },
        "forcePostalCode",
      ]
      : ["forcePostalCode"],
    variables: {},
  });

  const theme = useTheme()

  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (chooseCustomerPermission) {
          const defaultService =
            data?.shippingSettings?.["defaultShippingService"];
          const defaultTransactionType =
            data?.shippingSettings?.["defaultTransactionType"];
          setAutocompleteValues({
            branch: null,
            service: defaultService,
            transactionType: defaultTransactionType,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (chooseCustomerPermission) {
      tableFieldsLabels.current.splice(2, 0, {
        key: "customerId",
        translationKey: "customer",
      });
    }

    if (settings?.shippingSettings?.forcePostalCode) {
      tableFieldsLabels.current.splice(8, 0, {
        key: "recipientPostalCode",
        translationKey: "recipientPostalCode",
      });
      tableFieldsLabels.current.splice(25, 0, {
        key: "senderPostalCode",
        translationKey: "senderPostalCode",
      });
    }
    if (wareHouseOpen) {
      tableFieldsLabels.current.splice(4, 0, {
        key: "products",
        translationKey: "products",
      },);
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      shipmentsFile: "",
      fileName: "",
      branchId: "",
      transactionTypeId: "",
    },
  });
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [uploadFile, { loading: importLoading }] = useMutation(
    gql`
      ${IMPORT_SHIPMENTS.query}
    `
  );

  const [saveShipments, { loading: saveShipmentsLoading }] = useMutation(
    gql`
      ${SAVE_SHIPMENT_QUERY.query}
    `
  );

  const integerValues = [
    "price",
    "weight",
    "piecesCount",
    "latitude",
    "longitude",
  ];
  const shipmentQueryInputs = (shipmentIndex) => {
    let element = {};
    let products = [];
    const shipment = shipmentList[shipmentIndex];
    const shipmentDetails = shipment.shipmentDetails;
    const shipmentProductDetails = shipment.productDetails;
    for (let index = 0; index < shipmentDetails.length; index++) {
      const name = shipmentDetails[index].name;
      const value = shipmentDetails[index].value;
      element[name] =
        name.endsWith("Id") || integerValues.includes(name)
          ? parseFloat(value)
          : value;
    }

    for (let index = 0; index < shipmentProductDetails.length; index++) {
      let product = shipmentProductDetails[index]
      let elementProduct = {};
      for (let i = 0; i < product.length; i++) {

        const name = product[i].name;
        const value = product[i].value;

        elementProduct[name] =
          name.endsWith("Id") || integerValues.includes(name)
            ? parseFloat(value)
            : parseInt(value);
      }
      products.push(elementProduct)
    }
    element.shipmentProducts = products
    products.length === 0 && delete element.shipmentProducts;
    delete element.directive;
    return { ...shipment, shipmentDetails: element };
  };

  const handlingShipmentState = ({ index, loading, resName, resData }) => {
    setShipmentList((prev) => {
      prev[index]["loading"] = loading;
      if (resName) prev[index][resName] = resData;
      return [...prev];
    });
  };

  const stopShipmentLoading = (index) => {
    if (shipmentList.length - 1 === index) setSaveShipmentLoading(null);
  };
  const onSaveShipments = (index = 0) => {
    if (index === 0) {
      setSaveShipmentLoading(true);
    }
    if (shipmentList.length === index) return;
    const details = shipmentQueryInputs(index);

    saveShipments({
      variables: {
        input: {
          ...details.shipmentDetails,
          originBranchId: details.shipmentDetails.branchId,
        },
      },
    })
      .then((data) => {
        stopShipmentLoading(index);
        handlingShipmentState({
          index,
          loading: false,
          resName: "responseData",
          resData: data.data,
        });
        onSaveShipments(index + 1);
      })
      .catch((error) => {
        stopShipmentLoading(index);
        handlingShipmentState({
          index,
          loading: false,
          resName: "error",
          resData: error,
        });
        onSaveShipments(index + 1);
      });
  };

  const onUpload = (data) => {
    setShipmentList([]);
    setRowErrors([]);
    const { shipmentsFile, fileName, ...restdata } = data;
    for (const key in restdata) {
      if (restdata[key] === "") {
        delete restdata[key];
      }
    }
    const file = shipmentsFile[0];

    uploadFile({
      variables: {
        input: {
          file,
          ...restdata,
        },
      },
    })
      .then((res) => {
        const sortedData = res.data.importShipments.map((i, index) => {
          i.fields.map((e) => e.errors.length > 0 && setAllHasError(true))
          return {
            id: "SHP" + index,
            shipmentDetails: i.fields,
            productDetails: i.products,
            responseData: null,
            error: null,
            loading: null,
          }
        });
        setShipmentList(sortedData);
        setValue("shipmentsFile", "");
        setValue("fileName", "");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const parseData = (data) => {
    return data;
  };

  const resetForm = () => {
    reset((formValues) => ({
      ...formValues,
      shipmentsFile: "",
    }))
    setShipmentList([]);
    setRowErrors([]);
    setSaveShipmentLoading(false);
    setAllHasError(false);
  };

  const dataEmpty = shipmentList.length === 0;
  const saveButtonCondition =
    shipmentList.length > 0 && saveShipmentLoading !== null;

  //////////Table Function////////////
  return (
    <Root>
      <TitleAppBar path={props.match.path}>
        <IconButton
          download
          href={chooseCustomerPermission ? adminFile : customerFile}
          className={classes.download}
        >
          <HiOutlineDocumentDownload />
        </IconButton>
      </TitleAppBar>
      <Container maxWidth="xl">
        <Paper
          container
          spacing={2}
          sx={{ width: "100%", m: "16px 0" }}
          component={Grid}
        >
          <Grid
            component="form"
            container
            sx={{ width: "100%", m: 0, paddingTop: 0 }}
            className={classes.form}
            onSubmit={handleSubmit(onUpload)}
            justifyContent="flex-start"
          >
            <Grid
              justifyContent="space-between"
              sx={{ flexGrow: 1, display: "flex" }}
              xs={12}
            >

            </Grid>
            <Can showException permission="shipping.shipment.choose_branch">
              <Grid xs={12} md={6}>
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  rules={{ required: t("fieldIsRequired") }}
                  onChangeValue={(e) => {
                    setValue("transactionTypeId", "");
                  }}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
            </Can>
            <Can
              showException
              permission="shipping.shipment.choose_transaction_type"
            >
              <Grid xs={12} md={6}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"transactionTypeId"}
                  label={t("transactionType")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                  skip={Boolean(!watch("branchId"))}
                  disabled={Boolean(!watch("branchId"))}
                  variables={{
                    input: {
                      type: "PKR",
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    },
                  }}
                  defaultValue={autocompleteValues.transactionType}
                />
              </Grid>
            </Can>

            <Grid xs={12}>
              <UploadFile
                control={control}
                name="shipmentsFile"
                setValue={setValue}
                rules={{ required: t("fieldIsRequired") }}
                disabled={shipmentList.length > 0}
                iconDisable={shipmentList.length > 0}
                icon={"file_upload"}
                label={t("excelFile")}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Grid>

            <Grid
              sx={{ display: "flex", mb: 1 }}
              justifyContent="flex-end"
              xs={12}
            >
              {saveButtonCondition && (
                <Button
                  className={classes.saveButton}
                  disabled={saveShipmentsLoading || rowErrors.length > 0 || allHasError}
                  variant="contained"
                  size="medium"
                  onClick={() => onSaveShipments()}
                  startIcon={<Done />}
                >
                  {saveShipmentsLoading ? <ButtonLoading /> : t("save")}
                </Button>
              )}
              {shipmentList.length === 0 && (
                <Button
                  type="submit"
                  disabled={importLoading || saveShipmentsLoading}
                  variant="contained"
                  size="medium"
                  color="primary"
                  startIcon={<Icon>file_upload</Icon>}
                >
                  {importLoading ? <ButtonLoading /> : t("upload")}
                </Button>
              )}
              {shipmentList.length > 0 && (
                <Button
                  onClick={() => {
                    resetForm();
                  }}
                  disabled={importLoading || saveShipmentsLoading}
                  variant="contained"
                  size="medium"
                  className={classes.resetButton}
                  startIcon={saveButtonCondition ? <Close /> : <Add />}
                >
                  {saveButtonCondition ? t("cancel") : t("new")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
      {shipmentList.length !== 0 && (
        <Paper>
          <Grid container className={classes.table}>
            <TableFixedHeaderWraper>
              {importLoading || dataEmpty ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ margin: "10% 0" }}
                >
                  {importLoading ? (
                    <FullScreenLoading />
                  ) : (
                    <EmptyTableMessage
                      message={t("noResult")}
                    />
                  )}
                </Grid>
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <FixedTableCell>#</FixedTableCell>
                      {tableFieldsLabels.current.map((field) => (
                        <FixedTableCell key={field.translationKey}>
                          {t(field.translationKey)}
                        </FixedTableCell>
                      ))}
                      <FixedTableCell className={classes.shipmentStateCell} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shipmentList &&
                      (rowsPerPage > 0
                        ? shipmentList.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        : shipmentList
                      )?.map((row, index) => (
                        <TableRow
                          sx={{
                            ...(rowErrors.includes(row.id) && {
                              backgroundColor: theme.palette.mode === "dark" ? "#651e1c" : colors.red[100],
                            }),
                          }}
                          key={row.id}
                        >
                          <FixedTableCell>{index + 1 + (((page + 1) - 1) * rowsPerPage)}</FixedTableCell>
                          {tableFieldsLabels.current.map((field) => (
                            <CustomTableCell
                              {...{
                                row,
                                field,
                                setRowErrors,
                                rowErrors
                              }}
                              key={field.key}
                            />
                          ))}
                          <FixedTableCell className={classes.shipmentStateCell}>
                            <IconButton disableRipple size="large">
                              {Boolean(row.responseData) ? (
                                <CheckCircleOutline
                                  className={classes.shipmentSuccess}
                                />
                              ) : Boolean(row.error) ? (
                                <Tooltip title={row.error.message}>
                                  <CancelOutlined
                                    className={classes.shipmentError}
                                  />
                                </Tooltip>
                              ) : (
                                Boolean(saveShipmentLoading) && (
                                  <CustomSpinner name="PulseLoader" size={8} />
                                )
                              )}
                            </IconButton>
                          </FixedTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableFixedHeaderWraper>
          </Grid>

          <MUITablePagination
            count={shipmentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Root>
  );
};
export default ImportShipments;

const CustomTableCell = ({ row, field, setRowErrors, rowErrors }) => {
  const fieldDetails = row.shipmentDetails.find((i) => i.name === field.key);
  const hasError = fieldDetails?.errors?.length > 0;
  const { t } = useTranslation();
  useEffect(() => {
    if (hasError) {
      setRowErrors((prev) => [...prev, row.id]);
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (field.key === "products") {
    return row.productDetails.length > 0 ? (
      row.productDetails.map((products, index) => {
        return (
          <TableRow key={index}>
            {products.map((product, i) => {
              const productError = product?.errors?.length > 0;
              const errors = productError
                ? product.errors.map((i) => (
                  <span key={i} style={{ display: "block" }}>
                    {i}
                  </span>
                ))
                : undefined;
              if (productError) {
                if (!(rowErrors.includes(row.id))) {
                  setRowErrors((prev) => [...prev, row.id]);
                }
              }
              const name = product.name === "productId" ? "code" : product.name
              return (
                <FixedTableCell key={i}>
                  <Tooltip title={errors}>
                    <Box display="flex" alignItems="center">
                      {`${t(`${name}`)} : ${product.originalValue ?? "--"} `}
                      {productError && (
                        <PriorityHigh
                          fontSize="small"
                          // className={classes.errorIcon}
                          color="error"
                        />
                      )}
                    </Box>
                  </Tooltip>
                </FixedTableCell>
              )
            })}
          </TableRow>
        )
      }
      )
    ) : (
      <FixedTableCell />
    )
  }


  const errors = hasError
    ? fieldDetails.errors.map((i) => (
      <span key={i} style={{ display: "block" }}>
        {i}
      </span>
    ))
    : undefined;
  const shipmentDetails = row.responseData?.saveShipment;
  const codeField = field.key === "code" && shipmentDetails?.code;
  const cellValue = codeField
    ? shipmentDetails?.code
    : fieldDetails?.originalValue;

  return hasError ? (
    <FixedTableCell className={clsx({ [classes.cellError]: hasError })}>
      <Tooltip title={errors}>
        <Box display="flex" alignItems="center">
          {hasError && (
            <PriorityHigh
              fontSize="small"
              className={classes.errorIcon}
              color="error"
            />
          )}
          {cellValue ?? "--"}
        </Box>
      </Tooltip>
    </FixedTableCell>
  ) : codeField ? (
    <CellLink pathname={`/admin/shipments/${shipmentDetails?.id}`}>
      {cellValue}
    </CellLink>
  ) : (
    <FixedTableCell className={clsx({ [classes.cellError]: hasError })}>
      {cellValue ?? "--"}
    </FixedTableCell>
  );
};
