import { Box, Collapse, Grid, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  LIST_CANCELLATION_REASONS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import { useManifestShipmentsContext } from "./ManifestShipmentsProvider";

const RTSTableRowForm = (props) => {
  const { shipment } = props;

  const {
    manifestShipmentsContext: { updateSelectedShipmentByForm },
  } = useManifestShipmentsContext();

  const [autocompleteValues, setAutocompleteValues] = useState({
    cancellationReason: null,
    returnType: null,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    setError,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      returnTypeCode: "",
      cancellationReasonId: "",
      fees: "",
    },
  });
  const { t } = useTranslation(["translation", "validation"]);

  useEffect(() => {
    ["returnTypeCode", "cancellationReasonId", "fees"].forEach((i) => {
      if (watch(i) === "") {
        setError(i, {
          type: "required",
          message: t("fieldIsRequired"),
        });
      }
    });
    return () => { };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  const shipmentDetails = shipment.shipment;

  const updateShipment = (name, value) => {
    /************* Validation Funcs *******************/

    let allField = watch();
    for (const key in allField) {
      if (key.startsWith(name)) {
        delete allField[key];
      }
    }

    const checkIsEmpty =
      !Object.values(allField).some((i) => i === "") && value !== "";

    /*************************************************/
    updateSelectedShipmentByForm({
      id: shipmentDetails.id,
      checkIsEmpty,
      name,
      value,
    });
  };

  const handelAutocompleteDefaultValue = (
    data,
    value,
    fieldName,
    valueKey = "id"
  ) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i[valueKey] === parseInt(value)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  // useEffect(() => {
  //   return () => {
  //     // reset();
  //     // updateShipment("fees", "");
  //   };
  // }, []);

  const onChangeReturnType = (e) => {
    const fieldValue = e.code;
    const name = e.name;
    const value = fieldValue ? { code: fieldValue, name } : "";

    if (fieldValue === "WODF") {
      setValue("fees", 0, {
        shouldValidate: true,
      });
      updateShipment("fees", 0);
    } else {
      setValue("fees", shipmentDetails.returnFees, {
        shouldValidate: true,
      });
      updateShipment("fees", shipmentDetails.returnFees);
    }

    updateShipment("returnType", value);
  };

  const onChangeFees = (e) => {
    const value = parseFloat(e.target.value);
    updateShipment("fees", value || "");
  };

  const onChangeCancellationReason = (e) => {
    updateShipment("cancellationReason", e);
  };

  const onSubmit = (data) => {
    // console.log(data);
  };

  const conditionFPCAndWPDF = Boolean(
    watch("returnTypeCode") !== "FPC" && watch("returnTypeCode") !== "WPDF"
  );

  return (
    <TableRow>
      <TableCell
        sx={{ paddingBottom: 0, paddingTop: 0, height: 0 }}
        colSpan={10}
      >
        <Collapse in={shipment.select} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1, padding: "16px 8px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                item
                justifyContent="flex-start"
                alignContent="space-between"
                // className={classes.form}
                spacing={2}
              >
                <Grid container item sm={3} alignItems="flex-start">
                  <CustomAutocomplete
                    valueKey="code"
                    variant="outlined"
                    control={control}
                    errors={errors}
                    name={"returnTypeCode"}
                    label={t("returnType")}
                    onChangeValue={onChangeReturnType}
                    rules={{ required: t("fieldIsRequired") }}
                    query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                    variables={{
                      input: { code: "SHP_RETURNING_TYPE" },
                    }}
                    parseData={(data) => {
                      const arrFilter = shipmentDetails?.status?.code === 'RITS' ? ["WODF", "WPDF", "WFDF"] : ["WODF", "WPDF", "FPC", "WFDF"]
                      const dataFilter = data &&
                        data.filter((i) =>
                          arrFilter.includes(i.code)
                        )
                      return dataFilter
                    }
                    }
                    onCompleted={(data) => {
                      if (Boolean(shipment?.fees || shipment?.fees === 0)) {
                        setValue("fees", shipment?.fees, {
                          shouldValidate: true,
                        });
                      }
                      handelAutocompleteDefaultValue(
                        data,
                        shipment?.["returnType"]?.id,
                        "returnTypeCode"
                      );
                    }}
                    defaultValue={autocompleteValues.returnType}
                  />
                </Grid>
                <Grid container item sm={3} alignItems="flex-start">
                  <CustomAutocomplete
                    onChangeValue={onChangeCancellationReason}
                    variant="outlined"
                    control={control}
                    errors={errors}
                    name={"cancellationReasonId"}
                    label={t("reasonName")}
                    query={LIST_CANCELLATION_REASONS_DROPDOWN.query}
                    parseData={(data) => data}
                    rules={{ required: t("fieldIsRequired") }}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        shipment?.["cancellationReason"]?.id,
                        "cancellationReason"
                      )
                    }
                    defaultValue={autocompleteValues.cancellationReason}
                  />
                </Grid>

                <Grid container item sm={3} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    readOnly={conditionFPCAndWPDF}
                    name={"fees"}
                    type="number"
                    label={t("fees")}
                    onChange={onChangeFees}
                    rules={{
                      required: t("fieldIsRequired"),
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default RTSTableRowForm;
