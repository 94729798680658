import {
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    FlagImage,
} from "react-international-phone";
import PropTypes from "prop-types";
import countries from '../../../phoneNumber.json'
import { Globals } from "../Classes/Globals";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

const TextFieldStyle = styled(TextField)(({ theme }) => ({
    ".MuiInputBase-root": { paddingLeft: "6px", paddingRight: "8px" },
    ".MuiInputBase-input": { paddingRight: "2px" },
}));

const MuiPhone = (props) => {
    const {
        name,
        control,
        defaultValue,
        readOnly,
        inputProps,
        rules,
        onChange,
        variant,
        serverValidation,
        label,
        selectName,
        mobileData,
        countryCodeValue,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const {
        formState: { errors },
        field: { ref, onChange: fieldChange, value, ...fieldProps },
    } = useController({
        name,
        control,
        rules: {
            ...rules,
            ...(rules && {
                validate: {
                    whiteSpace: (value) => {
                        if (value && typeof value === "string") {
                            return !!value.trim() || t("fieldIsRequired");
                        }
                    },
                    ...(rules["validate"] && rules["validate"]),
                },
            }),
        },
        defaultValue: defaultValue ?? "",
    });

    const errorName = name.includes(".") && name.split(".");
    const serverError = errorName ? errorName[1] : name;
    const fieldError = errorName
        ? errors?.[errorName[0]]?.[errorName[1]]
        : errors?.[name];
    const isRequired =
        (rules && rules?.required) ||
        (rules?.validate?.max && typeof rules?.validate?.max() === "string") ||
        (rules?.validate?.require &&
            typeof rules?.validate?.require() === "string");

    const phoneNumberRegex = /^\d+$/

    return (
        <TextFieldStyle
            inputRef={ref}
            type="tel"
            dir="ltr"
            {...fieldProps}
            {...restProps}
            label={isRequired ? label + " *" : label}
            defaultValue={defaultValue}
            value={value}
            autoComplete="off"
            id={name}
            variant={variant || "filled"}
            fullWidth
            multiline={!!props.rows}
            error={Boolean(fieldError || serverValidation?.[serverError])}
            helperText={
                errors
                    ? fieldError?.message
                    : Boolean(serverValidation) && serverValidation?.[serverError]
                        ? serverValidation?.[serverError][0]
                        : null
            }
            inputProps={{
                readOnly: readOnly,
                maxLength: 15,
                ...inputProps,
            }}
            onChange={(e) => {
                let value = e.target.value
                const valid = phoneNumberRegex.test(value)
                const empty = value === ""
                if (!valid && !empty) {
                    return
                }
                fieldChange(e);
                onChange && onChange(e);
            }}
            size="small"
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ marginRight: "2px", marginLeft: "-2px", marginBottom: "-3px" }}
                    >
                        <MUIselect {...props} TextFieldChange={fieldChange} />
                    </InputAdornment>
                )
            }}
            {...restProps}
        />
    );
};

const TypographyStyle = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.8),
}));

const MUIselect = (props) => {
    const {
        control,
        selectName,
        disabled,
        onChanges,
        rules,
        readOnly,
        defaultValue,
        TextFieldChange,
        countryCodeValue,
    } = props;
    const name = selectName
    const {
        formState: { errors },
        field: { ref, value: val, onChange: fieldChange, ...fieldProps },
    } = useController({
        name,
        control,
        rules: { ...rules },
        defaultValue: countries && defaultValue ? defaultValue : "",
    });

    const { i18n } = useTranslation()

    const errorName = name.includes(".") && name.split(".");
    const fieldError = errorName
        ? errors?.[errorName[0]]?.[errorName[1]]
        : errors?.[name];

    const countryCode = Globals?.settings?.countryCode ?? countryCodeValue
    const multiCountries = Globals?.settings?.multiCountries

    useEffect(() => {
        if (!val) {
            const country = countries.filter((country) => "+" + country.phone === countryCode)
            fieldChange(country[0].code.toLowerCase())
            return () => { }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    countries.sort(function (a, b) {
        return i18n.language === "ar" ? a.labelAr.localeCompare(b.labelAr, ["ar"]) : a.label.localeCompare(b.label, ["en"])
    });

    return (
        <Select
            variant="outlined"
            {...fieldProps}
            inputProps={{
                readOnly: readOnly,
                IconComponent: () => null
            }}
            dir="ltr"
            inputRef={ref}
            error={Boolean(fieldError)}
            value={val}
            defaultValue={countries && defaultValue ? defaultValue : ""}
            defaultChecked
            disabled={disabled}
            onChange={(e) => {
                fieldChange(e.target.value);
                TextFieldChange("")
                onChanges && onChanges(e);
            }}
            MenuProps={{
                style: {
                    height: "300px",
                    width: "360px",
                    top: "10px",
                    right: "80%",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                }
            }}
            sx={{
                width: "max-content",
                ...(!multiCountries && {
                    width: 0,
                    visibility: "hidden",
                }),
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                "&.MuiOutlinedInput-root":
                {
                    padding: 0,
                },
                // Remove default outline (display only on focus)
                fieldset: {
                    display: "none"
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                        display: "block"
                    }
                },
                // Update default spacing
                ".MuiSelect-select": {
                    padding: "1px !important",
                },
                svg: {
                    right: 0
                }
            }}
            renderValue={(value) => {
                const country = countries.filter((country) => country.code === value.toUpperCase())
                return <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FlagImage iso2={value} style={{ display: "flex" }} height={20} />
                    <TypographyStyle color={"text.primary"}>+{country[0].phone}</TypographyStyle>
                </Box>
            }
            }
        >
            {countries.map((c) => {
                return (
                    <MenuItem key={c.code} value={c.code.toLowerCase()}>
                        <FlagImage
                            iso2={c.code.toLowerCase()}
                            style={{ marginRight: "8px" }}
                            height={16}
                        />
                        <Typography mx="8px">{i18n.language === "ar" ? c.labelAr : c.label}</Typography>
                        <Typography color="gray">+{c.phone}</Typography>
                    </MenuItem>
                );
            })}
        </Select>
    );
};

MUIselect.propTypes = {
    control: PropTypes.any,
    errors: PropTypes.any,
    data: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    onChanges: PropTypes.func,
    rules: PropTypes.object,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any,
};



MuiPhone.propTypes = {
    errors: PropTypes.any,
    name: PropTypes.string.isRequired,
    control: PropTypes.any.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any,
    selectName: PropTypes.string.isRequired,
};

export default MuiPhone;
