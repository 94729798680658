import { Alert, Box, IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
// import { dateFormatLL } from "../../helpers/dateFunctions";
import formatMoney from "../../helpers/numbersDot";
import { DELETE_PAYMENT_ENTRIES_MUTATION, initialData } from "./PaymentListQuary";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { dateFormatLL } from "../../helpers/dateFunctions";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
const PREFIX = "usePaymentDetails";
const classes = {
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));
export const CustomerPaymentMethod = (props) => {
  const { classes, customerData } = props
  const paymentMethodCode = customerData?.customer?.paymentMethod?.code
  const { t } = useTranslation();
  return <Alert
    severity="info"
    color="warning"
    variant="outlined"
    classes={{
      message: classes.alertMessage,
      root: classes.alertMessage,
    }}
  >
    <Grid container width={"100%"} p={0}>
      <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("paymentMethod") + " :"}
        </Box>
        <Box mx={1}>
          {customerData?.customer?.paymentMethod?.name ?? "-"}
        </Box>
      </Grid>
      {paymentMethodCode === "BNK" && <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("bank") + " :"}
        </Box>
        <Box mx={1}>
          {customerData.customer?.bank?.name ?? "-"}
        </Box>
      </Grid>}
      {paymentMethodCode === "BNK" && <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("accountNumber") + " :"}
        </Box>
        <Box mx={1}>
          {customerData.customer?.bankAccount ?? "-"}
        </Box>
      </Grid>}
      {paymentMethodCode === "VCSH" && <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("walletNumber") + " :"}
        </Box>
        <Box mx={1}>
          {customerData.customer?.vodafoneCash ?? "-"}
        </Box>
      </Grid>}
    </Grid>
  </Alert>
}

CustomerPaymentMethod.propTypes = {
  classes: PropTypes.any,
  customerData: PropTypes.any.isRequired,
}

const tableBody = (localDataKey, paymentShipment) => {
  const body = [
    {
      key: "code",
      pathName: `/admin/shipments/${paymentShipment.shipment.id}`,
      tag: CellLink,
      value: paymentShipment.shipment.code,
    },
    {
      key: "recipientName",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.recipientName,
    },
    {
      key: "date",
      tag: FixedTableCell,
      value: dateFormatLL(paymentShipment.shipment?.date)
    },
    {
      key: "deliveredOrReturnedDate",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.deliveredOrReturnedDate
    },
    {
      key: "recipientPhone",
      tag: FixedTableCell,
      value: <> <span dir="ltr" style={{ display: "block" }}>
        {paymentShipment?.shipment?.recipientMobile}
      </span>
        <span dir="ltr">{paymentShipment?.shipment?.recipientPhone}</span>
      </>
    },
    {
      key: "recipientZone",
      pathName: `/admin/zones/${paymentShipment?.shipment?.recipientZone?.id}`,
      tag: ShipmentRegion,
      value: paymentShipment?.shipment?.recipientZone?.name,
      subValue: paymentShipment?.shipment?.recipientSubzone?.name,
    },
    {
      key: "recipientAddress",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.recipientAddress,
    },
    {
      key: "paymentType",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.paymentType?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.deliveryType?.name ?? paymentShipment.shipment?.status?.name,
    },
    {
      key: "weight",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.weight,
    },
    {
      key: "piecesCount",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.piecesCount,
    },
    {
      key: "senderName",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.senderName,
    },
    {
      key: "senderAddress",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.senderAddress,
    },
    {
      key: "senderZone",
      pathName: `/admin/zones/${paymentShipment?.shipment?.senderZone?.id}`,
      tag: ShipmentRegion,
      value: paymentShipment?.shipment?.senderZone?.name,
      subValue: paymentShipment?.shipment?.senderSubzone?.name,
    },
    {
      key: "senderPhone",
      tag: FixedTableCell,
      value:
        <> <span dir="ltr" style={{ display: "block" }}>
          {paymentShipment?.shipment?.senderMobile}
        </span>
          <span dir="ltr">{paymentShipment?.shipment?.senderPhone}</span>
        </>
    },
    {
      key: "returnType",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.returnType?.name,
    },
    {
      key: "type",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.type?.name
    },
    {
      key: "description",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.description,
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment?.collectedAmount),
    },


    {
      key: "originBranch",
      pathName: `/admin/branches/${paymentShipment.shipment?.originBranch?.id}`,
      tag: CellLink,
      value: paymentShipment.shipment?.originBranch?.name
    },

    {
      key: "status",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.status?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value: paymentShipment.shipment?.deliveryType?.name ??
        paymentShipment.shipment?.status?.name,
    },
    {
      key: "totalAmount",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment.shipment?.totalAmount),
    },
    {
      key: "deliveredAmount",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment.shipment?.deliveredAmount),
    },
    {
      key: "collectedFees",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment.shipment?.collectedFees),
    },
    {
      key: "returningDueFees",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment.shipment?.returningDueFees),
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment.shipment?.collectedAmount),
    },
    {
      key: "paidAmount",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment?.paidAmount),
    },
    {
      key: "customerDueAmount",
      tag: FixedTableCell,
      value: formatMoney(paymentShipment?.paidAmount),
    }, {
      key: "note",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.notes,
    },
    {
      key: "refNumber",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.refNumber,
    },
    {
      key: "notes",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.transactions && paymentShipment?.shipment?.transactions[0]?.manifestShipment?.notes
    },
    {
      key: "description",
      tag: FixedTableCell,
      value: paymentShipment?.shipment?.description
    },
    {
      key: "barcode",
      tag: FixedTableCell,
      auto: true,
      value: <div className={classes.barcode}>
        *{paymentShipment?.shipment?.code}*
      </div>
    },
  ]
  let selected = []
  localDataKey.map((e) => {
    const selectBody = body.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0])
    }
    return ''
  })
  return selected
}
export const tableSumPayment = (localDataKey, TotalData, removeShipment) => {
  console.log(localDataKey);
  console.log(TotalData);
  const sumTableData = [
    {
      key: "collectedAmount",
      value: TotalData?.sumEntriesShipment?.collectedAmount,

    },
    {
      key: "weight",
      value: TotalData?.sumEntriesShipment?.weight,

    },
    {
      key: "piecesCount",
      value: TotalData?.sumEntriesShipment?.piecesCount,

    },
    {
      key: "totalAmount",
      value: TotalData?.sumEntriesShipment?.totalAmount,

    },
    {
      key: "collectedFees",
      value: TotalData?.sumEntriesShipment?.collectedFees,

    },
    {
      key: "returningDueFees",
      value: TotalData?.sumEntriesShipment?.dueFees,
    },
    {
      key: "paidAmount",
      value: TotalData?.sumEntriesShipment?.paymentAmount,
      // clientSum: true
    },
    {
      key: "customerDueAmount",
      value: TotalData?.sumEntriesShipment?.paymentAmount,
    },
    {
      key: "deliveredAmount",
      value: TotalData?.sumEntriesShipment?.deliveredAmount,
    },
  ]
  let selected = []
  let ifSum = false
  localDataKey.map((e, index) => {
    const selectBody = sumTableData.filter((ele, i) => ele.key === e)
    if (selectBody[0]) {
      ifSum = true
      selected.push(selectBody[0])
    } else {
      selected.push({
        key: e,
        value: "no",
        noSum: true
      })
    }
    return ""
  })
  removeShipment && selected.push({
    key: "removeShipment",
    value: "no",
    noSum: true
  })
  return ifSum ? selected : []
}
const tableHead = (localDataKey) => {
  const headCell = [
    {
      key: "paymentType",
      value: "paymentType"
    },
    {
      key: "code",
      value: "code",
    },
    {
      key: "date",
      value: "date"
    },
    {
      key: "deliveredOrReturnedDate",
      value: "deliveryCanclingDate"
    },
    {
      key: "description",
      value: "description"
    },
    {
      key: "weight",
      value: "weight",
    },
    {
      key: "piecesCount",
      value: "pieceCount",
    },
    {
      key: "status",
      value: "shipmentStatus",
    },
    {
      key: "recipientName",
      value: "recipient",
    },
    {
      key: "recipientZone",
      value: "destination",
    },
    {
      key: "originBranch",
      value: "originBranch",
    },
    {
      key: "collectedAmount",
      value: "collectedAmount",
    },
    {
      key: "recipientAddress",
      value: "recipientAddress",
    },
    {
      key: "senderName",
      value: "senderName",
    },
    {
      key: "senderAddress",
      value: "senderAddress",
    },
    {
      key: "senderZone",
      value: "source",
    },
    {
      key: "senderPhone",
      value: "senderPhone",
    },
    {
      key: "returnType",
      value: "returnType",
    },
    {
      key: "type",
      value: "packageType",
    },
    {
      key: "totalAmount",
      value: "totalAmount",
    },
    {
      key: "collectedAmount",
      value: "collectedAmount",
    },
    {
      key: "returningDueFees",
      value: "returningDueFees",
    },
    {
      key: "deliveryType",
      value: "deliveryType",
    },
    {
      key: "collectedFees",
      value: "collectedFees",
    },
    {
      key: "deliveredAmount",
      value: "deliveredAmount",
    },
    {
      key: "commission",
      value: "commission",
    },
    {
      key: "NetAmount",
      value: "NetAmount",
    },
    {
      key: "paidAmount",
      value: "deservedAmount",
    },
    {
      key: "recipientPhone",
      value: "recipientPhone",
    },
    {
      key: "customerDueAmount",
      value: "customerDueAmount",
    },
    {
      key: "refNumber",
      value: "refNumber",
    },
    {
      key: "note",
      value: "notes",
    },
    {
      key: "barcode",
      value: "barcode",
    },

  ]

  let selected = []
  localDataKey.map((e) => {
    const selectBody = headCell.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0].value)
    }
    return ''
  })
  return selected
}
const usePaymentsDetails = ({
  type,
  approved,
  setShipmentData: removeShipment,
  refetchShipmentDataQuery,
  typeData,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePaymentEntries, { loading: deletePaymentEntriesLoading }] = useMutation(
    gql`
      ${DELETE_PAYMENT_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteShipment = (shipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || shipment.shipment?.pendingDelete}
        onClick={() => {
          removeShipment(prev => {
            const shipments = prev.pickedShipment.map(e => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete: e.shipment.id === shipment.shipment.id ? true : e.shipment.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedShipment: shipments
            }
          })
          deletePaymentEntries({
            variables: {
              input: {
                paymentId: typeData.id,
                shipmentIds: [shipment.shipment.id]
              }
            }
          }).then(() => {
            refetchShipmentDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deletePaymentEntriesLoading && shipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  const localDataKey = localStorage.getItem(`shipmentPayment${type}`) ? JSON.parse(localStorage.getItem(`shipmentPayment${type}`)) : initialData[type]
  const tableBodyCell = (paymentShipment, index) => {
    let table = tableBody(localDataKey, paymentShipment)
    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        {table.map((ele, i) => {
          return ele.subValue ? <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} zone={ele.value}
            subzone={ele.subValue} /> : <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} auto={ele?.auto}>{ele.value}</ele.tag>
        })}
        {removeShipment && deleteShipment(paymentShipment)}
      </StyledTableRow>
    );
  };

  const tableHeadCell = () => {
    if (localDataKey) {
      let table = tableHead(localDataKey)
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed]
    }
  }
  const TableCell = tableHeadCell()
  const tableSumArr = () => {
    if (localDataKey) {
      let table = tableSumPayment(localDataKey, typeData, removeShipment)

      return table
    }
  }
  const tableSum = tableSumArr()

  const sumTableNumber = (shipment, tableTotal) => {
    // Object.entries(tableTotal).forEach(([k, v]) => {
    //   k === "paidAmount"
    //     && (tableTotal[k] += shipment[k] ?? " ")
    // });
  };

  /* --------------------------------- Manifests Object --------------------------------- */

  const paymentsObject = {
    CUSTM: {
      id: "1",
      customer: true,
      label: t("manifestDetails"),
      tableHead: TableCell,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
      sumTableNumber,
    },
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: TableCell,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
      sumTableNumber,
    },
  };

  return {
    details: paymentsObject[type],
  };
};

export default usePaymentsDetails;
