import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
// import SpanLink from "../HOC/CustomComponents/SpanLink";
import CellLink from "../../HOC/CustomComponents/CellLink";
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { gql, useQuery } from "@apollo/client";

const PREFIX = "integrationZoneTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  iconColor: `${PREFIX}-iconColor`,
  deleteIcon: `${PREFIX}-deleteIcon`,
  main: `${PREFIX}-main`,
};
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },

  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const IntegrationZoneTable = (props) => {
  const { addService, title, query, variables, onCompleted, control,
    async,
    parseData, } = props;

  const [inputValue, setInputValue] = React.useState("");
  const { t } = useTranslation();
  const { data: queryData, loading } = useQuery(
    gql`
    ${query}
  `,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,

      variables: {
        ...(!async && variables && { ...variables }),
        ...(async && { ...variables(inputValue) }),
      },
      onCompleted: (data) => {
        onCompleted && onCompleted(data);
      },
    }
  );
  const queryName = queryData && Object.keys(queryData)?.[0];
  const data = parseData(queryData?.[queryName]);




  const [serviceIndex, setServiceIndex] = useState(0);
  const [servicePage, serServicePage] = useState(0);
  const [serviceRowsPerPage, setServiceRowsPerPage] = useState(10);

  const handleTreasurersPage = (event, newPage) => {
    serServicePage(newPage);
  };
  const handleTreasurersRowsPerPage = (event) => {
    setServiceRowsPerPage(parseInt(event.target.value));
    serServicePage(0);
  };
  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    :
    <StyledGrid item xs={12} alignItems="flex-start" component={Paper}>
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography className={classes.title} color="inherit" variant="h6">
          {t(`${title}`)}
        </Typography>
      </Toolbar>
      <Grid container item className={classes.table}>
        {data.length <= 0 ? (
          <EmptyTableMessage loading={false} message={t(`${title}`)} />
        ) :
          (
            <TableFixedHeaderWraper containerScroll>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("zonesList")}</FixedTableCell>
                    <FixedTableCell>{t("remoteId")}</FixedTableCell>
                    <FixedTableCell>{t("zonesAndSubzones")}</FixedTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.length > 0 &&
                    (serviceRowsPerPage > 0
                      ? data.slice(
                        servicePage * serviceRowsPerPage,
                        servicePage * serviceRowsPerPage +
                        serviceRowsPerPage
                      )
                      : data
                    ).map((row, index) => (
                      <TableRow
                        className={clsx({
                          [classes.focus]:
                            servicePage * serviceRowsPerPage + index ===
                            serviceIndex,
                        })}
                        hover
                        key={index}
                      >
                        <FixedTableCell >
                          {row.name}
                        </FixedTableCell>
                        <FixedTableCell key={row.id} >
                          <ControlMUItextField
                            control={control}
                            name={`zone${row.id}`}
                            label={t("remoteId")}
                          />
                        </FixedTableCell>
                        <FixedTableCell>
                          <IconButton
                            color="primary"
                            onClick={() => {
                              addService(row.id)
                              // setDialog(true)
                              // setServiceIndex(0);
                            }}
                            aria-label="Add title"
                            size="large"
                          >
                            <Add />
                          </IconButton>
                        </FixedTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>

          )}
        <MUITablePagination
          count={data?.length ?? 0}
          rowsPerPage={serviceRowsPerPage}
          page={servicePage}
          onPageChange={handleTreasurersPage}
          onRowsPerPageChange={handleTreasurersRowsPerPage}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Grid>
    </StyledGrid>
};

export default IntegrationZoneTable;
