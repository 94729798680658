import React from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import Card from "@mui/material/Card";
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import * as gqlb from "gql-query-builder";
// import PropTypes from "prop-types";
import CountUp from "react-countup";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { Globals } from "../HOC/Classes/Globals";
import { VscPackage } from "react-icons/vsc";
import { BiPhoneCall, BiSupport } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import SpanLinkNoLine from "../HOC/CustomComponents/SpanLinkNoLine";
import { GoDash, GoPackageDependencies } from "react-icons/go";
import { MdOutlineReportProblem } from "react-icons/md";
import StatisticsFilterHeader from "./StatisticsFilterHeader";
import { GetPermissionSlug } from "../../helpers/getPermissionSlug";
import { MdOutlinePendingActions } from "react-icons/md";
import { RiEBike2Line } from "react-icons/ri";
import formatMoney from "../../helpers/numbersDot";

const PREFIX = "StatisticsCard";

const classes = {
  root: `${PREFIX}-root`,
  focus: `${PREFIX}-focus`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  typography: `${PREFIX}-typography`,
  outline: `${PREFIX}-outline`,
  listItem: `${PREFIX}-listItem`,
  numbers: `${PREFIX}-numbers`,
  childNumbers: `${PREFIX}-childNumbers`,
  fontSize: `${PREFIX}-fontSize`,
  text: `${PREFIX}-text`,
  link: `${PREFIX}-link`,
};

const StyledCard = styled(Card)(({ theme, ...props }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`&.${classes.focus}`]: {
    color: "black",
  },

  // [`&.${classes.card}`]: {
  //   width: "inherit",
  //   padding: 0,
  //   borderRadius: 12,
  //   boxShadow:
  //     "0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 5%), 0px 1px 18px 0px rgb(0 0 0 / 5%)",
  //   // height: "100%",
  //   display: "flex",
  //   flexWrap: "wrap",
  //   flexDirection: "column",
  //   justifyContent: "flex-start",
  //   position: "relative",
  // },

  [`& .${classes.typography}`]: {
    fontFamily: "sans-serif",
    fontWeight: "600",
  },

  [`& .${classes.outline}`]: {
    fontFamily: "Material Icons Outlined",
  },
}));

const LisItemStyled = styled("div")(({ theme, ...props }) => ({
  [`& .${classes.numbers}`]: {
    "& > span": {
      padding: theme.spacing(0, 0.5),
      "& span": {
        fontWeight: 600,
      },
    },
    width: theme.spacing(6),
  },
  [`& .${classes.childNumbers}`]: {
    fontWeight: 700,
    "& > span": {
      padding: theme.spacing(0, 0.3),
      "& span": {
        fontWeight: 600,
      },
    },
    margin: theme.spacing(0, 1),
  },
  [`& .${classes.fontSize}`]: {
    fontWeight: 600,
    fontSize: theme.spacing(2.2),
    "& span::first-letter": {
      textTransform: "capitalize",
    },
  },
  [`& .${classes.text}`]: {
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  [`& .${classes.link}`]: {
    backgroundColor: theme.palette.background.hover,
    justifyContent: "center",
    width: "80%",
    margin: "auto",
    borderRadius: "20px",
  },
}));

export const listShipments = {
  operation: "listShipments",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    shipmentsInput: {
      name: "input",
      type: "ListShipmentsFilterInput",
    },
  },
};
export const listRegistrationsMerchant = {
  operation: "listRegistrationsMerchant:listRegistrations",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    registrationsMerchantInput: {
      name: "input",
      type: "ListRegistrationsFilterInput",
    },
  },
};
export const listRegistrationsIndividual = {
  operation: "listRegistrationsIndividual:listRegistrations",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    registrationsIndividualInput: {
      name: "input",
      type: "ListRegistrationsFilterInput",
    },
  },
};
export const listPickups = {
  operation: "listPickups",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    pickupsInput: {
      name: "input",
      type: "ListPickupsFilterInput",
    },
  },
};
export const listTickets = {
  operation: "listTickets",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    ticketsInput: {
      name: "input",
      type: "ListTicketsFilterInput",
    },
  },
};

export const returnRequests = {
  operation: "listReturnRequests:listCustomerRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    returnRequestsInput: {
      name: "input",
      type: "ListCustomerRequestFilterInput!",
    },
  },
};
export const paymentRequests = {
  operation: "listPaymentRequests:listCustomerRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    paymentRequestsInput: {
      name: "input",
      type: "ListCustomerRequestFilterInput!",
    },
  },
};
export const materialRequests = {
  operation: "listMaterialRequests:listCustomerRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    materialRequestsInput: {
      name: "input",
      type: "ListCustomerRequestFilterInput!",
    },
  },
};
const sumShipmentCustomerDueCust = {
  operation: "sumShipmentCustomerDueCust:sumShipments",
  fields: ["customerDue"],
  variables: {
    inputCustomerDueCust: {
      name: "input",
      type: "ListShipmentsFilterInput",
    },
  },
};
const sumShipmentCollected = {
  operation: "sumShipmentCollected:sumShipments",
  fields: ["customerDue"],
  variables: {
    inputCustomerCollected: {
      name: "input",
      type: "ListShipmentsFilterInput",
    },
  },
};
const sumShipmentCustomerDueDlv = {
  operation: "sumShipmentCustomerDueDlv:sumShipments",
  fields: ["customerDue", "deliveredAmount ", "pendingCollectionAmount"],
  variables: {
    inputCustomerDueDlv: {
      name: "input",
      type: "ListShipmentsFilterInput",
    },
  },
};
const localCurrency = {
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
};

export const PendingApproval = (props) => {
  const { t } = useTranslation();
  const title = t("pendingApproval");
  const icon = MdOutlinePendingActions;

  const user = Globals.user;

  const listShipmentPermission = user.hasPermission("shipping.shipment.list");
  const listShipmentCustomerPermission = user.hasPermission(
    "shipping.customer_merchant.list_with_new_shipments"
  );
  const listRegistrationsMerchantPermission = user.hasPermission(
    GetPermissionSlug("shipping", "registration", "MERCHANT", "list")
  );
  const listRegistrationsIndividualPermission =
    user.hasPermission(
      GetPermissionSlug("shipping", "registration", "INDIVIDUAL", "list")
    );
  const listPickupsPermission = user.hasPermission("shipping.pickup.list");

  // const queryBody = queryBuilder();
  const queries = [];
  (listShipmentPermission || listShipmentCustomerPermission) &&
    queries.push(listShipments);
  listRegistrationsMerchantPermission && queries.push(listRegistrationsMerchant);
  listRegistrationsIndividualPermission && queries.push(listRegistrationsIndividual);
  listPickupsPermission && queries.push(listPickups);
  const LIST_ALL = gqlb.query(queries);
  const { data, loading } = useQuery(
    gql`
      ${LIST_ALL.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: queries.length === 0,
      variables: {
        first: 1,
        shipmentsInput: {
          new: true,
        },
        ...(listRegistrationsIndividualPermission && {
          registrationsIndividualInput: {
            new: true,
            customerTypeCode: "INDIVIDUAL",
          },
        }),
        ...(listRegistrationsMerchantPermission && {
          registrationsMerchantInput: {
            new: true,
            customerTypeCode: "MERCHANT",
          },
        }),
        pickupsInput: {
          new: true,
        },
      },
      onCompleted: (data) => { },
    }
  );

  const registrationsIndividualTotal =
    data?.listRegistrationsIndividual?.paginatorInfo?.total ?? 0;
  const registrationsMerchantTotal =
    data?.listRegistrationsMerchant?.paginatorInfo?.total ?? 0;
  const listData = [];

  if (listShipmentPermission || listShipmentCustomerPermission) {
    const newElement = {
      id: 1,
      name: t("shipments"),
      total: data?.listShipments?.paginatorInfo?.total,
      icon: VscPackage,
      path: listShipmentCustomerPermission
        ? "/admin/customer-new-shipment"
        : "/admin/shipments",
      search: listShipmentCustomerPermission ? `` : `new=true`,
    };
    listData.splice(0, 0, newElement);
  }
  if (listPickupsPermission) {
    const newElement = {
      id: 2,
      name: t("pickupRequests"),
      total: data?.listPickups?.paginatorInfo?.total,
      icon: BiPhoneCall,
      path: "/admin/pickups",
      search: `new=true&statusCode=NEW`,
    };
    listData.splice(1, 0, newElement);
  }
  if (listRegistrationsIndividualPermission) {
    const newElement = {
      id: 3,
      name: t("individualsAccounts"),
      total: registrationsIndividualTotal,
      icon: FiUsers,
      path: "/admin/registrations/individual",
      search: `new=true&verified=false`,
    };
    listData.push(newElement);
  }
  if (listRegistrationsMerchantPermission) {
    const newElement = {
      id: 3,
      name: t("customersAccounts"),
      total: registrationsMerchantTotal,
      icon: FiUsers,
      path: "/admin/registrations/merchant",
      search: `new=true&verified=false`,
    };
    listData.push(newElement);
  }

  return (
    <StatisticsCard
      {...props}
      listData={listData}
      loading={loading}
      title={title}
      icon={icon}
    />
  );
};
export const CustomerServices = (props) => {
  const { t } = useTranslation();
  const title = t("support");
  const icon = BiSupport;

  const user = Globals.user;
  const support = Globals.settings.support;

  const complaintsPermission =
    support &&
    (Globals.user.member.teams.length > 0 ||
      user.hasPermission("crm.ticket.list"));
  const returnRequestsPermission =
    support && user.hasPermission("shipping.customer_request_rtrn.list");
  const paymentRequestsPermission =
    support && user.hasPermission("shipping.customer_request_pmnt.list");
  const materialRequestsPermission =
    support && user.hasPermission("shipping.customer_request_mtrl.list");

  const per =
    complaintsPermission ||
    returnRequestsPermission ||
    paymentRequestsPermission ||
    materialRequestsPermission;
  // const queryBody = queryBuilder();
  const queries = [];
  complaintsPermission && queries.push(listTickets);
  returnRequestsPermission && queries.push(returnRequests);
  paymentRequestsPermission && queries.push(paymentRequests);
  materialRequestsPermission && queries.push(materialRequests);

  !per && queries.push(listTickets);

  const LIST_ALL = gqlb.query(queries);
  const { data, loading } = useQuery(
    gql`
      ${LIST_ALL.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !per,
      variables: {
        first: 1,
        ticketsInput: {
          statusCode: "NEW",
        },
        ...(returnRequestsPermission && {
          returnRequestsInput: {
            statusCode: "NEW",
            typeCode: "RTRN",
          },
        }),
        ...(paymentRequestsPermission && {
          paymentRequestsInput: {
            statusCode: "NEW",
            typeCode: "PMNT",
          },
        }),
        ...(materialRequestsPermission && {
          materialRequestsInput: {
            statusCode: "NEW",
            typeCode: "MTRL",
          },
        }),
      },
      onCompleted: (data) => { },
    }
  );

  const returnRequestsTotal =
    data?.listReturnRequests?.paginatorInfo?.total ?? 0;
  const paymentRequestsTotal =
    data?.listPaymentRequests?.paginatorInfo?.total ?? 0;
  const materialRequestsTotal =
    data?.listMaterialRequests?.paginatorInfo?.total ?? 0;
  const listData = [];
  if (paymentRequestsPermission) {
    const newElement = {
      id: 5,
      name: t("paymentRequests"),
      total: paymentRequestsTotal,
      icon: RiMoneyDollarCircleLine,
      path: "/admin/customer-requests/pmnt",
      search: `statusCode=NEW`,
    };
    listData.splice(1, 0, newElement);
  }
  if (returnRequestsPermission) {
    const newElement = {
      id: 5,
      name: t("returnRequests"),
      total: returnRequestsTotal,
      icon: GoPackageDependencies,
      path: "/admin/customer-requests/rtrn",
      search: `statusCode=NEW`,
    };
    listData.splice(2, 0, newElement);
  }
  if (materialRequestsPermission) {
    const newElement = {
      id: 5,
      name: t("materialRequests"),
      total: materialRequestsTotal,
      icon: VscPackage,
      path: "/admin/customer-requests/mtrl",
      search: `statusCode=NEW`,
    };
    listData.splice(3, 0, newElement);
  }
  if (complaintsPermission) {
    const newElement = {
      id: 4,
      name: t("complaints"),
      total: data?.listTickets?.paginatorInfo?.total,
      path: "/admin/crm/tickets",
      search: `statusCode=NEW`,
      icon: MdOutlineReportProblem,
    };
    listData.splice(4, 0, newElement);
  }

  return per ? (
    <StatisticsCard
      {...props}
      listData={listData}
      loading={loading}
      title={title}
      icon={icon}
    />
  ) : (
    ""
  );
};
export const CustomerWidget = (props) => {
  const { t } = useTranslation();
  const title = t("financialPayment");
  const icon = RiMoneyDollarCircleLine;
  const user = Globals.user;
  const customerPermission = user.hasPermission(
    "shipping.shipment.list_customer_not_paid"
  );
  const deliveryPermission = user.hasPermission(
    "shipping.shipment.list_delivery_agent_custody"
  );
  const queries = [];
  customerPermission && queries.push(sumShipmentCustomerDueCust);
  customerPermission && queries.push(sumShipmentCollected);
  customerPermission && queries.push(localCurrency);
  deliveryPermission && queries.push(localCurrency);
  deliveryPermission && queries.push(sumShipmentCustomerDueDlv);
  // deliveryPermission && queries.push(sumShipmentDliveredAmountDlv);
  const LIST_ALL = gqlb.query(queries);
  const { data, loading } = useQuery(
    gql`
      ${LIST_ALL.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        ...(customerPermission && {
          inputCustomerDueCust: {
            pendingCustomerAdjusting: true,
          },
        }),
        ...(customerPermission && {
          input: {},
        }),
        ...(customerPermission && {
          inputCustomerCollected: {
            pendingCustomerAdjusting: true,
            collected: true,
          },
        }),
        ...(deliveryPermission && {
          inputCustomerDueDlv: {
            inDeliveryAgentCustody: {},
          },
        }),
      },
      onCompleted: (data) => { },
    }
  );
  const localCurency = data?.shippingSettings?.localCurrency?.name;

  const customerTotal = formatMoney(
    data?.sumShipmentCustomerDueCust?.customerDue ?? 0
  );
  const collectedTotal = formatMoney(
    data?.sumShipmentCollected?.customerDue ?? 0
  );
  // const DlvTotal = formatMoney(
  //   data?.sumShipmentCustomerDueDlv?.deliveredAmount ?? 0
  // );
  const DlvCustomTotal = formatMoney(
    data?.sumShipmentCustomerDueDlv?.pendingCollectionAmount
    ?? 0
  );
  // const DlvCustomTotal = formatMoney(
  //   data?.sumShipmentCustomerDueDlv?.customerDue ?? 0
  // );

  const listData = [];
  if (customerPermission) {
    const newElement = {
      id: 1,
      name: t("customers"),
      icon: FiUsers,

      children: [
        {
          path: "/admin/not-paid-to-customer",
          name: t("totalBalance"),
          total: customerTotal,
          color: "error",
          local: localCurency,
        },
        {
          path: "/admin/not-paid-to-customer",
          name: t("payableBalance"),
          search: `collected=true`,
          total: collectedTotal,
          color: "green",
          local: localCurency,
        },
      ],
    };
    listData.splice(1, 0, newElement);
  }
  if (deliveryPermission) {
    const newElement = {
      id: 2,
      name: t("deliveryAgents"),
      icon: RiEBike2Line,
      children: [
        // {
        //   path: "/admin/uncollected-from-delivery-agents",
        //   name: t("deliveredAmount"),
        //   total: DlvTotal,
        //   color: "error",
        //   local: localCurency,
        // },
        {
          path: "/admin/uncollected-from-delivery-agents",
          name: t("collectedDue"),
          total: DlvCustomTotal,
          color: "green",
          local: localCurency,
        },
      ],
    };
    listData.splice(2, 0, newElement);
  }
  return (
    <>
      <StatisticsCard
        {...props}
        listData={listData}
        loading={loading}
        title={title}
        icon={icon}
      />
    </>
  );
};

const StatisticsCard = (props) => {
  const { avatarColor, listData, title, icon, loading } = props;
  return (
    <StyledCard>
      <Grid item container md={12} lg={12}>
        <StatisticsFilterHeader
          section={"Statistics"}
          title={title}
          icon={icon}
        />
      </Grid>
      <Grid item container justifyContent={"flex-start"} lg={12} md={12}>
        <List
          sx={{ width: "100%" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <LisItemStyled avatarcolor={avatarColor}>
            {listData.length > 0 &&
              listData?.map((item, index) => (
                <React.Fragment key={index}>
                  {item?.children ? (
                    <ListItem
                      className={classes.link}
                      sx={{
                        px: 2,
                        pb: 1,
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: 25 }}
                        className={classes.fontSize}
                      >
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        className={classes.text}
                        primary={item?.name}
                        sx={{
                          flex: "none",
                          maxWidth: "fit-content",
                          fontWeight: "800",
                          // margin: "16px"
                          // color: "rgba(0, 0, 0, 0.54)",
                        }}
                      />
                    </ListItem>
                  ) : (
                    <SpanLinkNoLine
                      pathname={item.path}
                      search={item.search || ""}
                    >
                      {item.label}

                      <ListItemButton sx={{ px: 2, pb: 1 }}>
                        <ListItemIcon
                          sx={{ minWidth: 25 }}
                          className={classes.fontSize}
                        >
                          <item.icon />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography={true}
                          className={classes.text}
                          primary={item.name}
                        />
                        {Boolean(loading) ? (
                          <CustomSpinner name="PulseLoader" size={10} />
                        ) : (
                          <Chip
                            className={classes.numbers}
                            size="small"
                            color="primary"
                            label={<CountUp end={item?.total} duration={1} />}
                          />
                        )}
                      </ListItemButton>
                    </SpanLinkNoLine>
                  )}
                  {item.children && item.children.length > 0 && (
                    <List>
                      {item.children.map((childItem, childIndex) => (
                        <SpanLinkNoLine
                          key={childIndex}
                          pathname={`${childItem?.path}`}
                          search={`${childItem?.search ?? ""}`}
                        >
                          <ListItemButton sx={{ flexWrap: "wrap" }}>
                            <ListItemIcon
                              sx={{ minWidth: 25 }}
                              className={classes.fontSize}
                            >
                              <GoDash />
                            </ListItemIcon>
                            <ListItemText
                              disableTypography={true}
                              className={classes.text}
                              primary={childItem?.name}
                            />
                            {Boolean(loading) ? (
                              <CustomSpinner name="PulseLoader" size={10} />
                            ) : (
                              <Typography
                                className={classes.childNumbers}
                                // size="small"
                                color={childItem.color}
                                variant="body2"
                              >{`${childItem?.total} ${childItem.local}`}</Typography>
                            )}
                          </ListItemButton>
                        </SpanLinkNoLine>
                      ))}
                    </List>
                  )}
                </React.Fragment>
              ))}
          </LisItemStyled>
        </List>
      </Grid>
    </StyledCard>
  );
};
