import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, Icon, TableRow } from "@mui/material";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { useTranslation } from "react-i18next";

const PREFIX = "CustomTableRow";

const classes = {
  productTable_checkbox: `${PREFIX}-productTable_checkbox`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(TableRow)(({ theme }) => ({
  [`& .${classes.productTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const CustomTableRow = (props) => {
  const { product, onChangeCheck, labelId, prepareShipment } = props;

  const { t } = useTranslation()

  const productDetails = product?.product;

  return (
    <Fragment>
      <Root
        hover
        role="checkbox"
        aria-checked={product.select}
        tabIndex={-1}
        selected={product.select}
      >
        {!prepareShipment ? <FixedTableCell
          className={classes.productTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            onChange={(event) => onChangeCheck(event)}
            checked={product.select}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </FixedTableCell> : <FixedTableCell>
          {product.select ? (
            <Icon className={classes.iconColor}>
              check_circle_outline
            </Icon>
          ) : (
            <Icon color="error">highlight_off</Icon>
          )}
        </FixedTableCell>
        }

        {!prepareShipment && (
          <CellLink
            component="th"
            scope="product"
            pathname={`/admin/products/${productDetails.id}`}
          >
            {productDetails?.code}
          </CellLink>
        )}
        <FixedTableCell>{productDetails?.name}</FixedTableCell>
        <FixedTableCell>{prepareShipment ? product?.quantityChecked + " " + t("from") + " " + product?.quantity : productDetails?.availableQuantity}</FixedTableCell>
        {!prepareShipment && <FixedTableCell>{productDetails?.price}</FixedTableCell>}
        {!prepareShipment && <FixedTableCell>{productDetails?.weight}</FixedTableCell>}
      </Root>
    </Fragment>
  );
};

export default CustomTableRow;
