import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
// import config from "../../config.json";
import { Globals } from "../HOC/Classes/Globals";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { GetCollectionPermissionSlug, GetPaymentPermissionSlug } from "../../helpers/getPermissionSlug";
export const EXPOERT_SHIPMENTS = gqlb.mutation({
  operation: "exportShipments",
  fields: [],
  variables: {
    input: {
      type: "ExportShipmentsInput",
      required: true,
    },
  },
});

export default function ExportShipments(props) {
  const { filters, disabled, openExport, setOpenExport } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [shipmentFieldsState, setShipmentFieldsState] = useState([]);
  const { t } = useTranslation();
  const icon = !Boolean(setOpenExport)
  const user = Globals.user;

  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );
  const invoicePermission = user.hasPermission("shipping.invoice.list");
  const collectionPermissionDLVBY = user.hasPermission(GetCollectionPermissionSlug('dlvby', 'list'));
  const collectionPermissionCUSTM = user.hasPermission(GetCollectionPermissionSlug('custm', 'list'));
  const paymentPermissionDLVBY = user.hasPermission(GetPaymentPermissionSlug('dlvby', 'list'));
  const paymentPermissionCUSTM = user.hasPermission(GetPaymentPermissionSlug('custm', 'list'));

  const shipmentFields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("date") },
    { name: "STATUS", label: t("status") },
    { name: "BRANCH", label: t("branch") },
    {
      name: "ORIGIN_BRANCH",
      label: t("originBranch"),
    },
    {
      name: "SERVICE",
      label: t("service"),
    },
    { name: "SENDER_NAME", label: t("senderName") },
    { name: "SENDER_ZONE", label: t("senderZone") },
    {
      name: "SENDER_SUBZONE",
      label: t("senderSubzone"),
    },
    {
      name: "SENDER_POSTAL_CODE",
      label: t("senderPostalCode"),
    },
    {
      name: "REFERENCE_NUMBER",
      label: t("refNumber"),
    },
    { name: "RECIPIENT_NAME", label: t("recipient") },
    {
      name: "RECIPIENT_ZONE",
      label: t("recipientZone"),
    },
    {
      name: "RECIPIENT_SUBZONE",
      label: t("recipientSubzone"),
    },
    {
      name: "RECIPIENT_ADDRESS",
      label: t("recipientAddress"),
    },
    {
      name: "RECIPIENT_POSTAL_CODE",
      label: t("recipientPostalCode"),
    },
    {
      name: "RECIPIENT_PHONE",
      label: t("recipientPhone"),
    },
    {
      name: "RECIPIENT_MOBILE",
      label: t("recipientMobile"),
    },
    { name: "DESCRIPTION", label: t("description") },
    { name: "WEIGHT", label: t("weight") },
    { name: "PIECES_COUNT", label: t("pieceCount") },
    { name: "PRICE", label: t("packagePrice") },
    { name: "FEES", label: t("fees") },
    { name: "NET_AMOUNT", label: t("customerNet") },
    { name: "TOTAL_AMOUNT", label: t("totalCost") },
    {
      name: "DELIVERED_AMOUNT",
      label: t("deliveredAmount"),
    },
    {
      name: "COLLECTED_FEES",
      label: t("collectedFees"),
    },
    {
      name: "CUSTOMER_DUE",
      label: t("customerDueAmount"),
    },
    {
      name: "RETURN_DUE_FEES",
      label: t("returningDueFees"),
    },
    { name: "PAYMENT_TYPE", label: t("paymentType") },
    { name: "PRICE_TYPE", label: t("priceType") },
    {
      name: "DELIVERY_TYPE",
      label: t("deliveryType"),
    },
    { name: "RETURN_TYPE", label: t("returnType") },
    {
      name: "DELIVERY_AGENT",
      label: t("deliveryAgent"),
    },
    { name: "COLLECTED", label: t("collected") },
    { name: "PAID", label: t("paidToCustomer") },
    { name: "NOTES", label: t("notes") },
    {
      name: "OPENABLE",
      label: t("packageOpenPossibility"),
    },
    { name: "CUSTOMER", label: t("customer") },
    {
      name: "CANCELLATION_REASON",
      label: t("CancellationReasonName"),
    },
    {
      name: "TYPE",
      label: t("packageType"),
    },
    {
      name: "DELIVERED_OR_RETURNED_DATE",
      label: t("deliveryCanclingDate"),
    },
    {
      name: "RETURNED_VALUE",
      label: t("returnedValue"),
    },
    {
      name: "ATTEMPTS",
      label: t("attempts"),
    },
    {
      name: "DELIVERY_DATE",
      label: t("redeliveryDate"),
    },
    {
      name: "CANCELLED",
      label: t("cancelled"),
    },
    {
      name: "LAST_TRANSACTION_DATE",
      label: t("lastTransactionDate"),
    },

  ];

  useEffect(() => {
    if (adminNotesPermission) {
      shipmentFields.push({
        name: "ADMIN_NOTES",
        label: t("adminNotes"),
      });
    }
    if (collectionPermissionDLVBY || collectionPermissionCUSTM) {
      shipmentFields.push({
        name: "COLLECTION_CODE",
        label: t("collection"),
      });
    }
    if (paymentPermissionCUSTM || paymentPermissionDLVBY) {
      shipmentFields.push({
        name: "CUSTOMER_PAYMENT_CODE",
        label: t("customerPayment"),
      });
    }
    if (invoicePermission) {
      shipmentFields.push({
        name: "INVOICE_CODE",
        label: t("invoice"),
      });
    }

    if (!shipmentFields[0].id)
      shipmentFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });

    const localStorageExportShipment = localStorage.getItem("export-shipments");
    const parsedExportShipment = localStorageExportShipment
      ? JSON.parse(localStorageExportShipment)
      : null;

    const notCheckedFields = shipmentFields.filter((i) => {
      if (
        !parsedExportShipment?.includes(i.name) &&
        parsedExportShipment !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = shipmentFields.filter((i) => {
      if (parsedExportShipment?.includes(i.name)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedExportShipment?.indexOf(a.name) >
        parsedExportShipment?.indexOf(b.name)
        ? 1
        : -1;
    });

    setShipmentFieldsState(
      localStorageExportShipment
        ? [...sortedFields, ...notCheckedFields]
        : shipmentFields
    );
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const [dialogState, setDialogState] = useState(false);

  const fieldCount = shipmentFieldsState.length;
  const fieldSelected = shipmentFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fileds = [...shipmentFieldsState];
    fileds.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setShipmentFieldsState(fileds);
  };

  const onCloseDialog = () => {
    icon ? setDialogState(false) : setOpenExport(false)
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };

  // let getBackendUri = (imgPath) => {
  //   const domain = config.backend.domain
  //     ? config.backend.domain
  //     : window.location.hostname;

  //   return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  // };
  const [exportShipments, { loading }] = useMutation(
    gql`
      ${EXPOERT_SHIPMENTS.query}
    `,
    {
      onCompleted: (data) => {
        onCloseDialog();
        if (data.exportShipments) {
          enqueueSnackbar(t("successExport"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          enqueueSnackbar(t("errorExport"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(shipmentFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setShipmentFieldsState(items);
  }

  const handelExportShipment = () => {
    const updatedFields = shipmentFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.name);
      }
      return previous;
    }, []);
    localStorage.setItem("export-shipments", JSON.stringify(updatedFields));
    const fields = shipmentFieldsState
      .filter((i) => i.checked)
      .map((i) => ({ label: i.label, name: i.name }));
    exportShipments({
      variables: {
        input: {
          filters,
          fields,
        },
      },
    });
  };

  return (
    <>
      {icon && <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip>}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={icon ? dialogState : openExport}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("exportExcel")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading || fieldSelected === 0}
              onClick={handelExportShipment}
            >
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {/* <ListItem dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      indeterminate={
                        fieldSelected > 0 && fieldSelected < fieldCount
                      }
                      checked={fieldCount > 0 && fieldSelected === fieldCount}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  
                  </ListItemIcon>
                  <ListItemText primary={t("selectAll")} />
                </ListItem> */}

                  {shipmentFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.name}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={shipmentFieldsState}
                            setState={setShipmentFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  // const [checkboxState,setCheckboxState] = React.state(checked)
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};
