import { Box, Icon, IconButton, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { DELETE_PRODUCT_MANIFEST_MUTATION } from "./Graphql";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import { gql, useMutation } from "@apollo/client";
const PREFIX = "useManifestDetails";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const useWarehouseManifestDetails = ({
  type,
  approved,
  typeData,
  saveManifestLoad,
  refetchProductDataQuery,
  setProductData: removeProduct,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteProductManifest, { loading: deleteProductManifestLoading }] = useMutation(
    gql`
      ${DELETE_PRODUCT_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteProduct = (manifestProduct) => (

    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={approved || saveManifestLoad}
        onClick={() => {
          removeProduct(prev => {
            const products = prev.pickedProduct.map(e => ({
              ...e,
              product: {
                ...e.product,
                pendingDelete: e.product.id === manifestProduct.product.id ? true : e.product.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedProduct: products
            }
          })
          deleteProductManifest({
            variables: {
              input: {
                manifestId: typeData.id,
                productIds: [manifestProduct.product.id]
              }
            }
          }).then(() => {
            refetchProductDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deleteProductManifestLoading && manifestProduct.product?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );



  /* --------------------------------- PADD --------------------------------- */

  const paddTableHead = [
    "#",
    "product",
    "wareHouse",
    "quantity",
    removeProduct ? "" : null,
  ];

  const paddTableBody = (manifestProducts, index) => {
    const toBeCollected = 0
    // manifestShipment?.shipment?.paymentType?.code === "CASH" ||
    // manifestShipment?.shipment?.type?.code === "RTS"
    //   ? manifestShipment?.shipment?.type?.code === "RTS"
    //     ? manifestShipment?.shipment?.totalAmount
    //     : manifestShipment?.shipment?.allDueFees
    //   : 0;
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestProducts?.product.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/products/${manifestProducts?.product?.id}`}>
          <Box
            component="span"
            fontWeight="bold"
          >{` (${manifestProducts?.product?.code}) `}</Box>
          <Box component="span">{manifestProducts?.product?.name}</Box>
        </CellLink>
        <CellLink
          pathname={`/admin/warehouse/${manifestProducts?.section?.id}`}
        >
          {manifestProducts?.section?.name}
        </CellLink>
        <FixedTableCell>
          {manifestProducts?.quantity}
        </FixedTableCell>
        {removeProduct && deleteProduct(manifestProducts)}
      </StyledTableRow>
    );
  };

  const paddTableTotal = {
    quantity: typeData?.sumManifestProducts?.productsQuantity,
  };
  /* --------------------------------- PRTRN --------------------------------- */

  const prtrnTableHead = [
    "#",
    "product",
    "quantity",
    removeProduct ? "" : null,
  ];

  const prtrnTableBody = (manifestProducts, index) => {
    const toBeCollected = 0
    // manifestShipment?.shipment?.paymentType?.code === "CASH" ||
    // manifestShipment?.shipment?.type?.code === "RTS"
    //   ? manifestShipment?.shipment?.type?.code === "RTS"
    //     ? manifestShipment?.shipment?.totalAmount
    //     : manifestShipment?.shipment?.allDueFees
    //   : 0;
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestProducts?.product.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/products/${manifestProducts?.product?.id}`}>
          <Box
            component="span"
            fontWeight="bold"
          >{` (${manifestProducts?.product?.code}) `}</Box>
          <Box component="span">{manifestProducts?.product?.name}</Box>
        </CellLink>
        <FixedTableCell>
          {manifestProducts?.quantity}
        </FixedTableCell>
        {removeProduct && deleteProduct(manifestProducts)}
      </StyledTableRow>
    );
  };

  const prtrnTableTotal = {
    quantity: typeData?.sumManifestProducts?.productsQuantity,
  };
  /* --------------------------------- PMBT --------------------------------- */

  const pmbtTableHead = [
    "#",
    "product",
    "quantity",
    removeProduct ? "" : null,
  ];

  const pmbtTableBody = (manifestProducts, index) => {
    const toBeCollected = 0
    // manifestShipment?.shipment?.paymentType?.code === "CASH" ||
    // manifestShipment?.shipment?.type?.code === "RTS"
    //   ? manifestShipment?.shipment?.type?.code === "RTS"
    //     ? manifestShipment?.shipment?.totalAmount
    //     : manifestShipment?.shipment?.allDueFees
    //   : 0;
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestProducts?.product.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/products/${manifestProducts?.product?.id}`}>
          <Box
            component="span"
            fontWeight="bold"
          >{` (${manifestProducts?.product?.code}) `}</Box>
          <Box component="span">{manifestProducts?.product?.name}</Box>
        </CellLink>
        <FixedTableCell>
          {manifestProducts?.quantity}
        </FixedTableCell>
        {removeProduct && deleteProduct(manifestProducts)}
      </StyledTableRow>
    );
  };

  const pmbtTableTotal = {
    quantity: typeData?.sumManifestProducts?.productsQuantity,
  };
  /* --------------------------------- PMBR --------------------------------- */

  const pmbrTableHead = [
    "#",
    "product",
    "wareHouse",
    "quantity",
    removeProduct ? "" : null,
  ];

  const pmbrTableBody = (manifestProducts, index) => {
    const toBeCollected = 0
    // manifestShipment?.shipment?.paymentType?.code === "CASH" ||
    // manifestShipment?.shipment?.type?.code === "RTS"
    //   ? manifestShipment?.shipment?.type?.code === "RTS"
    //     ? manifestShipment?.shipment?.totalAmount
    //     : manifestShipment?.shipment?.allDueFees
    //   : 0;
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestProducts?.product.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/products/${manifestProducts?.product?.id}`}>
          <Box
            component="span"
            fontWeight="bold"
          >{` (${manifestProducts?.product?.code}) `}</Box>
          <Box component="span">{manifestProducts?.product?.name}</Box>
        </CellLink>
        <CellLink
          pathname={`/admin/warehouse/${manifestProducts?.section?.id}`}
        >
          {manifestProducts?.section?.name}
        </CellLink>
        <FixedTableCell>
          {manifestProducts?.quantity}
        </FixedTableCell>
        {removeProduct && deleteProduct(manifestProducts)}
      </StyledTableRow>
    );
  };

  const pmbrTableTotal = {
    quantity: typeData?.sumManifestProducts?.productsQuantity,
  };
  /* --------------------------------- Manifests Object --------------------------------- */

  const manifestsObject = {
    PADD: {
      id: "1",
      customer: true,
      // sumTableNumber: null,
      label: t("receiveProductFromCustomer"),
      tableHead: paddTableHead,
      tableBody: paddTableBody,
      tableTotal: paddTableTotal,
      sumTableNumber: (product, tableTotal) => {

      },
    },
    PMBT: {
      id: "2",
      customer: false,
      // sumTableNumber: null,
      label: t("sendProductTransfer"),
      tableHead: pmbtTableHead,
      tableBody: pmbtTableBody,
      tableTotal: pmbtTableTotal,
      sumTableNumber: (product, tableTotal) => {

      },
    },
    PMBR: {
      id: "3",
      customer: false,
      // sumTableNumber: null,
      label: t("receiveProductTransfer"),
      tableHead: pmbrTableHead,
      tableBody: pmbrTableBody,
      tableTotal: pmbrTableTotal,
      sumTableNumber: (product, tableTotal) => {

      },
    },
    PRTRN: {
      id: "4",
      customer: true,
      // sumTableNumber: null,
      label: t("returnProductToCustomer"),
      tableHead: prtrnTableHead,
      tableBody: prtrnTableBody,
      tableTotal: prtrnTableTotal,
      sumTableNumber: (product, tableTotal) => {

      },
    }
  };

  return {
    details: manifestsObject[type],
  };
};

export default useWarehouseManifestDetails;
