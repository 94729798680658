import * as gqlb from "gql-query-builder";

export const LIST_FILTER_GROUPS = gqlb.query({
    operation: "listFilterGroups",
    fields: [
        "id",
        "name",
    ],
    variables: {
        input: {
            type: "ListFilterGroupsInput",
            required: true,
        },
    },
});

export const IDLE_SHIPMENT = (dataFields) => {
    return gqlb.query({
        operation: "listShipmentsWithFilterGroup",
        fields: [
            {
                operation: "data",
                fields: dataFields,
                variables: {},
            },
            {
                operation: "paginatorInfo",
                fields: ["total"],
                variables: {},
            },
        ],
        variables: {
            filterId: {
                type: "Int",
                required: true
            },
            first: {
                type: "Int",
            },
            page: {
                type: "Int",
            },
        },
    });
};

export const SUM_IDLE_SHIPMENT = (dataFields) => {
    return gqlb.query({
        operation: "sumShipmentsWithFilterGroup",
        fields: dataFields ?? [
            "price",
            "allDueFees",
            "deliveredAmount",
            "collectedFees",
            "pendingCollectionAmount",
            "collectedAmount",
            "amount",
            "totalAmount",
            "customerDueCredit",
            "customerDue",
            "customerDueDebit",
            "returningDueFees",
        ],
        variables: {
            filterId: {
                type: "Int",
                required: true
            },
        },
    });
};

export const FILTER_GROUP_ID = gqlb.query({
    operation: "filterGroup",
    fields: [
        "id",
        "filters",
        "name",
        {
            user: ["id"]
        }
    ],
    variables: {
        id: {
            type: "Int",
            required: true,
        },
    },
});

export const SAVE_SHIPMENT_FILTER_GROUP = gqlb.mutation({
    operation: "saveShipmentFilterGroup",
    fields: ["id"],
    variables: {
        input: {
            type: "ShipmentFilterGroupInput",
            required: true,
        },
    },
});

export const DELETE_FILTER_GROUP = gqlb.mutation({
    operation: "deleteFilterGroup",
    fields: [],
    variables: {
        id: {
            type: "Int",
            required: true,
        },
    },
});