import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { FilterList, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  TableBody,
  TableRow,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_SHIPMENTS_TRANSACTIONS, SETTINGS } from "./Graphql";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { Can } from "../HOC/CustomComponents/Secured";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import { GetManifestPermissionSlug } from "../../helpers/getPermissionSlug";
import formatMoney from "../../helpers/numbersDot";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import Grid from '@mui/material/Unstable_Grid2';
import config from "../../config.json";
import { ShipmentStatusCode } from "./ShipmentStatus";

const PREFIX = "ListShipmentsTransactions";

const classes = {
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const ShipmentsTransactions = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [refetch, setrefetch] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : null;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);


  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    zone: null,
    subzone: null,
    customer: null,
    product: null,
    statusCode: [],
    transactionType: [],
    warehouseManifest: null,
  });
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["shipmentCode"]) urlQuery["shipmentCode"] = urlQuery["shipmentCode"].toString().split(',');
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["statusCode"])
        urlQuery["statusCode"] = urlQuery["statusCode"].split(",");
      if (urlQuery["transactionType"])
        urlQuery["transactionType"] = urlQuery["transactionType"].split(",");
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["shipmentCode"] && setValue("shipmentCode", urlQuery["shipmentCode"]);
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["statusCode"] && {
        statusCode: urlQuery["statusCode"],
      }),
    }));
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["transactionType"] && {
        transactionType: urlQuery["transactionType"],
      }),
    }));
    return () => { };
  }, []);


  const { data: settings } = useQuery(
    gql`
            ${SETTINGS.query}
        `,
    {
      fetchPolicy: "no-cache",
    }
  );
  const currencyCode = settings?.shippingSettings?.localCurrency?.name ?? "";

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/shipments-transactions",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    (refetch) ? setrefetch(false) : setrefetch(true)
    setFilterLoading(false)
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      customerId: data.customerId,
      branchId: data.branchId,
      warehouseManifestId: data.warehouseManifestId,
      deliveryAgentId: data.deliveryAgentId,
      statusCode: data.statusCode,
      transactionType: data.transactionType,
      name: data.name,
      ...(data.shipmentCode && { shipmentCode: data.shipmentCode.split(',') }),
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const codeValidation = Boolean(watch("shipmentCode"));
  const user = Globals.user;
  // const canViewManifest = user?.hasPermission("shipping.manifest.list");

  const { data, loading } = useQuery(
    gql`
        ${LIST_SHIPMENTS_TRANSACTIONS.query}
        `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.shipmentCode && { shipmentCode: search.shipmentCode }),
          ...(search.name && { name: search.name }),
          ...(search.customerId && { customerId: search.customerId, }),
          ...(search.branchId && { branchId: search.branchId }),
          ...(search.warehouseManifestId && { warehouseManifestId: search.warehouseManifestId }),
          ...(search.deliveryAgentId && { deliveryAgentId: search.deliveryAgentId }),
          ...(search.statusCode && { statusCode: search.statusCode }),
          ...(search.transactionType && { transactionType: search.transactionType }),
        },
      },
      onCompleted: (data) => {
      },
    }
  );

  const ListShipmentsTransactions =
    data?.listShipmentTransactions?.data !== null ? data?.listShipmentTransactions?.data : null;

  const resetDate = () => {
    setDateRange([null, null]);
  };

  if (autocompleteValues.fromManifest) {
    autocompleteValues.fromManifest.name = autocompleteValues.fromManifest.code;
  }
  const tableHeaderArrey = [
    {
      name: "date"
    },
    {
      name: "shipmentCode"
    },
    {
      name: "code"
    },
    {
      name: "status"
    },
    {
      name: "customer"
    },
    {
      name: "branch"
    },
    {
      name: "manifestName"
    },
    {
      name: "manifestCode"
    },
    {
      name: "shippingAgent"
    },
    {
      name: "transactionTypeName"
    },
    {
      name: "deliveryType"
    },
    {
      name: "deliveredAmount"
    },
    {
      name: "returnType"
    },
    {
      name: "fees"
    },
    {
      name: "notes"
    },
    {
      name: "reasonName"
    },
  ]
  if (user?.hasPermission("shipping.shipment.choose_customer")) {
    tableHeaderArrey.push({
      name: "updatedBy"
    },)
  }
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
      color: "default"
    }
  ]

  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={ListShipmentsTransactions?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  disabled={codeValidation}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"shipmentCode"}
                  label={t("shipmentCode")}
                />
              </Grid>
              <Can showException permission={!Boolean(user.account)}>
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    name={"customerId"}
                    label={t("customer")}
                    control={control}
                    errors={errors}
                    skip={loading && filterLoading}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["customerId"],
                        "customer"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    defaultValue={autocompleteValues.customer}
                    disabled={codeValidation}
                  />
                </Grid>
              </Can>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  name={"deliveryAgentId"}
                  label={t("deliveryAgent")}
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["deliveryAgentId"],
                      "deliveryAgent"
                    )
                  }
                  query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                  defaultValue={autocompleteValues.product}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  multiple
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"transactionType"}
                  label={t("transactionType")}
                  parseData={(data) => parseData(data)}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  variables={{
                    input: { code: "SHP_ACTION_TYPES" },
                  }}
                  onChangeValue={(e) => {
                    setValue("warehouseManifestId", "");
                  }}
                  defaultValue={autocompleteValues.transactionType}
                />
              </Grid>
              {/* <Can permission={showManifest} showException>
                            
                        </Can> */}
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  label={t("branch")}
                  skip={loading && filterLoading}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>

            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          tableHeaderArrey

        }
        tableBody={
          <TableBody>
            {ListShipmentsTransactions && ListShipmentsTransactions.map((items, index) => {
              return (
                <TableRow key={index}>
                  <FixedTableCell component="th" scope="row">
                    {items.date}
                  </FixedTableCell>
                  <CellLink pathname={`/admin/shipments/${items?.shipment?.id}`}>
                    {items?.shipment?.code}
                  </CellLink>
                  <FixedTableCell align="left">
                    {items?.shipmentStatus.code}
                  </FixedTableCell>
                  <TableCellColor
                    align="left"
                    shipment={items}
                  />
                  <CellLink pathname={`/admin/products/${items?.shipment?.customer?.id}`}>
                    <Box
                      component="span"
                      fontWeight="bold"
                    >{` (${items?.shipment?.customer?.code}) `}</Box>
                    <Box component="span">{items?.shipment?.customer?.name}</Box>
                  </CellLink>
                  <CellLink
                    align="left"
                    pathname={`/admin/branches/${items?.branch?.id}`}
                  >
                    {items?.branch?.name}
                  </CellLink>
                  <FixedTableCell align="left">
                    {items?.trxType?.type?.name}
                  </FixedTableCell>
                  {Globals.supportedTransactionType.some(
                    (i) => i === items?.manifest?.transactionType?.type?.code
                  ) && user?.hasPermission(GetManifestPermissionSlug(items?.manifest?.transactionType?.type?.code.toLowerCase(), 'list')) ? (
                    <CellLink
                      align="left"
                      pathname={`/admin/manifests/${items?.manifest?.id}`}
                    >
                      {items?.manifest?.code}
                    </CellLink>
                  ) : (
                    <FixedTableCell align="left">
                      {items?.manifest?.code}
                    </FixedTableCell>
                  )}
                  {items?.deliveryAgent ? (
                    <CellLink
                      align="left"
                      pathname={`/admin/delivery-agents/${items?.deliveryAgent?.id}`}
                    >
                      {items?.deliveryAgent?.name}
                    </CellLink>
                  ) : (
                    <FixedTableCell align="left"></FixedTableCell>
                  )}

                  {items?.trxType ? (
                    <CellLink
                      align="left"
                      pathname={`/admin/shipment-transaction-types/${items?.trxType?.id}`}
                    >
                      {items?.trxType?.name}
                    </CellLink>
                  ) : (
                    <FixedTableCell align="left"></FixedTableCell>
                  )}
                  <FixedTableCell align="left">
                    {items?.returnType?.code === "PRTS"
                      ? items?.returnType?.name
                      : null ?? items?.deliveryType?.name}
                  </FixedTableCell>
                  <FixedTableCell align="left">
                    {formatMoney(items?.deliverdAmount) + " " + currencyCode}
                  </FixedTableCell>
                  <FixedTableCell align="left">
                    {items?.returnType?.name}
                  </FixedTableCell>
                  <FixedTableCell align="left">
                    {formatMoney(items?.fees) + " " + currencyCode}
                  </FixedTableCell>
                  <FixedTableCell align="left">
                    {items?.manifestShipment?.notes}
                  </FixedTableCell>
                  <FixedTableCell align="left">
                    {items?.cancellationReason?.name}
                  </FixedTableCell>
                  <Can
                    permission="shipping.shipment.choose_customer"
                    showException
                  >
                    {items?.createdBy ? (
                      <CellLink
                        align="left"
                        pathname={`/admin/users/${items?.createdBy?.id}`}
                      >
                        {items?.createdBy?.username}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                  </Can>
                </TableRow>
              );
            })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listShipmentTransactions?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listShipmentTransactions ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />


    </Root>
  );
};

export default ShipmentsTransactions;
