/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  TableBody,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from "react-hook-form";
import { FilterList, Add, Search } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { LISTCUSTOMER_REQUESTS_QUERY } from "./Graphql";
import moment from "moment";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CellLink from "../HOC/CustomComponents/CellLink";
import {
  LIST_CUSTOMERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";
import { dateTimeFormatA } from "../../helpers/dateFunctions";
import { GetCustomerReqPermissionSlug } from "../../helpers/getPermissionSlug";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { Globals } from "../HOC/Classes/Globals";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "CustomerRequestsList";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterFields: `${PREFIX}-filterFields`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

///////////////////////////////////////////////////

//////////////////////////////////////////////////

//*********Table Function*********

function EnhancedTableHead(props) {
  // const {  numSelected, rowCount, } = props;

  const { t } = useTranslation();
  const headCells = [
    { id: "1", numeric: true, disablePadding: false, label: t("code") },
    { id: "2", numeric: true, disablePadding: false, label: t("date") },
    {
      id: "3",
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    },
    {
      id: "4",
      numeric: true,
      disablePadding: false,
      label: t("deliveryType"),
    },
    {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("status"),
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <FixedTableCell
            className={classes.headerTable}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </FixedTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////
const initDate =
  moment(new Date())
    .locale("en")
    .subtract(1, "month")
    .add(1, "day")
    .format("YYYY-MM-DD")
const lastDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const CustomerRequestsList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const { t } = useTranslation();
  const [refetch, setrefetch] = useState(true);
  const [filterLoad, setFilterLoad] = useState(true);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters ? null : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters ? null : lastDate;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [page, setPage] = useState(0);

  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
  });

  const screenWidth = useWidth();

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const listType = props.match.params.type;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/customer-requests/${listType}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const { data, loading } = useQuery(
    gql`
      ${LISTCUSTOMER_REQUESTS_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          typeCode: listType.toUpperCase(),
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.statusCode && { statusCode: search.statusCode }),
          ...(search.deliveryTypeCode && { deliveryTypeCode: search.deliveryTypeCode }),
        },
      },
    }
  );

  const customerRequestsList =
    data?.listCustomerRequests?.data !== null ? data?.listCustomerRequests?.data : null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...urlQuery, page: newPage, rowsPerPage: rowsPerPage, });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
    pushUrlSearch({ ...urlQuery, page: 0, rowsPerPage: +event.target.value });
  };
  const resetDate = () => {
    setDateRange([null, null]);
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    (refetch) ? setrefetch(false) : setrefetch(true)
    setFilterLoad(false)
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      code: data.code,
      ...data,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  // const EnhancedTableToolbar = (props) => {

  //   return (
  //     <TitleAppBar path={props.match.path} type={listType}>
  //       <LongMenu icons={icons} />
  //     </TitleAppBar>
  //   );
  // };

  // EnhancedTableToolbar.propTypes = {};

  // trackingBody = (
  //   <Grid container item xs={12} justifyContent="center" sx={{ width: "100%" }}>
  //     {/* *******Table******* */}
  //     <Grid container item xs={12}>
  //       <Paper className={clsx(classes.paper)}>
  //         <EnhancedTableToolbar
  //           filter={toggleDrawer(filterAnchor, true)}
  //           {...props}
  //         />
  //         {loading || customerRequestsList?.length === 0 ? (
  //           <Grid
  //             container
  //             item
  //             justifyContent="center"
  //             className={classes.track}
  //           >
  //             {loading ? (
  //               <FullScreenLoading minHeight={30} />
  //             ) : (
  //               <Box textAlign="center">
  //                 <img width="50%" src={emptyList} alt={"delivery box"} />
  //                 <Box
  //                   component={Typography}
  //                   variant="h6"
  //                   color="text.secondary"
  //                   marginTop="0"
  //                 >
  //                   {t("searchNoResult")}
  //                 </Box>
  //               </Box>
  //             )}
  //           </Grid>
  //         ) : (
  //           <TableFixedHeaderWraper>
  //             <Table aria-labelledby="tableTitle" aria-label="enhanced table">
  //               <EnhancedTableHead
  //                 type={listType}
  //                 rowCount={customerRequestsList?.length}
  //               />

  //             </Table>
  //           </TableFixedHeaderWraper>
  //         )}

  //       </Paper>
  //     </Grid>
  //   </Grid>
  // );

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, `/admin/customer-requests/create/${listType}`),
      icon: Add,
      permission: GetCustomerReqPermissionSlug(listType, 'create'),
    },
  ]

  const customerRequestTypeFilters = listType === "pmnt" ? ["all"] : ["DELIVERYAGENT", "OFFICE"];

  return (
    <Root>
      {props.children}
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={customerRequestsList?.length === 0}
        loading={loading}
        type={listType}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                />
              </Grid>
              {!Globals.user.account && <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"customerId"}
                  label={t("customer")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["customerId"],
                      "customer"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  variables={{
                    input: {
                      active: true,
                      typeCode: ["MERCHANT"],
                    },
                  }}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>}
              <Grid item sm={12} xs={12}>
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  filters={customerRequestTypeFilters}
                  name={"deliveryTypeCode"}
                  label={t("deliveryType")}
                  variables={{
                    input: { code: "SHP_DELIVERY_METHOD" },
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      urlQuery["deliveryTypeCode"],
                      "deliveryTypeCode"
                    )
                  }
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"statusCode"}
                  label={t("status")}
                  variables={{
                    input: { code: "SHP_CUSTOMER_REQUEST_STATUS" },
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      urlQuery["statusCode"],
                      "statusCode"
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <EnhancedTableHead
            type={listType}
            rowCount={customerRequestsList?.length}
          />
        }
        tableBody={
          <TableBody>
            {customerRequestsList &&
              customerRequestsList?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <CellLink
                      pathname={`/admin/customer-requests/${row?.id}`}
                    >
                      {row?.id}
                    </CellLink>
                    <FixedTableCell>
                      {dateTimeFormatA(row?.date)}
                    </FixedTableCell>
                    <CellLink
                      pathname={`/admin/customers/${row?.customer?.id}`}
                    >
                      {row?.customer?.name}
                    </CellLink>
                    <FixedTableCell>
                      {row?.deliveryType?.name}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.status?.name}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listCustomerRequests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listCustomerRequests ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default CustomerRequestsList;
