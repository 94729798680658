import React, { useState, useCallback, useMemo, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";

import { FilterList, Print, TaskAlt, ViewColumn } from "@mui/icons-material";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useTranslation } from "react-i18next";
import SearchTable from "../ShipmentSearch/SearchTable";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import DeliveryActionForm from "./DeliveryActionForm";
import { Globals } from "../HOC/Classes/Globals";
import FiltersForm from "../../Hooks/shipmentListFilters";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ExportShipments from "../Shipments/ExportShipments";
import useWidth from "../../Hooks/useWidth";
import PrintComponent from "../Shipments/PrintComponent";

import { printLink } from "../../helpers/defaultPrintList";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ShipmentColumView from "../ShipmentSearch/shipmentColumView"
import config from "../../config.json";
import { defaultQueryFields, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";

const PREFIX = "DeliveryListActions";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  shipmentTable_: `${PREFIX}-shipmentTable_`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_status: `shipmentTable_status`,
  shipmentTable_button: `${PREFIX}-shipmentTable_button`,
  shipmentTable_bodyStatus: `shipmentTable_bodyStatus`,
  tableRow: `${PREFIX}-tableRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: theme.palette.background.appTitle,
    width: "100%",
    justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.shipmentTable_}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      right: "0",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: 0,
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: 0,
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      left: "141px",
    },
  },
  [`& .${classes.shipmentTable_bodyStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      left: "141px",
    },
  },



  [`& .${classes.shipmentTable_button}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

const drawerWidth = 240;

//*********Table Function*********
const DeliveryListActions = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const { t } = useTranslation();
  const user = Globals.user;

  const [keys, setKeys] = useState(localStorage.getItem("delivery-list-actions") ? JSON.parse(localStorage.getItem("delivery-list-actions")) : initialData["delivery-list-actions"])
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);

  const openColumView = () => {
    setOpenShipmentViewColum(true)

  };

  const [open, setDialogOpen] = useState(false);


  const [shipmentDetails, setShipmentDetails] = useState();

  const [tableLoad, setTableLoad] = useState(false);
  const [filterLoad, setFilterLoad] = useState(true);
  const [updateMultiple, setUpdateMultiple] = useState(false);
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const shipmentList = useRef([]);

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [checkedIds, setCheckedIds] = useState([]);
  const [queryVariables, setQueryVariables] = useState({
    refetch: false,
    page: -1,
  });

  const resetPage = () => setPage(0);
  const queryVariablesWithInitValue = {
    ...(queryVariables && queryVariables),
  };
  const {
    refetch,
    page: pageNum,
    ...excelFilters
  } = queryVariablesWithInitValue;

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/delivery-list-actions",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setUpdateMultiple(false);
    setCheckedIds([]);
    setShipmentDetails();
  };

  const rowActionFunc = (data) => {
    setShipmentDetails(data);
    handleClickOpen();
  };

  const updateMultipleShipment = () => {
    setUpdateMultiple(true);
    handleClickOpen();
  };

  const checkedShipmentStatus = useMemo(() => {
    let selectedShipments = shipmentList.current.filter(({ id }) =>
      checkedIds.includes(id)
    );
    const valid = selectedShipments.every(
      (e) => e.status.code === selectedShipments[0].status.code && user.canAccessBranch(e.branch.id)
    );
    return { valid, shipments: selectedShipments };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedIds]);

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };

  const statusDataHandler = (data) => {
    return data && data.filter((i) => i.code === "OTD" || i.code === "PKM");
  };

  const queryVariablesHandler = (variables) => {
    const varStatus = variables?.statusCode;
    const statusCode = {
      statusCode: varStatus?.length > 0 ? varStatus : ["PKM", "OTD"],
    };
    setQueryVariables({ ...variables, ...statusCode, cancelled: false });
  };

  const clearCheckBox = () => setCheckedIds([]);
  // const canShowAdminNotes = user.hasPermission('shipping.shipment.view_admin_note')

  const updataDeliveryBody = useCallback((shipment, index) => {
    const branchId = shipment?.branch?.id;
    const canUpdateShipment = user.canAccessBranch(branchId);

    shipmentList.current = [...shipmentList.current, shipment];
    return (
      <FixedTableCell
        key={index}
        className={classes.shipmentTable_button}
        component="th"
        scope="row"
      >
        <Button
          color="primary"
          variant="outlined"
          size="small"
          disabled={!canUpdateShipment}
          onClick={() => rowActionFunc(shipment)}
          startIcon={<Icon>task_alt</Icon>}
        >
          {t("update")}
        </Button>
      </FixedTableCell>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const defaultPrint = Globals.settings.waybillCode;
  const [openPrint, setOpenPrint] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, false, checkedIds) :
      setOpenPrint(true)
  };
  const openExportDialog = () => {
    setOpenExport(true)
  };
  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      if (i === "date") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent(y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      if (i === "lastTransactionDate") {
        for (const y in param[i]) {
          const lastTransaction = (y === "fromDate" ? "lastTransactionFromDate" : "lastTransactionToDate")
          queryParams.push(encodeURIComponent(lastTransaction) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      i !== "page" && i !== "refetch" && i !== "date" && i !== "lastTransactionDate" && encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const onPrint = () => {
    const params = searchParams(queryVariables);
    const domain = `${window.location.origin}`;

    window.open(`${domain}/shipment-list/print?listType=delivery-list-actions&${params}`);
  };
  const [printListDisabled, setPrintListDisabled] = useState(true)

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "update",
      title: "update",
      disabled: checkedIds.length === 0 || !checkedShipmentStatus.valid,
      action: updateMultipleShipment,
      icon: TaskAlt,
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0
    },
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIds.length === 0
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: printListDisabled
    },
    {
      id: "ViewColumn",
      title: "viewColums",
      action: openColumView,
      icon: ViewColumn,
    },
  ]
  return (
    <Root>
      <PrintComponent shipmentsId={checkedIds} openPrint={openPrint} setOpenPrint={setOpenPrint} />
      <ExportShipments
        filters={excelFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"delivery-list-actions"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />
      <SearchTable
        {...props}
        onPageChange={handleChangePage}
        onChangeRows={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        queryVariables={queryVariablesWithInitValue}
        loading={(param) => setTableLoad(param)}
        notifyOnNetworkStatusChange={true}
        updateDeliveryBody={updataDeliveryBody}
        setCheckedIds={(ids) => setCheckedIds(ids)}
        checkedIds={checkedIds}
        withCheckAll
        setPrintListDisabled={setPrintListDisabled}
        filterLoading={setFilterLoad}
        icons={icons}
        path={props.match.path}
        drawerState={drawerState[filterAnchor]}
        queryFields={defaultQueryFields(keys, adminNotesPermission, true)}
        keys={keys}
        notSumShipments={true}
        filters={
          <FiltersForm
            {...{ onSubmitFunc, resetPage, rowsPerPage, loading: tableLoad, filterLoad: filterLoad }}
            queryVariables={queryVariablesHandler}
            statusDataHandler={statusDataHandler}
            pathname="delivery-list-actions"
            filtersAllowed={[
              "dlvAtemp",
              "status",
              "branch",
              "originBranch",
              "customerType",
              "custm",
              "dlvAgent",
              "zoneSender",
              "subZoneSender",
              "zone",
              "subZone",
              "refNumber",
              "service",
              "packageType",
              "paymentType",
            ]}
            clearCheckBox={clearCheckBox}
          />}
      />
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle color="inherit">{t("updateShipmentStatus")}</DialogTitle>
        <DialogContent>
          <DeliveryActionForm
            closeDialog={handleClose}
            shipment={shipmentDetails}
            queryFields={defaultQueryFields(keys, adminNotesPermission, true)}
            shipmentsId={checkedIds}
            updateMultiple={updateMultiple}
            listShipments={checkedShipmentStatus.shipments}
          />
        </DialogContent>
      </Dialog>
    </Root>
  );
};
export default DeliveryListActions;
