import React from 'react'
import PropTypes from "prop-types";
import CellLink from '../HOC/CustomComponents/CellLink';
import { FixedTableCell } from '../HOC/CustomComponents/FixedTableCell';
// import { Globals } from '../HOC/Classes/Globals';
import { getListShipmentTable, shipmentFieldsFun } from './ListShipmentTableCells';
import ShipmentRegion from '../HOC/CustomComponents/ShipmentRegion';
import { Icon } from '@mui/material';
import { TableCellColor } from '../HOC/CustomComponents/TableCellColor';

const GenerateShipmentCell = (props) => {
    const { shipment, elementKey, classes, ifCode, adminNotesPermission, print ,account} = props
    const field = shipmentFieldsFun(adminNotesPermission).find((e) => e.key === elementKey)

    const fieldKey = getListShipmentTable(shipment, field?.key, ifCode,account)
    if (field?.type === "link") {
        return (
            <CellLink pathname={fieldKey?.pathname} className={fieldKey.ifCode && classes.shipmentTable_bodyCode}>
                {fieldKey?.value}
            </CellLink>
        )
    } else if (field?.type === "zone") {
        return (
            <ShipmentRegion
                pathname={fieldKey?.pathname}
                zone={fieldKey?.zone}
                subzone={fieldKey?.subzone}
            />
        )
    } else if (field?.type === "boolean") {
        return (
            <FixedTableCell >
                {fieldKey?.value ? (
                    <Icon className={classes.iconColor}>
                        check_circle_outline
                    </Icon>
                ) : (
                    <Icon color="error">highlight_off</Icon>
                )}
            </FixedTableCell>
        )
    } else if (field?.type === "status") {
        return print ? (
            <FixedTableCell >
                {fieldKey?.shipmentStatus?.name}
            </FixedTableCell>
        ) : (
            <TableCellColor
                className={ifCode ? classes.shipmentTable_bodyStatus : classes.shipmentTable_bodyCode}
                shipment={shipment}
                
            />
        )
    }
    else if (field?.type === "phone") {
        return (
            <FixedTableCell className={fieldKey.condition && classes.shipmentTable_description} dir="ltr">
                {fieldKey?.value}
            </FixedTableCell>
        )
    }
    else if (field?.type === "barcode") {
        return (
            <FixedTableCell className={classes.barcode} dir="ltr" auto={true}>
                *{shipment?.code}*
            </FixedTableCell>
        )
    }
    else if (fieldKey?.value === "noVal") {
        return null
    }

    else {
        return (
            <FixedTableCell className={fieldKey.condition && classes.shipmentTable_description}>
                {fieldKey?.value}
            </FixedTableCell>
        )
    }

}

GenerateShipmentCell.Prototype = {
    shipment: PropTypes.object.isRequired,
}

export default GenerateShipmentCell