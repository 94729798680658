import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import {
    IconButton,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    ListItemButton,
    Typography,
    InputAdornment,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
} from "@mui/material";
import { gql, useQuery } from '@apollo/client';
import { SEARCH } from './GraphQl';
import { useState } from 'react';
import SpanLink from '../HOC/CustomComponents/SpanLink';
import { useForm } from 'react-hook-form';
import { styled } from "@mui/material/styles";
import CustomSpinner from '../HOC/FunctionComponents/CustomSpinner';
import MUItextField from '../HOC/MUI/MUItextField';
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Globals } from '../HOC/Classes/Globals';
// import { pushUrl } from '../HOC/CustomFunctions/pushUrl';


const PREFIX = "BranchList";

const classes = {
    searchTextField: `${PREFIX}-searchTextField`,
    navSubItem: `${PREFIX}-navSubItem`,
    navItem: `${PREFIX}-navItem`,
    searchTitle: `${PREFIX}-searchTitle`,
};

const Root = styled("div")(({ theme }) => ({

    [`& .${classes.navSubItem}`]: {
        minWidth: "20px !important",
    },
    [`& .${classes.navItem}`]: {
        minWidth: "30px !important",
    },
    [`& .${classes.searchTitle}`]: {
        [`&::first-letter`]: {
            textTransform: 'capitalize'
        },
    },

}));

function SearchDialog(props) {
    const {
        handleSubmit,
        register,
        setValue,
        watch
    } = useForm();
    const { t } = useTranslation();
    const { onClose, open } = props;
    const [searchData, setSearchData] = useState([])

    const handleClose = () => {
        setValue('keyword', '')
        setSearchData([])
        onClose();
    };

    const { data, loading } = useQuery(
        gql`
            ${SEARCH.query}
          `,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            skip: !open || !watch('keyword'),
            variables: {
                keyword: watch('keyword') ?? ''
            },
            onCompleted: (data) => {
                const searchResult = {
                    PADD: {
                        name: 'receiveProductFromCustomer',
                        viewURL: '/admin/warehouse-manifests',
                        viewMoreURL: '/admin/warehouse-manifests/padd',
                        result: []
                    },
                    PRTRN: {
                        name: 'returnProductToCustomer',
                        viewURL: '/admin/warehouse-manifests',
                        viewMoreURL: '/admin/warehouse-manifests/prtrn',
                        result: []
                    },
                    PMBT: {
                        name: 'sendProductTransfer',
                        viewURL: '/admin/warehouse-manifests',
                        viewMoreURL: '/admin/warehouse-manifests/pmbt',
                        result: []
                    },
                    PMBR: {
                        name: 'receiveProductTransfer',
                        viewURL: '/admin/warehouse-manifests',
                        viewMoreURL: '/admin/warehouse-manifests/pmbr',
                        result: []
                    },
                    Invoice: {
                        viewURL: '/admin/invoices',
                        viewMoreURL: '/admin/invoices',
                        name: 'invoice',
                        result: []
                    },
                    Pickup: {
                        viewURL: '/admin/pickups',
                        viewMoreURL: '/admin/pickups',
                        name: 'pickup',
                        result: []
                    },
                    CUSTMCollection: {
                        viewURL: '/admin/collections',
                        viewMoreURL: '/admin/collections/custm',
                        name: 'collectCashShipments',
                        result: []
                    },
                    DLVBYCollection: {
                        viewURL: '/admin/collections',
                        name: 'collectFromDeliveryAgents',
                        viewMoreURL: '/admin/collections/dlvby',
                        result: []
                    },
                    Shipment: {
                        viewURL: '/admin/shipments',
                        viewMoreURL: '/admin/shipments',
                        name: 'shipment',
                        result: []
                    },
                    CUSTMPayment: {
                        viewURL: '/admin/payments',
                        name: 'customerPayment',
                        viewMoreURL: '/admin/payments/custm',
                        result: []
                    },
                    DLVBYPayment: {
                        viewURL: '/admin/payments',
                        name: 'deliveryAgentPayment',
                        viewMoreURL: '/admin/payments/dlvby',
                        result: []
                    },
                    RTS: {
                        viewURL: '/admin/manifests',
                        name: 'returnToCustomerLists',
                        viewMoreURL: '/admin/manifests/rts',
                        result: []
                    },
                    RTRN: {
                        viewURL: '/admin/manifests',
                        name: 'returnHandlingLists',
                        viewMoreURL: '/admin/manifests/rtrn',
                        result: []
                    },
                    RITS: {
                        viewURL: '/admin/manifests',
                        name: 'receivedInStore',
                        viewMoreURL: '/admin/manifests/rits',
                        result: []
                    },
                    PKM: {
                        viewURL: '/admin/manifests',
                        name: 'pickupManifestLists',
                        viewMoreURL: '/admin/manifests/pkm',
                        result: []
                    },
                    OTR: {
                        viewURL: '/admin/manifests',
                        name: 'returnDeliveryLists',
                        viewMoreURL: '/admin/manifests/otr',
                        result: []
                    },
                    OTD: {
                        viewURL: '/admin/manifests',
                        name: 'outForDeliveryLists',
                        viewMoreURL: '/admin/manifests/otd',
                        result: []
                    },
                    HTR: {
                        viewURL: '/admin/manifests',
                        name: 'retryDeliveryLists',
                        viewMoreURL: '/admin/manifests/htr',
                        result: []
                    },
                    BMT: {
                        viewURL: '/admin/manifests',
                        name: 'branchTransfareTo',
                        viewMoreURL: '/admin/manifests/bmt',
                        result: []
                    },
                    BMR: {
                        viewURL: '/admin/manifests',
                        name: 'branchTransfareFrom',
                        viewMoreURL: '/admin/manifests/bmr',
                        result: []
                    },
                    BMT_RTS: {
                        viewURL: '/admin/manifests',
                        name: 'ReturnTrips',
                        viewMoreURL: '/admin/manifests/bmt/rts',
                        result: []
                    },
                    BMT_DLV: {
                        viewURL: '/admin/manifests',
                        name: 'distributionTrips',
                        viewMoreURL: '/admin/manifests/bmt/dlv',
                        result: []
                    },
                    PRP: {
                        viewURL: '/admin/manifests',
                        name: 'packagingList',
                        viewMoreURL: '/admin/manifests/prp',
                        result: []
                    },
                    STD: {
                        viewURL: '/admin/manifests',
                        name: 'sendingToAgentList',
                        viewMoreURL: '/admin/manifests/std',
                        result: []
                    },
                    RCV: {
                        viewURL: '/admin/manifests',
                        name: 'receivingUndeliveredList',
                        viewMoreURL: '/admin/manifests/rcv',
                        result: []
                    },
                    TOPConciergeRequest: {
                        viewURL: '/admin/concierge-request',
                        viewMoreURL: '/admin/concierge-request/top',
                        name: 'topupList',
                        result: []
                    },
                    WDRConciergeRequest: {
                        viewURL: '/admin/concierge-request',
                        viewMoreURL: '/admin/concierge-request/wdr',
                        name: 'withdrawList',
                        result: []
                    },
                }
                data.universalSearch.map((ele) => {
                    if (ele?.WarehouseManifestTransactionType?.type?.code) {
                        searchResult[ele?.WarehouseManifestTransactionType?.type.code].result.push(ele)
                    }
                    else if (ele?.manifestTransactionType?.type?.code) {
                        if ((Globals.user.hasPermission("shipping.manifest_bmt.use_type_dlv") || Globals.user.hasPermission("shipping.manifest_bmt.use_type_dlv")) && ele?.manifestTransferType?.code) {
                            searchResult[`${ele?.manifestTransactionType?.type.code}_${ele?.manifestTransferType?.code}`].result.push(ele)
                        } else {
                            searchResult[ele?.manifestTransactionType?.type.code].result.push(ele)
                        }
                    }
                    else if (ele?.type?.code) {
                        searchResult[ele?.type.code + ele.__typename].result.push(ele)
                    }
                    else {
                        searchResult[ele.__typename].result.push(ele)
                    }
                    return {}
                })
                setSearchData(searchResult)
            },
        }
    );
    const SearchInQuery = (e) => {

    };
    const submit = () => {
        return {}
    };

    const [openList, setOpenList] = React.useState({
        PADD: true,
        PRTRN: true,
        PMBT: true,
        PMBR: true,
        Invoice: true,
        Pickup: true,
        CUSTMCollection: true,
        DLVBYCollection: true,
        Shipment: true,
        CUSTMPayment: true,
        DLVBYPayment: true,
        RTS: true,
        RTRN: true,
        RITS: true,
        PKM: true,
        OTR: true,
        OTD: true,
        HTR: true,
        BMT: true,
        BMR: true,
        BMT_RTS: true,
        BMT_DLV: true,
        PRP: true,
        STD: true,
        RCV: true,
        TOPConciergeRequest: true,
        WDRConciergeRequest: true,
    });

    const handleClick = (type) => {
        setOpenList((prev) => ({
            ...prev,
            [type]: !prev[type],
        }));
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={'xs'}
            fullWidth={true}
            PaperProps={{
                // sx: {
                //     minHeight: 150,
                // }
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Typography mb={3} color={"text.primary"}>{t('writeCodeInSearch')}</Typography>
                <form onSubmit={handleSubmit(submit)}>
                    <MUItextField
                        label={t("keyword")}
                        autoFocus
                        name={"keyword"}
                        variant={'outlined'}
                        register={register}
                        // inputProps={{
                        //     dir: "ltr",
                        // }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {loading && (
                                        <CustomSpinner name="PulseLoader" size={5} margin={2} />
                                    )}
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <SearchIcon sx={{ marginRight: 1 }} fontSize="medium" />
                            )
                        }}
                        defaultValue=""
                        onChange={(e) => SearchInQuery(e)}
                    />
                </form>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Root>
                    {!loading && data?.universalSearch.length > 0 ?
                        Object.entries(searchData).map((ele, index) =>
                            ele[1].result.length > 0 &&
                            <List
                                sx={{ width: '100%' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                key={index}
                            >
                                <ListItemButton onClick={() => handleClick(ele[0])}>
                                    <ListItemIcon className={classes.navItem}>
                                        {openList[ele[0]] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography className={classes.searchTitle} variant='body1'>
                                                {t(`${ele[1]?.name}`)}
                                            </Typography>
                                            <SpanLink
                                                onClick={() => {
                                                    onClose()
                                                    setValue("keyword", "")
                                                }}
                                                pathname={`${ele[1].viewMoreURL}`}
                                                search={
                                                    `${['shipment', 'topconciergerequest', 'wdrconciergerequest'].includes(ele[0].toLowerCase()) ?
                                                        "search=" :
                                                        ['pickups', 'invoice', 'custmcollection', 'dlvbycollection', 'custmpayment', 'dlvbypayment'].includes(ele[0].toLowerCase()) ? "code=" : "page=0&rowsPerPage=20&code="
                                                    }${watch('keyword')}`}
                                            >
                                                {t('viewMore')}
                                            </SpanLink>
                                        </Box>
                                    } />

                                </ListItemButton>
                                {ele[1].result.map((res, index) =>
                                    <Collapse in={openList[ele[0]]} timeout="auto" unmountOnExit key={index}>
                                        <List component="div" disablePadding>
                                            <ListItem sx={{ p: 0, pl: 4 }}>
                                                <ListItemIcon className={classes.navSubItem}>
                                                    -
                                                </ListItemIcon>
                                                <ListItemText sx={{ m: 0 }}>
                                                    <SpanLink
                                                        onClick={() => {
                                                            onClose()
                                                            setValue("keyword", "")
                                                        }}
                                                        pathname={`${ele[1].viewURL}/${res.id}`}
                                                    >
                                                        {res.code}
                                                    </SpanLink>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                )}
                            </List>
                        ) :
                        !loading && Boolean(watch('keyword')) && <Grid container justifyContent="center" alignItems="center">
                            <EmptyTableMessage loading={false} message={t("noResult")} />
                        </Grid>
                    }
                </Root>
            </DialogContent>
            <DialogActions sx={{ height: 40 }}>

            </DialogActions>
        </Dialog>
    );
}

SearchDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function GlobalSearch(props) {
    const { handleCloseMenu } = props
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        handleCloseMenu && handleCloseMenu()
    };

    return (
        <span>
            <Box component="span" onClick={handleClickOpen}>
                <IconButton
                    sx={{ p: 1 }}
                    aria-label="Search">
                    <SearchIcon fontSize="inherit" />
                </IconButton>
            </Box>
            <SearchDialog
                open={open}
                onClose={handleClose}
                props={props.props}
            />
        </span>
    );
}