/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELIVERY_AGENT_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Rating } from "react-simple-star-rating";
import clsx from "clsx";

const PREFIX = 'DeliveryAgentView';

const classes = {
  box: `${PREFIX}-box`,

  main: `${PREFIX}-main`,

  iconColor: `${PREFIX}-iconColor`,
  span: `${PREFIX}-span`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({

  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.span}`]: {
    display: "inline"
  }
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));
const DeliveryAgentView = (props) => {

  const DeliveryAgentId = props.match.params.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${DELIVERY_AGENT_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(DeliveryAgentId) },
    }
  );

  const user = Globals.user;
  const branchId = data?.deliveryAgent?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit =
    user.hasPermission("shipping.delivery_agent.update") && canAccessBranch;

  const editURL = () => {
    pushUrl(props, `/admin/delivery-agents/${DeliveryAgentId}/edit`);
  }

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: canEdit,
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.deliveryAgent ? (
      <NotFound />
    ) : (
      <StyledGrid
        container
        justifyContent="center"
        className={classes.mainTracking}
        sx={{ margin: "0", width: "100%" }}
      >
        <Fragment>
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
          </TitleAppBar>
        </Fragment>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", p: 2, gap: 2 }}
        >
          <Grid item xs={12}>
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair
                title={t("code")}
                value={data?.deliveryAgent?.code}
              />
              <KeyValuePair
                title={t("name")}
                value={data?.deliveryAgent?.name}
              />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.deliveryAgent?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("pickupCommission")}
                value={
                  data?.deliveryAgent?.pickupCommission === null ? "-" : data?.deliveryAgent?.pickupCommission ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("agentReview")}
              >
                <Grid>
                  <Rating
                    // onClick={handleRating}
                    initialValue={data?.deliveryAgent?.reviewAvg}
                    size={20}
                    label
                    transition
                    fillColor='orange'
                    emptyColor='gray'
                    className={clsx(classes.stars, 'foo')}
                    allowFraction
                    readonly={true}
                  />
                  ( {data?.deliveryAgent?.reviewCount} )
                </Grid>
              </KeyValuePair>
              <KeyValuePair
                title={t("autoPayCommission")}
                value={
                  data?.deliveryAgent?.autoPayCommission ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("branch")}
                value={
                  data?.deliveryAgent?.branch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.deliveryAgent?.branch?.id}`}
                    >
                      {data?.deliveryAgent?.branch?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("glAccount")}
                value={data?.deliveryAgent?.glAccount?.name}
              />
              <KeyValuePair
                title={t("custodyAccount")}
                value={data?.deliveryAgent?.custodyAccount?.name}
              />
              <KeyValuePair
                title={t("route")}
                value={
                  data?.deliveryAgent?.route?.name && (
                    <SpanLink
                      pathname={`/admin/routes/${data?.deliveryAgent?.route?.id}`}
                    >
                      {data?.deliveryAgent?.route?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("commissionType")}
                value={data?.deliveryAgent?.commissionType?.name}
              />
              <KeyValuePair
                title={t("commission")}
                value={data?.deliveryAgent?.commission}
              />
              <KeyValuePair
                title={t("commissionAccount")}
                value={
                  data?.deliveryAgent?.commissionList?.name && (
                    <SpanLink
                      pathname={`/admin/commission-list/${data?.deliveryAgent?.commissionList?.id}`}
                    >
                      {data?.deliveryAgent?.commissionList?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("users")}
                value={
                  data?.deliveryAgent?.users && (
                    data?.deliveryAgent?.users.map((user, index) =>
                      <SpanLink
                        key={index}
                        className={classes.span}
                        pathname={`/admin/users/${user.id}`}
                      >
                        {user.username} {!(data?.deliveryAgent?.users.length - 1 === index) && ' , '}
                      </SpanLink>
                    )
                  )
                }
              />
              {Globals.settings.integration && Globals.user.hasPermission("core.integration.save") && <KeyValuePair
                title={t("deliveryAgentsIntegration")}
                value={
                  data?.deliveryAgent?.integration ? (
                    <SpanLink
                      pathname={`/admin/delivery-agents-integration/${data?.deliveryAgent.id}/edit`}
                    >
                      {t("update")}
                    </SpanLink>
                  ) :
                    <SpanLink
                      pathname={`/admin/delivery-agents-integration/${data?.deliveryAgent.id}/create`}
                    >
                      {t("create")}
                    </SpanLink>
                }
              />}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair
                title={t("country")}
                value={data?.deliveryAgent?.country?.name}
              />
              <KeyValuePair
                title={t("state")}
                value={data?.deliveryAgent?.state?.name}
              />
              <KeyValuePair
                title={t("city")}
                value={data?.deliveryAgent?.city?.name}
              />
              <KeyValuePair
                title={t("subzone")}
                value={data?.deliveryAgent?.area?.name}
              />
              <KeyValuePair
                title={t("address")}
                value={data?.deliveryAgent?.address}
              />
              <KeyValuePair
                title={t("phone")}
                valueStyle={{ "inlineSize": "max-content" }}
                dir="ltr"
                value={data?.deliveryAgent?.phone}

              />
              <KeyValuePair
                title={t("mobile")}
                valueStyle={{ "inlineSize": "max-content" }}
                dir="ltr"
                value={data?.deliveryAgent?.mobile}
              />
              <KeyValuePair
                title={t("mailBox")}
                value={data?.deliveryAgent?.mailBox}
              />
              <KeyValuePair
                title={t("postalCode")}
                value={data?.deliveryAgent?.postalCode}
              />
              <KeyValuePair
                title={t("email")}
                value={data?.deliveryAgent?.email}
              />
            </Paper>
          </Grid>
        </Grid>
      </StyledGrid>


    );
};

export default DeliveryAgentView;
