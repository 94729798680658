/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  SwipeableDrawer,
  Table,
  TableBody,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Cancel, CheckCircle, FilterList } from "@mui/icons-material";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FiltersForm from "../../../Hooks/shipmentListFilters";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./ShipmentListDetails";
import { SAVE_INVOICE_ENTRIES_MUTATION } from "../InvoicesListQuary";
import { SHIPMENT_QUERY } from "./Graphql";

const TOOLBAR_PREFIX = "styledToolbar";
const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300],
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[800],
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "ShipmentList";
const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.default,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled, toggleFilterDrawer } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(toolbarClasses.root, {
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("shipment", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("shipments")}
          </Typography>
        )}

        <IconButton
          onClick={toggleFilterDrawer}
          className={toolbarClasses.filterButton}
          size="large"
        >
          <FilterList />
        </IconButton>

        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const ShipmentList = (props) => {
  const { variables, refetchShipmentDataQuery, invoiceRefetch } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [shipments, setShipments] = useState([]);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [drawerState, setDrawerState] = useState(false);
  const [queryVariables, setQueryVariables] = useState({ refetch: true });
  const { refetch, ...restVariables } = queryVariables;
  const screenWidth = useWidth();
  const isScreenSmall = isWidthDown("xs", screenWidth);
  const { t } = useTranslation();
  const theme = useTheme();

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          forInvoiceId: variables.id,
          ...restVariables,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listShipments?.data !== null ? data?.listShipments?.data : [];

        let updatedDate = handledData.map((i) => {
          const selectedShipment = selectedShipments.find(
            (ele) => ele.shipment.id === i.id
          );
          if (selectedShipment) {
            return selectedShipment;
          } else {
            return {
              shipment: i,
              select: false,
            };
          }
        });

        setShipments(updatedDate);
      },
    }
  );

  const isShipmentSelected = (addShipments, deleteShipments) => {
    const mainShipments = addShipments
      ? [...addShipments]
      : [...selectedShipments];

    const filterShipments = deleteShipments
      ? [...deleteShipments]
      : [...selectedShipments];

    return mainShipments.filter((newShipment) => {
      return !filterShipments.some(
        (current) => current.shipment.id === newShipment.shipment.id
      );
    });
  };

  const handleSelectAllClick = (event, rows) => {
    const selected = shipments?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });

    if (event.target.checked) {
      const newShipments = isShipmentSelected(selected);
      setSelectedShipments((prev) => [...prev, ...newShipments]);
    } else {
      const filteredShipments = isShipmentSelected(null, selected);
      setSelectedShipments(filteredShipments);
    }
    setShipments(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const resetPage = () => setPage(0);

  const toggleFilterDrawer = () => setDrawerState(!drawerState);

  const selectedShipmentParPage = () => {
    return shipments.filter((i) => i.select === true).length;
  };

  const validShipments = () => {
    return selectedShipmentLength <= 0;
  };

  const filtersForm = (
    <FiltersForm
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
      onSubmitFunc={() => setDrawerState(false)}
      filtersAllowed={[
        "date",
        "dlvAtemp",
        "branch",
        "originBranch",
        "dlvAgent",
        "zoneSender",
        "subZoneSender",
        "zone",
        "subZone",
        "refNumber",
        "service",
        "packageType",
      ]}
    />
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedShipmentLength = selectedShipments.length;

  let tableBody = null;

  let progress = (
    <Grid container item justifyContent="center" className={classes.track}>
      <CircularProgress />
    </Grid>
  );

  const [
    saveinvoiceEntriesMutation,
    { loading: saveinvoiceEntriesMutationLoad },
  ] = useMutation(
    gql`
      ${SAVE_INVOICE_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const onSaveEntries = (data) => {
    const shipmentIds = data.map((item) => item.shipment.id);
    shipmentIds.length > 0 &&
      saveinvoiceEntriesMutation({
        variables: {
          input: {
            invoiceId: variables.id,
            shipmentIds: shipmentIds,
          },
        },
      })
        .then((data) => {
          enqueueSnackbar(t("saveSuccessful"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          invoiceRefetch();
          refetchShipmentDataQuery();
          props.done();
        })
        .catch((error) => {
          console.log(error);
        });
  };

  if (shipments && !loading) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper containerScroll>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedShipmentParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, shipments)}
                rowCount={shipments?.length}
              />
              <TableBody>
                {shipments?.map((shipment, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      key={shipment.shipment.id}
                      shipment={shipment}
                      onChangeCheck={(check, selectedShipment) => {
                        setShipments((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };

                          if (check.target.checked) {
                            setSelectedShipments((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedShipments((prev) => {
                              return prev.filter(
                                (i) => i.shipment.id !== shipment.shipment.id
                              );
                            });
                          }

                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listShipments?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    );
  }
  return (
    <Root container justifyContent="center">
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={saveinvoiceEntriesMutationLoad}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isScreenSmall ? (
        <SwipeableDrawer
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor="left"
          open={drawerState}
          onClose={() => setDrawerState(false)}
          onOpen={() => setDrawerState(true)}
          classes={{ paper: classes.filters }}
          dir={theme.direction}
        >
          {filtersForm}
        </SwipeableDrawer>
      ) : (
        <Grid item sm={2}>
          {filtersForm}
        </Grid>
      )}
      <Grid item container sm={10} alignContent="flex-start">
        <EnhancedTableToolbar
          numSelected={selectedShipmentLength}
          clear={props.done}
          toggleFilterDrawer={toggleFilterDrawer}
          done={() => {
            onSaveEntries(selectedShipments);
          }}
          disabled={validShipments()}
        />

        {loading
          ? progress
          : shipments && shipments?.length !== 0
            ? tableBody
            : !loading && (
              <EmptyTableMessage loading={false} message={t("noShipments")} />
            )}
      </Grid>
    </Root>
  );
};

export default ShipmentList;
