import React from "react";
import { styled } from '@mui/material/styles';
import { Grid, Typography, Paper } from "@mui/material";
import CustomizedSteppers from "../HOC/FunctionComponents/Stepper";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { dateFormatLL } from "../../helpers/dateFunctions";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";

const PREFIX = 'GlobalTrackView';

const classes = {
  logo: `${PREFIX}-logo`,
  appBar: `${PREFIX}-appBar`,
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  paper: `${PREFIX}-paper`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  powered: `${PREFIX}-powered`,
  poweredBy: `${PREFIX}-poweredBy`,
  li: `${PREFIX}-li`,
  lang: `${PREFIX}-lang`,
  status: `${PREFIX}-status`,
  createdAt: `${PREFIX}-createdAt`,
  center: `${PREFIX}-center`,
  cancellationReason: `${PREFIX}-cancellationReason`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.logo}`]: {
    width: "180px",
  },

  [`& .${classes.appBar}`]: {
    boxShadow: "none",
  },

  [`& .${classes.track}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    boxShadow: "none",
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    marginTop: theme.spacing(2),
    display: "grid",
  },

  [`& .${classes.powered}`]: {
    padding: theme.spacing(2),
    position: "absolute",
    bottom: "0",
  },

  [`& .${classes.poweredBy}`]: {
    padding: theme.spacing(2),
    position: "relative",
  },

  [`& .${classes.li}`]: {
    padding: " 7px 0",
  },

  [`& .${classes.lang}`]: {
    marginLeft: "auto",
  },

  [`& .${classes.status}`]: { marginLeft: theme.spacing(2) },
  [`& .${classes.createdAt}`]: { fontWeight: "bold" },

  [`& .${classes.center}`]: {
    minHeight: 500,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  [`& .${classes.cancellationReason}`]: {
    color: "#e53935",
    paddingLeft: "10px",
    fontSize: "12px",
  }
}));

const GlobalTrackView = (props) => {
  const { loading, data } = props;


  const { t } = useTranslation();

  const date = new Date(data?.date);

  let transactions = new Map();
  if (data && data) {
    data.transactions.forEach((element) => {
      const date = moment(element.createdAt).format("ll");
      if (!transactions.has(date)) {
        transactions.set(date, []);
      }
      transactions.get(date).push(element);
    });
  }

  let tracking = null;

  if (loading) {
    tracking = (
      <Grid container item justifyContent="center" className={classes.center}>
        <FullScreenLoading />
      </Grid>
    );
  }

  if (data && !loading) {
    tracking = (
      <Grid container justifyContent="center">
        <Grid
          item
          className={clsx(classes.track, classes.paper)}
          component={Paper}
          container
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            sm={6}
            xs={12}
            item
            className={clsx(classes.textAlign)}
          >
            <Typography variant="body1" gutterBottom color="textSecondary">
              {t("shipmentCode")}
            </Typography>
            <Typography variant="h6" color="text.primary">{data?.code}</Typography>
          </Grid>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            sm={6}
            xs={12}
            item
            className={clsx(classes.textAlign)}
          >
            <Typography variant="body1" gutterBottom color="textSecondary">
              {t("shipmentStatus")}
            </Typography>
            <Typography variant="h6" color="text.primary">{data?.status.name}</Typography>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" className={classes.track}>
          <CustomizedSteppers
            statusCode={data?.status?.code}
            inWareHouse={data?.inWarehouse}
          />
        </Grid>

        <Grid item container sm={12}>
          <Grid
            container
            item
            sm={12}
            className={classes.paper}
            component={Paper}
          >
            <Grid sm={3} xs={12} item className={classes.textAlign}>
              <Typography variant="body2" gutterBottom color="textSecondary">
                {t("date")}
              </Typography>
              <Typography variant="body1">{dateFormatLL(date)}</Typography>
              {/* <Typography variant="body1" >{data?.createdAt ? (date?.getDate() + "/" + (date?.getMonth() + 1) + "/" + date?.getFullYear()) : null}</Typography> */}
            </Grid>
            <Grid sm={3} xs={12} item className={classes.textAlign}>
              <Typography variant="body2" gutterBottom color="textSecondary">
                {t("weight")}
              </Typography>
              <Typography variant="body1">{data?.weight ?? "0"}</Typography>
            </Grid>
            <Grid sm={3} xs={12} item className={classes.textAlign}>
              <Typography variant="body2" gutterBottom color="textSecondary">
                {t("pieceCount")}
              </Typography>
              <Typography variant="body1">
                {data?.piecesCount ?? "0"}
              </Typography>
            </Grid>
            <Grid sm={3} xs={12} item className={classes.textAlign}>
              <Typography variant="body2" gutterBottom color="textSecondary">
                {t("packageDescription")}
              </Typography>
              <Typography variant="body1">{data?.description}</Typography>
            </Grid>
            <Grid sm={3} xs={12} item className={classes.textAlign}>
              <Typography variant="body2" gutterBottom color="textSecondary">
                {t("recipient")}
              </Typography>
              <Typography variant="body1">{data?.recipientName}</Typography>
            </Grid>
            <Grid sm={3} xs={12} item className={classes.textAlign}>
              <Typography variant="body2" gutterBottom color="textSecondary">
                {t("address")}
              </Typography>
              <Typography variant="body1">
                {data?.recipientZone.name} - {data?.recipientSubzone.name} -{" "}
                {data?.recipientAddress}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* *******Table******* */}
        <Grid container item className={classes.table}>
          <Grid component={Paper} className={clsx(classes.paper)}>
            {Array.from(transactions, ([date, transactions], index) => (
              <div key={index} sx={{ margin: "16px 0" }}>
                <Typography variant="h6" color="text.primary">{date} </Typography>
                <ul>
                  {transactions.map((transaction, i) => (
                    <li key={i} className={classes.li}>
                      <Grid container>
                        <Typography>
                          {moment(transaction.createdAt).format("h:mm A")}
                        </Typography>
                        <Typography className={classes.status}>
                          {transaction.shipmentStatus.name}
                        </Typography>
                        {transaction.cancellationReason && (
                          <Typography className={classes.cancellationReason}>
                            {transaction.cancellationReason.name}
                          </Typography>
                        )}
                      </Grid>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (!loading) {
    tracking = (
      <EmptyTableMessage
        message={t("noResult")}
      />
    );
  }
  return (
    <Root>
      {tracking}
      {/* <Grid
        container
        item
        justifyContent="center"
        className={clsx({
          [classes.poweredBy]: data && !loading,
          [classes.powered]: !data || loading,
        })}
      ></Grid> */}
    </Root>
  );
};

export default GlobalTrackView;
