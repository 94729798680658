import React, { Fragment, useState } from "react";
import { Toolbar, IconButton, Typography, Icon } from "@mui/material";
import { AddCircleOutline, ViewColumn } from "@mui/icons-material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PrintIcon from "../Shipments/PrintIcon";
import ExportShipments from "../Shipments/ExportShipments";
import { Can } from "../HOC/CustomComponents/Secured";
import styled from "@emotion/styled";
import PrintComponent from "../Shipments/PrintComponent";
import SelectColumView from "../shipmentInview/shipmentColumView/SelectFieldColumView";
import { initialManifest, shipmentManifest } from "../ManifestsTransfer/Graphql";
import ExportManifestEntries from "./Components/ExportManifestEntries";

const PREFIX = "ManifestTableToolbar";

const classes = {
  titles: `${PREFIX}-titles`,
  addIcon: `${PREFIX}-addIcon`,
};
const Root = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));

export const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };
  const { selectedShipment, addShipment, approved, barcode, setLocalDataKey, manifestType, total, manifestId } = props;

  const shipmentId = selectedShipment.map((i) => i.shipment.id);
  return (
    <Root>
      <Typography
        className={classes.titles}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("shipmentsNumber")} {total}
      </Typography>

      {!addShipment && (
        <Fragment>
          <Can showException permission="shipping.shipment.print">
            <PrintComponent shipmentsId={shipmentId} />
          </Can>
          <ExportManifestEntries manifestId={manifestId} />
        </Fragment>
      )}
      {addShipment ? (
        <IconButton
          aria-label="Add"
          className={classes.addIcon}
          onClick={addShipment}
          disabled={approved}
          size="large"
        >
          <AddCircleOutline />
        </IconButton>
      ) : null}
      {barcode ? (
        <IconButton
          aria-label="Add"
          onClick={barcode}
          disabled={approved}
          size="large"
        >
          <Icon>qr_code_scanner</Icon>
        </IconButton>
      ) : null}
      {setLocalDataKey ? (
        <Can>
          <IconButton
            aria-label="print"
            onClick={openColumView}>
            <ViewColumn fontSize="inherit" />
          </IconButton>
          <SelectColumView
            openViewColum={shipmentViewColum}
            setOpenViewColum={setOpenShipmentViewColum}
            localStorageName={`shipmentManifest${manifestType}`}
            initialData={initialManifest[manifestType]}
            setLocalDataKey={setLocalDataKey}
            shipmentFields={shipmentManifest[manifestType]}
          />
        </Can>
      ) : null}
    </Root>
  );
};
