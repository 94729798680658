export function setValidationError(graphQLErrors, setError, useFormName, manyNames) {
  const vali = graphQLErrors?.[0]?.extensions;
  if (vali?.category === "validation") {
    let validation = [];
    for (const key in vali.validation) {
      validation = [
        ...validation,
        {
          message: vali.validation[key][0],
          name: key,
        },
      ];
    }

    validation.forEach(({ name, message }) => {
      let fieldName = name;
      console.log(fieldName);
      console.log(`input.${useFormName}`);

      if (name.includes(`input.${useFormName}`)) {
        console.log(name.split(".")[2]);
        fieldName = name.split(".")[2]
      } else if (name.includes("input.")) {
        if (manyNames) {
          fieldName = getValidationNameForMany(name.split("."))
        } else {
          fieldName = name.split(".")[1]
        }
      }
      else {
        fieldName = name
      }

      if (fieldName === "images") {
        fieldName = "fileName"
      }

      return setError(useFormName ? `${useFormName}${fieldName}` : fieldName, {
        type: "required",
        message: message,
        shouldFocus: true,
      });
    });
  }
}

function getValidationNameForMany(params) {
  let names = params
  names.shift()
  return names.join("")
}
