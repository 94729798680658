import React, { Fragment, useContext } from "react";
import withRoot from "./Component/HOC/withRoot";
import "./App.css";
import { withRouter } from "react-router";

import { useTranslation } from "react-i18next";
import config from "./config.json";
// *****Component*****

import CustomeRoute from "./Component/HOC/Routes";
import withErrorBoundarys from "./Error/withErrorBoubdary";
import moment from "moment";
import "moment/locale/ar";
import withApolloProvider from "./Component/HOC/withApolloProvider";
import WebsiteHeader from "./Layout/WebsiteHeader";
import { ModeContext } from "./Context/ModeContext";

function App(props) {
  const { i18n } = useTranslation();

  document.getElementsByTagName("html")[0].setAttribute("dir", i18n.dir());
  const Languages = config.app.languages;

  const { firstLoad } = useContext(ModeContext)

  moment.locale(localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : Languages[0]);
  return (
    <Fragment>
      {document.location.pathname.startsWith("/admin") ? firstLoad && <WebsiteHeader  {...props} /> : <WebsiteHeader  {...props} />}
      <CustomeRoute {...props} />
    </Fragment>
  );
}

export default withErrorBoundarys(
  withRoot(withApolloProvider(withRouter(App)))
);
