import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import formatMoney from "../../helpers/numbersDot";
import { DELETE_COLLECTION_ENTRIES_MUTATION, initialData } from "./CollectionListQuary";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
const PREFIX = "collectionDetails";
const classes = {
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));
const tableHead = (localDataKey, permission) => {
  const headCell = [
    {
      key: "code",
      value: "code",
    },
    {
      key: "status",
      value: "shipmentStatus",
    },
    {
      key: "recipientName",
      value: "recipient",
    },
    {
      key: "recipientPhone",
      value: "recipientPhone",
    },
    {
      key: "recipientZone",
      value: "destination",
    },
    {
      key: "originBranch",
      value: "originBranch",
    },
    {
      key: "currentBranch",
      value: "currentBranch",
    },
    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "description",
      value: "packageDescription",
    },

    {
      key: "recipientAddress",
      value: "recipientAddress",
    },
    {
      key: "senderName",
      value: "senderName",
    },
    {
      key: "senderPhone",
      value: "senderPhone",
    },
    {
      key: "senderAddress",
      value: "senderAddress",
    },
    {
      key: "senderZone",
      value: "source",
    },
    {
      key: "type",
      value: "packageType",
    },
    {
      key: "paymentType",
      value: "paymentType",
    },
    {
      key: "date",
      value: "date"
    },
    {
      key: "deliveredOrReturnedDate",
      value: "deliveryCanclingDate"
    },
    {
      key: "weight",
      value: "weight",
    },
    {
      key: "piecesCount",
      value: "pieceCount",
    },
    {
      key: "totalAmount",
      value: "totalCost",
    },

    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "returningDueFees",
      value: "returningDueFees",
    },
    {
      key: "deliveryType",
      value: "deliveryType",
    },
    {
      key: "collectedFees",
      value: "collectedFees",
    },
    {
      key: "deliveredAmount",
      value: "deliveredAmount",
    },
    {
      key: "commission",
      value: permission ? "commission" : "",
    },
    {
      key: "NetAmount",
      value: permission ? "NetAmount" : "",
    },
    {
      key: "note",
      value: "notes",
    },
    {
      key: "barcode",
      value: "barcode",
    },
  ]
  let selected = []
  localDataKey.map((e) => {
    const selectBody = headCell.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0].value)
    }
    return ""
  })
  return selected
}

const tableBody = (localDataKey, collection, permission) => {
  const body = [
    {
      key: "code",
      pathName: `/admin/shipments/${collection.shipment?.id}`,
      tag: CellLink,
      value: collection.shipment?.code,
    },
    {
      key: "recipientName",
      tag: FixedTableCell,
      value: collection.shipment?.recipientName,
    },
    {
      key: "recipientPhone",
      tag: FixedTableCell,
      value: <> <span dir="ltr" style={{ display: "block" }}>
        {collection?.shipment?.recipientMobile}
      </span>
        <span dir="ltr">{collection?.shipment?.recipientPhone}</span>
      </>
    },
    {
      key: "recipientZone",
      pathName: `/admin/zones/${collection?.shipment?.recipientZone?.id}`,
      tag: ShipmentRegion,
      value: collection?.shipment?.recipientZone?.name,
      subValue: collection?.shipment?.recipientSubzone?.name,
    },
    {
      key: "recipientAddress",
      tag: FixedTableCell,
      value: collection?.shipment?.recipientAddress,
    },
    {
      key: "senderName",
      tag: FixedTableCell,
      value: collection?.shipment?.senderName,
    },
    {
      key: "senderPhone",
      tag: FixedTableCell,
      value:
        <> <span dir="ltr" style={{ display: "block" }}>
          {collection?.shipment?.senderMobile}
        </span>
          <span dir="ltr">{collection?.shipment?.senderPhone}</span>
        </>
    },
    {
      key: "senderAddress",
      tag: FixedTableCell,
      value: collection?.shipment?.senderAddress,
    },
    {
      key: "senderZone",
      pathName: `/admin/zones/${collection?.shipment?.senderZone?.id}`,
      tag: ShipmentRegion,
      value: collection?.shipment?.senderZone?.name,
      subValue: collection?.shipment?.senderSubzone?.name,
    },
    {
      key: "type",
      tag: FixedTableCell,
      value: collection?.shipment?.type?.name
    },
    {
      key: "paymentType",
      tag: FixedTableCell,
      value: collection?.shipment?.paymentType?.name
    },
    {
      key: "weight",
      tag: FixedTableCell,
      value: collection?.shipment?.weight,
    },
    {
      key: "piecesCount",
      tag: FixedTableCell,
      value: collection?.shipment?.piecesCount,
    },
    {
      key: "description",
      tag: FixedTableCell,
      value: collection.shipment?.description,
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(collection?.collectedAmount),
    },
    {
      key: "originBranch",
      pathName: `/admin/branches/${collection.shipment?.originBranch?.id}`,
      tag: CellLink,
      value: collection.shipment?.originBranch?.name
    },
    {
      key: "currentBranch",
      pathName: `/admin/branches/${collection.shipment?.currentBranch?.id}`,
      tag: CellLink,
      value: collection.shipment?.branch?.name
    },
    {
      key: "status",
      tag: FixedTableCell,
      value: collection.shipment?.status?.name,
    }, {
      key: "date",
      tag: FixedTableCell,
      value: dateFormatLL(collection?.shipment?.date)
    },
    {
      key: "deliveredOrReturnedDate",
      tag: FixedTableCell,
      value: collection?.shipment?.deliveredOrReturnedDate
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value: collection.shipment?.deliveryType?.name ??
        collection.shipment?.status?.name,
    },
    {
      key: "destination",
      tag: FixedTableCell,
      value: collection.shipment?.recipientZone?.name
    },
    {
      key: "totalAmount",
      tag: FixedTableCell,
      value: formatMoney(collection.shipment?.totalAmount),
    },
    {
      key: "deliveredAmount",
      tag: FixedTableCell,
      value: formatMoney(collection.shipment?.deliveredAmount),
    },
    {
      key: "collectedFees",
      tag: FixedTableCell,
      value: formatMoney(collection.shipment?.collectedFees),
    },
    {
      key: "returningDueFees",
      tag: FixedTableCell,
      value: formatMoney(collection.shipment?.returningDueFees),
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(collection.shipment?.collectedAmount),
    },
    {
      key: "commission",
      tag: FixedTableCell,
      value: permission ? formatMoney(collection?.commission) : "",
    }, {
      key: "NetAmount",
      tag: FixedTableCell,
      value: permission ? formatMoney(collection?.collectedAmount - collection?.commission) : ""
    },
    {
      key: "note",
      tag: FixedTableCell,
      value: collection?.shipment?.notes,
    },
    {
      key: "barcode",
      tag: FixedTableCell,
      auto:true,
      value: <div className={classes.barcode}>
        *{collection?.shipment?.code}*
      </div>,
    },
  ]
  let selected = []
  localDataKey.map((e) => {
    const selectBody = body.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0])
    }
    return ""
  })
  return selected
}

const sumTable = (localDataKey, collection, permission, removeShipment) => {
  const sumTableData = [
    {
      key: "totalAmount",
      value: collection?.sumEntriesShipment?.totalAmount,
    },
    {
      key: "weight",
      value: collection?.sumEntriesShipment?.weight,
    },
    {
      key: "piecesCount",
      value: collection?.sumEntriesShipment?.piecesCount,
    },
    {
      key: "collectedFees",
      value: collection?.sumEntriesShipment?.collectedFees,
    },
    {
      key: "returningDueFees",
      value: collection?.sumEntriesShipment?.returningDueFees,
    },
    {
      key: "collectedAmount",
      value: collection?.sumEntriesShipment?.collectedAmount,
    },
    {
      key: "commission",
      value: permission ? collection?.sumEntriesShipment?.commissionAmount : "",
    },
    {
      key: "NetAmount",
      value: permission ? 0 : "",
      calc: {
        fir: "collectedAmount",
        sec: "commission",
        opr: "-"
      },
      clientSum: true
    },
    {
      key: "deliveredAmount",
      value: collection?.sumEntriesShipment?.deliveredAmount,
    },
  ]

  let selected = []
  let ifSum = false
  localDataKey.map((e, index) => {
    const selectBody = sumTableData.filter((ele, i) => ele.key === e)
    if (selectBody[0]) {
      ifSum = true
      selected.push(selectBody[0])
    } else {
      selected.push({
        key: e,
        value: "no",
        noSum: true
      })
    }
    return ""
  })
  removeShipment && selected.push({
    key: "removeShipment",
    value: "no",
    noSum: true
  })
  return ifSum ? selected : []
}

const useCollectionsDetails = ({
  type,
  approved,
  typeData,
  setShipmentData: removeShipment,
  refetchShipmentDataQuery,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCollectionEntries, { loading: deleteCollectionEntriesLoading }] = useMutation(
    gql`
      ${DELETE_COLLECTION_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteShipment = (shipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || shipment.shipment?.pendingDelete}
        onClick={() => {
          removeShipment(prev => {
            const shipments = prev.pickedShipment.map(e => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete: e.shipment.id === shipment.shipment.id ? true : e.shipment.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedShipment: shipments
            }
          })
          deleteCollectionEntries({
            variables: {
              input: {
                collectionId: typeData.id,
                shipmentIds: [shipment.shipment.id]
              }
            }
          }).then(() => {
            refetchShipmentDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deleteCollectionEntriesLoading && shipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );
  const localDataKey = localStorage.getItem(`shipmentcollection${type}`) ? JSON.parse(localStorage.getItem(`shipmentcollection${type}`)) : initialData[type]

  const autoPayCommission_DLVBY = typeData?.deliveryAgent?.autoPayCommission

  const tableBodyCell = (collection, index) => {
    let table = tableBody(localDataKey, collection, autoPayCommission_DLVBY)
    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        {table.map((ele, i) => {
          return ele.subValue ? <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} zone={ele.value}
            subzone={ele.subValue} /> : <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} auto={ele?.auto}>{ele.value}</ele.tag>
        })}
        {removeShipment && deleteShipment(collection)}
      </StyledTableRow>
    );
  };

  const tableHeadCell = (permission) => {
    if (localDataKey) {
      let table = tableHead(localDataKey, permission)
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed]
    }
  }

  const tableHeadCellArr = tableHeadCell(autoPayCommission_DLVBY)

  const tableSum = localDataKey && sumTable(localDataKey, typeData, autoPayCommission_DLVBY, removeShipment)
  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      (k === "commission" || k === "collectedAmount")
        && (tableTotal[k] += shipment[k] ?? " ")
    });
  };

  /* --------------------------------- Manifests Object --------------------------------- */

  const collectionObject = {
    CUSTM: {
      id: "1",
      customer: true,
      label: t("manifestDetails"),
      tableHead: tableHeadCellArr,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
      sumTableNumber,
    },
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: tableHeadCellArr,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
      sumTableNumber,
    },
  };

  return {
    details: collectionObject[type],
  };
};

export default useCollectionsDetails;
