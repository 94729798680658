import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Button, Container, Icon, Collapse } from "@mui/material";
import { useForm } from "react-hook-form";
import { IMPORT_SHIPMENTS_MESSAGES } from "./Graphql";
import { useTranslation } from "react-i18next";
import { useMutation, gql } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import UploadFile from "../HOC/MUI/UploadFile";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { useSnackbar } from "notistack";

const PREFIX = "ImportShipmentsMessages";

const classes = {
  form: `${PREFIX}-form`,
  download: `${PREFIX}-download`,
};

const Root = styled(`div`)(({ theme }) => ({
  [`& .${classes.form}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.download}`]: {
    "&:hover": {
      color: theme.palette.action.active,
    },
  },
}));

const ImportShipmentsMessages = (props) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      shipmentsFile: "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [uploadFile, { loading: importLoading }] = useMutation(
    gql`
      ${IMPORT_SHIPMENTS_MESSAGES.query}
    `
  );

  const onUpload = async (data) => {
    try {
      const { shipmentsFile, fileName, ...restdata } = data;
      for (const key in restdata) {
        if (restdata[key] === "") {
          delete restdata[key];
        }
      }
      const file = shipmentsFile[0];

      await uploadFile({
        variables: {
          input: {
            file,
            ...restdata,
          },
        },
      });
      setValue("shipmentsFile", "");
      setValue("fileName", "");
      enqueueSnackbar(t("successMessagesExport"), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    } catch ({ graphQLErrors }) {
      console.error(graphQLErrors);
      setValue("shipmentsFile", "");
      setValue("fileName", "");
      enqueueSnackbar(graphQLErrors[0].message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    }
  };

  return (
    <Root>
      <TitleAppBar path={props.match.path}></TitleAppBar>
      <Container maxWidth="xl">
        <Paper
          container
          spacing={2}
          sx={{ width: "100%", m: "16px 0" }}
          component={Grid}
        >
          <Grid
            component="form"
            container
            sx={{ width: "100%", m: 0, paddingTop: 0 }}
            className={classes.form}
            onSubmit={handleSubmit(onUpload)}
            justifyContent="flex-start"
          >
            <Grid
              justifyContent="space-between"
              sx={{ flexGrow: 1, display: "flex" }}
              xs={12}
            ></Grid>
            <Grid xs={12}>
              <UploadFile
                control={control}
                name="shipmentsFile"
                setValue={setValue}
                rules={{ required: t("fieldIsRequired") }}
                icon={"file_upload"}
                label={t("excelFile")}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Grid>
            <Grid
              sx={{ display: "flex", mb: 1 }}
              justifyContent="flex-end"
              xs={12}
            >
              <Button
                type="submit"
                disabled={importLoading}
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Icon>file_upload</Icon>}
              >
                {importLoading ? <ButtonLoading /> : t("upload")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Root>
  );
};
export default ImportShipmentsMessages;
