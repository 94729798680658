import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import config from "../../config.json";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Paper,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  AddCircleOutline,
  AttachMoney,
  Done,
  DoneAll,
  Print,
} from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  APPROVE_INVOICES_MUTATION,
  GL_APPROVE_INVOICES_MUTATION,
  INVOICES_BY_ID_QUERY,
  INVOICE_VIEW_SHIPMENT_DATA,
  SAVE_INVOICES_MUTATION,
} from "./InvoicesListQuary";
import ShipmentList from "./InvoicesShipments/ShipmentList";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CustomButton from "../HOC/MUI/CustomButton";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIDate from "../HOC/MUI/MUIDate";
import useInvoicesDetails from "./InvoicesDetails";
import BarcodeSelector from "./InvoicesShipments/BarcodeSelector";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import SubmitInvoice from "./SubmitInvoice";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "InvoicesForm";
const classes = {
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  shipmentList: `${PREFIX}-shipmentList`,
  button: `${PREFIX}-button`,
  titles: `${PREFIX}-titles`,
  root: `${PREFIX}-root`,
  addIcon: `${PREFIX}-addIcon`,
  overlay: `${PREFIX}-overlay`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },
  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { selectedShipment, addShipment, approved, barcode } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.titles}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("shipmentsNumber")} {selectedShipment}
      </Typography>

      <IconButton
        aria-label="Add"
        className={classes.addIcon}
        onClick={addShipment}
        disabled={approved}
        size="large"
      >
        <AddCircleOutline />
      </IconButton>
      {barcode ? (
        <IconButton
          aria-label="Add"
          onClick={barcode}
          disabled={approved}
          size="large"
        >
          <Icon>qr_code_scanner</Icon>
        </IconButton>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selectedShipment: PropTypes.array.isRequired,
};

/////////////////DIALOG///////////////

const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD");

const InvoicesForm = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const eInvoicing = Globals.settings?.eInvoicing;
  const user = Globals.user;
  const invoicesId = props.match.params.id;
  const { enqueueSnackbar } = useSnackbar();
  const screenWidth = useWidth();
  const history = useHistory();
  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const [pathURL, setPathURL] = useState(props.match.path);
  const [disableForm, setDisableForm] = useState(false);
  const [invoicesData, setInvoicesData] = useState();
  const [openDialogTax, setOpenDialogTax] = useState(false);

  const [fieldsState, setfieldsState] = useState({
    createdAt: new Date(),
  });

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    transactionType: null,
  });

  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    glApprove: false,
    data: null,
  });

  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: [],
    noData: true,
  });
  const [state, setState] = useState({
    shipmentList: {
      left: false,
      bottom: false,
      right: false,
    },
  });

  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
  } = useForm();

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChangePage = (newPage) => {
    setShipmentData((prev) => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setShipmentData((prev) => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: event.target.value,
      };
    });
  };

  const shipmentDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      shipmentList: { ...prev.shipmentList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  const handleDialogTaxClose = () => {
    setOpenDialogTax(false);
  };

  const handleDialogTaxOpen = () => {
    setOpenDialogTax(true);
  };

  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };

  const onGlApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, glApprove: true }));
  };

  const parseData = (data) => {
    return data;
  };

  const [saveInvoiceMutation, { loading: saveInvoiceLoad }] = useMutation(
    gql`
      ${SAVE_INVOICES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setInvoicesData(data["saveInvoice"]);
      },
    }
  );

  const [approveInvoiceMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_INVOICES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setInvoicesData(data["approveInvoice"]),
    }
  );

  const [glApproveInvoiceMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_INVOICES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setInvoicesData(data["glApproveInvoice"]),
    }
  );

  const {
    data: invoicesByIdQuery,
    loading: invoicesByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${INVOICES_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !invoicesId,
      variables: {
        id: parseInt(invoicesId ?? watch("id")),
      },
      onCompleted: (data) => {
        if (data?.invoice === null) return;
        const invoices = data?.invoice;
        setInvoicesData(invoices);
        setValue("id", parseInt(invoices?.id));
        setValue("code", invoices?.code);
        setValue("date", invoices?.date);
        invoices?.notes && setValue("notes", invoices?.notes);

        setfieldsState((prev) => ({
          ...prev,
          createdAt: invoices?.date,
        }));

        setAutocompleteValues({
          branch: invoices.branch,
          ...(invoices?.customer && {
            customer: invoices?.customer,
          }),
          transactionType: invoices.transactionType,
        });

        shipmentData.noData && getShipmentData();
        setShipmentData((prev) => ({
          ...prev,
          total: data.invoice.entries.paginatorInfo.total,
        }));
        setDisableForm(true);
      },
    }
  );

  const [
    getShipmentData,
    { loading: loadingShipmentData, refetch: refetchShipmentDataQuery },
  ] = useLazyQuery(
    gql`
      ${INVOICE_VIEW_SHIPMENT_DATA().query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",

      variables: {
        id: parseInt(watch("id")),
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage,
      },
      onCompleted: (data) => {
        if (data?.invoice === null) return;
        const invoice = data?.invoice;
        const shipments = invoice.entries.data.map((e) => {
          return {
            ...e,
            shipment: {
              ...e.shipment,
              pendingDelete: false,
            },
          };
        });
        setShipmentData((prev) => {
          return {
            ...prev,
            pickedShipment: shipments,
            total: invoice.entries.paginatorInfo.total,
            noData: false,
          };
        });
        refetch();
      },
    }
  );

  const { details: invoicesDetails } = useInvoicesDetails({
    TotalData: invoicesData,
    approved: !invoicesData?.approved,
    shipmentData,
    saveInvoiceLoad,
    setShipmentData,
    refetchShipmentDataQuery,
  });

  const shipLength = shipmentData.pickedShipment.length === 0;
  ////////////////////END DRAWER////////////////

  const saveCollectionMutationFun = (data, collectionRefetch) => {
    saveInvoiceMutation({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("code", data?.data?.saveInvoice?.code);
        setValue("id", parseInt(data?.data?.saveInvoice?.id));
        const url = history.createHref({
          pathname: `/admin/invoices/${data?.data?.saveInvoice?.id}/edit`,
        });
        setPathURL(url);
        windowReplaceUrl(url);
        collectionRefetch && collectionRefetch();
        collectionRefetch && refetchShipmentDataQuery();
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      });
  };

  const onSubmit = (data) => {
    const formChange =
      invoicesData &&
      ((data?.customerId &&
        data?.customerId !== parseInt(invoicesData?.customer?.id)) ||
        (data?.branchId &&
          data?.branchId !== parseInt(invoicesData?.branch?.id)));
    data["date"] = dateFormat(data["date"]);
    setOpenDialog((prev) => ({ ...prev, data: data }));
    if (shipmentData.pickedShipment.length !== 0 && formChange) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true }));
    } else {
      saveCollectionMutationFun(data);
    }
  };

  const approveInvoice = () => {
    approveInvoiceMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("approvedErrorMessage"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        if (!(user.hasPermission("shipping.invoice.submit") && eInvoicing)) {
          pushUrl(props, `/admin/invoices/${watch("id")}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const glApproveInvoice = () => {
    glApproveInvoiceMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/invoice/${watch("id")}`
        ),
      icon: Print,
      disabled: invoicesByIdLoad,
      permission: !shipLength,
    },
  ];

  let formBody = null;
  let tableBody = null;
  /////////////////////// Customer Shipments Table /////////////////////////////

  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          selectedShipment={shipmentData.total}
          addShipment={() => shipmentDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={invoicesData?.approved}
        />
        <TableFixedHeaderWraper>
          {loadingShipmentData ? (
            <FullScreenLoading minHeight={"100px"} />
          ) : (
            invoicesDetails && (
              <ShipmentsTable
                data={shipmentData.pickedShipment}
                headCells={invoicesDetails.tableHead}
                parseBodyCell={invoicesDetails.tableBody}
                total={invoicesDetails.tableTotal}
                sumTableNumber={invoicesDetails?.sumTableNumber}
                sum={true}
                ranking={true}
              />
            )
          )}
        </TableFixedHeaderWraper>
        {shipmentData.pickedShipment.length !== 0 && (
          <MUITablePagination
            count={shipmentData?.total}
            rowsPerPage={shipmentData.rowsPerPage}
            page={shipmentData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestFirst")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {shipmentData.pickedShipment.length === 0 &&
            watch("id") &&
            disableForm && (
              // <Box textAlign="center" marginTop="50px" marginBottom="50px">
              //   <img width="50%" src={emptyList} alt={"delivery box"} />
              //   <Box
              //     component={Typography}
              //     variant="h6"
              //     color="text.secondary"
              //     marginTop="0"
              //   >
              //     {t("noShipments")}
              //   </Box>
              // </Box>
              <EmptyTableMessage message={t("noResult")} />
            )}
        </Grid>
      </Paper>
    </Grid>
  );

  formBody = (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      justifyContent={"center"}
      sx={{ width: "100%" }}
    >
      {!invoicesByIdLoad ? (
        <Paper component={Stack} spacing={1} p={1}>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !invoicesData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {invoicesData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(invoicesData?.approved || disableForm) && (
              <Box className={classes.overlay}></Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDate
                name="date"
                label={t("date")}
                control={control}
                defaultValue={invoicesByIdQuery?.invoice?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setfieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  setValue("customerId", "");
                  setValue("transactionTypeId", "");
                  // setPickedShipment({ shipments: [] })
                }}
                skipDefaultBranch={Boolean(invoicesId)}
              />
            </Grid>
            <Grid sm={6} xs={12}>
              <CustomAutocomplete
                control={control}
                name={"customerId"}
                label={t("customer")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_CUSTOMERS_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    withDue: "INVOICE",
                    branchId: {
                      value: watch("branchId"),
                      includeNull: true,
                    },
                  },
                }}
                defaultValue={autocompleteValues.customer}
              />
            </Grid>
            <Grid sm={6} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"transactionTypeId"}
                label={t("transactionType")}
                selectFirst={true}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    type: "SHPINV",
                    ...(watch("branchId") && {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>

            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                disabled={
                  invoicesData?.approved || disableForm || saveInvoiceLoad
                }
                variant="contained"
                size="medium"
                loading={saveInvoiceLoad}
                // className={classes.button}
                startIcon={!saveInvoiceLoad && <Done />}
              >
                {!saveInvoiceLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  shipmentData.pickedShipment.length === 0 ||
                  invoicesData?.approved ||
                  saveInvoiceLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {invoicesData?.approved && (
              <CustomButton
                className={classes.button}
                disabled={invoicesData?.glApproved}
                variant="contained"
                size="medium"
                onClick={() => onGlApproved()}
                customcolor={theme.palette.info.main}
                loading={glApproveLoad}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </CustomButton>
            )}

            {user.hasPermission("shipping.invoice.submit") && eInvoicing && (
              <CustomButton
                className={classes.button}
                disabled={
                  !invoicesData?.glApproved ||
                  (invoicesData?.etaDocumentDetails?.id &&
                    ["VALID", "SUBMITTED"].includes(
                      invoicesData?.etaDocumentDetails?.status.code
                    ))
                }
                variant="contained"
                size="medium"
                onClick={() => handleDialogTaxOpen()}
                customcolor={theme.palette.info.main}
                loading={false}
                name="sendToETA"
              >
                {invoicesData?.etaDocumentDetails
                  ? t("resendToETA")
                  : t("sendToETA")}
              </CustomButton>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Box>
  );

  return invoicesByIdLoad ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !invoicesByIdQuery?.invoice && invoicesId ? (
    <NotFound />
  ) : (
    <Root>
      {openDialogTax && (
        <SubmitInvoice
          {...props}
          open={openDialogTax}
          handleClose={handleDialogTaxClose}
          etaDocument={invoicesData?.etaDocument}
          invoicesId={invoicesData.id}
        />
      )}

      <TitleAppBar path={pathURL}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <Dialog
        open={openDialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title" color={"text.primary"}>
          {openDialog.edit && t("updateRecord")}
          {openDialog.approve && t("approveRecord")}
          {openDialog.glApprove && t("glApprove")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDialog.edit && t("updateRecordMessage")}
            {openDialog.approve && t("approveRecordConfirmationMessage")}
            {openDialog.glApprove && t("glApproveRecordMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            disabled={approveLoad || glApproveLoad}
            onClick={() => {
              if (openDialog.edit) {
                handleDialogClose();
                setDisableForm(true);
                setShipmentData((prev) => {
                  return {
                    ...prev,
                    pickedShipment: [],
                  };
                });
                saveCollectionMutationFun(openDialog.data, refetch);
              }
              openDialog.approve && approveInvoice();
              openDialog.glApprove && glApproveInvoice();
            }}
            color="primary"
            autoFocus
          >
            {openDialog.edit && t("update")}
            {openDialog.approve && !approveLoad && t("approve")}
            {openDialog.glApprove && !glApproveLoad && t("glApprove")}
            {(approveLoad || glApproveLoad) && <ButtonLoading />}
          </Button>
        </DialogActions>
      </Dialog>

      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state.shipmentList[anchor]}
        onClose={() => false}
        onOpen={() => shipmentDrawerHandler(anchor, true)}
        classes={{ paper: classes.shipmentList }}
        dir={theme.direction}
      >
        {state.shipmentList[anchor] && (
          <ShipmentList
            selected={shipmentData.pickedShipment}
            shipmentData={shipmentData}
            done={() => shipmentDrawerHandler(anchor, false)}
            variables={watch()}
            loadingShipmentData={loadingShipmentData}
            invoiceRefetch={refetch}
            refetchShipmentDataQuery={refetchShipmentDataQuery}
          />
        )}
      </SwipeableDrawer>

      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={barcodeDarwerState[anchor]}
        onClose={() => false}
        onOpen={() => barcodeDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },
            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme.direction}
      >
        {barcodeDarwerState[anchor] && (
          <BarcodeSelector
            setSelected={(obj) => {
              setShipmentData((prev) => {
                return {
                  ...prev,
                  pickedShipment: obj,
                };
              });
            }}
            selected={shipmentData.pickedShipment}
            invoiceRefetch={refetch}
            refetchShipmentDataQuery={refetchShipmentDataQuery}
            done={() => barcodeDrawerHandler(anchor, false)}
            variables={watch()}
          />
        )}
      </SwipeableDrawer>

      <Stack spacing={2} margin={2}>
        {props.children}
        {formBody}
        {!invoicesByIdLoad && tableBody}
      </Stack>
    </Root>
  );
};

export default withRouter(InvoicesForm);
