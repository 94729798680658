/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Icon,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { Add, FilterList, Print, Search } from "@mui/icons-material";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import ExportInvoices from "./ExportInvoices";
import { LIST_INVOICES_QUERY } from "./InvoicesListQuary";
import formatMoney from "../../helpers/numbersDot";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import Grid from '@mui/material/Unstable_Grid2';
import config from "../../config.json";
const PREFIX = "InvoicesList";
const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column",
  },
}));

//////////////////////////////////////////////////
function EnhancedTableHead(props) {
  //*********Table Function*********
  const { t } = useTranslation();
  const headCells = [
    { numeric: true, disablePadding: false, label: t("recordCode") },
    { numeric: true, disablePadding: false, label: t("date") },
    {
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    },
    { numeric: true, disablePadding: false, label: t("shipmentsNumber") },
    { numeric: true, disablePadding: false, label: t("pieceCount") },
    { numeric: true, disablePadding: false, label: t("weight") },
    { numeric: true, disablePadding: false, label: t("deliveryFees") },
    { numeric: true, disablePadding: false, label: t("postFees") },
    { numeric: true, disablePadding: false, label: t("tax") },
    { numeric: true, disablePadding: false, label: t("value") },
    { numeric: true, disablePadding: false, label: t("approved") },
    { numeric: true, disablePadding: false, label: t("glApproved") },
    { numeric: true, disablePadding: false, label: t("createdBy") },
    { numeric: true, disablePadding: false, label: t("updatedBy") },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <FixedTableCell
              key={index}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////
const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const InvoicesList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const screenWidth = useWidth();
  const history = useHistory();
  const { t } = useTranslation(["translation", "validation"]);
  const [refetch, setrefetch] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const validUrlParameters =
    Object.keys(urlQuery).length !== 0 &&
    !Object.keys(urlQuery).includes("fromDate", "toDate");

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters
      ? null
      : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters
      ? null
      : initDate;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

  const [openExport, setOpenExport] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(config.app.pageSize);
  const [page, setPage] = useState(0);
  const [queryVar, setQuaryVar] = useState(null);

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [autocompleteValues, setAutocompleteValues] = useState({
    deliveryAgent: null,
    customer: null,
    toZone: null,
    toSubzone: null,
    fromZone: null,
    fromSubzone: null,
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };
  const parseData = (data) => {
    return data;
  };

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param) => {
    const params = searchParams(param);
    const url = history.createHref({
      pathname: `/admin/invoices`,
      search: "?" + params,
    });
    windowUrl(url);
  };

  useEffect(() => {
    const { first, page, ...input } = urlQuery;
    if (input["code"]) input["code"] = input["code"].toString();
    if (input["fromCode"]) input["fromCode"] = input["fromCode"].toString();
    if (input["toCode"]) input["toCode"] = input["toCode"].toString();
    if (input["toWeight"] !== undefined)
      input["toWeight"] = parseFloat(input["toWeight"]);
    if (input["fromWeight"] !== undefined)
      input["fromWeight"] = parseFloat(input["fromWeight"]);
    setQuaryVar((prev) => ({
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      ...prev,
      ...input,
      // type: listType.toUpperCase(),
    }));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());
    urlQuery["fromCode"] &&
      setValue("fromCode", urlQuery["fromCode"].toString());
    urlQuery["toCode"] && setValue("toCode", urlQuery["toCode"].toString());
    urlQuery["toWeight"] !== undefined &&
      setValue("toWeight", parseFloat(urlQuery["toWeight"]));
    urlQuery["fromWeight"] !== undefined &&
      setValue("fromWeight", parseFloat(urlQuery["fromWeight"]));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    urlQuery["first"] && setRowsPerPage(parseInt(urlQuery["first"]));
    urlQuery["page"] && setPage(parseInt(urlQuery["page"] - 1));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...urlQuery, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    pushUrlSearch({ ...urlQuery, page: 1, first: +event.target.value });
  };

  const onPrint = (param) => {
    const params = searchParams(queryVar);
    const domain = `${window.location.origin}`;
    window.open(`${domain}${param}?${params}`);
  };

  const addURL = () => {
    pushUrl(props, `/admin/invoices/create`);
  };

  const openExportDialog = () => {
    setOpenExport(true);
  };

  const onSubmit = (data) => {
    refetch ? setrefetch(false) : setrefetch(true);
    setFilterLoading(false);
    data["fromDate"] = dateRange[0] && fromDate;
    data["toDate"] = dateRange[1] && toDate;
    if (data["fromWeight"]) {
      data["fromWeight"] = parseFloat(data["fromWeight"]);
    }
    if (data["toWeight"]) {
      data["toWeight"] = parseFloat(data["toWeight"]);
    }
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    pushUrlSearch({
      ...data,
      first: rowsPerPage,
      page: 1,
    });
    setQuaryVar({
      ...data,
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      // type: listType.toUpperCase(),
    });
    setPage(0);
  };

  // List Query
  const { data, loading } = useQuery(
    gql`
      ${LIST_INVOICES_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !queryVar,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...queryVar,
        },
      },
      onCompleted: (data) => { },
    }
  );

  const invoicesData =
    data?.listInvoices?.data !== null ? data?.listInvoices?.data : null;
  const sumInvoices = data?.sumInvoices;
  const emptyInvoiceList = data?.listInvoices?.data?.length === 0;

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: "shipping.invoice.create",
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: !data || Boolean(data?.listInvoices?.data?.length === 0),
    },
    {
      id: "printList",
      title: "printInvoicesList",
      action: () => onPrint("/invoices/print"),
      icon: Print,
      disabled: emptyInvoiceList,
    },
    {
      id: "print",
      title: "printInvoicesDetails",
      action: () => onPrint(`/report/print/invoice/n`),
      icon: Print,
      disabled: emptyInvoiceList,
    },
  ];

  const EnhancedTableToolbar = (props) => {
    const { exportFilters } = props;
    return (
      <ExportInvoices
        filters={exportFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
    );
  };

  EnhancedTableToolbar.propTypes = {};

  return (
    <Root>
      {props.children}
      <EnhancedTableToolbar
        filter={toggleDrawer(filterAnchor, true)}
        exportFilters={queryVar}
        {...props}
      />
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={invoicesData?.length === 0}
        loading={!queryVar || loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item xs={12}>
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  name={"approved"}
                  label={t("status")}
                  data={[
                    { key: t("approved"), value: true },
                    { key: t("notApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomAutocomplete
                  name={"branchId"}
                  label={t("currentBranch")}
                  control={control}
                  errors={errors}
                  skip={!queryVar || (loading && filterLoading)}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  type={"number"}
                  name={"fromWeight"}
                  label={t("fromWeight")}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  type={"number"}
                  name={"toWeight"}
                  label={t("toWeight")}
                  rules={{
                    ...(watch("fromWeight") && {
                      min: {
                        value: watch("fromWeight"),
                        message: t("validation:min", {
                          field: t("weight"),
                          number: watch("fromWeight"),
                        }),
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"fromCode"}
                  label={t("fromCode")}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"toCode"}
                  label={t("toCode")}
                />
              </Grid>
              <Grid item xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  name={"glApproved"}
                  label={t("glApprove")}
                  data={[
                    { key: t("glApproved"), value: true },
                    { key: t("notGlApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={!queryVar || (loading && filterLoading)}
                  name={"customerId"}
                  label={t("customer")}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["customerId"],
                      "customer"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  variables={{
                    input: {
                      active: true,
                      typeCode: ["MERCHANT"],
                    },
                  }}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={!queryVar || (loading && filterLoading)}
                  name={"fromZoneId"}
                  label={t("fromZone")}
                  onChangeValue={(e) => {
                    setValue("fromSubzoneId", "");
                    delete urlQuery["fromSubzoneId"];

                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromZoneId"],
                      "fromZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromZone}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"fromSubzoneId"}
                  label={t("fromSubzone")}
                  skip={!watch("fromZoneId")}
                  disabled={!watch("fromZoneId")}
                  variables={{ input: { parentId: watch("fromZoneId") } }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromSubzoneId"],
                      "fromSubzone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromSubzone}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={!queryVar || (loading && filterLoading)}
                  name={"toZoneId"}
                  label={t("toZone")}
                  onChangeValue={(e) => {
                    delete urlQuery["toSubzoneId"];
                    setValue("toSubzoneId", "");

                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toZoneId"],
                      "toZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toZone}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"toSubzoneId"}
                  label={t("toSubzone")}
                  skip={!watch("toZoneId")}
                  disabled={!watch("toZoneId")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toSubzoneId"],
                      "toSubzone"
                    )
                  }
                  variables={{ input: { parentId: watch("toZoneId") } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toSubzone}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={<EnhancedTableHead />}
        tableBody={
          <TableBody>
            {invoicesData &&
              invoicesData?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink
                      pathname={`/admin/invoices/${row?.id}`}
                      component="th"
                      scope="row"
                    >
                      {row.code}
                    </CellLink>
                    <FixedTableCell>
                      {moment(row?.date).format("DD-MM-YYYY")}
                    </FixedTableCell>
                    {row?.customer ? (
                      <CellLink
                        pathname={`/admin/customers/${row?.customer?.id}`}
                      >
                        <Box
                          component="span"
                          fontWeight="bold"
                        >{` (${row.customer.code}) `}</Box>
                        <Box component="span">{row.customer.name}</Box>
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}

                    <FixedTableCell>
                      {row?.entries?.paginatorInfo?.total}
                    </FixedTableCell>
                    <FixedTableCell>{row?.sumEntries?.pieces}</FixedTableCell>
                    <FixedTableCell>{row?.sumEntries?.weight}</FixedTableCell>
                    <FixedTableCell>
                      {formatMoney(row?.sumEntries?.deliveryFees)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {formatMoney(row?.sumEntries?.postFees)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {formatMoney(row?.sumEntries?.tax)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {formatMoney(row?.sumEntries?.allFees)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.glApproved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <CellLink
                      align="left"
                      pathname={`/admin/users/${row?.createdBy?.id}`}
                    >
                      {row?.createdBy?.username}
                    </CellLink>
                    <CellLink
                      align="left"
                      pathname={`/admin/users/${row?.updatedBy?.id}`}
                    >
                      {row?.updatedBy?.username}
                    </CellLink>
                  </TableRow>
                );
              })}
            <TableRow>
              <FixedTableCell colSpan={2} />
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              <FixedTableCell variant="head">
                {sumInvoices?.shipmentsCount}
              </FixedTableCell>
              <FixedTableCell variant="head">
                {sumInvoices?.piecesCount}
              </FixedTableCell>
              <FixedTableCell variant="head">
                {sumInvoices?.weight}
              </FixedTableCell>
              <FixedTableCell variant="head">
                {formatMoney(sumInvoices?.deliveryFees)}
              </FixedTableCell>
              <FixedTableCell variant="head">
                {formatMoney(sumInvoices?.postFees)}
              </FixedTableCell>
              <FixedTableCell variant="head">
                {formatMoney(sumInvoices?.tax)}
              </FixedTableCell>
              <FixedTableCell variant="head">
                {formatMoney(sumInvoices?.allFees)}
              </FixedTableCell>
              <FixedTableCell colSpan={4} />
            </TableRow>
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listInvoices?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listInvoices ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default InvoicesList;
