import * as gqlb from "gql-query-builder";

export const LIST_INVOICES_QUERY = gqlb.query([
  {
    operation: "listInvoices",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "code",
          "id",
          "date",
          "approved",
          "glApproved",
          "notes",
          {
            operation: "transactionType",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "sumEntries",
            fields: [
              "weight",
              "pieces",
              "deliveryFees",
              "postFees",
              "tax",
              "allFees",
            ],
            variables: {},
          },
          {
            operation: `entries`,
            fields: [
              {
                paginatorInfo: ["total"],
              },
            ],
            variables: {},
          },
          {
            customer: ["id", "name", "code"],
          },
          {
            updatedBy: ["username", "id"],
          },
          {
            createdBy: ["username", "id"],
          },
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListInvoicesFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
        value: 15,
      },
      page: {
        type: "Int",
        value: 1,
      },
    },
  },
  {
    operation: "sumInvoices",
    fields: [
      "shipmentsCount",
      "piecesCount",
      "weight",
      "deliveryFees",
      "postFees",
      "tax",
      "allFees",
    ],
    variables: {
      input: {
        type: "ListInvoicesFilterInput",
        required: true,
      },
    },
  },
]);

export const invoiceFields = [
  "id",
  "date",
  "code",
  "approved",
  "glApproved",
  "notes",
  {
    operation: "sumEntries",
    fields: ["weight", "pieces", "deliveryFees", "postFees", "tax", "allFees"],
    variables: {},
  },
  { etaDocument: ["document", "issuedAt"] },
  {
    etaDocumentDetails: [
      "dateTimeIssued",
      "publicUrl",
      "id",
      "code",
      { status: ["code", "name", "active"] },
    ],
  },
  {
    customer: [
      "id",
      "code",
      "name",
      "mobile",
      "phone",
      "address",
      { zone: ["name"] },
      { subzone: ["name"] },
    ],
  },
  {
    operation: "branch",
    fields: ["id", "name"],
    variables: {},
  },
  {
    transactionType: ["id", "code", "name", { type: ["name", "code"] }],
  },
  {
    operation: `entries`,
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  },
];

export const INVOICES_BY_ID_QUERY = gqlb.query({
  operation: "invoice",
  fields: invoiceFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const INVOICES_PRINT = gqlb.query([
  {
    operation: "invoice",
    fields: invoiceFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
      "registrationNumber",
      "taxNumber",
    ],
    variables: {},
  },
]);

export const INVOICES_VIEW = (permission, shipmentFiled) =>
  gqlb.query([
    {
      operation: "invoice",
      fields: [
        "id",
        "date",
        "code",
        "approved",
        "glApproved",
        "notes",
        {
          etaDocumentDetails: [
            "dateTimeIssued",
            "publicUrl",
            "id",
            "code",
            { status: ["code", "name", "active"] },
          ],
        },
        {
          operation: "sumEntries",
          fields: [
            "weight",
            "pieces",
            "deliveryFees",
            "postFees",
            "tax",
            "allFees",
          ],
          variables: {},
        },
        {
          customer: [
            "id",
            "code",
            "name",
            "mobile",
            "phone",
            "address",
            { zone: ["name"] },
            { subzone: ["name"] },
          ],
        },
        {
          operation: `journalEntry @include(if: ${permission})`,
          fields: ["code", "id"],
          variables: {},
        },
        {
          operation: "branch",
          fields: ["id", "name"],
          variables: {},
        },
        {
          transactionType: ["id", "code", "name", { type: ["name", "code"] }],
        },
        {
          operation: `entries`,
          fields: [
            {
              operation: "paginatorInfo",
              fields: ["total"],
              variables: {},
            },
          ],
          variables: {
            first: {
              type: "Int",
            },
            page: {
              type: "Int",
            },
          },
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);

export const INVOICE_VIEW_SHIPMENT_DATA = (shipmentQueryFields) =>
  gqlb.query([
    {
      operation: "invoice",
      fields: [
        {
          operation: `entries`,
          fields: [
            {
              operation: `paginatorInfo`,
              fields: [
                "count",
                "currentPage",
                "total",
                "firstItem",
                "hasMorePages",
                "lastItem",
                "lastPage",
                "perPage",
              ],
              variables: {},
            },
            {
              operation: `data`,
              fields: [
                "deliveryFees",
                "postFees",
                "tax",
                "allFees",
                {
                  operation: `shipment`,
                  fields: [
                    "id",
                    "code",
                    ...(shipmentQueryFields
                      ? shipmentQueryFields
                      : [
                        "notes",
                        "recipientName",
                        "description",
                        "totalAmount",
                        "deliveredAmount",
                        "recipientAddress",
                        "collectedFees",
                        "returningDueFees",
                        "customerDue",
                        "piecesCount",
                        "weight",
                        "date",
                        "senderName",
                        "senderMobile",
                        "recipientMobile",
                        "recipientPhone",
                        "senderPhone",
                        "senderAddress",
                        "deliveredOrReturnedDate",
                        {
                          senderZone: ["id", "name"],
                        },
                        {
                          senderSubzone: ["name"],
                        },
                        {
                          recipientZone: ["id", "name"],
                        },
                        {
                          recipientSubzone: ["name"],
                        },
                        {
                          deliveryType: ["name"],
                        },
                        {
                          recipientSubzone: ["name"],
                        },
                        {
                          customer: ["name"],
                        },
                        {
                          status: ["name"],
                        },
                        { branch: ["id", "name"] },
                        { originBranch: ["id", "name"] },
                      ]),
                  ],
                  variables: {},
                },
              ],
              variables: {},
            },
          ],
          variables: {
            first: {
              type: "Int",
            },
            page: {
              type: "Int",
            },
          },
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);

export const SAVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "saveInvoice",
  fields: invoiceFields,
  variables: {
    input: {
      type: "InvoiceInput",
      required: true,
    },
  },
});

export const SAVE_INVOICE_ENTRIES_MUTATION = gqlb.mutation({
  operation: "createInvoiceEntry",
  fields: [
    {
      invoice: ["id"],
    },
  ],
  variables: {
    input: {
      type: "InvoiceEntryInput",
      required: true,
    },
  },
});

export const SAVE_SUBMIT_INVOICE_MUTATION = gqlb.mutation({
  operation: "submitInvoice",
  fields: [
    "id",
    {
      etaDocumentDetails: [
        "dateTimeIssued",
        "publicUrl",
        "id",
        "code",
        { status: ["code", "name", "active"] },
      ],
    },
  ],
  variables: {
    input: {
      type: "EtaDocumentInput",
      required: true,
    },
  },
});

export const DELETE_INVOICES_MUTATION = gqlb.mutation({
  operation: "deleteInvoice",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELETE_INVOICES_ENTRIES_MUTATION = gqlb.mutation({
  operation: "deleteInvoiceEntries",
  fields: [],
  variables: {
    input: {
      type: "DeleteInvoiceEntryInput",
      required: true,
    },
  },
});

export const APPROVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "approveInvoice",
  fields: invoiceFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DISAPPROVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "disapproveInvoice",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const GL_APPROVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "glApproveInvoice",
  fields: invoiceFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const invoiceEntriesFieldsPrint = [
  "deliveryFees",
  "postFees",
  "tax",
  "allFees",
  {
    operation: `shipment`,
    fields: [
      "id",
      "code",
      "notes",
      "recipientName",
      "description",
      "totalAmount",
      "deliveredAmount",
      "recipientAddress",
      "collectedFees",
      "returningDueFees",
      "customerDue",
      "piecesCount",
      "weight",
      "date",
      "senderName",
      "senderMobile",
      "recipientMobile",
      "recipientPhone",
      "senderPhone",
      "senderAddress",
      "deliveredOrReturnedDate",
      {
        senderZone: ["id", "name"],
      },
      {
        senderSubzone: ["name"],
      },
      {
        recipientZone: ["id", "name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        deliveryType: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        customer: ["name"],
      },
      {
        status: ["name"],
      },
      { branch: ["id", "name"] },
      { originBranch: ["id", "name"] },
    ],
    variables: {},
  },
];
// with print only
export const invoiceAllEntries = {
  allEntries: invoiceEntriesFieldsPrint,
};

export const shipmentInvoice = [
  { key: "code", name: "code", label: "requestCode" },
  { name: "id", key: "allFees", label: "returningDueFees" },
  { name: "senderPhone", key: "tax", label: "tax" },
  { name: { customer: ["name"] }, key: "postFees", label: "postFees" },
  { name: { status: ["name"] }, key: "deliveryFees", label: "deliveryFees" },
  { name: "recipientName", key: "recipient", label: "recipient" },
  {
    name: "recipientAddress",
    key: "recipientAddress",
    label: "recipientAddress",
  },
  {
    name: "deliveredOrReturnedDate",
    key: "deliveredOrReturnedDate",
    label: "deliveryCanclingDate",
  },
  {
    name: { originBranch: ["id", "name"] },
    key: "originBranch",
    label: "originBranch",
  },
  {
    name: { branch: ["id", "name"] },
    key: "currentBranch",
    label: "currentBranch",
  },
  { name: "senderName", key: "sender", label: "senderName" },
  { name: "senderAddress", key: "senderAddress", label: "senderAddress" },
  { name: "weight", key: "weight", label: "weight" },
  { name: "piecesCount", key: "piecesCount", label: "pieceCount" },
  { name: "date", key: "date", label: "date" },
  {
    name: { recipientZone: ["id", "name"] },
    key: "recipientZone",
    label: "destination",
  },
  { name: "description", key: "description", label: "packageDescription" },
  { name: "notes", key: "note", label: "notes" },
  { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
  { name: "", key: "barcode", label: "barcode" },
]
export const invoice = [
  "code",
  "date",
  "recipient",
  "recipientZone",
  "sender",
  "senderZone",
  "weight",
  "piecesCount",
  "deliveryFees",
  "postFees",
  "tax",
  "allFees",
]
