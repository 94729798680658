import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Icon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import clsx from "clsx";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { LIST_SUBSIDIARIES } from "./Graphql";
import { useTranslation } from "react-i18next";
import SubsidiaryForm from "./SubsidiaryForm";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";

const PREFIX = 'SubsidiariesList';

const classes = {
  iconColor: `${PREFIX}-iconColor`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

const SubsidiariesList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [subsidiaryObject, setSubsidiaryObject] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSubsidiaryObject();
  };
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_SUBSIDIARIES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => { },
    }
  );

  const listSubsidiaries = data?.listSubsidiaries?.data;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  let tableBody;

  const icons = [
    {
      id: "add",
      title: "createNew",
      action: () => handleClickOpen(),
      icon: Add,
    },
  ]

  tableBody = (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      className={classes.mainContainer}
    >
      {/* *******Table******* */}
      <Paper className={clsx(classes.paper)}>
        <TitleAppBar path={props.match.path} >
          <LongMenu icons={icons} />
        </TitleAppBar>

        {loading || listSubsidiaries?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight={30} />
            ) : (
              <EmptyTableMessage
                loading={false}
                message={t("searchNoResult")}
              />
            )}
          </Grid>
        ) : (
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("name")}</FixedTableCell>
                  <FixedTableCell>{t("code")}</FixedTableCell>
                  <FixedTableCell>{t("active")}</FixedTableCell>
                </TableRow>
              </TableHead>

            </Table>
          </TableFixedHeaderWraper>
        )}

      </Paper>
    </Grid>
  );

  return (
    <Root>
      {open && (
        <SubsidiaryForm
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          subsidiaryObject={subsidiaryObject}
        />
      )}
      <ListWrapper
        drawerState={false}
        icons={icons}
        path={props.match.path}
        empty={listSubsidiaries?.length === 0}
        loading={loading}
        filters={false}
        tableHeaders={
          [
            {
              name: "name"
            },
            {
              name: "code"
            },
            {
              name: "active"
            },
          ]
        }
        tableBody={
          <TableBody>
            {listSubsidiaries &&
              listSubsidiaries?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink
                      onClick={() => {
                        handleClickOpen();
                        setSubsidiaryObject(row);
                      }}
                    >
                      {row?.name}
                    </CellLink>

                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listSubsidiaries?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listSubsidiaries ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default SubsidiariesList;
