import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import {
  Button,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUIDate from "../HOC/MUI/MUIDate";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_ITEMS } from "./Graphql";
import config from "../../config.json";

const PREFIX = "EInvoiceList";

const classes = {
  button: `${PREFIX}-button`,
  iconColor: `${PREFIX}-iconColor`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  tablePaper: `${PREFIX}-tablePaper`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    zIndex: "250",
    height: "100%",
    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.tablePaper}`]: {
    borderRadius: 0,
    width: "100%",
    display: "grid",
  },

  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },
}));

const drawerWidth = 240;

const stringFormatToDate = (date) =>
  date ? moment(date, "YYYY-MM-DD").toDate() : null;
const dateFormat = (date) =>
  date ? moment(date).locale("en").format("YYYY-MM-DD") : null;

const EInvoiceList = (props) => {
  const { t } = useTranslation(["translation", "validation"]);
  const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [dateState, setDateState] = useState({
    activeFrom: stringFormatToDate(urlQuery["activeFrom"]) ?? null,
    activeTo: stringFormatToDate(urlQuery["activeTo"]) ?? null,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);

    return () => {};
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/e-invoice",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });

    const activeFrom = dateFormat(dateState.activeFrom);

    const activeTo = dateFormat(dateState.activeTo);

    let handledData = {
      name: data.name,
      code: data.code,
      description: data.description,
      parentCode: data.parentCode,
      statusCode: data.statusCode,
      active: data.active,
      activeFrom,
      activeTo,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if ([undefined, "", null].includes(handledData[key])) {
        delete handledData[key];
      }
    }

    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_ITEMS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.name && { name: search.name }),
          ...(search.code && { code: search.code }),
          ...(search.description && { description: search.description }),

          ...(search.parentCode && { parentCode: search.parentCode }),
          ...(search.statusCode && { statusCode: search.statusCode }),
          ...(search.activeFrom && { activeFrom: search.activeFrom }),
          ...(search.activeTo && { activeTo: search.activeTo }),
          ...(search.active !== undefined && { active: search.active }),
        },
      },
      onCompleted: (data) => {},
    }
  );
  const eInvoiceList =
    data?.listItems?.data !== null ? data?.listItems?.data : null;

  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <div className={classes.drawerHeader} />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="space-between"
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.searchForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.searchField}
          >
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"name"}
                label={t("name")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"description"}
                label={t("description")}
              />
            </Grid>

            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"parentCode"}
                label={t("parentCode")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"statusCode"}
                label={t("status")}
              />
            </Grid>

            <Grid container item sm={12} alignItems="flex-start">
              <MUIDate
                clearable
                readOnly={false}
                name="activeFrom"
                label={t("activeFrom")}
                control={control}
                value={dateState.activeFrom}
                rules={{
                  validate: {
                    max: (value) =>
                      value > stringFormatToDate(watch("activeTo"))
                        ? t("validation:lt", {
                            field1: t("activeFrom"),
                            field2: t("activeTo"),
                          })
                        : true,
                  },
                }}
                onChange={(e) =>
                  setDateState((prev) => ({ ...prev, activeFrom: e }))
                }
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <MUIDate
                clearable
                readOnly={false}
                name="activeTo"
                label={t("activeTo")}
                control={control}
                value={dateState.activeTo}
                rules={{
                  validate: {
                    max: (value) =>
                      value < stringFormatToDate(watch("activeFrom"))
                        ? t("validation:mt", {
                            field1: t("activeTo"),
                            field2: t("activeFrom"),
                          })
                        : true,
                  },
                }}
                onChange={(e) =>
                  setDateState((prev) => ({ ...prev, activeTo: e }))
                }
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <MUIselect
                name={"active"}
                label={t("active")}
                control={control}
                errors={errors}
                data={[
                  { key: t("notActive"), value: false },
                  { key: t("active"), value: true },
                ]}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            className={clsx(classes.searchPadding, classes.button)}
            alignItems="center"
            alignContent="flex-end"
            justifyContent="flex-end"
          >
            <Button
              className={clsx(classes.btnMargin)}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              startIcon={<Search />}
              disabled={loading}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="center"
        spacing={3}
        className={clsx(classes.content, classes.mainSearch, classes.root, {
          [classes.contentShift]: drawerState[filterAnchor],
        })}
      >
        {/* *******Table******* */}

        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography className={classes.toolbarHeader} variant="h6">
            {t("eInvoice")}
          </Typography>
          <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
            <FilterList fontSize="inherit" />
          </IconButton>
          <SecuredNavLink
            permission="invoicing.item.create"
            to={{ pathname: "/admin/e-invoice/create" }}
          >
            <IconButton color={"primary"} size="large">
              <Add fontSize="inherit" />
            </IconButton>
          </SecuredNavLink>
        </Toolbar>

        {loading || eInvoiceList?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight='15%' />
            ) : (
              <EmptyTableMessage
                loading={false}
                message={t("searchNoResult")}
              />
            )}
          </Grid>
        ) : (
          <Paper className={classes.tablePaper}>
            <TableFixedHeaderWraper>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("type")}</FixedTableCell>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("description")}</FixedTableCell>
                    <FixedTableCell>{t("requestedBy")}</FixedTableCell>
                    <FixedTableCell>{t("activeFrom")}</FixedTableCell>
                    <FixedTableCell>{t("activeTo")}</FixedTableCell>
                    <FixedTableCell>{t("active")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eInvoiceList &&
                    eInvoiceList?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          className={classes.tableBodyRow}
                        >
                          <CellLink pathname={`/admin/e-invoice/${row?.id}`}>
                            {row?.type}
                          </CellLink>
                          <FixedTableCell>{row.code}</FixedTableCell>
                          <FixedTableCell>
                            {row?.name}
                            <Typography variant="body2" color="textSecondary">
                              {row.categorization?.level4?.name}
                            </Typography>
                          </FixedTableCell>
                          <FixedTableCell>{row?.description}</FixedTableCell>
                          <FixedTableCell>{row?.requestedBy}</FixedTableCell>
                          <FixedTableCell>{row?.activeFrom}</FixedTableCell>
                          <FixedTableCell>{row?.activeTo}</FixedTableCell>
                          <FixedTableCell>
                            {row?.active ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
            <MUITablePagination
              count={data?.listItems?.paginatorInfo?.total}
              rowsPerPage={rowsPerPage}
              page={!data?.listItems ? 0 : search?.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Grid>
    </Root>
  );
};

export default EInvoiceList;
