export const ShipmentStatusCode = (code, collected, paidToCustomer, name, inWarehouse) => {

  if (code === "DTR") {
    if (collected !== undefined && !collected) {
      return "collectedFalse";
    } else if (collected !== undefined && collected && paidToCustomer) {
      return "paidTrue";
    } else if (collected !== undefined && collected && !paidToCustomer) {
      return name;
    }
  } else if (code === "RTS") {
    if(inWarehouse!==undefined){
    return inWarehouse ? "returnToCompany" : "returnToDelivery";}
     return name;
  }
  return name;
};
