export const printLink = (code, batch, shipmentsId, afterSaveFun) => {
    const printUrl = batch ? `${window.location.origin}/batch` : `${window.location.origin}/shipment`;
    if (code === "SHEET_SAME_1") {
        window.open(`${printUrl}/print/${shipmentsId.toString()}/1c`);
    }
    if (code === "copy" || code === "SHEET_DIFF_2") {
        window.open(`${printUrl}/print/${shipmentsId.toString()}/2d`);
    }
    if (code === "SHEET_SAME_2") {
        window.open(`${printUrl}/print/${shipmentsId.toString()}/2c`);
    }
    if (code === "SHEET_SAME_3") {
        window.open(`${printUrl}/print/${shipmentsId.toString()}/3c`);
    }
    if (code === "SHEET_DIFF_3") {
        window.open(`${printUrl}/print/${shipmentsId.toString()}/3d`);
    }
    if (code === "STICKER_4X5") {
        window.open(
            `${printUrl}/sticky-print/${shipmentsId.toString()}`
        );
    }
    if (code === "STICKER_2X5") {
        window.open(
            `${printUrl}/sticky2X5-print/${shipmentsId.toString()}`
        );
    }
    if (code === "STICKER_10X15") {
        window.open(
            `${printUrl}/sticky10X15-print/${shipmentsId.toString()}`
        );
    }
    if (code === "STICKER_7X9") {
        window.open(
            `${printUrl}/sticky7X9-print/${shipmentsId.toString()}`
        );
    }
    if (code === "STICKER_10X10") {
        window.open(
            `${printUrl}/sticky10X10-print/${shipmentsId.toString()}`
        );
    }
    if (code === "STICKER_17X10") {
        window.open(
            `${printUrl}/sticky17X10-print/${shipmentsId.toString()}`
        );
    }
    afterSaveFun && afterSaveFun()
};