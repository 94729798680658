import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Icon,
} from "@mui/material";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import darkLogo from "../../assets/Image/logo-dark.png";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
// import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import "../ManifestPrint/print.css";
import { dateFormatLL } from "../../helpers/dateFunctions";
import {
  RootPrintCollectionList,
  classesPrintCollectionList,
} from "./CollectionStyle";
import formatMoney from "../../helpers/numbersDot";
import LogoImg from "../../Layout/LogoImg";

export { };

const org = {
  operation: "organization",
  fields: [
    "name",
    "phone",
    "email",
    "website",
    "reportHeader",
    "reportFooter",
    "registrationNumber",
    "taxNumber",
  ],
  variables: {},
};

const settings = {
  operation: "shippingSettings",
  fields: [
    "allowedCustomerTypes"
  ],
  variables: {},
};

const collectionsSum = {
  operation: "sumCollections",
  fields: ["collectedAmount", "commissionAmount"],
  variables: {
    input: {
      type: "ListCollectionFilterInput",
      required: true,
    },
  },
};
const collectionsListFields = [
  {
    data: [
      "code",
      "id",
      "date",
      "collectedAmount",
      {
        deliveryAgent: ["id", "name", "code"],
      },
      {
        operation: `sumEntries`,
        fields: [
          "collectedAmount",
          "commissionAmount",
        ],
        variables: {},
      },
      {
        safe: ["id", "name"],
      },
      {
        customer: ["id", "name", "code"],
      },
      "approved",
      "glApproved",
    ],
  },
];

const PAGE_COUNT = gqlb.query({
  operation: "listCollections",
  fields: [{ paginatorInfo: ["lastPage"] }],
  variables: {
    input: {
      type: "ListCollectionFilterInput",
      required: true,
    },
    first: {
      type: "Int",
    },
  },
});

const CollectionPrint = (props) => {
  // useEffect(() => {
  //   document.body.style.backgroundColor = "#fff"
  //   const divElements = document.getElementsByTagName('div');

  //   console.log(divElements);

  //   // Iterate through the collection and apply styles
  //   for (let i = 0; i < divElements.length; i++) {
  //     const div = divElements[i];
  //     div.style.color = '#000'; // Example style: set background color to lightblue
  //     // Add other styles as needed
  //   }
  //   return () => {

  //   }
  // }, [])

  const urlQuery = urlParameters(window.location.search);

  const stringValues = ["code", "fromCode", "toCode"];
  stringValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
  });

  const { data: pageCount, loading: pageLoad } = useQuery(
    gql`
      ${PAGE_COUNT.query}
    `,
    {
      first: 100,
      variables: {
        input: urlQuery,
        first: 100,
      },
    }
  );
  const pages = pageCount?.listCollections?.paginatorInfo?.lastPage;

  const queryBuilder = () => {
    const queryNumber = Math.ceil(pages / 100);
    const queryArray = [org, collectionsSum];
    for (let index = 0; index < queryNumber; index++) {
      const initialQuery = {
        operation: `listCollections${index}:listCollections`,
        fields: collectionsListFields,
        variables: {
          input: {
            type: "ListCollectionFilterInput",
            required: true,
          },
          first: {
            type: "Int",
          },
          ["page" + index]: {
            type: "Int",
            name: "page",
          },
        },
      };
      queryArray.push(initialQuery);
    }
    return queryArray;
  };

  const queryBody = queryBuilder();
  const COLLECTIONS_PRINT = gqlb.query(queryBody);

  const { t } = useTranslation();

  let variables = {
    input: urlQuery,
    first: 100,
  };
  for (let index = 0; index < pages; index++) {
    variables["page" + index] = index + 1;
  }

  const { data: queryData, loading } = useQuery(
    gql`
      ${COLLECTIONS_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pages,
      variables,
      onError: () => {
        return;
      },
    }
  );

  let parsedData = [];

  if (queryData?.listCollections0) {
    let concatData = [];
    for (const key in queryData) {
      if (key.startsWith("list")) {
        const listData = queryData[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }

  useEffect(() => {
    if (parsedData.length > 0) {
      window.print();
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const type = urlQuery?.typeCode;
  const collectionTitles = {
    DLVBY: t("collectFromDeliveryAgents"),
    CUSTM: t("collectCashShipments"),
  };

  const sumCollections =
    queryData?.sumCollections !== null ? queryData?.sumCollections : null;

  const reportDetails = parsedData.length > 0;

  const header = !loading && !pageLoad && reportDetails && (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell padding="none">
            <Table>
              <TableBody>
                <TableRow
                  className={classesPrintCollectionList.rowWithoutBorder}
                >
                  <TableCell>
                    <Box className={classesPrintCollectionList.logoSection}>
                      {/* <img src={logo} alt="logo" height={30} /> */}
                      <LogoImg height={30} forceLight={true} />
                      <Typography
                        className={clsx(
                          classesPrintCollectionList.reportHeader,
                          "landscape-zoom"
                        )}
                      >
                        {queryData.organization.reportHeader}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell
                    className={clsx(classesPrintCollectionList.headerDate)}
                  >
                    <Typography variant="h6">
                      {moment(new Date()).format("ll")}
                    </Typography>
                    <TypographyKeyValue
                      title={t("registrationNumber")}
                      value={queryData.organization?.registrationNumber}
                      hidden={!queryData.organization?.registrationNumber}
                    />
                    <TypographyKeyValue
                      title={t("taxNumber")}
                      value={queryData.organization?.taxNumber}
                      hidden={!queryData.organization?.taxNumber}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    className={classesPrintCollectionList.headerTableCell}
                  >
                    <Table className={classesPrintCollectionList.whiteSpace}>
                      <TableBody>
                        <TableRow
                          className={
                            classesPrintCollectionList.rowWithoutBorder
                          }
                        >
                          <TableCell>
                            <Typography variant="h5">
                              {collectionTitles[type]}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TypographyKeyValue
                              title={t("fromDate")}
                              value={
                                urlQuery?.fromDate &&
                                moment(urlQuery?.fromDate).format("ll")
                              }
                            />
                            <TypographyKeyValue
                              title={t("toDate")}
                              value={
                                urlQuery?.toDate &&
                                moment(urlQuery?.toDate).format("ll")
                              }
                            />
                          </TableCell>

                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
  const table = !loading && !pageLoad && reportDetails !== null && (
    <Table id="manifestPrintTable">
      <EnhancedTableHead type={type} />

      <TableBody>
        {parsedData.length > 0 &&
          parsedData?.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <FixedTableCell component="th" scope="row">
                  {row.code}
                </FixedTableCell>
                <FixedTableCell>{dateFormatLL(row?.date)}</FixedTableCell>
                {row?.deliveryAgent || row?.customer ? (
                  <FixedTableCell>
                    {type === "DLVBY"
                      ? row?.deliveryAgent?.name
                      : row?.customer?.name}
                  </FixedTableCell>
                ) : (
                  <FixedTableCell> </FixedTableCell>
                )}

                <FixedTableCell>{row?.safe?.name}</FixedTableCell>

                <FixedTableCell>
                  {formatMoney(row?.sumEntries?.collectedAmount)}
                </FixedTableCell>
                {type === "DLVBY" && (
                  <FixedTableCell>{formatMoney(row?.sumEntries?.commissionAmount)}</FixedTableCell>)}
                {type === "DLVBY" && sumCollections && (
                  <FixedTableCell>
                    {formatMoney(row?.sumEntries?.collectedAmount - row?.sumEntries?.commissionAmount)}
                  </FixedTableCell>)}
                <FixedTableCell>
                  {row?.approved ? (
                    <Icon className={classesPrintCollectionList.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )}
                </FixedTableCell>
                <FixedTableCell>
                  {row?.glApproved ? (
                    <Icon className={classesPrintCollectionList.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )}
                </FixedTableCell>
              </TableRow>
            );
          })}
        <TableRow>
          <FixedTableCell colSpan={3} />
          <FixedTableCell variant="head">{t("total")}</FixedTableCell>
          <FixedTableCell variant="head">
            {formatMoney(sumCollections?.collectedAmount)}
          </FixedTableCell>
          {type === "DLVBY" && (
            <FixedTableCell variant="head">
              {formatMoney(sumCollections?.commissionAmount)}
            </FixedTableCell>)}
          {type === "DLVBY" && sumCollections && (
            <FixedTableCell variant="head">
              {formatMoney((sumCollections?.collectedAmount - sumCollections?.commissionAmount))}
            </FixedTableCell>)}
          <FixedTableCell colSpan={3}> </FixedTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <RootPrintCollectionList>
      {loading || pageLoad ? (
        <>
          <FullScreenLoading minHeight="20%" />
          {/* this spans for download font before print */}
          <span className={clsx(classesPrintCollectionList.barcodeFont)} />
          <span
            style={{ opacity: 0 }}
            className={classesPrintCollectionList.cairoFont}
          >
            lا
          </span>
        </>
      ) : reportDetails === null ? (
        <NotFound />
      ) : (
        <>
          <div>
            <div className="page-header">{header}</div>

            <div className="page-footer">
              {queryData.organization.reportFooter}
            </div>

            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td>
                    <div className="page-header-space">{header}</div>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div
                      className={clsx(
                        classesPrintCollectionList.shipmentsTable,
                        {
                          // [classes.tableWidth]: tableZoom,
                        }
                      )}
                    >
                      {table}
                    </div>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <div className="page-footer-space">
                      {queryData.organization.reportFooter}
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </RootPrintCollectionList>
  );

  function TypographyKeyValue(props) {
    const { title, value, hidden, ...restProps } = props;
    return hidden ? null : (
      <Typography variant="body1" paddingBottom={1} {...restProps}>
        <span className={classesPrintCollectionList.title}>{title}:</span>{" "}
        <span style={{ color: "#000" }}>{value}</span>
      </Typography>
    );
  }
};

function EnhancedTableHead(props) {
  //*********Table Function*********
  const { type } = props;
  const { t } = useTranslation();
  const headCells = [
    { numeric: true, disablePadding: false, label: t("recordCode") },
    { numeric: true, disablePadding: false, label: t("date") },
    {
      numeric: true,
      disablePadding: false,
      label: t(type === "DLVBY" ? "shippingAgent" : "customer"),
    },
    { numeric: true, disablePadding: false, label: t("safe") },
    { numeric: true, disablePadding: false, label: t("value") },
    { numeric: true, disablePadding: false, label: t("approved") },
    { numeric: true, disablePadding: false, label: t("glApproved") },
  ];

  if (type === "DLVBY") {
    const commission = {
      numeric: true,
      disablePadding: false,
      label: t("commission"),
    };
    const NetAmount = {
      numeric: true,
      disablePadding: false,
      label: t("NetAmount"),
    };
    headCells.splice(5, 0, commission);
    headCells.splice(6, 0, NetAmount);
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <FixedTableCell
              key={index}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default CollectionPrint;
