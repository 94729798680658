import React, { useCallback, useMemo, useRef, useState } from 'react'
import { styled } from "@mui/material/styles";
import {
  TableBody,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Icon,
} from "@mui/material";
import { FilterList, Add, TaskAlt, Print } from "@mui/icons-material";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { gql, useQuery } from "@apollo/client";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";
import { dateTimeFormatA } from "../../helpers/dateFunctions";
import formatMoney from "../../helpers/numbersDot";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import CustomDialog from '../HOC/CustomComponents/CustomDialog';
import UpdateConciergeStatus from './UpdateConciergeStatus';
import { CONCIERGE_REQUESTS_LIST, SUM_CONCIERGE_REQUESTS_QUERY } from './Graphql';
import { TableCellColor } from './TableCellColor';
import { GetPermissionSlug } from '../../helpers/getPermissionSlug';
import { Globals } from '../HOC/Classes/Globals';
import ConciergeRequestFilter from './ConciergeRequestFilter';
import ExportConciergeRequests from './ExportConciergeRequest';

const PREFIX = "conciergeList";

const classes = {
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  bodyCheckbox: `${PREFIX}-bodyCheckbox`,
  checkbox: `${PREFIX}-checkbox`,
  table_button: `${PREFIX}-table_button`,
  header_button: `${PREFIX}-header_button`,
  table_bodyCode: `${PREFIX}-table_bodyCode`,
  table_headCode: `${PREFIX}-table_headCode`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    zIndex: 101,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.bodyCheckbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.table_bodyCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      left: 60,
    },
  },

  [`& .${classes.table_headCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "102",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      left: 60,
      top: 0,
    },
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.checkbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "102",
      backgroundColor: theme.palette.background.paper,
      top: 0,
      left: 0,
      minWidth: 60,
      position: "sticky",
    },
  },

  [`& .${classes.table_button}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
  [`& .${classes.header_button}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "102",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

function EnhancedTableHead(props) {
  const {
    indeterminate,
    selectAllChecked,
    onCheckAll,
    classes,
    updateStatusPermission
  } = props
  const { t } = useTranslation();
  const headCells = [
    { id: "9", numeric: true, disablePadding: false, label: t("status") },
    { id: "2", numeric: true, disablePadding: false, label: t("implementationDate") },
    { id: "10", numeric: true, disablePadding: false, label: t("branch") },
    {
      id: "3",
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    },
    {
      id: "7",
      numeric: true,
      disablePadding: false,
      label: t("amount"),
    },
    {
      id: "14",
      numeric: true,
      disablePadding: false,
      label: t("localAmount"),
    },
    { id: "8", numeric: true, disablePadding: false, label: t("deliveryFees") },
    { id: "11", numeric: true, disablePadding: false, label: t("collectionFees") },
    { id: "12", numeric: true, disablePadding: false, label: t("total") },
    { id: "13", numeric: true, disablePadding: false, label: t("shippingAgent") },
    {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("accountNumber"),
    },
    { id: "6", numeric: true, disablePadding: false, label: t("phone") },
    { id: "4", numeric: true, disablePadding: false, label: t("address") },
  ];

  return (
    <TableHead>
      <TableRow>
        <FixedTableCell className={classes.checkbox}>
          <Checkbox
            edge="start"
            id="check-all-shipment"
            indeterminate={indeterminate()}
            checked={selectAllChecked()}
            inputProps={{ "aria-label": "select all desserts" }}
            tabIndex={-1}
            onChange={(e) => onCheckAll(e)}
            disableRipple
          />
        </FixedTableCell>
        <FixedTableCell
          className={classes.table_headCode}
        >
          {t("requestCode")}
        </FixedTableCell>
        {headCells.map((headCell) => (
          <FixedTableCell
            className={classes.headerTable}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </FixedTableCell>
        ))}
        {updateStatusPermission && <FixedTableCell
          className={classes.header_button}
        > </FixedTableCell>}
      </TableRow>
    </TableHead>
  );
}

export default function ConciergeRequestList(props) {
  const screenWidth = useWidth();
  const { t } = useTranslation();
  const history = useHistory();
  const urlQuery = urlParameters(window.location.search);
  const conciergeRequestsList = useRef([]);
  const listType = props.match.params.type;
  const user = Globals.user;
  const updateStatusPermission = user.hasPermission(GetPermissionSlug("shipping", "concierge_request", listType, "update_status"))

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [noData, setNoData] = useState(true);

  const [checkedIds, setCheckedIds] = useState([]);
  const [updateMultiple, setUpdateMultiple] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [conciergeRequest, setConciergeRequest] = useState();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [searchQuery, setSearchQuery] = useState()

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/concierge-request/${listType}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const addURL = () => {
    pushUrl(props, `/admin/concierge-request/create/${listType}`);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [refetch, setRefetch] = useState(true);

  const { data, loading } = useQuery(
    gql`
          ${CONCIERGE_REQUESTS_LIST.query}
        `,
    {
      skip: validUrlParameters && !searchQuery,
      notifyOnNetworkStatusChange: true,
      partialRefetch: refetch,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          typeCode: listType.toUpperCase(),
          ...searchQuery
        },
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: () => {
        setNoData(false)
        conciergeRequestsList.current = []
      }
    })

  const { data: sum } = useQuery(
    gql`
        ${SUM_CONCIERGE_REQUESTS_QUERY.query}
      `,
    {
      skip: noData,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          typeCode: listType.toUpperCase(),
          ...searchQuery
        },
        first: rowsPerPage,
        page: page + 1,
      }
    }
  );

  const listConciergeRequests = data?.listConciergeRequests?.data

  const resetPage = () => setPage(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...searchQuery, page: newPage, rowsPerPage: rowsPerPage, });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    pushUrlSearch({ ...searchQuery, page: 0, rowsPerPage: +event.target.value });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setUpdateMultiple(false)
  }

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const [openExport, setOpenExport] = useState(false);
  const openExportDialog = () => {
    setOpenExport(true)
  };
  const clearCheckBox = () => setCheckedIds([]);

  const currentConciergeRequestsIds = listConciergeRequests?.map((i) => i.id);
  const shipmentsCount = currentConciergeRequestsIds?.length;

  const fieldSelected = currentConciergeRequestsIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < shipmentsCount;

  const selectAllChecked = () =>
    shipmentsCount > 0 && fieldSelected === shipmentsCount;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentConciergeRequestsIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentConciergeRequestsIds.includes(i));
    }
    setCheckedIds(ids);
  };
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const rowActionFunc = (data) => {
    setUpdateMultiple(false)
    setDialogOpen(true)
    setConciergeRequest(data);
    clearCheckBox()
  }

  const updateMultipleRequest = () => {
    setUpdateMultiple(true)
    handleOpenDialog()
  }
  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      i !== "page" && i !== "refetch" && encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };
  const onPrint = () => {
    const params = searchParams(searchQuery);
    const domain = `${window.location.origin}`;
    window.open(`${domain}/concierge-request/print?typeCode=${listType}&${params}`);
  };
  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const checkTypeValidations = (type, selectedConciergeRequests) => {
    if (type === "top") {
      return Boolean(!user.account) ?
        selectedConciergeRequests.every(({ status }) => !["RECHARGED"].includes(status.code) && status.code === selectedConciergeRequests[0].status.code) :
        selectedConciergeRequests.every(({ status }) => ["NEW", "ASSIGNED", "RESCHEDULED", "ONWAY", "RECHARGED"].includes(status.code) && status.code === selectedConciergeRequests[0].status.code)
    }
    if (type === "wdr") {
      return Boolean(!user.account) ?
        selectedConciergeRequests.every(({ status }) => !["PAID"].includes(status.code) && status.code === selectedConciergeRequests[0].status.code) :
        selectedConciergeRequests.every(({ status }) => ["PAID", "NEW"].includes(status.code) && status.code === selectedConciergeRequests[0].status.code)
    }
  }

  const customerCanUpdateStatus = (request) => {
    if (listType === "top") {
      return Boolean(user.account) ? !["RECHARGED", "ASSIGNED", "NEW", "RESCHEDULED", "ONWAY"].includes(request.status.code) : ["RECHARGED"].includes(request.status.code)
    }
    if (listType === "wdr") {
      return Boolean(user.account) ? !["PAID", "NEW"].includes(request.status.code) : ["PAID"].includes(request.status.code)
    }
  }

  const checkedConciergeRequestsStatus = useMemo(() => {
    let selectedConciergeRequests = conciergeRequestsList.current.filter(({ id }) =>
      checkedIds.includes(id)
    );
    const valid = selectedConciergeRequests.every(
      (e) =>
        user.canAccessBranch(e.branch.id)
        && !["CONFIRMED", "CANCELLED"].includes(e.status.code)
    );
    const validCode = checkTypeValidations(listType, selectedConciergeRequests)

    return { validCode, conciergeRequests: selectedConciergeRequests, valid };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedIds]);

  const updateStatusFun = useCallback((request, index) => {
    conciergeRequestsList.current = [...conciergeRequestsList.current, request];
    return (
      <FixedTableCell
        key={index}
        className={classes.table_button}
        component="th"
        scope="row"
      >
        <Button
          color="primary"
          variant="outlined"
          size="small"
          disabled={["CONFIRMED", "CANCELLED"].includes(request.status.code) || customerCanUpdateStatus(request) || !user.canAccessBranch(request.branch.id)}
          onClick={() => rowActionFunc(request)}
          startIcon={<Icon>task_alt</Icon>}
        >
          {t("update")}
        </Button>
      </FixedTableCell>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultTableBody = (row, index) => (
    <TableRow
      className={classes.tableBodyRow}
      role="checkbox"
      tabIndex={-1}
      key={index}
    >
      <FixedTableCell className={classes.bodyCheckbox}>
        <Checkbox
          edge="start"
          checked={isChecked(row.id)}
          onChange={(e) => toggleCheck(e, row.id)}
          disableRipple
        />
      </FixedTableCell>
      <CellLink
        className={classes.table_bodyCode}
        pathname={`/admin/concierge-request/${row.id}`}
        component="th"
        scope="row"
      >
        {row.code}
      </CellLink>
      <TableCellColor
        status={row.status}
      />
      <FixedTableCell>
        {dateTimeFormatA(row.date)}
      </FixedTableCell>

      <CellLink
        pathname={`/admin/branches/${row.branch?.id}`}
      >
        {row.branch?.name}
      </CellLink>
      <CellLink
        pathname={`/admin/customers/${row.customer?.id}`}
      >
        {row.customer?.name}
      </CellLink>
      <FixedTableCell>{`${formatMoney(row.amount)} ${row.currency.name}`}</FixedTableCell>
      <FixedTableCell>{formatMoney(row.localAmount)}</FixedTableCell>
      <FixedTableCell>{formatMoney(row.deliveryFees)}</FixedTableCell>
      <FixedTableCell>{formatMoney(row.collectionFees)}</FixedTableCell>
      <FixedTableCell>{formatMoney(row.totalAmount)}</FixedTableCell>
      <CellLink
        pathname={`/admin/delivery-Agents/${row.transactions[0]?.deliveryAgent?.id}`}
      >
        {row.transactions[0]?.deliveryAgent?.name}
      </CellLink>
      <FixedTableCell>{row.accountNumber}</FixedTableCell>
      <FixedTableCell dir="ltr">{row?.phone}</FixedTableCell>
      <FixedTableCell>{row.address}</FixedTableCell>

      {updateStatusPermission && updateStatusFun(row, index)}
    </TableRow>
  );

  const memorizedTableBody = useMemo(
    () => defaultTableBody,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, toggleCheck]
  );
  const searchData = {
    typeCode: listType.toUpperCase(),
    ...searchQuery
  }

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "update",
      title: "update",
      disabled: checkedIds.length === 0 || !checkedConciergeRequestsStatus.validCode || !checkedConciergeRequestsStatus.valid,
      action: updateMultipleRequest,
      icon: TaskAlt,
      permission: updateStatusPermission,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      permission: user.hasPermission(GetPermissionSlug("shipping", "concierge_request", listType, "create")),
      icon: Add,
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: listConciergeRequests?.length === 0
    },
    {
      id: "print",
      title: "print",
      action: onPrint,
      icon: Print,
      disabled: listConciergeRequests?.length === 0
    },
  ]

  return (
    <Root>
      <CustomDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={t("updateRequestStatus")}
        maxWidth={"sm"}
        fullWidth={true}
        content={
          dialogOpen && <UpdateConciergeStatus
            clearCheckBox={clearCheckBox}
            conciergeRequest={conciergeRequest}
            statusCode={checkedConciergeRequestsStatus.validCode}
            conciergeRequests={checkedConciergeRequestsStatus.conciergeRequests}
            listType={listType}
            conciergeRequestsIds={checkedIds}
            updateMultiple={updateMultiple}
            onClose={handleCloseDialog}
          />
        }
      />
      <ExportConciergeRequests
        filters={searchData}
        openExport={openExport}
        setOpenExport={setOpenExport}
        typeCode={listType.toUpperCase()}
      />
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        type={listType}
        empty={listConciergeRequests?.length === 0}
        loading={loading}
        filters={
          <ConciergeRequestFilter
            {...{ onSubmitFunc, rowsPerPage, page, noData, resetPage, setSearchQuery, listType, setRefetch, refetch }}
            pathname="Concierge-request"
            clearCheckBox={clearCheckBox}
            filtersAllowed={[
              "date",
              "code",
              "status",
              "custm",
              "dlvAgent",
              "branch",
              "zone",
              "subZone",
              "account",
            ]}
          />
        }
        tableHeaders={
          <EnhancedTableHead
            type={listType}
            rowCount={listConciergeRequests?.length}
            indeterminate={indeterminate}
            selectAllChecked={selectAllChecked}
            onCheckAll={onCheckAll}
            classes={classes}
            updateStatusPermission={updateStatusPermission}
          />
        }
        tableBody={
          <TableBody>{listConciergeRequests &&
            listConciergeRequests?.map((row, index) => {
              return (
                memorizedTableBody(row, index)
              );
            })}
            {sum?.sumConciergeRequests && (
              <TableRow hover>
                <FixedTableCell className={classes.bodyCheckbox}> </FixedTableCell>
                <FixedTableCell
                  colSpan={5}
                />
                <FixedTableCell>{t("total")}</FixedTableCell>
                <FixedTableCell>
                  {formatMoney(sum?.sumConciergeRequests?.localAmount)}
                </FixedTableCell>
                <FixedTableCell>
                  {formatMoney(sum?.sumConciergeRequests?.deliveryFees)}
                </FixedTableCell>
                <FixedTableCell>
                  {formatMoney(sum?.sumConciergeRequests?.collectionFees)}
                </FixedTableCell>
                <FixedTableCell>
                  {formatMoney(sum?.sumConciergeRequests?.totalAmount)}
                </FixedTableCell>
                {updateStatusPermission && <FixedTableCell className={classes.table_button}> </FixedTableCell>}
              </TableRow>
            )}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listConciergeRequests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listConciergeRequests ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        } />
    </Root>
  )
}
