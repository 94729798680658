import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Icon,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Add, FilterList, Search } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_USERS } from "./Graphql";
import { useTranslation } from "react-i18next";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import CellLink from "../HOC/CustomComponents/CellLink";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUIselect from "../HOC/MUI/MUIselect";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_ROLES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "ListUsers";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  button: `${PREFIX}-button`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const ListUsers = (props) => {
  const { t } = useTranslation();
  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    role: null,
    account: null,
  });
  const [roleCode, setRoleCode] = useState(null);
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

 const [filterLoad, setFilterLoad] = useState(true);
  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    if (urlQuery["username"])
      urlQuery["username"] = urlQuery["username"].toString();
    urlQuery["username"] && setValue("username", urlQuery["username"]);

    return () => { };
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/users",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    (refetch) ? setrefetch(false) : setrefetch(true)
    setFilterLoad(false)
    let handledData = {
      active: data.active,
      username: data.username.toString(),
      roleId: data.roleId,
      accountId: data.accountId,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const parseData = (data) => {
    return data;
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_USERS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.active != null && { active: search.active }),
          ...(search.username && { username: search.username }),
          ...(search.roleId && { roleId: search.roleId }),
          ...(search.accountId && { accountId: search.accountId }),
        },
      },
    }
  );
  const convertToString = (row, link) => {
    let content = row.map((role, index) =>
      link ? <SpanLink key={index} className={classes.span}
        pathname={`/admin/roles/${role.id}`}
      >
        {role.name} {!(row.length - 1 === index) && ' , '}
      </SpanLink> :
        <span key={index} className={classes.span}>
          {role.name} {!(row.length - 1 === index) && ' , '}
        </span>
    )
    return content
  }
  const listUsers =
    data?.listUsers?.data !== null ? data?.listUsers?.data : null;

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, `/admin/users/create`),
      icon: Add,
      permission: "core.user.create",
    },
  ]

  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listUsers?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"username"}
                  label={t("username")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading&&filterLoad}
                  name={"roleId"}
                  label={t("role")}
                  onChangeValue={(e) => {
                    setRoleCode(e.id);
                    setValue("accountId", "");
                  }}
                  onCompleted={(data) => {
                    const role = data[Object.keys(data)[0]].find(
                      (i) => i.id === urlQuery["roleId"]
                    );
                    if (role?.id === 2 || role?.id === 3) setRoleCode(role.id);

                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["roleId"],
                      "role"
                    );
                  }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ROLES_DROPDOWN.query}
                  defaultValue={autocompleteValues.role}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                {(roleCode === 3 || roleCode === 2) && (
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"accountId"}
                    label={t("account")}
                    parseData={(data) => parseData(data)}
                    query={
                      roleCode === 2
                        ? LIST_CUSTOMERS_DROPDOWN.query
                        : LIST_DELIVERY_AGENTS_DROPDOWN.query
                    }
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["accountId"],
                        "account"
                      )
                    }
                    variables={{
                      input: {
                        active: true,
                      },
                    }}
                    defaultValue={autocompleteValues.account}
                  />
                )}
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "username"
            },
            {
              name: "account"
            },
            {
              name: "role"
            },
            {
              name: "active"
            },
          ]
        }
        tableBody={
          <TableBody>
            {listUsers &&
              listUsers?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/users/${row?.id}`}>
                      {row?.username}
                    </CellLink>
                    {row?.account ? (
                      <CellLink
                        pathname={
                          row?.roles?.some(el => el.id === 2)
                            ? `/admin/customers/${row?.account?.id}`
                            : `/admin/delivery-agents/${row?.account?.id}`
                        }
                      >
                        {row?.account?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell></FixedTableCell>
                    )}
                    <FixedTableCell>
                      ( {convertToString(row.roles, 'roles')} )
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listUsers?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listUsers ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ListUsers;
