/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Icon,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { WARE_HOUSE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
const PREFIX = 'WareHouseView';

const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));


const WareHouseView = (props) => {

  const warehouseId = props.match.params.id?.trim();
  const { t } = useTranslation();

  const { loading, data } = useQuery(
    gql`
      ${WARE_HOUSE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(warehouseId) },
    }
  );
  const user = Globals.user;
  const branchId = data?.warehouseSection?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit = user.hasPermission("shipping.warehouse_section.update")
    ? canAccessBranch
    : false;

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/warehouse/${warehouseId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ]
  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.warehouseSection ? (
      <NotFound />
    ) : (
      <Root>
        <Grid
          container
          justifyContent="center"
          sx={{ margin: "0", width: "100%" }}
        >
          <TitleAppBar path={props.match.path} >
            <LongMenu icons={icons} />
          </TitleAppBar>
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair title={t("name")} value={data?.warehouseSection?.name} />
            <KeyValuePair
              title={t("active")}
              value={
                data?.warehouseSection?.active ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("branch")}
              value={
                data?.warehouseSection?.branch ? (
                  <SpanLink
                    pathname={`/admin/branches/${data?.warehouseSection?.branch?.id}`}
                  >
                    {data?.warehouseSection?.branch?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
          </Paper>
        </Grid>
      </Root>
    );
};

export default WareHouseView;
