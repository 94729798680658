import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { FixedTableCell } from "./FixedTableCell";
import { useTranslation } from "react-i18next";
import { FixedSumTableCell } from "./FixedSumTableCell";
import formatMoney from "../../../helpers/numbersDot";


const ShipmentsOperationTable = (props) => {
  const { page, headCells, parseBodyCell, data, rowsPerPage, total, ...restProps } = props;
  // const tableData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const tableData =
    (rowsPerPage
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data) ?? [];
  const { t } = useTranslation();
  return (
    <Table
      aria-labelledby="tableTitle"
      aria-label="enhanced table"
      {...restProps}
    >
      <TableHead>
        <TableRow>
          {headCells && headCells.map((headCell, index) => {
            return headCell !== null ? (
              <FixedTableCell
                key={index}
              >
                {t(headCell)}
              </FixedTableCell>
            ) : null;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map(parseBodyCell)}
        {tableData.length > 0 && total.length > 0 && <TableRow role="checkbox" tabIndex={-1}>
          <FixedTableCell>
          </FixedTableCell>
          {total.map((e, i) => {
            return e.clientSum ?
              <FixedSumTableCell
                data={data}
                sumKey={e}
                key={i} /> :
              e.noSum ?
                <FixedTableCell key={i}> </FixedTableCell> :
                <FixedTableCell
                  key={i}
                >
                  {formatMoney(typeof (e.value) === "number" ? e.value.toFixed(2) : e.value)}
                </FixedTableCell>
          }
          )}
        </TableRow>}
      </TableBody>
    </Table>
  )
};

ShipmentsOperationTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};
export default ShipmentsOperationTable;
