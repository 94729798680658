import { styled } from '@mui/material/styles';
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const PREFIX = 'CounterSection';

const classes = {
  background: `${PREFIX}-background`,
  headerColor: `${PREFIX}-headerColor`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.background}`]: {
    background: theme.palette.primary.main,
  },

  [`& .${classes.headerColor}`]: {
    "& h2": {
      "&::before": {
        background: theme.palette.primary.main,
      },
    },
  }
}));

const CounterSection = (props) => {
  const { t } = useTranslation("home");
  const sections = t("home:statisticSections", { returnObjects: true });


  return (
    <Root id="statistics" className={`wpo-counter-area ${props.subclass}`}>
      <Container>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} lg={7}>
            <div className={clsx(classes.background, "wpo-counter-content")}>
              <h2>{t("home:statisticsTitle")}</h2>
              <p className="lead">{t("home:statisticsBody")}</p>
            </div>
          </Grid>
          <Grid xs={12} md={6} lg={5}>
            <div className={clsx(classes.headerColor, "wpo-counter-grids")}>
              {sections.map((section, i) => (
                <div className="grid" key={i}>
                  <div>
                    <h2>
                      <span>{section.number}</span>
                    </h2>
                  </div>
                  <p>{section.title}</p>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default CounterSection;
