import {  IconButton, Toolbar,Typography } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
export const EnhancedTableToolbarForm = (props) => {
    const { classes,addShipmentProduct } = props;
    const { t } = useTranslation();
    return (
        <Toolbar
        variant="dense"
        className={classes.toolbar}
      >
        <Typography
          className={classes.tableTitle}
          variant="h6"
          color={"text.primary"}
        >
        </Typography>
        <IconButton
          aria-label="Add"
          className={classes.addIcon}
          onClick={addShipmentProduct}
          size="large"
        >
          <AddCircleOutline />
        </IconButton>
      </Toolbar>
    );
};
