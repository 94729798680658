import React from 'react'
import PropTypes from "prop-types";
import { FixedTableCell } from '../HOC/CustomComponents/FixedTableCell';
import { Typography } from '@mui/material';
import formatMoney from '../../helpers/numbersDot';

const GenerateShipmentSumCell = (props) => {
    const { shipmentSum, elementKey } = props

    const getShipmentsSumValue = (shipmentSum, elementKey) => {
        if (elementKey) {
            if (elementKey === "netCollection") {
                const netCollection = shipmentSum["pendingCollectionAmount"] - shipmentSum["deliveryCommission"]
                return formatMoney(netCollection?.toFixed(2)) ?? "--"
            }
            return formatMoney(shipmentSum[elementKey]?.toFixed(2)) ?? "--"
        } else {
            return ''
        }
    }

    return <FixedTableCell >
        <Typography color="inherit" variant="h6" >
            {getShipmentsSumValue(shipmentSum, elementKey)}
        </Typography>
    </FixedTableCell>

    // if (conditions.ifCode && !elementKey) {
    //     return <FixedTableCell className={classes.shipmentTable_bodyCode} > </FixedTableCell>
    // } else if (conditions.ifStatus && !elementKey) {
    //     return <FixedTableCell className={!conditions.ifCode ? classes.shipmentTable_bodyCode : classes.shipmentTable_bodyStatus} > </FixedTableCell>
    // } else {
    //     return <FixedTableCell key={key}>
    //         {getShipmentsSumValue(shipmentSum, elementKey)}
    //     </FixedTableCell>
    // }
}

GenerateShipmentSumCell.prototype = {
    shipmentSum: PropTypes.object.isRequired,
}

export default GenerateShipmentSumCell