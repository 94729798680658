import React, { Fragment, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Paper,
  Collapse,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { GL_ACCOUNT } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { SAVE_GL_ACCOUNT } from "./Graphql";
import { LIST_GL_ACCOUNTS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import Grid from "@mui/material/Unstable_Grid2";
import NotFound from "../../Error/NotFound";
const PREFIX = 'CreateGlAccount';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));
const CreateGlAccount = (props) => {
  const { updateGlId, createGlWithParent, saveGlAccountResponse } = props;
  const [activeUser, setActiveUser] = useState(true);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    parent: null,
    closingAccount: null,
  });

  useEffect(() => {
    if (createGlWithParent) {
      setAutocompleteValues((prev) => ({
        ...prev,
        parent: createGlWithParent,
      }));
    }

    return () => {};
  }, []);


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;

  const [saveGlAccount, { loading: saveGlAccountLoading }] = useMutation(
    gql`
      ${SAVE_GL_ACCOUNT.query}
    `
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };

  // update

  const { data: updateGlAccount, loading } = useQuery(
    gql`
      ${GL_ACCOUNT.query}
    `,

    {
      skip: !updateGlId,
      variables: { id: updateGlId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const glAccountData = data.glAccount;
        setActiveUser(glAccountData.active);
        const saveGlAccountParams = ["id", "code", "name", "active"];
        saveGlAccountParams.forEach((i) => {
          glAccountData[i] && setValue(i, glAccountData[i]);
        });
        setAutocompleteValues({
          branch: glAccountData?.branch,
          parent: glAccountData?.parent,
          closingAccount: glAccountData?.closingAccount,
        });
      },
    }
  );

  const glAccountData = updateGlAccount?.glAccount;
  const selectDefaultValue = (data) => {
    return data;
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const lookupsFieldArray = [
    {
      name: "natureCode",
      label: "accountNature",
      code: "ACCOUNT_NATURE",
      objectName: "nature",
    },
    {
      name: "typeCode",
      label: "accountType",
      code: "ACCOUNT_TYPE",
      objectName: "type",
      disabled: updateGlId && true,
    },
    {
      name: "classCode",
      label: "accountClass",
      code: "ACCOUNT_CLASS",
      objectName: "class",
    },
  ];

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    if (updateGlId) {
      delete data["parentId"];
      delete data["accountType"];
    }
    saveGlAccount({
      variables: {
        input: {
          ...data,
          ...(updateGlId && { id: updateGlId }),
          active: activeUser,
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        saveGlAccountResponse(data, updateGlId);
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  return loading ?
  <StyledLoading container item justifyContent="center" className={classes.main}>
    <FullScreenLoading height={"100%"} />
  </StyledLoading>
  : !updateGlAccount&&updateGlId? (
    <NotFound />
  ): (
    <Root>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            className={clsx(classes.mainGrid)}
            spacing={2}
          >
            <Paper container component={Grid} className={clsx(classes.spacing)}>
              <Grid container justifyContent="space-between" xs={12}>
                <Typography variant="h6">{t("account")}</Typography>
                <FormControlLabel
                  checked={activeUser}
                  control={<Switch color="primary" />}
                  label={t("active")}
                  labelPlacement="start"
                  onChange={handelActivate}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={updateGlId}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"parentId"}
                  label={t("parentAccount")}
                  disabled={Boolean(updateGlId)}
                  parseData={(data) => selectDefaultValue(data)}
                  query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                  variables={{
                    input: {
                      typeCode: "MAIN",
                    },
                  }}
                  defaultValue={autocompleteValues.parent}
                />
              </Grid>

              <Grid xs={12} sm={6} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"closingAccountId"}
                  label={t("closingAccount")}
                  parseData={(data) => selectDefaultValue(data)}
                  query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                  variables={{
                    input: {
                      classCode: "ENDING",
                    },
                  }}
                  defaultValue={autocompleteValues.closingAccount}
                />
              </Grid>
              {lookupsFieldArray.map((lookup, index) => (
                <Grid key={index} xs={12} sm={6} alignItems="flex-start">
                  <LookupDropdown
                    control={control}
                    errors={errors}
                    name={lookup.name}
                    label={t(lookup.label)}
                    variables={{ input: { code: lookup.code } }}
                    rules={{ required: t("fieldIsRequired") }}
                    disabled={lookup.disabled}
                    onCompleted={(data) =>
                      lookupsComplete(
                        data,
                        glAccountData?.[lookup.objectName]?.code,
                        lookup.name
                      )
                    }
                  />
                </Grid>
              ))}
            </Paper>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.spacing}
            >
              <FormButton disabled={saveGlAccountLoading}>
                {saveGlAccountLoading ? <ButtonLoading /> : t("save")}
              </FormButton>
            </Grid>
          </Grid>
        </form>
    </Root>
  );
};

export default CreateGlAccount;
