import { tablesum } from "../Invoices/InvoicesDetails"
import { invoice } from "../Invoices/InitialInvoiceData";
import { initialData } from "../Payment/PaymentListQuary";
import { tableSumPayment } from "../Payment/paymentDetails";

const useTotalTableDetails = ({
    printType,
    reportDetails,
    manifestTransactionType
}) => {
    let custmTableTotal
    if (printType === 'invoice') {
        const localDataKey = localStorage.getItem(`shipmentInvoice`) ? JSON.parse(localStorage.getItem(`shipmentInvoice`)) : invoice
        const tableSumArr = () => {
            if (localDataKey) {
                let table = tablesum(localDataKey, reportDetails)

                return table
            }
        }
        const tableSum = tableSumArr()
        custmTableTotal = tableSum

    }
    else if (printType === 'payment') {
        if (manifestTransactionType === 'DLVBY' || manifestTransactionType === 'CUSTM') {
            const localDataKey = localStorage.getItem(`shipmentPayment${manifestTransactionType}`) ? JSON.parse(localStorage.getItem(`shipmentPayment${manifestTransactionType}`)) : initialData[manifestTransactionType]
            const tableSumArr = () => {
                if (localDataKey) {
                    let table = tableSumPayment(localDataKey, reportDetails)
                    return table
                }
            }
            const tableSum = tableSumArr()
            custmTableTotal = tableSum
        }
    }
    return {
        custmTableTotal,
    };
}

export default useTotalTableDetails;