import { Box, Grid, Paper, styled, TableRow, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { dateFormatLL } from "../../helpers/dateFunctions";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

const PREFIX = "HistoryTable";

const classes = {
  table: `${PREFIX}-table`,
  loading: `${PREFIX}-loading`,
  shipmentTable_code: `shipmentTable_code`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  tablePaper: `${PREFIX}-tablePaper`,
  tableHead: `${PREFIX}-tableHead`,
  mainTableClass: `${PREFIX}-mainTableClass`,
  shipmentTable_updatedBy: `${PREFIX}-shipmentTable_updatedBy`,
  shipmentTable_bodyUpdatedBy: `${PREFIX}-shipmentTable_bodyUpdatedBy`,
  shipmentTable_bodyUpdatedAt: `${PREFIX}-shipmentTable_bodyUpdatedAt`,
  shipmentTable_updatedAt: `${PREFIX}-shipmentTable_updatedAt`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
    minHeight: "250px",
  },
  [`& .${classes.loading}`]: {
    paddingTop: theme.spacing(8),
  },

  [`& .${classes.shipmentTable_updatedBy}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      right: "0",
    },
  },
  [`& .${classes.shipmentTable_updatedAt}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      left: "0",
    },
  },

  [`& .${classes.shipmentTable_bodyUpdatedBy}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
  [`& .${classes.shipmentTable_bodyUpdatedAt}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`&.${classes.tablePaper}`]: {
    position: "relative",
    width: "100%",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.tableHead}`]: {
    "& .MuiTableCell-head": { fontWeight: 600 },
    "& th": { background: theme.palette.background.paper },
  },

  [`& .${classes.mainTableClass}`]: {
    "& td": {
      maxWidth: 250,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const ShipmentHistory = (props) => {
  const { shipments, warehousing, shipmentProducts, historyLoading } = props;
  const shipmentsLength = shipments?.length;
  const { t } = useTranslation();

  const tableHeadCell = [
    "updatedAt",
    "code",
    "date",
    "originBranch",
    "currentBranch",
    "customer",
    "senderName",
    "phone",
    "mobile",
    "address",
    "postalCode",
    "source",
    "recipient",
    "phone",
    "mobile",
    "address",
    "postalCode",
    "destination",
    "packageDescription",
    "packageOpen",
    "priceType",
    "paymentType",
    "packageType",
    "deliveryType",
    "returnType",
    "service",
    "partialReturnStatusName",
    "refNumber",
    "pieceCount",
    "length",
    "width",
    "height",
    "packagePrice",
    "allDueFees",
    "deliveredAmount",
    "collectedFees",
    "customerNet",
    "totalPackagePrice",
    "customerDueAmount",
    "returningDueFees",
    "returnedValue",
    "deliveryFees",
    "deliveryCommission",
    "extraWeightCost",
    "collectionFees",
    "tax",
    "postFees",
    "returnFees",
    "returnPiecesCount",

    "shippingAgent",
    "forwarded",
    "inWarehouse",
    "cancelled",
    "collected",
    "paidToCustomer",
    "CancellationReasonName",
    "adminNotes",
    "notes",
    "weight",
    "paidToDeliveryAgent",
    "redeliveryDate",
    "wareHouse",
    "pickup",
    "status",
    "updatedBy",
  ];

  if (warehousing && shipmentProducts) {
    const referenceBranch = "unpacked";
    tableHeadCell.splice(50, 0, referenceBranch);
  }

  const theme = useTheme()
  const compareValues = (val_1, val_2, index) => {
    if (val_1 === val_2 || index === shipments.length - 1) {
      return {};
    } else {
      return { backgroundColor: theme.palette.mode === "dark" ? "#651e1c !important" : red[50] + "!important" };
    }
  };

  const tableBody = (shipment, index) => {
    let prevShipment = shipments[index + 1];
    const updatedBy =
      index === shipments.length - 1 ? shipment.createdBy : shipment.updatedBy;
    const updatedAt =
      index === shipments.length - 1 ? shipment.createdAt : shipment.updatedAt;
    return (
      <TableRow key={index} className={classes.tableBodyRow}>
        <FixedTableCell
          className={classes.shipmentTable_bodyUpdatedAt}
          sx={compareValues(updatedAt, prevShipment?.updatedAt, index)}
        >
          {updatedAt}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(shipment.code, prevShipment?.code, index)}
        >
          {shipment.code}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(shipment.createdAt, prevShipment?.createdAt, index)}
        >
          {dateFormatLL(shipment.date)}
        </FixedTableCell>

        <CellLink
          sx={compareValues(
            shipment.originBranch.id,
            prevShipment?.originBranch?.id,
            index
          )}
          pathname={`/admin/branches/${shipment.originBranch.id}`}
        >
          {shipment.originBranch.name}
        </CellLink>
        <CellLink
          sx={compareValues(
            shipment.branch.id,
            prevShipment?.branch?.id,
            index
          )}
          pathname={`/admin/branches/${shipment.branch.id}`}
        >
          {shipment.branch.name}
        </CellLink>
        <CellLink
          sx={compareValues(
            shipment.customer.id,
            prevShipment?.customer?.id,
            index
          )}
          pathname={`/admin/customers/${shipment.customer.id}`}
        >
          <Box
            component="span"
            fontWeight="bold"
          >{` (${shipment.customer.code}) `}</Box>
          <Box component="span">{shipment.customer.name}</Box>
        </CellLink>
        <FixedTableCell
          sx={compareValues(
            shipment.senderName,
            prevShipment?.senderName,
            index
          )}
        >
          {shipment.senderName}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.senderPhone,
            prevShipment?.senderPhone,
            index
          )}
        >
          {shipment.senderPhone}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.senderMobile,
            prevShipment?.senderMobile,
            index
          )}
          dir="ltr"
        >
          {shipment.senderMobile}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.senderAddress,
            prevShipment?.senderAddress,
            index
          )}
        >
          {shipment.senderAddress}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.senderPostalCode,
            prevShipment?.senderPostalCode,
            index
          )}
        >
          {shipment.senderPostalCode}
        </FixedTableCell>
        <ShipmentRegion
          sx={compareValues(
            shipment.senderSubzone.id,
            prevShipment?.senderSubzone?.id,
            index
          )}
          pathname={`/admin/zones/${shipment.senderZone.id}`}
          zone={shipment.senderZone?.name}
          subzone={shipment.senderSubzone.name}
        />
        <FixedTableCell
          sx={compareValues(
            shipment.recipientName,
            prevShipment?.recipientName,
            index
          )}
        >
          {shipment.recipientName}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.recipientPhone,
            prevShipment?.recipientPhone,
            index
          )}
          dir="ltr"
        >
          {shipment.recipientPhone}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.recipientMobile,
            prevShipment?.recipientMobile,
            index
          )}
          dir="ltr"
        >
          {shipment.recipientMobile}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.recipientAddress,
            prevShipment?.recipientAddress,
            index
          )}
        >
          {shipment.recipientAddress}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.recipientPostalCode,
            prevShipment?.recipientPostalCode,
            index
          )}
        >
          {shipment.recipientPostalCode}
        </FixedTableCell>
        <ShipmentRegion
          sx={compareValues(
            shipment.recipientSubzone.id,
            prevShipment?.recipientSubzone?.id,
            index
          )}
          pathname={`/admin/zones/${shipment.recipientZone.id}`}
          zone={shipment.recipientZone?.name}
          subzone={shipment.recipientSubzone.name}
        />

        <FixedTableCell
          sx={compareValues(
            shipment.description,
            prevShipment?.description,
            index
          )}
        >
          {shipment.description}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.openable.code,
            prevShipment?.openable?.code,
            index
          )}
        >
          {shipment.openable?.name}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.priceType.code,
            prevShipment?.priceType?.code,
            index
          )}
        >
          {shipment.priceType.name}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.paymentType.code,
            prevShipment?.paymentType?.code,
            index
          )}
        >
          {shipment.paymentType.name}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.type.code,
            prevShipment?.type?.code,
            index
          )}
        >
          {shipment.type.name}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.deliveryType?.code,
            prevShipment?.deliveryType?.code,
            index
          )}
        >
          {shipment.deliveryType?.name}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.returnType?.code,
            prevShipment?.returnType?.code,
            index
          )}
        >
          {shipment.returnType?.name}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.service.id,
            prevShipment?.service?.id,
            index
          )}
        >
          {shipment.service?.name}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.returnStatus?.code,
            prevShipment?.returnStatus?.code,
            index
          )}
        >
          {shipment.returnStatus?.name}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(shipment.refNumber, prevShipment?.refNumber, index)}
        >
          {shipment.refNumber}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.piecesCount,
            prevShipment?.piecesCount,
            index
          )}
        >
          {shipment.piecesCount}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.size.length,
            prevShipment?.size?.length,
            index
          )}
        >
          {shipment.size.length}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.size.width,
            prevShipment?.size?.width,
            index
          )}
        >
          {shipment.size.width}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.size.height,
            prevShipment?.size?.height,
            index
          )}
        >
          {shipment.size.height}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(shipment.price, prevShipment?.price, index)}
        >
          {shipment.price}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.allDueFees,
            prevShipment?.allDueFees,
            index
          )}
        >
          {shipment.allDueFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.deliveredAmount,
            prevShipment?.deliveredAmount,
            index
          )}
        >
          {shipment.deliveredAmount}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.collectedFees,
            prevShipment?.collectedFees,
            index
          )}
        >
          {shipment.collectedFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(shipment.amount, prevShipment?.amount, index)}
        >
          {shipment.amount}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.totalAmount,
            prevShipment?.totalAmount,
            index
          )}
        >
          {shipment.totalAmount}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.customerDue,
            prevShipment?.customerDue,
            index
          )}
        >
          {shipment.customerDue}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.returningDueFees,
            prevShipment?.returningDueFees,
            index
          )}
        >
          {shipment.returningDueFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.returnedValue,
            prevShipment?.returnedValue,
            index
          )}
        >
          {shipment.returnedValue}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.deliveryFees,
            prevShipment?.deliveryFees,
            index
          )}
        >
          {shipment.deliveryFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.deliveryCommission,
            prevShipment?.deliveryCommission,
            index
          )}
        >
          {shipment.deliveryCommission}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.extraWeightFees,
            prevShipment?.extraWeightFees,
            index
          )}
        >
          {shipment.extraWeightFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.collectionFees,
            prevShipment?.collectionFees,
            index
          )}
        >
          {shipment.collectionFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(shipment.tax, prevShipment?.tax, index)}
        >
          {shipment.tax}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(shipment.postFees, prevShipment?.postFees, index)}
        >
          {shipment.postFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.returnFees,
            prevShipment?.returnFees,
            index
          )}
        >
          {shipment.returnFees}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.returnPiecesCount,
            prevShipment?.returnPiecesCount,
            index
          )}
        >
          {shipment.returnPiecesCount}
        </FixedTableCell>

        {shipment.lastDeliveryAgent?.id ? (
          <CellLink
            sx={compareValues(
              shipment.lastDeliveryAgent?.id,
              prevShipment?.lastDeliveryAgent?.id,
              index
            )}
            pathname={`/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`}
          >
            {shipment.lastDeliveryAgent?.name}
          </CellLink>
        ) : (
          <FixedTableCell
            sx={compareValues(
              shipment.lastDeliveryAgent?.id,
              prevShipment?.lastDeliveryAgent?.id,
              index
            )}
          ></FixedTableCell>
        )}
        {warehousing && shipmentProducts && (
          <FixedTableCell
            sx={compareValues(shipment.unpacked, prevShipment?.unpacked, index)}
          >
            <CheckCrossIcons active={shipment.unpacked} />
          </FixedTableCell>
        )}
        <FixedTableCell
          sx={compareValues(
            shipment.forwarded,
            prevShipment?.forwarded,
            index
          )}
        >
          <CheckCrossIcons active={shipment.forwarded} />
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.inWarehouse,
            prevShipment?.inWarehouse,
            index
          )}
        >
          <CheckCrossIcons active={shipment.inWarehouse} />
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.cancelled,
            prevShipment?.cancelled,
            index
          )}
        >
          <CheckCrossIcons active={shipment.cancelled} />
        </FixedTableCell>



        <FixedTableCell
          sx={compareValues(shipment.collected, prevShipment?.collected, index)}
        >
          <CheckCrossIcons active={shipment.collected} />
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.paidToCustomer,
            prevShipment?.paidToCustomer,
            index
          )}
        >
          <CheckCrossIcons active={shipment.paidToCustomer} />
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.cancellationReason?.id,
            prevShipment?.cancellationReason?.id,
            index
          )}
        >
          {shipment.cancellationReason?.name}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.adminNotes,
            prevShipment?.adminNotes,
            index
          )}
        >
          {shipment.adminNotes}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(shipment.notes, prevShipment?.notes, index)}
        >
          {shipment.notes}
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(shipment.weight, prevShipment?.weight, index)}
        >
          {shipment.weight}
        </FixedTableCell>

        <FixedTableCell
          sx={compareValues(
            shipment.paidToDeliveryAgent,
            prevShipment?.paidToDeliveryAgent,
            index
          )}
        >
          <CheckCrossIcons active={shipment.paidToDeliveryAgent} />
        </FixedTableCell>
        <FixedTableCell
          sx={compareValues(
            shipment.deliveryDate,
            prevShipment?.deliveryDate,
            index
          )}
        >
          {shipment.deliveryDate && dateFormatLL(shipment.deliveryDate)}
        </FixedTableCell>

        <CellLink
          sx={compareValues(
            shipment.warehouseSection?.id,
            prevShipment?.warehouseSection?.id,
            index
          )}
          pathname={`/admin/warehouse/${shipment?.warehouseSection?.id}`}
        >
          {shipment?.warehouseSection?.name}
        </CellLink>
        <CellLink
          sx={compareValues(
            shipment.pickup?.id,
            prevShipment?.pickup?.id,
            index
          )}
          pathname={`/admin/pickups/${shipment?.pickup?.id}`}
        >
          {shipment?.pickup?.code}
        </CellLink>
        <TableCellColor
          shipment={shipment}
        />
        <CellLink
          className={classes.shipmentTable_bodyUpdatedBy}
          pathname={`/admin/users/${updatedBy?.id}`}
        >
          {updatedBy?.username}
        </CellLink>
      </TableRow>
    );
  };

  return (
    <StyledPaper className={classes.tablePaper}>
      {historyLoading ?
        <FullScreenLoading minHeight={"100px"} /> :
        <Grid container item alignItems="flex-start" className={classes.table}>
          {shipmentsLength === 0 ? (
            <EmptyTableMessage loading={false} message={t("noShipments")} />
          ) : (
            <TableFixedHeaderWraper>
              <ShipmentsTable
                stickyHeader
                className={classes.mainTableClass}
                classes={classes}
                data={shipments ?? []}
                headCells={tableHeadCell}
                parseBodyCell={tableBody}
                sumRow={false}
              />
            </TableFixedHeaderWraper>
          )}
        </Grid>}
    </StyledPaper>
  );
};

export default ShipmentHistory;
