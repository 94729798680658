import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  AppBar,
  Button,
  Dialog,
  Icon,
  IconButton,
  Slide,
  TableBody,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  Add,
  Edit,
  Close,
  Search,
  FilterList,
  DeleteOutline,
} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "react-spring"; // web.cjs is required for IE 11 support
import { useQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { LIST_GL_ACCOUNTS, DELETE_GLACCOUNT } from "./Graphql";
import CreateGlAccount from "./CreateGlAccount";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import CellLink from "../HOC/CustomComponents/CellLink";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import Grid from '@mui/material/Unstable_Grid2';
import config from "../../config.json";

const PREFIX = "GlAccountsTree";


const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const DRAWER_WIDTH = 240;

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div sx={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GlAccountsTree(props) {
  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccount: null,
    branch: null,
  });
  const [glAccountDialog, setGlAccountDialog] = useState(false);
  const [saveGlAccountState, setSaveGlAccountState] = useState({
    create: null,
    update: null,
  });

  //****************** Start NavDrawer Search ************************//

  const theme = useTheme();

  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: props.width === "xs" ? false : true,
    bottom: props.width === "xs" ? false : true,
    right: props.width === "xs" ? false : true,
  });
  const [refetchs, setrefetch] = useState(true);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [filterLoad, setFilterLoad] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(0);
  const initSearch = () => {
    let searchParameters = {
      refetchs: true,
      page: page,
      parentId: null,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    // urlQuery["parentId"] && setValue("parentId", urlQuery["parentId"]);

    return () => { };
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param, push) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    if (push)
      return history.push({
        pathname: "/admin/finance/gl-account",
        search: "?" + queryString,
      });

    const url = history.createHref({
      pathname: "/admin/finance/gl-account",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = props.width === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    (refetchs) ? setrefetch(false) : setrefetch(true)

    setFilterLoad(false)
    let handledData = {
      name: data.name,
      code: data.code,
      branchId: data.branchId,
      typeCode: data.typeCode,
      parentId: data.parentId,
      page: 0,
    };
    if (data.code) handledData = { code: data.code, page: 0 };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    // if (Object.keys(handledData).length < 2) handledData = {};
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const openChild = (id) => {
    setSearch((prev) => ({
      parentId: id,
      refetch: !prev.refetch,
      page: 0,
    }));
    pushUrlSearch(
      {
        parentId: id,
        page: 0,
      },
      true
    );
  };

  //****************** end NavDrawer Search ************************//

  const {
    data,
    loading: searchLoad,
    refetch,
  } = useQuery(
    gql`
      ${LIST_GL_ACCOUNTS.query}
    `,
    {
      // notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetchs,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...((search.parentId || search.parentId === null) &&
            !search.name && {
            parentId: search.parentId,
          }),
          ...(search.name && { name: search.name }),
          ...(search.code && !search.name && { code: search.code }),
          ...(search.branchId &&
            !search.name && { branchId: { value: search.branchId } }),
          ...(search.typeCode && !search.name && { typeCode: search.typeCode }),
        },
      },
      onCompleted: (data) => { },
    }
  );

  const glAccountsData = data?.listGLAccounts?.data;

  const { t } = useTranslation();

  const openGlAccountDialog = () => {
    setGlAccountDialog(true);
  };
  const updateGl = (id) => {
    openGlAccountDialog();
    setSaveGlAccountState({
      create: null,
      update: id,
    });
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: page,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: page }));
  };

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => setGlAccountDialog(true),
      icon: Add,
    }
  ]

  return (
    <Root>
      <Dialog
        fullScreen
        open={glAccountDialog}
        onClose={() => setGlAccountDialog(false)}
        TransitionComponent={Transition}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setGlAccountDialog(false)}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
            <Typography color="inherit" variant="h6">
              {t("account")}
            </Typography>
          </Toolbar>
        </AppBar>
        <CreateGlAccount
          updateGlId={saveGlAccountState.update}
          createGlWithParent={saveGlAccountState.create}
          saveGlAccountResponse={(data) => {
            refetch();
            setGlAccountDialog(false);
            setSaveGlAccountState({
              create: null,
              update: null,
            });
          }}
        />
      </Dialog>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={glAccountsData?.length === 0}
        loading={searchLoad}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm} >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                  disabled={Boolean(watch("name"))}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"parentId"}
                  label={t("parentAccount")}
                  skip={searchLoad && filterLoad}
                  disabled={Boolean(watch("name"))}
                  parseData={(data) => parseData(data)}
                  query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                  variables={{
                    input: {
                      typeCode: "MAIN",
                    },
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["parentId"],
                      "glAccount"
                    )
                  }
                  defaultValue={autocompleteValues.glAccount}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  name={"branchId"}
                  label={t("currentBranch")}
                  skip={searchLoad && filterLoad}
                  control={control}
                  errors={errors}
                  disabled={Boolean(watch("name"))}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={searchLoad && filterLoad}
                  name="typeCode"
                  disabled={Boolean(watch("name"))}
                  label={t("accountType")}
                  variables={{ input: { code: "ACCOUNT_TYPE" } }}
                  onCompleted={(data) =>
                    lookupsComplete(data, urlQuery["typeCode"], "typeCode")
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "name"
          },
          {
            name: "code"
          },
          {
            name: "branch"
          },
          {
            name: "parentAccount"
          },
          {
            name: "closingAccount"
          },
          {
            name: "accountNature"
          },
          {
            name: "accountType"
          },
          {
            name: "accountClass"
          },
          {
            name: "active"
          },
          {
            name: " "
          },
          {
            name: " "
          },

        ]
        }
        tableBody={
          <TableBody>
            {glAccountsData?.map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={classes.tableBodyRow}
                >
                  {row.type.code === "SUB" ? (
                    <FixedTableCell>{row.name}</FixedTableCell>
                  ) : (
                    <CellLink onClick={() => openChild(row.id)}>
                      {row.name}
                    </CellLink>
                  )}
                  <FixedTableCell>{row.code}</FixedTableCell>
                  <CellLink
                    pathname={`/admin/branches/${row?.branch?.id}`}
                  >
                    {row?.branch?.name}
                  </CellLink>
                  <CellLink
                    onClick={() =>
                      row.parent?.id && openChild(row.parent?.id)
                    }
                  >
                    {row?.parent?.name}
                  </CellLink>
                  <FixedTableCell>
                    {row?.closingAccount?.name}
                  </FixedTableCell>
                  <FixedTableCell>{row?.nature?.name}</FixedTableCell>
                  <FixedTableCell>{row?.type?.name}</FixedTableCell>
                  <FixedTableCell>{row?.class?.name}</FixedTableCell>
                  <FixedTableCell>
                    {row?.active ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )}
                  </FixedTableCell>
                  <FixedTableCell className={classes.editIcon}>
                    <IconButton
                      color="primary"
                      onClick={() => updateGl(row.id)}
                      size="large"
                    >
                      <Edit />
                    </IconButton>
                  </FixedTableCell>

                  <FixedTableCell className={classes.editIcon}>
                    {row.deletable && <MutationWithDialog
                      mutaion={DELETE_GLACCOUNT.query}
                      icon={DeleteOutline}
                      permission={true}
                      iconTooltip={t("deleteGlAccount")}
                      dialogTitle={t("deleteGlAccount")}
                      dialogContent={t("deleteGlAccountConfirmation")}
                      mutaionProps={{ variables: { id: parseInt(row.id) } }}
                      onCompleted={() =>
                        refetch()
                      }
                      onCompleteMessage={t("categoryDeleted")}
                    />}
                  </FixedTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.glAccountsData?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.glAccountsData ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
}
