import { Container, Icon, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2';

import "./style.css";
const PREFIX = "ServiceArea";

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: theme.palette.background.default,
    "& .wpo-section-title": {
      textAlign: "center"
    },
    "& .wpo-section-title span": {
      color: theme.palette.primary.main,
      fontSize: "16px",
    },
    "& .wpo-section-title h2:before": {
      background: theme.palette.primary.main,
    },
    "& .wpo-section-title h2": {
      margin: 0,
      color: theme.palette.text.primary,
    },
    "& .wpo-service-single": {
      background: theme.palette.background.paper,
    },
    "& .wpo-service-item .wpo-service-single .wpo-service-icon": {
      background: theme.palette.primary.main,
    },
  },

  [`& .${classes.icon}`]: {
    fontFamily: "Material Icons Outlined",
    fontSize: 50,
    verticalAlign: "middle",
    color: "white",
  },
}));

const ServiceArea = (props) => {
  const { t } = useTranslation("home");

  const sections = t("home:serviceSections", { returnObjects: true });

  return (
    <Root id="service" className={clsx("wpo-service-s2", classes.root)}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <div className="wpo-section-title text-center">
              <span>{t("home:servicesSubtitle")}</span>
              <Typography variant="h2" color={"text.primary"} className="wpo-section-title h2">{t("home:servicesTitle")}</Typography>
            </div>
          </Grid>
          <Grid container>
            {sections.map((section, i) => (
              <Service
                key={i}
                icon={section.icon}
                title={section.title}
                body={section.body}
              />
            ))}
          </Grid>

        </Grid>
      </Container>
    </Root>
  );
};

export default ServiceArea;

const Service = (props) => {
  const { title, body, icon } = props;

  return (
    // <div className="col-lg-4 col-md-6 col-12 flex">
    <Grid xs={12} lg={4}>
      <div className="wpo-service-item">
        <div className="wpo-service-single">
          <div className="wpo-service-icon">
            <Icon className={classes.icon}>{icon}</Icon>
          </div>
          <Typography variant="h2" color={"text.primary"}>{title}</Typography>
          <Typography variant="body1" color={"text.primary"}>{body}</Typography>
        </div>
      </div>
    </Grid>
    // </div>
  );
};
