import React, { Fragment } from "react";
import { Toolbar, IconButton, Typography, Icon } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PrintIcon from "../Shipments/PrintIcon";
import ExportShipments from "../Shipments/ExportShipments";
import { Can } from "../HOC/CustomComponents/Secured";
import styled from "@emotion/styled";

const PREFIX = "ManifestTableToolbar";

const classes = {
  titles: `${PREFIX}-titles`,
  addIcon: `${PREFIX}-addIcon`,
};
const Root = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));

export const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { selectedProduct, addProduct, approved, barcode } = props;


  // const shipmentId = selectedShipment.map((i) => i.product.id);
  return (
    <Root>
      <Typography
        className={classes.titles}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("productsNumber")} {selectedProduct}
      </Typography>

      {/* {!addShipment && (
        <Fragment>
          <Can showException permission="shipping.shipment.print">
            <PrintComponent shipmentsId={shipmentId} />
          </Can>
          <ExportShipments filters={{ id: shipmentId }} />
        </Fragment>
      )} */}
      {addProduct ? (
        <IconButton
          aria-label="Add"
          className={classes.addIcon}
          onClick={addProduct}
          disabled={approved}
          size="large"
        >
          <AddCircleOutline />
        </IconButton>
      ) : null}
      {/* {barcode ? (
        <IconButton
          aria-label="Add"
          onClick={barcode}
          disabled={approved}
          size="large"
        >
          <Icon>qr_code_scanner</Icon>
        </IconButton>
      ) : null} */}
    </Root>
  );
};
