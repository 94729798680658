import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { COMMISSION_LIST_ID, SAVE_COMMISSION_LIST } from "./Graphql";

import { gql, useMutation, useQuery } from "@apollo/client";
import Grid from "@mui/material/Unstable_Grid2";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import DestinationsTable from "./DestinationsTable";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import MuiSwitch from "../HOC/MUI/MUIswitch";

const PREFIX = "CommissionListForm";

const classes = {
  mainForm: `${PREFIX}-mainForm`,
  paper: `${PREFIX}-paper`,
  forDeliveryAgent: `${PREFIX}-forDeliveryAgent`,
  typography: `${PREFIX}-typography`,
  main: `${PREFIX}-main`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainForm}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },

  [`& .${classes.forDeliveryAgent}`]: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0),
    boxShadow: "none",
  },

  [`& .${classes.typography}`]: {
    margin: theme.spacing(2),
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));
const CommissionListForm = (props) => {
  const { onSave, deliveryAgentName } = props;
  const [destinationsList, setDestinationsList] = useState([]);

  const [destinationIndex, setDestinationIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [destinationErrorMessage, setDestinationErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    commission: "",
    zone: "",
    subzone: "",
    commissionType: "",
    active: "",
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, setValue, setError } = useForm({
    defaultValues: {
      name: deliveryAgentName ?? "",
      active: true,
    },
  });
  const {
    control: destinationsControl,
    formState: { errors: destinationsErrors },
    handleSubmit: destinationsHandleSubmit,
    setValue: destinationsSetValue,
    watch: destinationsWatch,
    reset: destinationsReset,
  } = useForm({
    defaultValues: {
      commission: "",
      zoneId: "",
      subzoneId: "",
      commissionTypeCode: "",
      active: true,
    },
  });
  const { errors } = formState;
  const [saveCommission, { loading: saveCommissionLoading }] = useMutation(
    gql`
      ${SAVE_COMMISSION_LIST.query}
    `
  );
  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog(false);
    setDestinationErrorMessage(false);
    setDestinationIndex((prev) => ({ ...prev, update: false }));
    // destinationsReset();
  };

  const addDestinationDialog = (index) => {
    destinationsReset();
    if (index || index === 0) {
      setDestinationIndex({
        index,
        update: true,
      });
    } else {
      setDestinationIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e?.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setDestinationErrorMessage(false);
  };

  const onSubmitDestinations = (data) => {
    const newDestination = {
      commission: parseFloat(data.commission),
      zone: { id: data.zoneId, name: selectedNames.zone },
      commissionType: {
        code: data.commissionTypeCode,
        name: selectedNames.commissionType,
      },
      active: data.active,
      subzone: data.subzoneId
        ? { id: data.subzoneId, name: selectedNames.subzone }
        : null,
    };
    const updateDestinations = [...destinationsList];
    const invalidDestination = updateDestinations.some((i, index) =>
      destinationIndex.update && index === destinationIndex.index
        ? false
        : i.zone.id === newDestination.zone.id &&
        i.subzone?.id === newDestination.subzone?.id
    );

    if (invalidDestination) {
      setDestinationErrorMessage(true);
    } else {
      if (destinationIndex.update) {
        updateDestinations[destinationIndex.index] = {
          ...updateDestinations[destinationIndex.index],
          ...newDestination,
        };
        setDestinationIndex({ index: destinationIndex.index, update: false });
      } else {
        updateDestinations.push(newDestination);
        setDestinationIndex({
          index: 0,
          update: false,
        });
      }
      setDestinationsList(updateDestinations);
      handelCloseDialog();
    }
  };

  const commissionId = parseInt(props.match?.params?.id);

  const { loading: commissionLoading, data } = useQuery(
    gql`
      ${COMMISSION_LIST_ID.query}
    `,
    {
      skip: !commissionId,
      variables: { id: commissionId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const commissionData = data?.commissionList;
        const saveCommissionsParams = ["id", "code", "name", "description", "active"];
        setDestinationsList(commissionData.destinations);
        saveCommissionsParams.forEach((i) => {
          commissionData[i] && setValue(i, commissionData[i]);
        });
        setValue("active", commissionData["active"]);
      },
    }
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const destinations = destinationsList
      ? destinationsList.map((i) => {
        const destination = {
          commission: parseFloat(i.commission),
          zoneId: i.zone.id,
          commissionTypeCode: i.commissionType.code,
          active: i.active,
          ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
        };
        return destination;
      })
      : [];
    saveCommission({
      variables: {
        input: {
          ...data,
          destinations,
        },
      },
    })
      .then((data) => {
        onSave && onSave(data);
        !Boolean(onSave) &&
          pushUrl(
            props,
            `/admin/commission-list/${data?.data?.saveCommissionList?.id}`
          );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);

        console.log(graphQLErrors);
      });
  };

  const destinationForm = (
    <DestinationsForm
      handleSubmit={destinationsHandleSubmit}
      onSubmitDestination={onSubmitDestinations}
      control={destinationsControl}
      errors={destinationsErrors}
      setValue={destinationsSetValue}
      watch={destinationsWatch}
      parseData={parseData}
      onChangeNames={onChangeNames}
      destinationIndex={destinationIndex}
      destinationList={destinationsList}
      destinationErrorMessage={destinationErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedNames={setSelectedNames}
    />
  );

  return commissionLoading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.commissionList && commissionId ? (
      <NotFound />
    ) :
      (
        <Root>
          {props?.match?.path && <TitleAppBar path={props.match.path} />}

          <Dialog fullWidth maxWidth="xs" open={dialog} onClose={handelCloseDialog}>
            {destinationForm}
          </Dialog>
          <Paper
            className={clsx(classes.paper, {
              [classes.forDeliveryAgent]: onSave,
            })}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <Typography variant="h6" color={"text.primary"} className={classes.typography}>
              {t("commissionDetails")}
            </Typography> */}
            <Grid container xs={12} sm={12} justifyContent="end">
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
            </Grid>
            <Grid container spacing={2} className={classes.mainForm}>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"description"}
                  label={t("description")}
                />
              </Grid>

              <Grid xs={12} justifyContent="flex-end">
                <FormButton
                  className={classes.button}
                  disabled={saveCommissionLoading}
                >
                  {saveCommissionLoading ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>
            </Grid>
          </Paper>
          <DestinationsTable
            destinations={destinationsList}
            addDestination={addDestinationDialog}
            setDestinationsList={setDestinationsList}
          />
        </Root>
      );
};

export default CommissionListForm;

function DestinationsForm({
  handleSubmit,
  onSubmitDestination,
  control,
  errors,
  parseData,
  onChangeNames,
  destinationIndex,
  destinationList,
  setValue,
  watch,
  destinationErrorMessage,
  handelCloseDialog,
  setSelectedNames,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    zone: null,
    subzone: null,
    commissionType: null,
  });
  const [activeCommission, setActiveCommission] = useState(true);

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveCommission(active);
    setValue("active", active);
  };
  useEffect(() => {
    if (destinationIndex.update) {
      const update = destinationList[destinationIndex.index];
      setValue("commission", update.commission);
      setValue("active", update.active);
      setActiveCommission(update.active);
      setSelectedNames((prev) => ({
        ...prev,
        ...(update?.zone?.name && { zone: update?.zone?.name }),
        ...(update?.subzone?.name && { subzone: update?.subzone?.name }),
        ...(update?.commissionType?.name && {
          commissionType: update?.commissionType?.name,
        }),
      }));
      setAutocompleteValues({
        ...update,
      });
    } else {
      setValue("active", true);
    }
    return () => { };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitDestination)}>
      <DialogTitle>
        <Grid container justifyContent="space-between" sm={12}>
          <Typography variant="h6" color={"text.primary"}>{t("addDestinationArea")}</Typography>
          <FormControlLabel
            checked={activeCommission}
            control={<Switch color="primary" />}
            label={<Typography variant="body2" color={"text.primary"}>{t("active")}</Typography>}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <CustomAutocomplete
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"zoneId"}
          label={t("zone")}
          parseData={(data) => parseData(data)}
          query={LIST_ZONES_DROPDOWN.query}
          variables={{
            input: {
              active: true,
              parentId: null,
            },
          }}
          onChangeValue={(e) => {
            setValue("subzoneId", "");
            onChangeNames(e, "zone", "subzone");
          }}
          margin="normal"
          defaultValue={autocompleteValues.zone}
        />
        <CustomAutocomplete
          control={control}
          errors={errors}
          name={"subzoneId"}
          label={t("subzone")}
          parseData={(data) => parseData(data)}
          query={LIST_ZONES_DROPDOWN.query}
          skip={!watch("zoneId")}
          variables={{
            input: {
              active: true,
              parentId: watch("zoneId"),
            },
          }}
          onChangeValue={(e) => onChangeNames(e, "subzone")}
          margin="normal"
          defaultValue={autocompleteValues.subzone}
        />
        <CustomAutocomplete
          valueKey="code"
          control={control}
          errors={errors}
          name={"commissionTypeCode"}
          label={t("commissionType")}
          parseData={(data) => parseData(data)}
          query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
          variables={{
            input: { code: "SHIPPING_COMMISION_TYPE" },
          }}
          onChangeValue={(e) => onChangeNames(e, "commissionType")}
          margin="normal"
          rules={{ required: t("fieldIsRequired") }}
          defaultValue={autocompleteValues.commissionType}
        />
        <ControlMUItextField
          control={control}
          errors={errors}
          name={"commission"}
          type="number"
          label={t("commission")}
          rules={{ required: t("fieldIsRequired") }}
          margin="normal"
        />
        {destinationErrorMessage && (
          <FormHelperText error>
            {t("thisCommissionAlreadyExists")}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}
