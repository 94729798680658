import React, { useEffect } from 'react'
import CustomTab from '../../HOC/FunctionComponents/CustomTab';
import { useTranslation } from 'react-i18next';
import { Chip, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import clsx from 'clsx';
import { EnhancedTableToolbarView } from './EnhancedTableToolbar';
import { classesCollectionView } from '../CollectionStyle';
import TableFixedHeaderWraper from '../../HOC/CustomComponents/TableWithFixedHeader';
import ShipmentsTable from '../../HOC/CustomComponents/ShipmentsTable';
import MUITablePagination from '../../HOC/MUI/TablePagination/MUITablePagination';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import useConciergeRequestsCollectionDetails from '../ConciergeRequestsDetails';
import ShipmentsOperationTable from '../../HOC/CustomComponents/ShipmentsOperationTable';
import { Globals } from '../../HOC/Classes/Globals';
import useCollectionsDetails from '../CollectionDetails';
import FullScreenLoading from '../../HOC/FunctionComponents/LoadingPages/FullScreenLoading';

function Tabs(props) {
    const {
        collectionId,
        setLocalDataKey,
        collectionType,
        collectionsData,
        shipmentData,
        setShipmentData,
        conciergeRequestsData,
        setConciergeRequestsData,
        getConciergeData,
        loadingConciergeData,
        loadingShipmentData
    } = props
    const { t } = useTranslation()
    const ALLOWED_CONCIERGE = collectionType === "DLVBY" && Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
    const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")
    let tapsArray = [];
    if (ALLOWED_SHIPMENT) {
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classesCollectionView.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("shipments")}
                        <Chip color="primary" size="small" label={shipmentData.total} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <Shipment
                        setShipmentData={setShipmentData}
                        shipmentData={shipmentData}
                        collectionId={collectionId}
                        setLocalDataKey={setLocalDataKey}
                        collectionType={collectionType}
                        collectionsData={collectionsData}
                        loadingShipmentData={loadingShipmentData}
                    />,
            }
        )
    }
    if (ALLOWED_CONCIERGE) {
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classesCollectionView.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("totalConciergeRequests")}
                        <Chip color="primary" size="small" label={conciergeRequestsData.total} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <ConciergeRequests
                        collectionId={collectionId}
                        collectionType={collectionType}
                        collectionsData={collectionsData}
                        getConciergeData={getConciergeData}
                        conciergeRequestsData={conciergeRequestsData}
                        setConciergeRequestsData={setConciergeRequestsData}
                        loadingConciergeData={loadingConciergeData}
                    />
            }
        )
    }

    return (
        <CustomTab tapDetails={tapsArray} />
    )
}

export default Tabs

const Shipment = (props) => {
    const {
        setShipmentData,
        shipmentData,
        collectionId,
        setLocalDataKey,
        collectionType,
        collectionsData,
        loadingShipmentData
    } = props

    const handleChangePageShip = (event, newPage) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPageShip = (event) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const { details: collectionDetails } = useCollectionsDetails({
        type: collectionType,
        typeData: collectionsData,
    });

    return <Paper
        className={clsx(
            classesCollectionView.table,
            classesCollectionView.paper
        )}
    >
        <EnhancedTableToolbarView
            {...props}
            type="SHIPMENT"
            ids={shipmentData?.pickedShipment.map((i) => i.shipment.id)}
            selected={shipmentData?.pickedShipment.length}
            collectionId={collectionId}
            setLocalDataKey={setLocalDataKey}
            typeCode={collectionType}
        />

        {!loadingShipmentData && (
            <TableFixedHeaderWraper>
                <ShipmentsOperationTable
                    headCells={collectionDetails.tableHead}
                    parseBodyCell={collectionDetails.tableBody}
                    total={collectionDetails.tableTotal}
                    data={shipmentData?.pickedShipment}
                />
            </TableFixedHeaderWraper>
        )}
        <Grid container justifyContent="center" alignItems="center">
            {loadingShipmentData ?
                <FullScreenLoading height={"327px"} />
                : shipmentData.total === 0 && (
                    <EmptyTableMessage loading={false} />
                )}
        </Grid>
        {shipmentData.total !== 0 &&
            <MUITablePagination
                count={shipmentData.total}
                rowsPerPage={shipmentData.rowsPerPage}
                page={shipmentData.page}
                onPageChange={handleChangePageShip}
                onRowsPerPageChange={handleChangeRowsPerPageShip}
            />}

    </Paper>
}

const ConciergeRequests = (props) => {
    const {
        collectionType,
        collectionsData,
        getConciergeData,
        conciergeRequestsData,
        setConciergeRequestsData,
        loadingConciergeData,
    } = props

    useEffect(() => {
        conciergeRequestsData.noData && getConciergeData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangePageConcierge = (event, newPage) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPageConcierge = (event) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const { details: conciergeRequestsDetails } = useConciergeRequestsCollectionDetails({
        type: collectionType,
        typeData: collectionsData
    });

    return <Paper
        className={clsx(
            classesCollectionView.table,
            classesCollectionView.paper
        )}
    >
        {!loadingConciergeData && (
            <TableFixedHeaderWraper>
                <ShipmentsTable
                    data={conciergeRequestsData.pickedConciergeRequests}
                    headCells={conciergeRequestsDetails.tableHead}
                    parseBodyCell={conciergeRequestsDetails.tableBody}
                    total={conciergeRequestsDetails.tableTotal}
                    sumRow={conciergeRequestsDetails?.sum}
                    sumTableNumber={conciergeRequestsDetails?.sumTableNumber}
                />
            </TableFixedHeaderWraper>
        )}
        <Grid container justifyContent="center" alignItems="center">
            {loadingConciergeData ?
                <FullScreenLoading height={"327px"} />
                : conciergeRequestsData.total === 0 && (
                    <EmptyTableMessage loading={false} />
                )}
        </Grid>
        {conciergeRequestsData.total !== 0 &&
            <MUITablePagination
                count={conciergeRequestsData.total}
                rowsPerPage={conciergeRequestsData.rowsPerPage}
                page={conciergeRequestsData.page}
                onPageChange={handleChangePageConcierge}
                onRowsPerPageChange={handleChangeRowsPerPageConcierge}
            />}


    </Paper>
}