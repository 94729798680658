import React, { memo } from "react";
import { styled } from '@mui/material/styles';
import { TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import formatMoney from "../../../helpers/numbersDot";

const PREFIX = 'FixedTableCell';

const classes = {
  cellWidth: `${PREFIX}-cellWidth`
};

const StyledTableCell = styled(TableCell)((
  {
    theme
  }
) => ({
  [`& .${classes.cellWidth}`]: {
    whiteSpace: "normal",
    maxWidth: "200px",
    inlineSize: "max-content",
  }
}));

function performCalculation(obj, data) {
  let firstOperand = data[obj.calc.fir];
  let secondOperand = data[obj.calc.sec];
  let operator = obj.calc.opr;

  switch (operator) {
    case "+":
      return firstOperand + secondOperand;
    case "-":
      return firstOperand - secondOperand;
    case "*":
      return firstOperand * secondOperand;
    case "/":
      return firstOperand / secondOperand;
    default:
      throw new Error("Invalid operator");
  }
}

export const FixedSumTableCell = memo((props) => {

  const { allowPlaceholder = true, sumKey, data, ...restProps } = props;

  const { t } = useTranslation();


  if (data.length > 0) {
    if (sumKey.calc) {
      sumKey.value === 0 && data.map((e) => {
        sumKey.value += performCalculation(sumKey, e);
        return ""
      })
    } else {
      sumKey.value === 0 && data.map((e) => {
        sumKey.value += e[sumKey.key]
        return ""
      })
    }
  }

  return (
    <StyledTableCell {...restProps}>
      <div className={classes.cellWidth}>
        {formatMoney(sumKey.value) ?? (allowPlaceholder && t("placeholder"))}
      </div>
    </StyledTableCell>
  );
});
