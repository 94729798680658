import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import FullScreenLoading from "./LoadingPages/FullScreenLoading";
import { TbDeviceDesktopSearch } from 'react-icons/tb';
import { useTranslation } from "react-i18next";

const EmptyTableMessage = (props) => {
  const { loading, message } = props;
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "25em" }}
    >
      {loading ? (
        <FullScreenLoading />
      ) : (
        <Box textAlign="center">
          <TbDeviceDesktopSearch size={70} color="gray" />
          <Box
            component={Typography}
            variant="h6"
            marginTop="0"
            sx={{ color: "gray" }}
          >
            {message ?? t("noResult")}
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default EmptyTableMessage;
