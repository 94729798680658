export const GetStatus = (bmtApprove, bmtShipmentLength, bmrData) => {
    const bmrApproved = bmrData.every((e) => e.approved === true)
    let bmrShipment = 0

    for (let i = 0; i < bmrData.length; i++) {
        bmrShipment += bmrData[i]?.entries?.paginatorInfo?.total;
    }

    if (!bmtApprove) {
        return "preparing"
    }
    if (bmtApprove && bmrData.length === 0) {
        return "inTransit"
    }
    if (bmrShipment !== bmtShipmentLength) {
        return "receiving"
    }
    if (bmrShipment === bmtShipmentLength) {
        if (bmtApprove && !bmrApproved) {
            return "receiving"
        } else {
            return "received"
        }
    }
    return null
};
