import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";

export const EXPOERT_INVOICES = gqlb.mutation({
  operation: "exportInvoices",
  fields: [],
  variables: {
    input: {
      type: "ExportInvoicesInput",
      required: true,
    },
  },
});

export default function ExportInvoices(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { filters, openExport, setOpenExport } = props;
  const [invoiceFieldsState, setInvoiceFieldsState] = useState([]);
  const { t } = useTranslation();

  const invoiceFields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("date") },
    { name: "CUSTOMER", label: t("customer") },
    { name: "WEIGHT", label: t("weight") },
    { name: "SHIPMENT_COUNT", label: t("shipmentsNumber") },
    { name: "PIECE_COUNT", label: t("pieceCount") },
    {
      name: "DELIVERY_FEES",
      label: t("deliveryFees"),
    },
    {
      name: "POST_FEES",
      label: t("postFees"),
    },
    {
      name: "TAX",
      label: t("tax"),
    },
    {
      name: "ALL_FEES",
      label: t("value"),
    },
    {
      name: "CUSTOMERCODE",
      label: t("customerCode"),
    },
  ];

  useEffect(() => {
    if (!invoiceFields[0].id)
      invoiceFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });

    const localStorageExportInvoices = localStorage.getItem("export-invoices");
    const parsedExportInvoice = localStorageExportInvoices
      ? JSON.parse(localStorageExportInvoices)
      : null;

    const notCheckedFields = invoiceFields.filter((i) => {
      if (
        !parsedExportInvoice?.includes(i.name) &&
        parsedExportInvoice !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = invoiceFields.filter((i) => {
      if (parsedExportInvoice?.includes(i.name)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedExportInvoice?.indexOf(a.name) >
        parsedExportInvoice?.indexOf(b.name)
        ? 1
        : -1;
    });

    setInvoiceFieldsState(
      localStorageExportInvoices
        ? [...sortedFields, ...notCheckedFields]
        : invoiceFields
    );
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const fieldCount = invoiceFieldsState.length;
  const fieldSelected = invoiceFieldsState.filter((i) => i.checked).length;

  const onSelectAllClick = (e) => {
    const fileds = [...invoiceFieldsState];
    fileds.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setInvoiceFieldsState(fileds);
  };

  const onCloseDialog = () => {
    setOpenExport(false);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(invoiceFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setInvoiceFieldsState(items);
  }
  const [exportInvoices, { loading }] = useMutation(
    gql`
      ${EXPOERT_INVOICES.query}
    `,
    {
      onCompleted: (data) => {
        onCloseDialog();
        if (data.exportInvoices) {
          enqueueSnackbar(t("successExport"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          enqueueSnackbar(t("errorExport"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handelExportInvoice = () => {
    const updatedFields = invoiceFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.name);
      }
      return previous;
    }, []);
    localStorage.setItem("export-invoices", JSON.stringify(updatedFields));
    const fields = invoiceFieldsState
      .filter((i) => i.checked)
      .map((i) => ({ label: i.label, name: i.name }));
    exportInvoices({
      variables: {
        input: {
          filters,
          fields,
        },
      },
    });
  };

  return (
    <>
      {/* <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip> */}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={openExport}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("exportExcel")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading || fieldSelected === 0}
              onClick={handelExportInvoice}
            >
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {invoiceFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.name}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={invoiceFieldsState}
                            setState={setInvoiceFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;

  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };

  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};
