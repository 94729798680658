import * as gqlb from "gql-query-builder";

export const SHIPMENT_QUERY = gqlb.query({
    operation: "listShipments",
    fields: [
        {
            operation: "data",
            fields: [
                "id",
                "code",
                "date",
                "notes",
                "recipientName",
                "recipientAddress",
                "recipientMobile",
                "recipientPhone",
                "description",
                "weight",
                "price",
                "piecesCount",
                "totalAmount",
                "senderName",
                "senderPhone",
                "senderMobile",
                "senderAddress",
                "returningDueFees",
                "customerDue",
                "amount",
                "deliveredAmount",
                "collectedFees",
                "allDueFees",
                "returnFees",
                "deliveryFees",
                "postFees",
                "extraWeightFees",
                "tax",
                "deliveredOrReturnedDate",
                {
                    branch: ["id", "name"],
                },
                {
                    originBranch: ["id", "name"],
                },
                {
                    status: ["name"],
                },
                {
                    deliveryType: ["name"],
                },
                {
                    returnType: ["name", "code"],
                },
                {
                    type: ["name"],
                },
                {
                    recipientZone: ["id", "name"],
                },
                {
                    recipientSubzone: ["name"],
                },
                {
                    senderZone: ["id", "name"],
                },
                {
                    senderSubzone: ["name"],
                },
                {
                    customer: [
                        "id",
                        "name",
                        "mobile",
                        { zone: ["id", "name"] },
                        { subzone: ["name"] },
                    ],
                },
            ],
            variables: {},
        },
        {
            operation: "paginatorInfo",
            fields: ["total"],
            variables: {},
        },
    ],
    variables: {
        input: {
            type: "ListShipmentsFilterInput",
        },
    },
});
