import { Icon, IconButton, TableRow, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { DELETE_MANIFEST_ENTRIES_MUTATION, initialManifest } from "./Graphql";
import { dateFormatLL } from "../../helpers/dateFunctions";
import formatMoney from "../../helpers/numbersDot";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

const PREFIX = "useManifestDetails";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  barcode: `${PREFIX}-barcode`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },
}));
const tableBody = (localDataKey, manifestShipment) => {
  const body = [
    {
      key: "code",
      pathName: `/admin/shipments/${manifestShipment.shipment.id}`,
      tag: CellLink,
      value: manifestShipment.shipment.code,
    },
    {
      key: "customer",
      pathName: `/admin/customers/${manifestShipment?.shipment?.customer?.id}`,
      tag: CellLink,
      value: manifestShipment?.shipment?.customer?.name,
    },
    {
      key: "openable",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.openable?.code === "Y" ? (
        <Icon className={classes.iconColor}>done</Icon>
      ) : (
        <Icon color="error">close</Icon>
      ),
    },
    {
      key: "warehouseSection",
      pathName: `/admin/shipments/${manifestShipment?.warehouseSection?.id}`,
      tag: CellLink,
      value: manifestShipment?.warehouseSection?.name,
    },
    {
      key: "recipientName",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.recipientName,
    },
    {
      key: "date",
      tag: FixedTableCell,
      value: dateFormatLL(manifestShipment.shipment?.date)
    },
    {
      key: "type",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.type?.name
    },
    {
      key: "deliveredOrReturnedDate",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.deliveredOrReturnedDate
    },
    {
      key: "recipientPhone",
      tag: FixedTableCell,
      value: <><span style={{ display: "block" }} dir="ltr">
        {manifestShipment?.shipment?.recipientMobile}
      </span>
        <span dir="ltr">{manifestShipment?.shipment?.recipientPhone}</span></>
    },
    {
      key: "sender",
      tag: FixedTableCell,
      value: <>  <span style={{ display: "block" }}>
        {manifestShipment?.shipment?.senderName}
      </span>
        {!manifestShipment?.shipment?.customer?.isMobileHidden && <span dir="ltr" style={{ display: "block" }}>
          {manifestShipment?.shipment?.senderMobile}
        </span>}
        {!manifestShipment?.shipment?.customer?.isMobileHidden && <span dir="ltr">{manifestShipment?.shipment?.senderPhone}</span>}</>
    },
    {
      key: "recipient",
      tag: FixedTableCell,
      value: <> <span style={{ display: "block" }}>
        {manifestShipment?.shipment?.recipientName}
      </span>
        <span dir="ltr" style={{ display: "block" }}>
          {manifestShipment?.shipment?.recipientMobile}
        </span>
        <span dir="ltr">{manifestShipment?.shipment?.recipientPhone}</span></>
    },
    {
      key: "recipientZone",
      pathName: `/admin/zones/${manifestShipment?.shipment?.recipientZone?.id}`,
      tag: ShipmentRegion,
      value: manifestShipment?.shipment?.recipientZone?.name,
      subValue: manifestShipment?.shipment?.recipientSubzone?.name,
    },
    {
      key: "senderZone",
      pathName: `/admin/zones/${manifestShipment?.shipment?.senderZone?.id}`,
      tag: ShipmentRegion,
      value: manifestShipment?.shipment?.senderZone?.name,
      subValue: manifestShipment?.shipment?.senderSubzone?.name,
    },
    {
      key: "recipientAddress",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.recipientAddress,
    },
    {
      key: "notes",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.notes,
    },
    {
      key: "senderAddress",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.senderAddress,
    },
    {
      key: "paymentType",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.paymentType?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.deliveryType?.name ?? manifestShipment.shipment?.status?.name,
    },
    {
      key: "weight",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.weight,
    },
    {
      key: "piecesCount",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.piecesCount,
    },
    {
      key: "senderName",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.senderName,
    },
    {
      key: "description",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.description,
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.collectedAmount),
    },


    {
      key: "originBranch",
      pathName: `/admin/branches/${manifestShipment.shipment?.originBranch?.id}`,
      tag: CellLink,
      value: manifestShipment.shipment?.originBranch?.name
    },

    {
      key: "status",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.status?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.deliveryType?.name ??
        manifestShipment.shipment?.status?.name,
    },
    {
      key: "totalAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.type?.code === "RTS" ? 0 : manifestShipment?.shipment?.totalAmount),
    },
    {
      key: "deliveredAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.deliveredAmount),
    },
    {
      key: "collectedFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.collectedFees),
    },
    {
      key: "returningDueFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.returningDueFees),
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.collectedAmount),
    },
    {
      key: "price",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.price),
    },
    {
      key: "paidAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.paidAmount),
    },
    {
      key: "customerDueAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.paidAmount),
    },
    {
      key: "deliveryFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.deliveryFees),
    },
    {
      key: "postFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.postFees),
    },
    {
      key: "tax",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.tax),
    },
    {
      key: "allFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.allFees),
    },
    {
      key: "amount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.amount),
    },
    {
      key: "amounts",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.amount),
    },
    {
      key: "note",
      tag: FixedTableCell,
      value: manifestShipment?.notes,
    },
    {
      key: "deliveryDate",
      tag: FixedTableCell,
      value: manifestShipment?.deliveryDate,
    },
    {
      key: "transactions",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.transactions?.find((i) => i.returnType !== null)?.returnType?.name,
    }
    ,

    {
      key: "returnTypes",
      tag: FixedTableCell,
      value: manifestShipment?.returnType?.name,
    },
    {
      key: "cancellationReason",
      tag: FixedTableCell,
      value: manifestShipment?.cancellationReason?.name,
    },
    {
      key: "fees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.fees),
    },
    {
      key: "returnedValue",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.returnedValue),
    },
    {
      key: "returnType",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.returnType?.name,
    },
    {
      key: "barcode",
      tag: FixedTableCell,
      auto:true,
      value: <div className={classes.barcode}>
        *{manifestShipment?.shipment?.code}*
      </div>,
    },
    {
      key: "refNumber",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.refNumber,
    },

  ]
  let selected = []
  localDataKey.forEach((e) => {
    const selectBody = body.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0])
    }
  })
  return selected
}
const tableSum = (localDataKey, typeData) => {
  const sumTable = [
    {
      key: "weight",
      value: typeData?.sumEntries?.weight,

    },
    {
      key: "piecesCount",
      value: typeData?.sumEntries?.piecesCount,

    },
    {
      key: "totalAmount",
      value: typeData?.sumEntries?.totalAmount,

    },
    {
      key: "returningDueFees",
      value: typeData?.sumEntries?.returningDueFees,
    },

    {
      key: "price",
      value: typeData?.sumEntries?.price,
    },
    {
      key: "deliveredAmount",
      value: typeData?.sumEntries?.deliveredAmount,
    },
    {
      key: "pickupAmount",
      value: typeData?.sumEntries?.pickupAmount,
    },
    {
      key: "amount",
      value: typeData?.sumEntries?.amount,
    },
    {
      key: "fees",
      value: typeData?.sumEntries?.fees,
    },
    {
      key: "returnedValue",
      value: typeData?.sumEntries?.returnedValue,
    },
    // {
    //   key: "amounts",
    //   value: typeData.sumEntries,
    // },
  ]

  let selected = {}
  localDataKey.forEach((e, index) => {
    const selectBody = sumTable.filter((ele, i) => ele.key === (e === "amounts" ? "amount" : e))
    // selected.push(selectBody[0] ? selectBody[0].value : "")
    selected[selectBody[0] ? selectBody[0].key : `${index}key`] = selectBody[0] ? selectBody[0].value : " "
  })

  return selected
}
const tableHead = (localDataKey) => {
  const headCell = [
    {
      key: "paymentType",
      value: "paymentType"
    },
    {
      key: "returnTypes",
      value: "returnType",
    },
    {
      key: "returnType",
      value: "returnType",
    },
    {
      key: "returnedValue",
      value: "returnedValue",
    },
    {
      key: "cancellationReason",
      value: "reasonName",
    },
    {
      key: "fees",
      value: "feesAmount",
    },
    {
      key: "code",
      value: "code",
    },
    {
      key: "senderAddress",
      value: "senderAddress",
    },
    {
      key: "transactions",
      value: "returnType",
    },
    {
      key: "date",
      value: "date"
    },
    {
      key: "deliveredOrReturnedDate",
      value: "deliveryCanclingDate"
    },
    {
      key: "weight",
      value: "weight",
    },
    {
      key: "customer",
      value: "customer",
    },
    {
      key: "piecesCount",
      value: "pieceCount",
    },
    {
      key: "status",
      value: "shipmentStatus",
    },
    {
      key: "recipientName",
      value: "recipient",
    },
    {
      key: "recipientZone",
      value: "destination",
    },
    {
      key: "senderZone",
      value: "source",
    },
    {
      key: "originBranch",
      value: "originBranch",
    },
    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "description",
      value: "packageDescription",
    },

    {
      key: "recipientAddress",
      value: "recipientAddress",
    },
    {
      key: "senderName",
      value: "senderName",
    },
    {
      key: "recipient",
      value: "recipient",
    },
    {
      key: "recipientPhone",
      value: "recipientPhone",
    },
    {
      key: "sender",
      value: "sender",
    },
    {
      key: "notes",
      value: "notes",
    },
    {
      key: "totalAmount",
      value: "total",
    },

    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "returningDueFees",
      value: "returningDueFees",
    },
    {
      key: "deliveryType",
      value: "deliveryType",
    },
    {
      key: "collectedFees",
      value: "collectedFees",
    },
    {
      key: "deliveredAmount",
      value: "deliveredAmount",
    },
    {
      key: "commission",
      value: "commission",
    },
    {
      key: "NetAmount",
      value: "NetAmount",
    },
    {
      key: "paidAmount",
      value: "deservedAmount",
    }, {
      key: "recipientMobile",
      value: "recipientMobile",
    }
    , {
      key: "customerDueAmount",
      value: "customerDueAmount",
    }
    , {
      key: "amount",
      value: "customerDueAmount",
    },
    {
      key: "amounts",
      value: "totalPackagePrice",
    },
    {
      key: "deliveryFees",
      value: "deliveryFees",
    },
    {
      key: "warehouseSection",
      value: "wareHouse",
    },
    {
      key: "postFees",
      value: "postFees",
    },
    {
      key: "tax",
      value: "tax",
    },
    {
      key: "allFees",
      value: "returningDueFees",
    },
    {
      key: "allDueFees",
      value: "amountMustBeCollected",
    },
    {
      key: "price",
      value: "packagePrice",
    },
    {
      key: "type",
      value: "packageType",
    },
    {
      key: "openable",
      value: "packageOpen",
    },
    {
      key: "deliveryDate",
      value: "redeliveryDate",
    },
    {
      key: "note",
      value: "notes",
    },
    {
      key: "barcode",
      value: "barcode",
    },
    {
      key: "refNumber",
      value: "refNumber",
    },

  ]
  let selected = []
  localDataKey.forEach((e) => {
    const selectBody = headCell.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0].value)
    }
  })
  return selected
}

const useManifestTransferDetails = ({
  type,
  typeData,
  approvedBmr,
  edit,
  // need to delete it
  setPickedShipment: removeShipment,
  setReceivedShipment,
  referredManifestData,
  allReferredManifest,
  approved,
  refetchShipmentDataQuery
}) => {
  const { t } = useTranslation();
  const typeCode = typeData?.transactionType?.type?.code
  const localDataKey = localStorage.getItem(`shipmentManifest${typeCode}`) ? JSON.parse(localStorage.getItem(`shipmentManifest${typeCode}`)) : initialManifest[typeCode]
  const { enqueueSnackbar } = useSnackbar();

  const [pendingDelete, setPendingDelete] = useState([])
  const [deleteManifestEntries, { loading: deleteManifestEntriesLoading }] = useMutation(
    gql`
      ${DELETE_MANIFEST_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: ((data) => {
        setPendingDelete([])
        refetchShipmentDataQuery()
      })
    }
  );

  const deleteShipment = (manifestShipment) => {
    return (
      <FixedTableCell component="th" scope="row">
        <IconButton
          size="small"
          disabled={deleteManifestEntriesLoading && pendingDelete.includes(manifestShipment.shipment.id)}
          onClick={() => {
            setPendingDelete(prev => [...prev, manifestShipment.shipment.id])
            deleteManifestEntries({
              variables: {
                input: {
                  manifestId: typeData.id,
                  shipmentIds: [manifestShipment.shipment.id]
                }
              }
            }).then(() => {
              enqueueSnackbar(t("successfullyDeletedRecord"), {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            }).catch((error) => {
              enqueueSnackbar(error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            })
          }}
        >
          {deleteManifestEntriesLoading && pendingDelete.includes(manifestShipment.shipment.id) ? (
            <CustomSpinner name="ClockLoader" size={20} />
          ) : (
            <Delete />
          )}
        </IconButton>
      </FixedTableCell>
    )
  };

  const deleteReceiveShipment = (manifestShipment) => {
    // const receive = receivedShipment.shipments.find((i) => i.shipment.deletable && i.shipment.id === manifestShipment.shipment.id)
    const receive = manifestShipment?.shipment?.lastTransaction?.shipmentStatus?.code !== "BMT"
    return (
      <FixedTableCell component="th" scope="row">
        {receive ? <IconButton
          size="small"
          disabled={deleteManifestEntriesLoading && pendingDelete.includes(manifestShipment.shipment.id)}
          onClick={() => {
            setPendingDelete(prev => [...prev, manifestShipment.shipment.id])
            deleteManifestEntries({
              variables: {
                input: {
                  manifestId: referredManifestData.id,
                  shipmentIds: [manifestShipment.shipment.id]
                }
              }
            }).then(() => {
              enqueueSnackbar(t("successfullyDeletedRecord"), {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            }).catch((error) => {
              enqueueSnackbar(error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            })
          }}
        >
          {deleteManifestEntriesLoading && pendingDelete.includes(manifestShipment.shipment.id) ? (
            <CustomSpinner name="ClockLoader" size={20} />
          ) : (
            <Delete />
          )}
        </IconButton> : null}
      </FixedTableCell >
    )
  }

  const sameShipment = (manifestShipment) => {
    // const receive = receivedShipment?.shipments?.find((i) => i.shipment.id === manifestShipment.shipment.id)
    const receive = manifestShipment?.shipment?.lastTransaction?.shipmentStatus?.code !== "BMT"
    return (
      <FixedTableCell component="th" scope="row">
        {receive ? <Icon className={classes.iconColor}>check_circle_outline</Icon>
          : <Icon color="error">highlight_off</Icon>
        }
      </FixedTableCell>
    )
  };

  const sameShipmentView = (manifestShipment) => {
    // const receive = receivedShipment.find((i) => i.shipment.id === manifestShipment.shipment.id)
    const receive = manifestShipment?.shipment?.lastTransaction?.shipmentStatus?.code !== "BMT"
    return (
      <FixedTableCell component="th" scope="row">
        {receive ? <Icon className={classes.iconColor}>check_circle_outline</Icon>
          : <Icon color="error">highlight_off</Icon>
        }
      </FixedTableCell>
    )
  };

  /* --------------------------------- BTM --------------------------------- */
  const bmtTableBody = (manifestShipment, index) => {
    const canDelete = manifestShipment?.shipment?.lastTransaction?.shipmentStatus?.code !== "BMT" && !manifestShipment?.shipment?.lastTransaction?.manifest?.approved
    let table = tableBody(localDataKey, manifestShipment)
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        {(referredManifestData || allReferredManifest) && sameShipment(manifestShipment)}
        {table.map((ele, i) => {
          return ele.subValue ? <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} zone={ele.value}
            subzone={ele.subValue} /> :
            <ele.tag pathname={ele.pathName ? ele.pathName : ""} auto={ele?.auto} key={ele.key}>{ele.value}</ele.tag>
        })}

        {!(referredManifestData || allReferredManifest) && !approved && edit && deleteShipment(manifestShipment)}
        {(referredManifestData || allReferredManifest) && canDelete && edit && deleteReceiveShipment(manifestShipment)}
      </StyledTableRow>
    );
  };

  const tableHeadCell = () => {
    if (localDataKey) {
      let table = tableHead(localDataKey)
      const removed = edit ? (!(referredManifestData || allReferredManifest) && !approved) || ((referredManifestData || allReferredManifest) && referredManifestData) ? "__" : null : null
      const receive = referredManifestData || allReferredManifest ? "received" : null;
      return ["#", receive, ...table, removed]
    }
  }

  const bmtTableHead = tableHeadCell()
  const tableSumArr = () => {
    if (localDataKey) {
      let table = tableSum(localDataKey, typeData)
      const updatedTable = {
        // eslint-disable-next-line no-useless-computed-key
        ["recive"]: " ",
        ...table,
      }
      return referredManifestData ? updatedTable : table
    }
  }
  const bmtTableTotal = tableSumArr()

  /* --------------------------------- BMR --------------------------------- */
  const tableHeadCellBmr = () => {
    if (localDataKey) {
      let table = tableHead(localDataKey)
      const removed = removeShipment ? "" : null;
      const receive = referredManifestData ? "received" : null;
      return ["#", receive, ...table, removed]
    }
  }
  const bmrTableHead = tableHeadCellBmr()

  const bmrTableBody = (manifestShipment, index) => {
    let table = tableBody(localDataKey, manifestShipment)
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        {referredManifestData && sameShipmentView(manifestShipment)}
        {table.map((ele, i) => {
          return ele.subValue ? <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} zone={ele.value}
            subzone={ele.subValue} /> :
            <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key}>{ele.value}</ele.tag>
        })}

        {removeShipment && deleteShipment(manifestShipment)}
      </StyledTableRow>
    );
  };

  const tableSumArrBmr = () => {
    if (localDataKey) {
      let table = tableSum(localDataKey, typeData)
      const updatedTable = {
        // eslint-disable-next-line no-useless-computed-key
        ["recive"]: " ",
        ...table,
      }
      return referredManifestData ? updatedTable : table
    }
  }
  const bmrTableTotal = tableSumArrBmr()

  const manifestsObject = {
    BMT: {
      id: "4",
      sumTableNumber: (shipment, tableTotal) => {
      },
      customer: false,
      label: t("branchTransfareTo"),
      tableHead: bmtTableHead,
      tableBody: bmtTableBody,
      tableTotal: bmtTableTotal,
    },
    BMR: {
      id: "5",
      sumTableNumber: (shipment, tableTotal) => {
      },
      customer: false,
      label: t("branchTransfareFrom"),
      tableHead: bmrTableHead,
      tableBody: bmrTableBody,
      tableTotal: bmrTableTotal,
    },
  };

  return {
    details: manifestsObject[type],
  };
};

export default useManifestTransferDetails;
