import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import { gql, useMutation } from "@apollo/client";
import { FilterList, Print, TaskAlt, ViewColumn } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import SearchTable, { SEARCH } from "../ShipmentSearch/SearchTable";
import { RECEIVE_SHIPMENT_IN_WAREHOUSE } from "./Graphql";

import FiltersForm from "../../Hooks/shipmentListFilters";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ExportShipments from "../Shipments/ExportShipments";
import { useForm } from "react-hook-form";
import { LIST_WAREHOUSE_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import DeliveryActionForm from "../DeliveryListActions/DeliveryActionForm";
import { useMemo } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import PrintComponent from "../Shipments/PrintComponent";
import { printLink } from "../../helpers/defaultPrintList";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ShipmentColumView from "../ShipmentSearch/shipmentColumView"
import config from "../../config.json";
import { defaultQueryFields, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";

const PREFIX = "ReceiveUndeliveredShipments";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_: `${PREFIX}-shipmentTable_`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_button: `${PREFIX}-shipmentTable_button`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: theme.palette.background.appTitle,
    width: "100%",
    justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.shipmentTable_}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      right: "0",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: 0,
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: 0,
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_button}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

const drawerWidth = 240;

//*********Table Function*********

const ReceiveUndeliveredShipments = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const { t } = useTranslation();
  const user = Globals.user;

  const [keys, setKeys] = useState(localStorage.getItem("receive-undelivered-shipments") ? JSON.parse(localStorage.getItem("receive-undelivered-shipments")) : initialData["receive-undelivered-shipments"])
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };
  const [filterLoad, setFilterLoad] = useState(true);
  const [tableLoad, setTableLoad] = useState(false);

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  // const [dateValue, setDateValue] = useState();
  const [queryVariables, setQueryVariables] = useState({
    refetch: false,
    page: -1,
  });
  const [checkedIds, setCheckedIds] = useState([]);
  const [shipmentDetails, setShipmentDetails] = useState();

  const checkedIdsIsEmpty = checkedIds.length === 0;

  const resetPage = () => setPage(0);
  const queryVariablesWithInitValue = {
    ...(queryVariables && queryVariables),
  };
  const {
    refetch,
    page: pageNum,
    ...excelFilters
  } = queryVariablesWithInitValue;

  //autocompleteValues
  const [autocompleteValues, setAutocompleteValues] = useState({
    section: null
  });
  const selectDefaultValue = (data) => {
    return data;
  };
  const { handleSubmit, control, formState, setValue } = useForm();
  const { errors } = formState;

  const [dialogState, setDialogState] = useState(false);
  const [shipmentId, setShipmentId] = useState([0]);
  const [receiveShipment, { loading, client }] = useMutation(
    gql`
      ${RECEIVE_SHIPMENT_IN_WAREHOUSE.query}
    `,
    {
      onCompleted: ((data) => {
        checkedShipmentStatus.valid && setDialogStateAction(true)
      })
    }
  );
  const handelShipmentInWarehouse = (id) => {
    checkedIds.length >= 2 && setUpdateMultiple(true)
    setCheckedIds(id);
    setShipmentId(id);
    setDialogState(true);
  };
  const handleDialogClose = () => {
    setDialogState(false);
    setAutocompleteValues({
      section: null
    })
    setValue("sectionId", "")
  };

  const receiveShipmentInWarehose = (data) => {
    receiveShipment({
      variables: {
        input: {
          id: shipmentId,
          ...(data.sectionId && { sectionId: data.sectionId }),
        }
      },
    })
      .then((data) => {
        handleDialogClose();
        if (!checkedShipmentStatus.valid) {
          client.refetchQueries({
            include: [
              gql`
              ${SEARCH(defaultQueryFields(keys, adminNotesPermission, true)).query}
            `,
            ],
          });
          setShipmentId([0]);
          setCheckedIds([]);
        }
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        console.log(error);
        setShipmentId([0]);
        setCheckedIds([]);

        handleDialogClose();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/receive-undelivered-shipments",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };
  const clearCheckBox = () => setCheckedIds([]);

  const statusDataHandler = (data) => {
    return data && data.filter((i) => i.code === "DEX" || i.code === "HTR" || i.code === "RTS" || i.code === "OTD" || i.code === "DTR");
  };

  // const brifeCells = [
  //   "code",
  //   "shipmentStatus",
  //   "reason",
  //   "packageType",
  //   "date",
  //   "redeliveryDate",
  //   "attempts",
  //   "deliveryAgents",
  //   "senderName",
  //   "recipient",
  //   "destination",
  //   "deliveryType",
  //   "",
  // ];
  const shipmentList = useRef([]);

  const [receiveInWarehouse, setReceiveInWarehouse] = useState(false)
  const [branchId, setBranchId] = useState()

  const checkedShipmentStatus = useMemo(() => {
    let selectedShipments = shipmentList.current.filter(({ id }) =>
      checkedIds.includes(id)
    );

    checkedIds.length === 1 ? setShipmentDetails(selectedShipments[0]) : setShipmentDetails()

    const valid = selectedShipments.every(
      ({ status }) => status.code === "DEX"
    );
    setBranchId(selectedShipments[0]?.branch?.id)
    const validReceiveInWarehouse = selectedShipments.every(
      ({ branch }) => branch.id === selectedShipments[0]?.branch?.id
    );
    const accessBranch = selectedShipments.every(
      ({ branch }) => user.canAccessBranch(branch.id)
    );
    setReceiveInWarehouse(validReceiveInWarehouse)
    return { valid, shipments: selectedShipments, accessBranch };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedIds]);

  const updateDeliveryBody = useCallback((shipment, index) => {
    const branchId = shipment.branch.id;
    const canReceiveShipment = user.canAccessBranch(branchId);

    shipmentList.current = [...shipmentList.current, shipment];
    return (
      <FixedTableCell
        className={classes.shipmentTable_button}
        component="th"
        scope="row"
        key={index}
      >
        <Button
          color="primary"
          variant="outlined"
          size="small"

          disabled={!canReceiveShipment}
          onClick={() => {
            handelShipmentInWarehouse([shipment.id])
            setShipmentDetails(shipment)
          }}
          startIcon={<Icon>task_alt</Icon>}
        >
          {t("receive")}
        </Button>
      </FixedTableCell>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [updateMultiple, setUpdateMultiple] = useState(false);

  const [dialogStateAction, setDialogStateAction] = useState(false);
  const handleClose = () => {
    setDialogStateAction(false);
    setUpdateMultiple(false);
    setCheckedIds([]);
    setShipmentDetails();
    client.refetchQueries({
      include: [
        gql`
          ${SEARCH(defaultQueryFields(keys, adminNotesPermission, true)).query}
        `,
      ],
    });
  };


  const defaultPrint = Globals.settings.waybillCode;

  const [openPrint, setOpenPrint] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, false, checkedIds) :
      setOpenPrint(true)
  };
  const openExportDialog = () => {
    setOpenExport(true)
  };

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      if (i === "date") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent(y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      if (i === "lastTransactionDate") {
        for (const y in param[i]) {
          const lastTransaction = (y === "fromDate" ? "lastTransactionFromDate" : "lastTransactionToDate")
          queryParams.push(encodeURIComponent(lastTransaction) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      i !== "page" && i !== "refetch" && i !== "date" && i !== "lastTransactionDate" && encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const onPrint = () => {
    const params = searchParams(queryVariables);
    const domain = `${window.location.origin}`;

    window.open(`${domain}/shipment-list/print?listType=receive-undelivered-shipments&${params}`);
    // window.open(`${domain}/shipment-list/print`);
  };

  const [printListDisabled, setPrintListDisabled] = useState(true)

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "receive",
      title: "receive",
      action: () => handelShipmentInWarehouse(checkedIds),
      icon: TaskAlt,
      disabled: checkedIdsIsEmpty || !checkedShipmentStatus.accessBranch
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0
    },
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIdsIsEmpty
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: printListDisabled
    },
    {
      id: "ViewColumn",
      title: "viewColums",
      action: openColumView,
      icon: ViewColumn,
    },
  ]

  return (
    <Root>

      <Dialog
        open={dialogState}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <form onSubmit={handleSubmit(receiveShipmentInWarehose)}>
          <DialogTitle color="inherit" id="alert-dialog-title">
            {t("receiveShipmentInWarehouse")}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
              {t("receiveShipmentInWarehouseConfirmation", {
                shipment: t(checkedIds.length > 1 ? "shipments" : "shipment"),
              })}
            </DialogContentText> */}
            <CustomAutocomplete
              control={control}
              errors={errors}
              skip={!receiveInWarehouse}
              name={"sectionId"}
              label={t("wareHouse")}
              disabled={!receiveInWarehouse}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              defaultValue={autocompleteValues.section}
              variables={{
                input: {
                  branchId: branchId,
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" autoFocus disabled={loading} variant="contained" type="submit">
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={dialogStateAction}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle color="inherit">{t("updateShipmentStatus")}</DialogTitle>
        <DialogContent>
          <DeliveryActionForm
            closeDialog={handleClose}
            shipment={shipmentDetails}
            queryFields={defaultQueryFields(keys, adminNotesPermission, true)}
            shipmentsId={checkedIds}
            updateMultiple={updateMultiple}
            listShipments={checkedShipmentStatus.shipments}
            dexShipment={checkedShipmentStatus.valid}

            filterTransactionType={["RTS", "HTR"]}
          />
        </DialogContent>
      </Dialog>

      <PrintComponent shipmentsId={checkedIds} openPrint={openPrint} setOpenPrint={setOpenPrint} />
      <ExportShipments
        filters={excelFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"receive-undelivered-shipments"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />
      {/* *******Table******* */}

      {queryVariables && (
        <SearchTable
          {...props}
          onPageChange={handleChangePage}
          onChangeRows={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          queryVariables={queryVariablesWithInitValue}
          loading={(param) => setTableLoad(param)}
          updateDeliveryBody={updateDeliveryBody}
          queryFields={defaultQueryFields(keys, adminNotesPermission, true)}
          notifyOnNetworkStatusChange={false}
          setCheckedIds={(ids) => setCheckedIds(ids)}
          checkedIds={checkedIds}
          withCheckAll
          setPrintListDisabled={setPrintListDisabled}
          icons={icons}
          path={props.match.path}
          drawerState={drawerState[filterAnchor]}
          filterLoading={setFilterLoad}
          notSumShipments={true}
          keys={keys}
          filters={<FiltersForm
            {...{ onSubmitFunc, resetPage, rowsPerPage, loading: tableLoad, filterLoad: filterLoad }}
            queryVariables={(variables) =>
              setQueryVariables({
                ...variables,
                notDelivered: true
              })
            }
            // dateValue={(date) => setDateValue(date)}
            statusDataHandler={statusDataHandler}
            // initData={FILTER_INIT_DATE}
            pathname="receive-undelivered-shipments"
            filtersAllowed={[
              "date",
              "status",
              "branch",
              "originBranch",
              "customerType",
              "custm",
              "dlvAgent",
              "zoneSender",
              "subZoneSender",
              "zone",
              "subZone",
              "refNumber",
              "service",
              "deliveryType",
              "returnType",
              "packageType",
              "paymentType",
            ]}
            clearCheckBox={clearCheckBox}
          />}
        />)}

    </Root>
  );
};
export default ReceiveUndeliveredShipments;
