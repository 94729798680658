/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Toolbar,
  Icon,
  Table,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { FINANCIAL_YEAR_ID, } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import useLocalCurrency from "../../Hooks/LocalCurrency";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { dateFormatLL } from "../../helpers/dateFunctions";
import UpdateExchangeRates from "./updateExchangeRate";
const PREFIX = 'financialYearView';

const classes = {
  paper: `${PREFIX}-paper`,
  toolbarTable: `${PREFIX}-toolbarTable`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    flexGrow: 1,
  },

  [`& .${classes.toolbarTable}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  }
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const FinancialYearView = (props) => {
  const financialYear = props.match.params.id?.trim();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };
  const { currency } = useLocalCurrency();
  const [cancelDialog, setCancelDialog] = useState(false);
  const [refetchQueries, setRefetchQueries] = useState(false);
  const [exchangeRate, setExchangeRate] = useState([]);
  const handleClose = () => {
    setCancelDialog(false);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const { loading, data } = useQuery(
    gql`
      ${FINANCIAL_YEAR_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      partialRefetch: refetchQueries,
      variables: { id: parseInt(financialYear) },
    }

  );



  const user = Globals.user;
  const canUpdate = user.hasPermission('cash.financial_year.update')

  const periodList = data?.financialYear?.periods ?? [];

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/finance/financial-year/${financialYear}/edit`),
      icon: Edit,
      permission: canUpdate,
    },

  ]
  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !data ?
      <NotFound /> :
      <Root>

        {cancelDialog && <UpdateExchangeRates
          open={cancelDialog}
          handleClose={handleClose}
          exchangeRate={exchangeRate}
          setRefetchQueries={setRefetchQueries}
          onClose={handleClose}
          currency={currency}
        />}

        <Grid
          container
          justifyContent="center"
          sx={{ margin: "0", width: "100%" }}
        >
          <TitleAppBar path={props.match.path} >
            <LongMenu icons={icons} />
          </TitleAppBar>
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair title={t("code")} value={data?.financialYear?.code} />
            <KeyValuePair title={t("name")} value={data?.financialYear?.name} />
            <KeyValuePair
              title={t("active")}
              value={
                data?.financialYear?.active ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair title={t("startDate")} value={data?.financialYear?.startDate} />
            <KeyValuePair title={t("endDate")} value={data?.financialYear?.endDate} />
          </Paper>

          <Paper
            container
            sm={12}
            md={8}
            className={classes.table}
            component={Grid}
          >
            <Toolbar className={classes.toolbarTable} variant="dense">
              <Typography
                className={classes.title}
                color="inherit"
                variant="h6"
              >
                {t("periods")}
              </Typography>
            </Toolbar>
            <TableFixedHeaderWraper component={Paper}>
              {loading || periodList.length <= 0 ? (
                <EmptyTableMessage
                  loading={loading}
                  message={t("searchNoResult")}
                />
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <FixedTableCell>{t("code")}</FixedTableCell>
                      <FixedTableCell>{t("name")}</FixedTableCell>
                      <FixedTableCell>{t("startDate")}</FixedTableCell>
                      <FixedTableCell>{t("endDate")}</FixedTableCell>
                      <FixedTableCell>{t("active")}</FixedTableCell>
                      <FixedTableCell>{t("exchangeRates")}</FixedTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {periodList &&
                      (rowsPerPage > 0
                        ? periodList.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        : periodList
                      )?.map((row, index) => (
                        <TableRow hover key={index}>
                          <FixedTableCell>{row.code}</FixedTableCell>
                          <FixedTableCell>{row.name}</FixedTableCell>
                          <FixedTableCell>{dateFormatLL(row?.startDate)}</FixedTableCell>
                          <FixedTableCell>{dateFormatLL(row?.endDate)}</FixedTableCell>
                          <FixedTableCell>
                            {row?.active ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                          <FixedTableCell>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setExchangeRate(row.exchangeRates)
                                setCancelDialog(true)
                              }}

                            >
                              <Edit />
                            </IconButton>

                          </FixedTableCell>

                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableFixedHeaderWraper>
            <MUITablePagination
              count={periodList.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePage}
              onRowsPerPageChange={handleRowsPerPage}
            />
          </Paper>
        </Grid>
      </Root>
};

export default FinancialYearView;
