import { Collapse, IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import formatMoney from "../../helpers/numbersDot";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { DELETE_INVOICES_ENTRIES_MUTATION } from "./InvoicesListQuary";
import { useSnackbar } from "notistack";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { invoice } from "./InitialInvoiceData";
const PREFIX = "useInvoiceDetails";
const classes = {
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));
const tableBody = (localDataKey, invoiceShipment) => {
  const body = [
    {
      key: "code",
      pathName: `/admin/shipments/${invoiceShipment.shipment?.id}`,
      tag: CellLink,
      value: invoiceShipment.shipment.code,
    },
    {
      key: "recipientName",
      tag: FixedTableCell,
      value: invoiceShipment.shipment?.recipientName,
    },
    {
      key: "date",
      tag: FixedTableCell,
      value: dateFormatLL(invoiceShipment.shipment?.date),
    },
    {
      key: "deliveredOrReturnedDate",
      tag: FixedTableCell,
      value: invoiceShipment.shipment?.deliveredOrReturnedDate,
    },
    {
      key: "recipientPhone",
      tag: FixedTableCell,
      value: (
        <>
          <span style={{ display: "block" }} dir="ltr">
            {invoiceShipment?.shipment?.recipientMobile}
          </span>
          <span dir="ltr">{invoiceShipment?.shipment?.recipientPhone}</span>
        </>
      ),
    },
    {
      key: "sender",
      tag: FixedTableCell,
      value: (
        <>
          <span style={{ display: "block" }}>
            {invoiceShipment?.shipment?.senderName}
          </span>
          {!invoiceShipment?.shipment?.customer?.isMobileHidden && (
            <span dir="ltr" style={{ display: "block" }}>
              {invoiceShipment?.shipment?.senderMobile}
            </span>
          )}
          {!invoiceShipment?.shipment.customer.isMobileHidden && (
            <span dir="ltr">{invoiceShipment?.shipment?.senderPhone}</span>
          )}
        </>
      ),
    },
    {
      key: "senderAddress",
      tag: FixedTableCell,
      value: invoiceShipment?.shipment?.senderAddress,
    },
    {
      key: "recipient",
      tag: FixedTableCell,
      value: (
        <>
          <span style={{ display: "block" }}>
            {invoiceShipment?.shipment?.recipientName}
          </span>
          {
            <span style={{ display: "block" }} dir="ltr">
              {invoiceShipment?.shipment?.recipientPhone}
            </span>
          }
          {!invoiceShipment?.shipment.customer.isMobileHidden && (
            <span dir="ltr">{invoiceShipment?.shipment?.recipientMobile}</span>
          )}
        </>
      ),
    },
    {
      key: "recipientZone",
      pathName: `/admin/zones/${invoiceShipment?.shipment?.recipientZone?.id}`,
      tag: ShipmentRegion,
      value: invoiceShipment?.shipment?.recipientZone?.name,
      subValue: invoiceShipment?.shipment?.recipientSubzone?.name,
    },
    {
      key: "senderZone",
      pathName: `/admin/zones/${invoiceShipment?.shipment?.senderZone?.id}`,
      tag: ShipmentRegion,
      value: invoiceShipment?.shipment?.senderZone?.name,
      subValue: invoiceShipment?.shipment?.senderSubzone?.name,
    },
    {
      key: "recipientAddress",
      tag: FixedTableCell,
      value: invoiceShipment?.shipment?.recipientAddress,
    },
    {
      key: "paymentType",
      tag: FixedTableCell,
      value: invoiceShipment.shipment?.paymentType?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value:
        invoiceShipment.shipment?.deliveryType?.name ??
        invoiceShipment.shipment?.status?.name,
    },
    {
      key: "weight",
      tag: FixedTableCell,
      value: invoiceShipment.shipment?.weight,
    },
    {
      key: "piecesCount",
      tag: FixedTableCell,
      value: invoiceShipment.shipment?.piecesCount,
    },
    {
      key: "senderName",
      tag: FixedTableCell,
      value: invoiceShipment?.shipment?.senderName,
    },
    {
      key: "description",
      tag: FixedTableCell,
      value: invoiceShipment.shipment?.description,
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment?.collectedAmount),
    },

    {
      key: "originBranch",
      pathName: `/admin/branches/${invoiceShipment.shipment?.originBranch?.id}`,
      tag: CellLink,
      value: invoiceShipment.shipment?.originBranch?.name,
    },
    {
      key: "currentBranch",
      pathName: `/admin/branches/${invoiceShipment.shipment?.branch?.id}`,
      tag: CellLink,
      value: invoiceShipment.shipment?.branch?.name,
    },
    {
      key: "status",
      tag: FixedTableCell,
      value: invoiceShipment.shipment?.status?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value:
        invoiceShipment.shipment?.deliveryType?.name ??
        invoiceShipment.shipment?.status?.name,
    },
    {
      key: "totalAmount",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.shipment?.totalAmount),
    },
    {
      key: "deliveredAmount",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.shipment?.deliveredAmount),
    },
    {
      key: "collectedFees",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.shipment?.collectedFees),
    },
    {
      key: "returningDueFees",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.shipment?.returningDueFees),
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.shipment?.collectedAmount),
    },
    {
      key: "paidAmount",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.paidAmount),
    },
    {
      key: "customerDueAmount",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.paidAmount),
    },
    {
      key: "deliveryFees",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.deliveryFees),
    },
    {
      key: "postFees",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.postFees),
    },
    {
      key: "tax",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.tax),
    },
    {
      key: "allFees",
      tag: FixedTableCell,
      value: formatMoney(invoiceShipment.allFees),
    },
    {
      key: "note",
      tag: FixedTableCell,
      value: invoiceShipment?.shipment?.notes,
    },
    {
      key: "barcode",
      tag: FixedTableCell,
      auto: true,
      value: <div className={classes.barcode}>
        *{invoiceShipment?.shipment?.code}*
      </div>,
    },
  ]
  let selected = []
  localDataKey.map((e) => {
    const selectBody = body.filter((ele) => ele.key === e);
    if (selectBody.length > 0) {
      selected.push(selectBody[0]);
    }
  });
  return selected;
};

export const tablesum = (localDataKey, TotalData) => {
  const sumTable = [
    {
      key: "collectedAmount",
      value: TotalData?.collectedAmount,
    },
    {
      key: "weight",
      value: TotalData?.sumEntries.weight,
    },
    {
      key: "piecesCount",
      value: TotalData?.sumEntries.pieces,
    },
    {
      key: "totalAmount",
      value: TotalData?.totalAmount,
    },
    {
      key: "collectedFees",
      value: TotalData?.collectedFees,
    },
    {
      key: "returningDueFees",
      value: TotalData?.dueFees,
    },
    {
      key: "paidAmount",
      value: 0,
    },
    {
      key: "customerDueAmount",
      value: TotalData?.paymentAmount,
    },
    {
      key: "deliveredAmount",
      value: TotalData?.deliveredAmount,
    },
    {
      key: "deliveryFees",
      value: TotalData?.sumEntries.deliveryFees,
    },
    {
      key: "postFees",
      value: TotalData?.sumEntries.postFees,
    },
    {
      key: "tax",
      value: TotalData?.sumEntries.tax,
    },
    {
      key: "allFees",
      value: TotalData?.sumEntries.allFees,
    },
  ];
  let selected = {};
  localDataKey.map((e, index) => {
    const selectBody = sumTable.filter((ele, i) => ele.key === e);
    // selected.push(selectBody[0] ? selectBody[0].value : "")
    selected[selectBody[0] ? selectBody[0].key : `${index}key`] = selectBody[0]
      ? selectBody[0].value
      : " ";
  });

  return selected;
};

const tableHead = (localDataKey) => {
  const headCell = [
    {
      key: "paymentType",
      value: "paymentType",
    },
    {
      key: "code",
      value: "code",
    },
    {
      key: "date",
      value: "date",
    },
    {
      key: "deliveredOrReturnedDate",
      value: "deliveryCanclingDate",
    },
    {
      key: "weight",
      value: "weight",
    },
    {
      key: "piecesCount",
      value: "pieceCount",
    },
    {
      key: "status",
      value: "shipmentStatus",
    },
    {
      key: "recipientName",
      value: "recipient",
    },
    {
      key: "recipientZone",
      value: "destination",
    },
    {
      key: "senderZone",
      value: "source",
    },
    {
      key: "originBranch",
      value: "originBranch",
    },
    {
      key: "currentBranch",
      value: "currentBranch",
    },
    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "description",
      value: "packageDescription",
    },

    {
      key: "recipientAddress",
      value: "recipientAddress",
    },
    {
      key: "senderName",
      value: "senderName",
    },
    {
      key: "senderAddress",
      value: "senderAddress",
    },
    {
      key: "recipient",
      value: "recipient",
    },
    {
      key: "sender",
      value: "senderName",
    },
    {
      key: "totalAmount",
      value: "totalAmount",
    },

    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "returningDueFees",
      value: "returningDueFees",
    },
    {
      key: "deliveryType",
      value: "deliveryType",
    },
    {
      key: "collectedFees",
      value: "collectedFees",
    },
    {
      key: "deliveredAmount",
      value: "deliveredAmount",
    },
    {
      key: "commission",
      value: "commission",
    },
    {
      key: "NetAmount",
      value: "NetAmount",
    },
    {
      key: "paidAmount",
      value: "deservedAmount",
    },
    {
      key: "recipientPhone",
      value: "recipientPhone",
    },
    {
      key: "customerDueAmount",
      value: "customerDueAmount",
    },
    {
      key: "deliveryFees",
      value: "deliveryFees",
    },
    {
      key: "postFees",
      value: "postFees",
    },
    {
      key: "tax",
      value: "tax",
    },
    {
      key: "allFees",
      value: "returningDueFees",
    },
    {
      key: "note",
      value: "notes",
    },
    {
      key: "barcode",
      value: "barcode",
    },
  ]
  let selected = []
  localDataKey.map((e) => {
    const selectBody = headCell.filter((ele) => ele.key === e);
    if (selectBody.length > 0) {
      selected.push(selectBody[0].value);
    }
  });
  return selected;
};

const useInvoicesDetails = ({
  approved,
  setShipmentData: removeShipment,
  refetchShipmentDataQuery,
  TotalData,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const localDataKey = localStorage.getItem(`shipmentInvoice`)
    ? JSON.parse(localStorage.getItem(`shipmentInvoice`))
    : invoice;

  const [deleteCollectionEntries, { loading: deleteCollectionEntriesLoading }] =
    useMutation(
      gql`
        ${DELETE_INVOICES_ENTRIES_MUTATION.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
      }
    );

  const deleteShipment = (shipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || shipment.shipment?.pendingDelete}
        onClick={() => {
          removeShipment((prev) => {
            const shipments = prev.pickedShipment.map((e) => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete:
                  e.shipment.id === shipment.shipment.id
                    ? true
                    : e.shipment.pendingDelete,
              },
            }));
            return {
              ...prev,
              pickedShipment: shipments,
            };
          });
          deleteCollectionEntries({
            variables: {
              input: {
                invoiceId: TotalData.id,
                shipmentIds: [shipment.shipment.id],
              },
            },
          })
            .then(() => {
              refetchShipmentDataQuery();
              enqueueSnackbar(t("successfullyDeletedRecord"), {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            })
            .catch((error) => {
              enqueueSnackbar(error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            });
        }}
      >
        {deleteCollectionEntriesLoading && shipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  const tableBodyCell = (invoiceShipment, index) => {
    let table = tableBody(localDataKey, invoiceShipment);
    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        {table.map((ele, i) => {
          return ele.subValue ? (
            <ele.tag
              pathname={ele.pathName ? ele.pathName : ""}
              key={ele.key}
              zone={ele.value}
              subzone={ele.subValue}
            />
          ) : ele.value2 ?
            <ele.tag key={ele.key}>
              <span style={{ display: "block" }}>{ele.value}</span>
              <span>{ele.value2}</span>
            </ele.tag> : <ele.tag pathname={ele.pathName ? ele.pathName : ""} auto={ele?.auto} key={ele.key}>{ele.value}</ele.tag>
        })}
        {removeShipment && deleteShipment(invoiceShipment)}
      </StyledTableRow>
    );
  };

  const tableHeadCell = () => {
    if (localDataKey) {
      let table = tableHead(localDataKey);
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed];
    }
  };
  const TableCell = tableHeadCell();

  const tableSumArr = () => {
    if (localDataKey) {
      let table = tablesum(localDataKey, TotalData);

      return table;
    }
  };
  const tableSum = tableSumArr();

  /* --------------------------------- DLVBY --------------------------------- */

  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      k === "deliveryFees" || k === "postFees" || k === "tax"
        ? (tableTotal[k] += shipment[k] ?? 0)
        : (tableTotal[k] += shipment.shipment[k] ?? 0);
    });
  };
  /* --------------------------------- Manifests Object --------------------------------- */

  const details = {
    id: "1",
    customer: true,
    label: t("manifestDetails"),
    tableHead: TableCell,
    tableBody: tableBodyCell,
    tableTotal: tableSum,
    sumTableNumber,
    // totalTest:totalTest
  };

  return {
    details,
  };
};

export default useInvoicesDetails;
